import { createContext, PropsWithChildren } from 'react';
import { useSearchParams } from 'react-router-dom';

export const PreviewContext = createContext(false);

export default function PreviewProvider({ children }: PropsWithChildren) {
  const [searchParams] = useSearchParams();
  const paramsPreview = searchParams.get('preview');

  let preview;
  if (paramsPreview) {
    preview = paramsPreview === 'true';
    sessionStorage.setItem('preview', preview.toString());
  } else {
    const sessionPreview = sessionStorage.getItem('preview');
    preview = sessionPreview === 'true';
  }

  return (
    <PreviewContext.Provider value={preview}>
      {children}
    </PreviewContext.Provider>
  );
}
