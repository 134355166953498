import { getFieldLabel } from '@smart/components-adb/adb-required-label/helper';
import { Text, TextInput } from '@smart/react-components';
import classNames from 'classnames';
import { UseComboboxGetItemPropsOptions, useCombobox } from 'downshift';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { TypeAheadPropList, TypeAheadProps } from './TypeAhead.config';
import './TypeAhead.scss';

const BASE_CLASS = 'adb-type-ahead';
/**
 * @method filterByTitle
 * method to filter the list according to given input title value
 * @param inputValue string | unknown
 * @returns array of object
 */
const filterByTitle = (
  inputValue: string,
  listItems: TypeAheadPropList[]
): TypeAheadPropList[] | [] => {
  const inputValueLower = inputValue.toLowerCase();
  return listItems.filter(
    (item) => item.title.toLowerCase().indexOf(inputValueLower) !== -1
  );
};

/**
 * TypeAhead is a component for search suggestions and autocomplete.
 *
 * At the most basic configuration, it takes a list of items and on select callback function as a prop.
 * It can be configured so that the component itself filters the list or it invokes the onChange callback function received as a prop,
 *  which then delegates the filter to the application using it and then sends a new list to show to the component.
 * The component also has a delay prop, which should be used especially when isSelfFilter is set to false
 *  and API call is made to bring a new filtered list, every time a new search is made.
 *
 * It is built using [DownShift](https://github.com/downshift-js/downshift).
 *
 * ## Configuration
  | Parameter             |   Type   | Required | Default |                                                Description |
  | :-------------------- | :------: | :------: | :-----: | ---------------------------------------------------------: |
  | list                  |  array   |    No    |         |                                              List contents |
  | onSelect              | function |    No    |         |        callback for when an item is selected from the list |
  | onChange              | function |    No    |         |                    callback for when text input is changed |
  |                       |          |          |         |    (optional if isSelfFilter to true, mandatory otherwise) |
  | label                 |  string  |    No    |         |                                          input field Label |
  | name                  |  string  |    No    |         |                                           input field name |
  | customClass           |  string  |    No    |         |                             custom class for the component |
  | delay                 |  number  |    No    |   100   |                 delay for debouncing the onChange callback |
  | isSelfFilter          | boolean  |    No    |  true   |       when true, the component filters the list by itself. |
  |                       |          |          |         |               Set to false, if an API call gets a new list |
  | minCharsToSearch      |  number  |    No    |    0    |                               minimum characters to search |
  | errorMessage          |  string  |    No    |   ''    |                   error message to show with the component |
  | errorMessageMinLength |  string  |    No    |         | error message to show when min length condition is not met |
  |                       |          |          |         |   (Default: Enter at least ${minCharsToSearch} characters) |
  | searchKey             |  string  |    No    |   ''    |       default value of the input or currently searched key |

 *
 * ## How to use?
  ```tsx
  <TypeAhead
    list={[{title: 'demo', id: 'demo123', attributes: {"key": "item"}}]}
    onSelect={(res)=> console.log(res)}
    onChange={(res)=>console.log(res)}
    label='Search'
    name='search'
    delay={100}
    isSelfFilter={false}
    minCharsToSearch={3}
    errorMessage=""
    errorMessage="Enter minimum 3 characters."
  />
  ```
 */
export const TypeAhead = ({
  list,
  onSelect,
  onChange,
  label,
  name,
  customClass,
  delay = 100,
  isSelfFilter = true,
  minCharsToSearch = 0,
  disableMinCharsToSearch = false,
  errorMessage = '',
  errorMessageMinLength = `Enter at least ${minCharsToSearch} characters`,
  searchKey = '',
  disabled,
  schema,
}: TypeAheadProps) => {
  const listItems: TypeAheadPropList[] | [] = list ?? [];

  // local state
  const [inputItems, setInputItems] = useState<TypeAheadPropList[]>(listItems);
  const [error, setError] = useState<string>('');

  // Debounce onChange method by `delay`
  const debouncedOnChange = useMemo(
    () => debounce((val) => onChange(val), delay),
    [onChange, delay]
  );

  /**
   * @method handleChange
   * method to handle input change
   * @param inputValue string | unknown
   * @returns void
   */
  const handleChange = (
    inputValue?: string,
    selectedItem?: TypeAheadPropList | null
  ) => {
    if (selectedItem?.id === inputValue) return;

    if (
      disableMinCharsToSearch ||
      (inputValue &&
        inputValue.length >= minCharsToSearch &&
        inputValue.trim() !== '')
    ) {
      setError('');
      const filteredItems = filterByTitle(inputValue ?? '', listItems);

      // Handle input change callback
      if (
        disableMinCharsToSearch ||
        (inputValue &&
          typeof onChange === 'function' &&
          filteredItems?.length === 0)
      ) {
        debouncedOnChange(inputValue);
      }

      // self filter the list
      if (isSelfFilter) {
        setInputItems(filteredItems);
      }
    } else {
      setError(errorMessageMinLength);
    }
  };

  // useEffect to handle any error messages that may be passed after state is updated.
  useEffect(() => {
    if (searchKey) {
      setError(errorMessage);
    } else {
      setError('');
    }
  }, [errorMessage, searchKey]);

  /**
   * @method shouldShowContent
   * show content if the suggestion dropdown is open and there is no error
   * @returns boolean
   */
  const shouldShowContent = (isOpen: boolean): boolean =>
    !!(isOpen && error === '');

  /**
   * @method getContent
   * method to return type ahead suggestions list items
   * @returns JSX.Element
   */
  const getContent = (
    highlightedIndex: number,
    getItemProps: (
      options: UseComboboxGetItemPropsOptions<TypeAheadPropList>
    ) => any,
    details: Array<TypeAheadPropList>
  ): Array<JSX.Element> =>
    details?.map((item, index) => (
      <div
        className={classNames(`${BASE_CLASS}__dropdown-item`, {
          [`${BASE_CLASS}__dropdown-item--highlight`]:
            highlightedIndex === index,
        })}
        {...getItemProps({ item, index })}
        key={item.id}
      >
        <Text
          variant="p-200"
          className={classNames({
            [`${BASE_CLASS}__dropdown-item--highlight-text`]:
              highlightedIndex === index,
          })}
        >
          {item.title}
        </Text>
      </div>
    ));

  // component definitions from downshift
  const {
    isOpen,
    getItemProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
  } = useCombobox({
    items: isSelfFilter ? inputItems : listItems,
    initialInputValue: typeof searchKey === 'string' ? searchKey : '',
    onSelectedItemChange: ({ inputValue }) => {
      if (onSelect && typeof onSelect === 'function') {
        onSelect(
          inputValue ? filterByTitle(inputValue, listItems)[0] : inputItems[0]
        );
      }
    },
    onInputValueChange: ({ inputValue, selectedItem }) =>
      handleChange(inputValue, selectedItem),
    itemToString: (item: TypeAheadPropList | null) => (item ? item.title : ''),
  });

  const classes = [BASE_CLASS, customClass].join(' ');

  /**
   * @returns JSX Element
   */
  return (
    <div className={classes} data-testid="downshiftContainerScope">
      <div>
        <TextInput
          {...getInputProps({
            'aria-labelledby': undefined,
          })}
          label={getFieldLabel(schema, name, label ?? '')}
          name={name}
          disabled={disabled ?? false}
          autoComplete="auto-complete-off"
        />
        {error && <span className={`${BASE_CLASS}__error`}>{error}</span>}
        <div className={`${BASE_CLASS}__dropdown`}>
          <div {...getMenuProps()}>
            {shouldShowContent(isOpen) &&
              getContent(
                highlightedIndex,
                getItemProps,
                isSelfFilter ? inputItems : listItems
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
