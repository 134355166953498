/**
 * @type PasswordInputProps
 * prop types for generateRandomPassword function
 */
type PasswordInputProps = {
  upperCase: boolean;
  numbers: boolean;
  symbols: boolean;
  passwordLength: number;
};

/**
 * generateRandomPassword
 * @param props PasswordInputProps
 * @returns random string for password
 */
const generateRandomPassword = (props: PasswordInputProps): string => {
  const { upperCase, numbers, symbols, passwordLength } = props;
  const defaultCharacters = 'abcdefghijklmnopqrstuvwxyz';
  const characters = {
    uppercase: defaultCharacters.toUpperCase(),
    numbers: '0123456789',
    symbols: '!@#$%^&*()?><',
  };

  const characterList = [
    defaultCharacters,
    ...(upperCase ? characters.uppercase : []),
    ...(numbers ? characters.numbers : []),
    ...(symbols ? characters.symbols : []),
  ].join('');

  return Array.from({ length: passwordLength }, () =>
    Math.floor(Math.random() * characterList.length)
  )
    .map((number) => characterList[number])
    .join('');
};

/**
 * @method validatePassword
 * This method is used to validate the password to match the criteria -
 * it contains uppercase, lowercase, special character & numeric value
 */
const validatePassword = (password: string) => {
  const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]+$/;

  return regex.test(password);
};

/**
 * @method getValidRandomPassword
 * This method is used to validate the password to match the criteria -
 * it contains uppercase, lowercase, special character & numeric value
 * generate new password is the criteria is not met
 */
let newPassword = '';

export const getValidRandomPassword = () => {
  newPassword = generateRandomPassword({
    upperCase: true,
    numbers: true,
    symbols: true,
    passwordLength: 12,
  });

  if (!validatePassword(newPassword)) {
    getValidRandomPassword();
  }

  return newPassword;
};
