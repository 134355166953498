import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { Flex, Tabs, Text } from '@smart/react-components';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DefaultOpeningDateState } from '../requests/requests.config';
import TaskSearch from '../search/search';
import TaskTable from './Table/TaskTable';
import {
  allStatusTaskFilter,
  completedTasksFilter,
  newTasksFilter,
  openTasksFilter,
  TaskContextProvider,
  useTaskContext,
} from './task-context';
import './tasks.scss';
import { TabId } from './utils';

const BASE_CLASS = 'adb-tasks';

type Tab = {
  id: TabId;
  headline: string;
};

const Tasks = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const locationState = location.state as DefaultOpeningDateState;

  const [search, setSearch] = useState<string>('');
  const [count, setCount] = useState<number>(0);
  const [newTasksCount, setNewTasksCount] = useState<number>(0);
  const { refetchTasks, loading } = useTaskContext();
  const [currentTab, setCurrentTab] = useState<TabId>('new');

  const { setFilters, filters } = useTaskContext();

  const tabs: Tab[] = useMemo(
    () => [
      { id: 'new', headline: t('task.filters.new') },
      {
        id: 'all',
        headline: t('task.filters.all'),
      },
      { id: 'open', headline: t('navigation.tasks.open') },
      { id: 'completed', headline: t('task.filters.completed') },
    ],
    [t]
  );

  const getTabFilter = useCallback((id: TabId) => {
    switch (id) {
      case 'new':
        return newTasksFilter;
      case 'open':
        return openTasksFilter;
      case 'completed':
        return completedTasksFilter;
      default:
        return allStatusTaskFilter;
    }
  }, []);

  const setTab = useCallback(
    (id: TabId) => {
      setCurrentTab(id);
      setFilters((prev) => ({
        ...prev,
        ...getTabFilter(id),
      }));
    },
    [setFilters, setCurrentTab, getTabFilter]
  );

  const tasks = (
    <TaskTable
      setCount={setCount}
      setNewTasksCount={setNewTasksCount}
      setSearch={setSearch}
      search={search}
      filters={Object.values(filters).flat()}
    />
  );

  useEffect(() => {
    if (locationState?.initialTab) {
      setTab(locationState?.initialTab ?? 'all');
    }
  }, [locationState, setTab]);

  return (
    <div className={BASE_CLASS}>
      <AdbLayout.Header backgroundColor="bg-level-2">
        <TaskSearch
          refetch={refetchTasks}
          onSearchQueryUpdate={setSearch}
          rows={count}
          filters={filters}
          setFilters={setFilters}
        />

        <Flex className="adb-tasks__header-panel">
          <div className={`${BASE_CLASS}__tabs`}>
            <Tabs
              defaultValue={currentTab}
              onValueChange={(id) => setTab(id as TabId)}
              key={currentTab}
            >
              {tabs.map((filter) => (
                <Tabs.Tab
                  label={filter.headline}
                  value={filter.id}
                  key={filter.id}
                />
              ))}
            </Tabs>
          </div>

          <Flex
            justifyContent="center"
            alignItems="center"
            margin={{ left: 'auto' }}
          >
            <Text as="span" variant="p-100">
              {`${t('task.task_total_count')}: ${count} | ${t('task.task_new_count')}: ${newTasksCount}`}
            </Text>
          </Flex>
        </Flex>
      </AdbLayout.Header>
      <AdbLayout.Content>
        {loading ? <LoadingIndicator /> : tasks}
      </AdbLayout.Content>
    </div>
  );
};

const TasksProvided = () => (
  <TaskContextProvider>
    <Tasks />
  </TaskContextProvider>
);
export default TasksProvided;
