import { QueryResult } from '@apollo/client/react/types/types';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { AuthAgentIDTokenResponsePayload } from '@store/auth/types';
import { AuthTokens } from '@ui/data-models/auth/auth.model';
import { ADB_COOKIES } from '@utils/cookie/adb-cookies';
import {
  getCookie,
  removeCookie,
  setCookie,
} from '@utils/cookie/cookie-configurator';
import { getCurrentMarket } from '@utils/helpers/market';
import { MarketCode } from '@utils/market/types';
import {
  LogoutQuery,
  LogoutQueryVariables,
  useLogoutLazyQuery,
} from 'contexts/queries/auth.generated';
import { useTokensQuery } from 'guards/queries.generated';
import { generateMarketUrl } from 'guards/utils';
import { jwtDecode as jwt, jwtDecode } from 'jwt-decode';
import React, { PropsWithChildren, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface AuthCookie {
  ['access-token']: string;
  ['refresh-token']: string;
  ['id-token']: string;
  ['agent-id']: string;
}

interface AuthContextState {
  auth?: AuthCookie;
}

interface AuthContextValue extends AuthContextState {
  setAuthorization: (tokens: AuthTokens) => void;
  logout: (
    agentId: string
  ) => Promise<QueryResult<LogoutQuery, LogoutQueryVariables>>;
  clearAuthorization: () => void;
  isAuthenticated: () => boolean;
}

const AuthContext = React.createContext<AuthContextValue | undefined>(
  undefined
);

export const getAuthCookie = () => {
  const cookie = getCookie(ADB_COOKIES.TOKENS);

  if (!cookie) return undefined;

  const json = JSON.parse(cookie) as AuthCookie;
  const jwtToken: AuthAgentIDTokenResponsePayload = jwt(json['id-token']);

  json['agent-id'] = jwtToken.sub;

  return json;
};

export const getAssertedAuthCookie = () => {
  const cookie = getAuthCookie();
  if (!cookie) throw new Error('Auth cookie was null or undefined!');

  return cookie;
};

export const setAuthCookie = (tokens: AuthTokens) => {
  const tokenCookie = (getAuthCookie() ?? {}) as AuthCookie;

  const auth = {
    ...tokenCookie,
    'access-token': tokens.access_token ?? tokenCookie['access-token'],
    'refresh-token': tokens.refresh_token ?? tokenCookie['refresh-token'],
    'id-token': tokens.id_token ?? tokenCookie['id-token'],
    'agent-id': tokens.agent_id ?? tokenCookie['agent-id'],
  };

  setCookie(ADB_COOKIES.TOKENS, JSON.stringify(auth));

  return auth;
};

export const isAuthenticated = () => {
  const cookie = getAuthCookie();

  if (!cookie) return false;

  return !!cookie['access-token'];
};

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = React.useState<AuthContextState>({
    auth: (getAuthCookie() ?? {}) as AuthCookie,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const authCode = searchParams.get('code');
  const [logoutQuery] = useLogoutLazyQuery();

  const value = useMemo(() => {
    const setAuthorization = (tokens: AuthTokens) => {
      const auth = setAuthCookie(tokens);
      setState({ auth });
    };

    const clearAuthorization = () => {
      removeCookie(ADB_COOKIES.TOKENS);
      setState({ auth: undefined });
    };

    const logout = (agentId: string) =>
      logoutQuery({
        variables: {
          input: {
            agentId,
          },
        },
      });

    return {
      isAuthenticated,
      setAuthorization,
      clearAuthorization,
      logout,
      ...state,
    };
  }, [state, logoutQuery]);

  const market = getCurrentMarket(window.location.hostname) as MarketCode;

  // check if the url query params have auth code
  // get the tokens using the auth code
  const { loading } = useTokensQuery({
    variables: {
      input: {
        authCode: authCode ?? '',
        redirectUri: generateMarketUrl(market),
      },
    },
    onCompleted: (data) => {
      const agentId = jwtDecode(data.tokens.id_token).sub ?? '';
      searchParams.delete('code');
      setSearchParams(searchParams);
      value.setAuthorization({ ...data.tokens, agent_id: agentId });
    },
    onError: () => {
      searchParams.delete('code');
      setSearchParams(searchParams);
      value.clearAuthorization();

      window.location.reload();
    },
    skip: !authCode,
  });

  if (loading || authCode) return <LoadingIndicator onFullPage />;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const value = React.useContext(AuthContext);

  if (!value) throw new Error('Got undefined auth context!');

  return value;
};
