import { endOfWeek, startOfWeek } from 'date-fns';

export interface Style {
  style: {
    backgroundColor?: string;
    borderLeft?: string;
  };
  className?: string;
}

export interface DefaultOpeningAppointment {
  appointmentId: string;
  start: Date;
}

export interface Color {
  border: string;
  background: string;
  dotted?: boolean;
}

const colors: Color[] = [
  { border: '#9C27B0', background: '#F3E5F5' },
  { border: '#1976D2', background: '#E3F2FD' },
  { border: '#00897B', background: '#E0F2F1' },
  { border: '#D14900', background: '#FFF3E0' },
  { border: '#880E4F', background: '#FCE4EC' },
  { border: '#B71C1C', background: '#FFEBEE' },
  { border: '#1A237E', background: '#E8EAF6' },
  { border: '#01579B', background: '#E1F5FE' },
  { border: '#006064', background: '#E0F7FA' },
  { border: '#388E3C', background: '#E8F5E9' },
  { border: '#9E9D24', background: '#F9FBE7' },
  { border: '#F57F17', background: '#FFFDE7' },
  { border: '#D84315', background: '#FBE9E7' },
  { border: '#5D4037', background: '#EFEBE9' },
  { border: '#424242', background: '#FAFAFA' },
  { border: '#546E7A', background: '#ECEFF1' },
  { border: '#FF4081', background: '#FCE4EC' },
];

export const getColor = (i: number): Color =>
  i >= colors.length - 1 ? colors[colors.length - 1] : colors[i];

export const defaultCalendarDateRange = (selectedDate: Date): [Date, Date] => [
  startOfWeek(new Date(selectedDate), { weekStartsOn: 1 }),
  endOfWeek(new Date(selectedDate), { weekStartsOn: 1 }),
];
