import { AssetType, ExtendedCar } from '@smart/adb-shared';
import EmptyLayout from '@smart/components-adb/atoms/EmptyDataLayout/EmptyLayout';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import CarCard from '@smart/components-adb/molecules/CarCard';
import { useCustomerContext } from 'contexts/customer-context';
import { useCarsQuery } from 'graphql/queries/cars.generated';
import { enhanceError } from 'graphql/reactive-error';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PurchasedItemsSearch from '../../../pages/customer/purchased-products/Search/Search';
import SimActivation from '../../../pages/customer/purchased-products/SimStatus';
import './PurchasedItems.scss';

const BASE_CLASS = 'adb-customer-products';

const searchBasedOnText = (order: ExtendedCar, searchQuery: string): boolean =>
  Object.keys(order).some((key) => {
    const value = order[key as keyof ExtendedCar];

    if (
      value &&
      typeof value === 'string' &&
      value.toLowerCase().includes(searchQuery.toLowerCase())
    )
      return true;

    return false;
  });

export const PurchasedItems = () => {
  const { t } = useTranslation();
  const { customer } = useCustomerContext();

  const [searchText, setSearchText] = useState<string>('');

  const { data, loading } = useCarsQuery({
    variables: {
      input: {
        uuid: customer?.uuid,
        type: AssetType.SellableVehicle,
      },
    },
    skip: !customer?.uuid,
    onError: (error) => {
      enhanceError({
        error,
        label: 'Failed to fetch customer products',
        displayMessage: error.message,
      });
    },
  });

  const products = data?.getCars.products;
  const sortedCars = [...(products ?? [])]
    .sort((a, b) => Number(b.orderNumber) - Number(a.orderNumber))
    .filter((car) => searchBasedOnText(car, searchText));

  const handleSearchQuery = (textSearch?: string) => {
    setSearchText(textSearch!);
  };

  const getPurchasedProductHeader = (purchasedProduct: ExtendedCar): string => {
    const headerItems: string[] = [
      purchasedProduct.name ?? '-',
      purchasedProduct.orderNumber ?? '-',
    ];
    // Only for vechicle orders, wallbox orders doesn't have a line/lineCode
    if (purchasedProduct.line) {
      headerItems.push(
        purchasedProduct.vin
          ? purchasedProduct.vin
          : t('orders.vin_not_assigned')
      );
    }
    return headerItems.filter((key) => key).join(' / ');
  };

  return (
    <div className={BASE_CLASS}>
      <AdbLayout.Header>
        <PurchasedItemsSearch
          handleQueryUpdate={handleSearchQuery}
          rows={sortedCars?.length ?? 0}
          searchText={searchText}
        />
      </AdbLayout.Header>
      <AdbLayout.Content backgroundColor="bg-level-2">
        {!loading && sortedCars.length > 0 ? (
          <div className={`${BASE_CLASS}__container`}>
            {sortedCars.map((car) => (
              <CarCard
                key={car.vin}
                car={car}
                headerText={getPurchasedProductHeader(car)}
                saveButtonText={t('customer.purchased_products.buttons.save')}
                additionalClasses={`${BASE_CLASS}__item`}
                disableActivation
                enableAccordion
              >
                <SimActivation vin={car.vin} />
              </CarCard>
            ))}
          </div>
        ) : (
          <div className={`${BASE_CLASS}__loading`}>
            <LoadingIndicator loading={loading}>
              <EmptyLayout
                message={t('customer.purchased_products.no_products_found')}
              />
            </LoadingIndicator>
          </div>
        )}
      </AdbLayout.Content>
    </div>
  );
};
