import SmartCalendar from '@components/calendar/SmartCalendar';
import { useNavigate } from 'react-router-dom';

const Calendar = () => {
  const navigate = useNavigate();
  const calendarProps = {
    onClose: () => {
      navigate('/');
    },
  };

  return <SmartCalendar {...calendarProps} />;
};

export default Calendar;
