import { Dropdown } from '@smart/adb-shared';
import { Checkbox } from '@smart/react-components';
import { getTranslationLabel } from '@ui/library/helpers/filters';
import type { SelectOptionProps } from 'features/features.config';
import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { PrivateCommunicationInfoFormValues } from '../helper';
import { usePreferredOptionsQuery } from '../queries.generated';

interface CommunicationChannelCheckboxProps
  extends Omit<SelectOptionProps, 'value' | 'handleChange'> {
  value: Record<string, boolean>;
  onCheckedChange: (
    checked: boolean,
    id: string
  ) => Promise<void | FormikErrors<PrivateCommunicationInfoFormValues>>;
}
const CommunicationChannelCheckbox = ({
  value,
  onCheckedChange,
}: CommunicationChannelCheckboxProps) => {
  const { t } = useTranslation();

  const { data } = usePreferredOptionsQuery();

  const options = data?.preferredOptions.communicationChannel ?? [];

  return (
    <>
      {options.map((item: Dropdown) => (
        <Checkbox
          id={item.value}
          name={`communicationPreferences.${[item.value]}`}
          checked={!!value[item.value]}
          onCheckedChange={(checked: boolean) =>
            onCheckedChange(checked, `communicationPreferences.${[item.value]}`)
          }
          key={item.value}
        >
          {t(getTranslationLabel(item.label, 'communication_channel'))}
        </Checkbox>
      ))}
    </>
  );
};

export default CommunicationChannelCheckbox;
