import { ExtendedCar } from '@smart/adb-shared';
import { CalendarAppointment } from '../config';

interface SharedProps {
  appointment: CalendarAppointment;
  calendarDateRange: [Date, Date];
}

export interface AppointmentDialogProps extends SharedProps {
  demoCar?: ExtendedCar;
  isOutsideCalendar?: boolean;
}

export interface CancelDialogProps extends Omit<SharedProps, 'appointment'> {
  appointmentId: string;
  onClose: () => void;
  onCloseParent?: () => void;
  type: 'decline' | 'cancel';
}

export const TextCharacterLimit = 255;
