import { AddressInfo } from '@smart/adb-shared';
import { TypeAheadPropList } from '@smart/components-adb/molecules/TypeAhead/TypeAhead.config';
import { CONFIG_FALLBACK } from '../defaults/config';

const REGEX_ADDRESS_LABEL_EXCLUDE = /[\s\-\s]*[0-9]+\s(Addresses)$/gm;

/**
 * Get list of valid addresses that can be displayed in the type ahead component
 */
export const getAllowedAddresses = (
  addresses: AddressInfo[]
): TypeAheadPropList[] =>
  addresses
    .filter((item) =>
      item.type && CONFIG_FALLBACK.allowedResults
        ? CONFIG_FALLBACK.allowedResults.includes(item.type)
        : true
    )
    .map((item) => ({
      ...item,
      description: item.description
        ? item.description.replace(REGEX_ADDRESS_LABEL_EXCLUDE, '')
        : '',
    }))
    .map((item) => ({
      id: item.id,
      title:
        item.text && item.description
          ? `${item.text}, ${item.description}`
          : item.text || item.description,
      attributes: {
        type: item.type,
      },
    }));
