import {
  AssetType,
  ContractType,
  Dropdown,
  ExtendedCar,
  HandoverContractType,
  OrderStatus,
  OrderType,
} from '@smart/adb-shared';
import { TypeAheadPropList } from '@smart/components-adb/molecules/TypeAhead/TypeAhead.config';
import { MarketCode } from '@utils/market/types';
import { useAgentContext } from 'contexts/agent-context';
import { useMarketContext } from 'contexts/market-context';
import { useCarsQuery } from 'graphql/queries/cars.generated';
import { useAllOrdersQuery } from 'graphql/queries/orders.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutlet } from 'hooks/outlet';
import { useOutletOrderQuery } from 'pages/orders/queries.generated';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadType } from '../Documents.config';

export const useMarketSpecificContractOptions = (
  market: MarketCode,
  type?: UploadType
) => {
  const [contractOptions, setContractOptions] = useState<Dropdown[]>([]);
  const { t } = useTranslation();

  const getFilterOptions = useCallback(
    (filters: typeof HandoverContractType | typeof ContractType) => {
      const formateFilter = Object.keys(filters).map((data: string) => ({
        label: t(
          `form_fields.documents.type.${data.toLowerCase().replace(/ /g, '_')}`
        ),
        value: filters[data as keyof typeof filters] as string,
      }));
      formateFilter.unshift({ label: '', value: '' });
      return formateFilter;
    },
    [t]
  );

  useEffect(() => {
    if (type === UploadType.Outlet) {
      setContractOptions([]);
      return;
    }

    switch (market) {
      case 'gb':
        setContractOptions(getFilterOptions(HandoverContractType));
        break;
      default:
        setContractOptions(getFilterOptions(ContractType));
        break;
    }
  }, [t, market, getFilterOptions, type]);

  return contractOptions;
};

export const useAvailableOutletCars = ({
  orderNumber,
  skip,
}: {
  orderNumber?: string;
  skip?: boolean;
}) => {
  const { market } = useMarketContext();
  const { agent } = useAgentContext();
  const outlet = useCurrentOutlet();

  const { data: ordersData, loading: loadingAllOrders } = useAllOrdersQuery({
    variables: {
      input: {
        filter: {
          includeOrderTypes: [OrderType.DemoCar, OrderType.ShowroomCar],
          marketId: market,
        },
        userId: agent?.email ?? '',
        paging: {
          page: 0,
          pageSize: 1000,
        },
      },
    },
    skip: !agent?.email || skip,
    fetchPolicy: 'no-cache',
  });

  const { data: demoCarsData, loading: loadingDemoCars } = useCarsQuery({
    variables: {
      input: {
        type: AssetType.DemoVehicle,
        bpid: outlet?.bpId,
      },
    },
    skip: !outlet?.bpId || skip,
  });

  const { data: showroomCarsData, loading: loadingShowroomCars } = useCarsQuery(
    {
      variables: {
        input: {
          type: AssetType.ShowroomVehicle,
          bpid: outlet?.bpId,
        },
      },
      skip: !outlet?.bpId || skip,
    }
  );

  const { data: selectedOrder, loading: selectedOrderLoading } =
    useOutletOrderQuery({
      variables: {
        input: {
          userId: agent?.email ?? '',
          orderId: orderNumber ?? '',
        },
      },
      skip: !orderNumber,
      onError: (error) => {
        console.error(error);
        enhanceError({
          error,
          label: 'Failed to fetch selected order',
          displayMessage: error.message,
        });
      },
    });

  const value = useMemo(() => {
    const availableOrders = (ordersData?.allOrders?.orders ?? [])
      .filter(
        (order) =>
          order.status &&
          ![
            OrderStatus.HandedOver,
            OrderStatus.Cancelled,
            OrderStatus.Cancelling,
          ].includes(order.status)
      )
      .map((order) => order.code);

    const outletCars = [
      ...(demoCarsData?.getCars.products ?? []),
      ...(showroomCarsData?.getCars.products ?? []),
    ].filter(
      (car) =>
        car.orderNumber && car.vin && availableOrders.includes(car.orderNumber)
    );

    const orderNumbersAsOptions: TypeAheadPropList[] = outletCars
      .map((car) => ({
        id: car.orderNumber ?? '',
        title: car.orderNumber ?? '',
      }))
      .sort((a, b) => Number(a.id) - Number(b.id));

    return {
      outletCars,
      orderNumbersAsOptions,
      availableOrders,
      selectedOrderLoading,
      loading: loadingAllOrders || loadingDemoCars || loadingShowroomCars,
      selectedOrder: selectedOrder?.outletOrder,
      getSelectedCar: (orderNo: string) =>
        outletCars?.find((car: ExtendedCar) => car.orderNumber === orderNo),
    };
  }, [
    selectedOrder,
    selectedOrderLoading,
    ordersData,
    demoCarsData,
    showroomCarsData,
    loadingAllOrders,
    loadingDemoCars,
    loadingShowroomCars,
  ]);

  return value;
};
