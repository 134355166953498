import { IconBackground } from '@smart/components-adb/atoms/AdbIcon/AdbIcon.config';
import { Button, Link, Spinner, Text } from '@smart/react-components';
import { enhanceError } from 'graphql/reactive-error';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SimStatusContainer from './SimStatusContainer';
import {
  SimStatusDocument,
  useSimActivateMutation,
  useSimStatusQuery,
} from './queries.generated';
import { isActivatable, isErrorResponse } from './utils';

const BASE_CLASS = 'adb-customer-products';

const ActivationMessage = () => {
  const { t } = useTranslation();
  return <span>{t('customer.purchased_products.sim.activating')}</span>;
};

const ActivatableSim = ({ vin }: { vin: string }) => {
  const { t } = useTranslation();
  const [isActivating, setIsActivating] = useState(false);

  const {
    data: simData,
    startPolling,
    stopPolling,
  } = useSimStatusQuery({
    variables: { vin },
  });

  const [activateSim, { loading }] = useSimActivateMutation({
    variables: {
      vin,
    },
    refetchQueries: [
      {
        query: SimStatusDocument,
        variables: {
          vin,
        },
      },
    ],
    onError: (error) => {
      enhanceError({
        error,
        label: t('customer.purchased_products.sim.failed_to_activate_title'),
        displayMessage: error.message,
      });
    },
    awaitRefetchQueries: true,
  });

  const handleActivateSim = () => {
    setIsActivating(true);
    startPolling(5000);
    activateSim();
  };

  useEffect(() => {
    if (!simData) return;

    if (isErrorResponse(simData?.simStatus)) {
      stopPolling();
      return;
    }

    const status = simData.simStatus;
    if (!isActivatable(status.data.lifecycle, status.data.status)) {
      stopPolling();
    }
  }, [simData, stopPolling]);

  return (
    <SimStatusContainer
      icon="minus"
      iconBackground={IconBackground.WARNING}
      title={t('customer.purchased_products.sim.sim_card_not_activated')}
    >
      {isActivating && <ActivationMessage />}
      {loading && <Spinner size="md" />}
      {!isActivating && (
        <Text
          variant="cap-300"
          className={`${BASE_CLASS}__details-description`}
        >
          <Link asChild>
            <Button onClick={handleActivateSim} disabled={loading}>
              {t('customer.purchased_products.sim.activate_sim_card')}
            </Button>
          </Link>
        </Text>
      )}
    </SimStatusContainer>
  );
};

export default ActivatableSim;
