import { Button, Text } from '@smart/react-components';
import type { FacetValue } from '@ui/data-models/facet/facet.model';
import classNames from 'classnames';
import { useClickOutside } from 'hooks/useClickOutside';
import { useRef, useState } from 'react';
import '../FilterDropdown/FilterDropdown.scss';
import type { FilterDropdownMultipleProps } from './FilterDropdownMultiple.config';
import FilterItemMultiple from './FilterItemMultiple/FilterItemMultiple';

const BASE_CLASS = 'adb-filter-dropdown';

const FilterDropdownMultiple = ({
  filterName,
  filterValues,
  onSelect,
  currentFilters = [],
  disableOverflowScroll = false,
}: FilterDropdownMultipleProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const dropdownRef = useRef(null);

  const toggle = () => setOpen(!open);

  useClickOutside(dropdownRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  const filters: FacetValue[] = currentFilters;

  const addFilter = (filterItem: FacetValue): void => {
    filters.push(filterItem);
    onSelect(filters, filterItem.initialQueryValue, true);
  };

  const removeFilter = (filterItem: FacetValue): void => {
    filters.splice(
      filters.findIndex((filterValue) => filterValue.name === filterItem.name),
      1
    );
    onSelect(filters, filterItem.initialQueryValue, false);
  };

  const isFilterSelected = (filterItem: FacetValue): boolean =>
    filters.some((currentFilter) => currentFilter.name === filterItem.name);

  const onFilterSelect = (filterItem: FacetValue): void =>
    isFilterSelected(filterItem)
      ? removeFilter(filterItem)
      : addFilter(filterItem);

  return (
    <div ref={dropdownRef}>
      <Button
        variant="ghost"
        onClick={toggle}
        className={`${BASE_CLASS}__button`}
      >
        <div
          className={classNames(
            `${BASE_CLASS}__button-container`,
            `${BASE_CLASS}__button-padding`
          )}
        >
          <Text variant="lbl-100" as="p">
            {filterName}
          </Text>
          <Button.Icon
            className={`${BASE_CLASS}__button-icon`}
            icon={open ? 'chevron-compact-up' : 'chevron-compact-down'}
          />
        </div>
      </Button>
      {open && (
        <ul
          className={classNames(`${BASE_CLASS}__dropdown`, {
            [`${BASE_CLASS}__dropdown--overflow-scroll`]:
              !disableOverflowScroll,
          })}
        >
          {filterValues?.map((filterItem) => (
            <FilterItemMultiple
              filterItem={filterItem}
              key={filterItem.name}
              onFilterSelect={onFilterSelect}
              isFilterSelected={isFilterSelected}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilterDropdownMultiple;
