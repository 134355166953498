import SearchFilterPanel from '@smart/components-adb/atoms/SearchFilterPanel/SearchFilterPanel';
import { TextInput } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import { DemoCarsSearchProps } from './Search.config';

const DemoCarsSearch = ({
  initialInputValue,
  handleInputChange,
  rows,
  filters,
}: DemoCarsSearchProps) => {
  const { t } = useTranslation();

  return (
    <SearchFilterPanel
      searchBar={
        <TextInput
          type="text"
          name="search"
          value={initialInputValue}
          onChange={handleInputChange}
          label={t('outlet.showroom.demo_cars.search')}
          caption={`${rows} ${t('general.labels.results')}`}
        />
      }
      filters={filters}
      darkMode
    />
  );
};

export default DemoCarsSearch;
