import { ReactNode } from 'react';
import './FocusLayout.scss';
import FocusFooter from './footer';
import { FooterListItemProps } from './footer/FocusFooter.config';
import FocusHeader, { FocusHeaderProps } from './header';

type FocusLayoutProps = FooterListItemProps &
  FocusHeaderProps & {
    children: ReactNode;
  };

const BASE_CLASS = 'adb-layout';

const FocusLayout = ({
  children,
  navList,
  isPrevStepsDisabled,
  pathParam,
  backButtonLabel,
  backButtonRoute,
  footerFirstJSX,
  footerSecondJSX,
  nextButtonLabel,
  isNextButtonDisabled,
  nextButtonHandler,
  nextButtonRoute,
  darkMode,
  isRequiredFooterSeparator,
  isNextButtonLoading = false,
  hideDialogWarning = false,
  footerRight,
}: FocusLayoutProps) => (
  <div className={BASE_CLASS}>
    <FocusHeader
      navList={navList}
      isPrevStepsDisabled={isPrevStepsDisabled}
      pathParam={pathParam}
      darkMode={darkMode}
      hideDialogWarning={hideDialogWarning}
    />
    <div className={`${BASE_CLASS}__children`}>{children}</div>

    <FocusFooter
      backButtonLabel={backButtonLabel}
      backButtonRoute={backButtonRoute}
      footerFirstJSX={footerFirstJSX}
      footerSecondJSX={footerSecondJSX}
      isNextButtonDisabled={isNextButtonDisabled}
      nextButtonLabel={nextButtonLabel}
      nextButtonRoute={nextButtonRoute}
      nextButtonHandler={nextButtonHandler}
      darkMode={darkMode}
      isRequiredFooterSeparator={isRequiredFooterSeparator}
      isNextButtonLoading={isNextButtonLoading}
      footerRight={footerRight}
    />
  </div>
);
export default FocusLayout;
