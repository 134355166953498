import { isValidPhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { string } from 'yup';

export const MobileNumberSchema = () => {
  const { t } = useTranslation();

  return {
    mobileNumber: string()
      .required(
        `${t('form_fields.basic.mobile')} ${t(
          'general.error_messages.validations.is_required'
        )}`
      )
      .test(
        'is-valid-phone',
        `${t('form_fields.basic.mobile')} ${t(
          'general.error_messages.validations.invalid'
        )}`,
        (value) => isValidPhoneNumber(value ?? '')
      ),
  };
};
