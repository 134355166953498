import { Spinner, Text } from '@smart/react-components';
import classNames from 'classnames';
import Badge from '../../../atoms/Badge/Badge';
import { BadgeShape } from '../../../atoms/Badge/Badge.config';
import {
  CarCategoryType,
  FeatureProps,
  LengthType,
} from '../CarListItem.config';
import './Features.scss';

const BASE_CLASS = 'adb-list-features';

const Features = ({
  carItem,
  index,
  featuresCount,
  open,
  loading,
}: FeatureProps) => {
  const title =
    typeof carItem.key === 'string' ? carItem.key : (carItem.key.text ?? '-');
  const value = carItem.value ?? '-';

  return (
    <div
      className={classNames(BASE_CLASS, {
        [`${BASE_CLASS}--mobile`]: !open && featuresCount > LengthType.FOUR,
        [`${BASE_CLASS}--mobile-border`]:
          open &&
          featuresCount > LengthType.FOUR &&
          (index === LengthType.ZERO ||
            index === LengthType.FOUR ||
            index === LengthType.SIX ||
            index === LengthType.EIGHT),
      })}
    >
      {(!carItem.type || carItem.type === 'text') && (
        <>
          <div className={`${BASE_CLASS}__title-with-icon`}>
            <Text variant="cap-300" as="p" className={`${BASE_CLASS}__header`}>
              {title}
            </Text>
            {typeof carItem.key !== 'string' && carItem.key.tooltip}
          </div>
          {loading ? (
            <Spinner size="md" />
          ) : (
            <Text variant="cap-300" as="p">
              {value}
            </Text>
          )}
          {carItem.extraText && (
            <Text variant="cap-300" as="p">
              {carItem.extraText}
            </Text>
          )}
        </>
      )}
      {carItem.type === CarCategoryType.INLINETEXT && (
        <>
          <Text variant="cap-300" as="p" className={`${BASE_CLASS}__header`}>
            {title}
          </Text>
          <div className={`${BASE_CLASS}__value`}>
            <Text variant="cap-300" as="p">
              {carItem.inlineTextValue ?? ''}
            </Text>
            <Text variant="cap-300" as="p">
              {value}
            </Text>
          </div>
        </>
      )}
      {carItem.type === CarCategoryType.BADGE && (
        <>
          <Text variant="cap-300" as="p" className={`${BASE_CLASS}__header`}>
            {title}
          </Text>

          <Badge
            text={value}
            shape={BadgeShape.ROUND_SQUARE}
            theme={carItem.badgeColor}
            key={
              typeof carItem.key === 'string' ? carItem.key : carItem.key.text
            }
          />
        </>
      )}
    </div>
  );
};

export default Features;
