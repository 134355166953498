import { ListItem, UnOrderedList } from '@smart/components-adb/atoms/List/List';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button, IconButton, Logo, Text } from '@smart/react-components';
import classnames from 'classnames';
import { TestDriveNavItem } from 'pages/test-drive/config';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import './FocusHeader.scss';

const BASE_CLASS = 'adb-layout-focus';

export type FocusHeaderProps = {
  navList: TestDriveNavItem[];
  isPrevStepsDisabled?: boolean;
  pathParam?: string;
  darkMode?: boolean;
  hideDialogWarning?: boolean;
};

/**
 * FocusHeader component
 * @param TestDriveNavItem TestDriveNavItem
 * @returns <FocusHeader> ReactNode
 */

const FocusHeader = ({
  navList,
  isPrevStepsDisabled = false,
  pathParam = '',
  darkMode,
  hideDialogWarning = false,
}: FocusHeaderProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { customerId, testDriveId } = useParams();
  const navigate = useNavigate();

  const isRouteMatching = (
    status: boolean,
    routePath?: string,
    subPath?: string[]
  ): boolean => {
    // replace pathParam with customerId | testDriveId
    const replaceRouteParam = (path: string): string =>
      path.replace(`:${pathParam}`, customerId ?? testDriveId ?? '');

    // check for matching route
    const isActualRouteMatching = (path: string): boolean =>
      location?.pathname === replaceRouteParam(path);

    // check for matching subPaths
    const isSubRouteMatching = (): boolean => {
      const matchingSubPath = subPath?.find(
        (path) => location?.pathname === replaceRouteParam(path)
      );

      return typeof matchingSubPath === 'string';
    };

    // check final matching route
    return (
      (routePath && isActualRouteMatching(routePath)) ||
      (subPath && isSubRouteMatching()) ||
      status
    );
  };

  const getClassNames = (
    status: boolean,
    routePath?: string,
    subPath?: string[]
  ) =>
    classnames(
      isRouteMatching(status, routePath, subPath) &&
        `${BASE_CLASS}__header-tabs-title--selected`,
      `${BASE_CLASS}__header-tabs-title`
    );

  const getLinkTitle = (item: TestDriveNavItem) => (
    <div className={`${BASE_CLASS}__header-tabs`}>
      <Text
        className={getClassNames(item?.status, item?.path, item?.subPath)}
        variant="lbl-100"
        as="p"
      >
        0{item?.order} {t(item?.title)}
      </Text>
    </div>
  );

  const getHeaderNavLinkClassNames = (
    path: string,
    stepStatus: boolean,
    subPath?: string[]
  ) =>
    classnames(
      `${BASE_CLASS}__header-tabs`,
      isRouteMatching(false, path, subPath ?? [])
        ? `${BASE_CLASS}__header-tabs--selected`
        : {
            [`${BASE_CLASS}__header-tabs--prev-step`]:
              stepStatus && !isPrevStepsDisabled,
            [`${BASE_CLASS}__header-tabs--disable`]:
              (!stepStatus && !isPrevStepsDisabled) ||
              (stepStatus && isPrevStepsDisabled),
          }
    );

  const { registerModal, closeModal } = useModal();

  // reset test drive state into initial state
  const resetStoreHandler = () => {
    closeModal();

    navigate('/');
  };

  const openModal = () =>
    registerModal(
      <AdbDialog
        id="test-drive-cancel-dialog"
        theme="dark"
        buttons={{
          primary: {
            label: t('test_drive.dialog.button.confirm'),
            onClick: resetStoreHandler,
            isLoading: false,
          },
          secondary: {
            label: t('test_drive.dialog.button.close'),
            onClick: () => closeModal(),
          },
        }}
      >
        <AdbDialog.Header>
          <Text variant="hl-100" color="bg-level-1-inverted">
            {t('test_drive.dialog.cancel_scheduling')}
          </Text>
        </AdbDialog.Header>
        <AdbDialog.Content>
          <Text variant="cap-100" as="p" color="bg-level-1-inverted">
            {t('test_drive.dialog.close_dialog_text')}
          </Text>
        </AdbDialog.Content>
      </AdbDialog>
    );

  const handleCloseBtnClick = () =>
    hideDialogWarning ? resetStoreHandler() : openModal();

  return (
    <nav
      className={classnames(`${BASE_CLASS} dark-theme`, {
        [`${BASE_CLASS}__border-bottom`]: darkMode,
      })}
    >
      <UnOrderedList>
        <div className={`${BASE_CLASS}__logo-nav`}>
          <div className={`${BASE_CLASS}__wrap ${BASE_CLASS}__wrap--logo`}>
            <ListItem
              content={
                <NavLink
                  to="/"
                  onClick={(e) => {
                    e.preventDefault();
                    return hideDialogWarning
                      ? resetStoreHandler()
                      : openModal();
                  }}
                >
                  <Logo
                    logo="smart-logo-vertical"
                    label={t('general.labels.home')}
                  />
                </NavLink>
              }
            />
          </div>
          <div className={`${BASE_CLASS}__header-links`}>
            {navList?.map((item) => (
              <ListItem
                content={
                  item && (
                    <NavLink
                      data-testid={`completed-${item?.status}`}
                      to={item.path ? item.path : ''}
                      className={getHeaderNavLinkClassNames(
                        item?.path,
                        item?.status,
                        item?.subPath
                      )}
                    >
                      {getLinkTitle(item)}
                    </NavLink>
                  )
                }
                key={item.order}
              />
            ))}
          </div>
        </div>
        <div className={`${BASE_CLASS}__wrap ${BASE_CLASS}__wrap--icon`}>
          <ListItem
            content={
              <div className={`${BASE_CLASS}__x-wrap`}>
                <IconButton
                  aria-label="Close"
                  mode={200}
                  variant="ghost"
                  onClick={handleCloseBtnClick}
                >
                  <Button.Icon
                    icon="close-without-circle"
                    aria-label="close-without-circle"
                  />
                </IconButton>
              </div>
            }
            additionalClasses={`${BASE_CLASS}__icon-wrap`}
          />
        </div>
      </UnOrderedList>
    </nav>
  );
};

export default FocusHeader;
