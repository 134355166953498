import { useTranslation } from 'react-i18next';
import { number } from 'yup';

export const ReturnMileageSchema = (required = false) => {
  const { t } = useTranslation();

  return {
    returnMileage: required
      ? number()
          .typeError(
            `${t('general.error_messages.validations.invalid')} ${t(
              'form_fields.document_test_drive_contract.mileage'
            )} `
          )
          .required(
            `${t('form_fields.document_test_drive_contract.mileage')} ${t(
              'general.error_messages.validations.is_required'
            )}`
          )
      : number().typeError(
          `${t('general.error_messages.validations.invalid')} ${t(
            'form_fields.document_test_drive_contract.mileage'
          )} `
        ),
  };
};
