import { GigyaAgent } from '@smart/adb-shared';
import { ColumnFiltersState } from '@tanstack/react-table';
import { FacetValue } from '@ui/data-models/facet/facet.model';
import { TFunction } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { string } from 'yup';
import {
  ExpertFilter,
  FilterType,
  TaskFilterPriority,
  TaskFilterType,
} from '../config';
import {
  createdDate,
  taskExpert,
  taskStatus,
  taskStep,
  taskType,
} from '../tasks/Table/tasktable.config';

export type TaskSearchProps<T> = {
  refetch?: () => Promise<T | void>;
  onSearchQueryUpdate: (
    freeTextSearch: string,
    filterQuery?: TaskFilterPriority | TaskFilterType
  ) => void;
  filters?: Record<FilterType, ColumnFiltersState>;
  setFilters?: Dispatch<SetStateAction<Record<FilterType, ColumnFiltersState>>>;
  rows: number;
  currentFilter?: TaskFilterPriority | TaskFilterType;
};

export const getFormattedFiltersWithTranslation = (
  filterArr: FacetValue[],
  t: TFunction
): FacetValue[] =>
  filterArr.map((item) => ({
    ...item,
    displayName: t(`task.filters.${item.displayName}`),
    label: t(`task.${item.label}`),
  }));

export const filterIdMap: Record<FilterType, string> = {
  [FilterType.TASK]: taskType,
  [FilterType.EXPERT]: taskExpert,
  [FilterType.STATUS]: taskStatus,
  [FilterType.STEP]: taskStep,
  [FilterType.NEW]: createdDate,
};

export const getFilterValue = (
  filterType: FilterType,
  filter: FacetValue,
  agent?: GigyaAgent
) => {
  switch (filterIdMap[filterType]) {
    case taskExpert:
      if (filter.queryValue === ExpertFilter.YOU) return agent?.id ?? '-';
      return '-';
    default:
      return filter.queryValue;
  }
};

export const validateEmail = (email: string) =>
  string().email().isValidSync(email);
