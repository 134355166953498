import { GigyaAgent } from '@smart/adb-shared';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import React, { PropsWithChildren, useMemo } from 'react';
import { useAuthContext } from './auth-context';
import { useOutletAgentQuery } from './queries/agent.generated';

interface AgentContextState {
  agent: GigyaAgent;
}

export const AgentContext = React.createContext<AgentContextState | undefined>(
  undefined
);

export const AgentContextProvider = ({ children }: PropsWithChildren) => {
  const { auth } = useAuthContext();

  const { data, loading: agentIsLoading } = useOutletAgentQuery({
    variables: {
      agentId: auth?.['agent-id'] ?? '',
    },
    skip: !auth?.['agent-id'],
  });

  const value = useMemo(
    () => ({
      agent: data?.outletAgent,
    }),
    [data?.outletAgent]
  );

  if (agentIsLoading || !value) return <LoadingIndicator onFullPage />;

  return (
    <AgentContext.Provider value={value as AgentContextState}>
      {children}
    </AgentContext.Provider>
  );
};

export const useAgentContext = () => {
  const value = React.useContext(AgentContext);

  if (!value) throw new Error('Got undefined agent context!');

  return value;
};
