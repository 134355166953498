import { ApolloQueryResult } from '@apollo/client';
import {
  Customer,
  OrderSummary,
  OrderType,
  Pagination,
} from '@smart/adb-shared';
import { FacetValue } from '@ui/data-models/facet/facet.model';
import { useAgentContext } from 'contexts/agent-context';
import { useMarketContext } from 'contexts/market-context';
import {
  AllOrdersQuery,
  useAllOrdersQuery,
} from 'graphql/queries/orders.generated';
import OrderFilter from 'pages/orders/order-filter';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

const emptyPagination = {
  currentPage: 0,
  pageSize: 0,
  totalPages: 0,
  totalResults: 0,
};

interface OrdersContextProps extends PropsWithChildren {
  customer?: Customer;
  includeOrderTypes?: OrderType[];
}

interface OrdersContextValue {
  allOrders: OrderSummary[];
  refetchOrders: () => Promise<ApolloQueryResult<AllOrdersQuery> | void>;
  loading: boolean;
  pagination: Pagination;
  handleQueryUpdate: (
    sort: string,
    filter: FacetValue[],
    textSearch: string | undefined
  ) => void;
}

const OrdersContext = React.createContext<OrdersContextValue>({
  allOrders: [],
  loading: true,
  refetchOrders: () => Promise.resolve(),
  pagination: emptyPagination,
  handleQueryUpdate: () => {},
});

export const OrdersContextProvider = ({
  children,
  customer,
  includeOrderTypes,
}: OrdersContextProps) => {
  const { agent } = useAgentContext();
  const [params] = useSearchParams();
  const { market } = useMarketContext();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterQueries, setFilterQueries] = useState<FacetValue[]>([]);
  const [sortQuery, setSortQuery] = useState<string>('');

  const page = Number(params.get('page') ?? 1);
  const pageSize = Number(params.get('size') ?? 300);

  const { data, loading, refetch } = useAllOrdersQuery({
    variables: {
      input: {
        filter: {
          includeOrderTypes,
          marketId: market,
          ...(customer?.userId && {
            customerId: customer.userId,
          }),
        },
        userId: agent?.email ?? '',
        paging: {
          page: page - 1,
          pageSize,
        },
      },
    },
    skip: !agent?.email,
    fetchPolicy: 'no-cache',
  });

  const orderFilter = new OrderFilter(filterQueries);
  const selectedFilteredOrders = orderFilter.findOrders(
    data?.allOrders.orders,
    sortQuery,
    searchQuery
  );

  const pagination = data?.allOrders.pagination ?? emptyPagination;

  const [localPagination, setLocalPagination] = useState({
    totalPages: pagination.totalPages ?? 0,
    totalResults: pagination.totalResults ?? 0,
  });

  useEffect(() => {
    if (
      pagination.totalPages !== localPagination.totalPages ||
      pagination.totalResults !== localPagination.totalResults
    ) {
      setLocalPagination({
        totalPages: pagination.totalPages ?? 0,
        totalResults: pagination.totalResults ?? 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  // Function to store the current query
  const handleQueryUpdate = useCallback(
    (
      sort: string,
      filter: FacetValue[],
      textSearch: string | undefined
    ): void => {
      setSortQuery(sort);
      setFilterQueries(filter);
      setSearchQuery(textSearch ?? '');
    },
    []
  );

  const value = useMemo(
    () => ({
      allOrders: selectedFilteredOrders,
      refetchOrders: refetch,
      loading,
      pagination: localPagination,
      handleQueryUpdate,
    }),
    [
      selectedFilteredOrders,
      refetch,
      loading,
      localPagination,
      handleQueryUpdate,
    ]
  );

  return (
    <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>
  );
};

export const useOrdersContext = () => {
  const context = useContext(OrdersContext);
  if (!context) {
    throw new Error('useOrdersContext must be used within a OrdersContext');
  }
  return context;
};
