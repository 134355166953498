import { TypeAhead } from '@smart/components-adb/molecules/TypeAhead/TypeAhead';
import { LABELS_FALLBACK } from '../defaults/labels';
import type { ValidatorFormProps } from './ValidatorForm.config';

const ValidatorForm = ({
  onChange,
  onSelect,
  inputLabel,
  searchKey,
  listItems,
  hintMessage,
  minimumLengthErrorMessageLabel,
}: ValidatorFormProps) => (
  <TypeAhead
    list={listItems}
    onChange={onChange}
    onSelect={onSelect}
    label={inputLabel ?? LABELS_FALLBACK.input}
    searchKey={searchKey}
    errorMessage={hintMessage}
    errorMessageMinLength={
      minimumLengthErrorMessageLabel ?? LABELS_FALLBACK.errorMessageMinLength
    }
    isSelfFilter={false}
    minCharsToSearch={3}
    delay={1000}
  />
);

export default ValidatorForm;
