import { Button } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import { BASE_CLASS } from '../config';
import CalendarAccordion from './CalendarAccordion/CalendarAccordion';
import { SidebarProps } from './Sidebar.config';
import './Sidebar.scss';

const BASE_CLASS_SIDEBAR = `${BASE_CLASS}__sidebar`;

const Sidebar = ({ onCreateAppointment }: SidebarProps) => {
  const { t } = useTranslation();

  return (
    <div className={BASE_CLASS_SIDEBAR}>
      <div className={`${BASE_CLASS_SIDEBAR}-new-appointment`}>
        <Button
          mode={200}
          type="button"
          variant="primary"
          onClick={onCreateAppointment}
        >
          <Button.Icon icon="plus" />
          {t('feature_calendar.general.appointment')}
        </Button>
      </div>
      <CalendarAccordion />
    </div>
  );
};

export default Sidebar;
