import SimActivation from 'pages/customer/purchased-products/SimStatus';
import { useTranslation } from 'react-i18next';
import AdbDialog from '../AdbDialog/AdbDialog';
import CarCard from '../CarCard';
import { CarActivationModalProps } from './config';

const BASE_CLASS = 'adb-car-activation-modal';

const CarActivationModal = ({ car, customer }: CarActivationModalProps) => {
  const { t } = useTranslation();

  const defaultName = 'No car name found';

  return (
    <AdbDialog id={BASE_CLASS} size="large">
      <AdbDialog.Content>
        <CarCard
          car={car}
          headerText={car.name ?? defaultName}
          saveButtonText={t('customer.details.buttons.save')}
          uuid={customer.uuid ?? ''}
          disableActivation
        >
          <SimActivation vin={car.vin} />
        </CarCard>
      </AdbDialog.Content>
    </AdbDialog>
  );
};

export default CarActivationModal;
