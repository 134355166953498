import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { Modal, useModal } from '../Modal';
import './CaseModal.scss';
import Header from './Header/Header';
import Message from './Message/Message';
import { useAllEmailsQuery } from './queries.generated';

const CaseModal = ({
  caseId,
  customerId,
}: {
  caseId: string;
  customerId: string;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const BASE_CLASS = 'adb-case-modal';

  const { data, loading, error } = useAllEmailsQuery({
    variables: {
      input: {
        uuid: customerId ?? '',
        caseId,
      },
    },
    skip: !customerId,
  });

  const emails = data?.allEmails.emails ?? [];
  const caseHasEmails = emails.length > 0;

  return (
    <Modal.Window id={caseId}>
      {loading && (
        <LoadingIndicator additionalClasses={`${BASE_CLASS}__loader`} />
      )}
      {error && (
        <>
          <Header title={error.message} closeHistory={closeModal} />
          <div className={`${BASE_CLASS}__container`}>
            <div className={`${BASE_CLASS}__container--fill`} />
            {error?.message}
            <div className={`${BASE_CLASS}__container--fill`} />
          </div>
        </>
      )}
      {!loading && !error && (
        <>
          <Header
            title={caseHasEmails ? emails[0].subject : undefined}
            closeHistory={closeModal}
          />
          <div className={`${BASE_CLASS}__container`}>
            <div className={`${BASE_CLASS}__container--fill`} />
            {caseHasEmails ? (
              emails?.map((email) => (
                <Message
                  key={`${email.date}${email.receipient}${email.body.substring(
                    5,
                    10
                  )}`}
                  {...email}
                />
              ))
            ) : (
              <p>{t('customer.activity.cec_no_emails')}</p>
            )}
          </div>
        </>
      )}
    </Modal.Window>
  );
};

export default CaseModal;
