import { AmountOfCarsSchema } from '@ui/validations/schemas/AmountOfCars';
import { FleetSizeSchema } from '@ui/validations/schemas/FleetSize';
import { FrameworkAgreementSchema } from '@ui/validations/schemas/FrameworkAgreement';
import { FrameworkAgreementNumberSchema } from '@ui/validations/schemas/FrameworkAgreementNumber';
import { MarketCode } from '@utils/market/types';
import { object } from 'yup';

const BusinessAdditionalInfoSchema = (validationMarket: MarketCode) => {
  const daimlerFrameworkAgreement = FrameworkAgreementSchema(validationMarket);
  const frameworkAgreementNumber =
    FrameworkAgreementNumberSchema(validationMarket);
  const fleetSize = FleetSizeSchema(validationMarket);
  const amountOfCars = AmountOfCarsSchema(validationMarket);

  return object().shape({
    ...daimlerFrameworkAgreement,
    ...frameworkAgreementNumber,
    ...fleetSize,
    ...amountOfCars,
  });
};

export default BusinessAdditionalInfoSchema;
