import { Button, Toast, useToaster } from '@smart/react-components';
import { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  text: string;
  children?: ReactNode;
};

const CopyText = ({ text, children }: Props) => {
  const { t } = useTranslation();
  const { toast } = useToaster();

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    toast({
      children: <Toast.Text>{t('general.copy.copied')}</Toast.Text>,
    });
  };

  // Since the parent is of type Button this has to be a div in order to please HTML specs.
  return (
    <div onClick={onClick} onKeyDown={() => {}} role="button" tabIndex={0}>
      {children ?? text}
      <Button.Icon aria-label={t('general.copy.copy')} icon="copy" />
    </div>
  );
};

export default CopyText;
