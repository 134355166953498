import { Flex, Text } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import OrderItemFeature from './OrderItemFeature';
import { OrderItemSection } from './OrderItems.config';

const BASE_CLASS = 'adb-order-items';

const AdditionalInterests = ({ orderDetail }: OrderItemSection) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" width="full" gap={300}>
      <Text variant="lbl-200">{t('orders.additional_interests.title')}</Text>
      <Flex
        alignContent="space-between"
        width="full"
        wrap="wrap"
        gap={300}
        className={`${BASE_CLASS}__features`}
      >
        {orderDetail?.orderPreferences.map((item) => (
          <OrderItemFeature
            key={item.id}
            title={t(`orders.additional_interests.${item.id.toLowerCase()}`)}
            text={
              item.option
                ? t('orders.additional_interests.yes')
                : t('orders.additional_interests.no')
            }
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default AdditionalInterests;
