import { Maybe, SystemInfo as SystemInfoI } from '@smart/adb-shared';
import Section from '@smart/components-adb/atoms/Section/Section';
import { Select, TextInput } from '@smart/react-components';
import useSelect from '@utils/hooks/useSelect';
import { useTranslation } from 'react-i18next';
import { CustomerForm } from '../helper';
import { usePreferredOptionsQuery } from '../queries.generated';

const BASE_CLASS = 'adb-details';

type SystemInfoProps = CustomerForm & {
  systemDetails: Maybe<SystemInfoI> | undefined;
};

const SystemInfo = ({ systemDetails, hasExpanded }: SystemInfoProps) => {
  const { t } = useTranslation();
  const { getTranslatedFilters } = useSelect();

  const { data } = usePreferredOptionsQuery();

  const leadSourceOptions = data?.preferredOptions.source ?? [];
  const sactionFlagOptions = data?.preferredOptions.sanctionFlag ?? [];

  const defaultSource = leadSourceOptions.length
    ? leadSourceOptions[0].value
    : '';

  const defaultSanction = sactionFlagOptions.length
    ? sactionFlagOptions[0].value
    : '';

  const initialSystemInfo = {
    source: systemDetails?.source ?? defaultSource,
    sanctionFlag: systemDetails?.sanctionFlag ?? defaultSanction,
    recordType: systemDetails?.recordType ?? '',
    preferredOutlet: systemDetails?.preferredSmartAgent ?? '',
  };

  return (
    <Section
      expanded={!!hasExpanded}
      title={t('customer.details.system_information')}
      hasAccordion
    >
      <div
        className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--two-columns-1-1`}
      >
        <div className={`${BASE_CLASS}__form-input`}>
          <TextInput
            id="source"
            type="text"
            name="source"
            label={t('form_fields.system.source')}
            value={initialSystemInfo?.source}
            className={`${BASE_CLASS}__form-input__disabled`}
            disabled
          />
        </div>
        <div className={`${BASE_CLASS}__form-input`}>
          <Select
            id="customer_sanction_flag"
            name="sanctionFlag"
            label={t('form_fields.system.sanction_flag')}
            value={initialSystemInfo?.sanctionFlag}
            items={getTranslatedFilters(
              sactionFlagOptions ?? [],
              'sanction_flag'
            )}
            disabled
          >
            {(items) =>
              items.map((item) => <Select.Item key={item.value} {...item} />)
            }
          </Select>
        </div>
      </div>
      <div
        className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--two-columns-1-1`}
      >
        <div className={`${BASE_CLASS}__form-input`}>
          <TextInput
            id="record_type"
            type="text"
            name="recordType"
            label={t('form_fields.system.record_type')}
            value={initialSystemInfo?.recordType}
            className={`${BASE_CLASS}__form-input__disabled`}
            disabled
          />
        </div>
        <div className={`${BASE_CLASS}__form-input`}>
          <TextInput
            id="preferred_outlet"
            type="text"
            name="preferredOutlet"
            label={t('form_fields.system.preferred_outlet')}
            value={initialSystemInfo?.preferredOutlet}
            className={`${BASE_CLASS}__form-input__disabled`}
            disabled
          />
        </div>
      </div>
    </Section>
  );
};

export default SystemInfo;
