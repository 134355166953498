import { useReactiveVar } from '@apollo/client';
import AdbNotification from '@smart/components-adb/molecules/AdbNotification/AdbNotification';
import { SignalVariant } from '@smart/components-adb/molecules/AdbNotification/AdbNotification.config';
import { ErrorBoundaryFallback } from '@smart/components-adb/organisms/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { clearGraphQLError, errorVar } from 'graphql/reactive-error';
import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type NotificationNode = ReactNode;

interface NotificationContextValue {
  addWarning: (props: NotificationPropsBase) => void;
  addInformation: (props: NotificationPropsBase) => void;
  addSuccess: (props: NotificationPropsBase) => void;
  addError: (props: NotificationPropsBase) => void;
  addNotification: (props: NotificationProps) => void;
  closeNotification: () => void;
}

export const NotificationContext = createContext<NotificationContextValue>({
  addWarning: () => {},
  addInformation: () => {},
  addSuccess: () => {},
  addError: () => {},
  addNotification: () => {},
  closeNotification: () => {},
});

type NotificationPropsBase = {
  label: string;
  message: ReactNode;
  onClose?: () => void;
  persist?: boolean;
};

type NotificationProps = {
  type: SignalVariant;
} & NotificationPropsBase;

export const NotificationContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [notifications, setNotifications] = useState<NotificationNode[]>([]);
  const gqlError = useReactiveVar(errorVar);

  const setNotification = useCallback(
    ({ label, message, type, persist, onClose }: NotificationProps) => {
      const notificationId = crypto.randomUUID();
      setNotifications((prev) => [
        <AdbNotification
          key={notificationId}
          label={label}
          variant={type}
          text={message}
          onClose={() => {
            if (onClose && typeof onClose === 'function') onClose();
            setNotifications((curr) => curr.filter((val) => val === curr));
          }}
          persist={persist}
          isVisible
        />,
        ...prev,
      ]);
    },
    []
  );

  const closeNotification = useCallback(() => {
    setNotifications((prev) => prev.slice(1));
  }, []);

  const addWarning = useCallback(
    (props: NotificationPropsBase) => {
      setNotification({ ...props, type: 'warning' });
    },
    [setNotification]
  );

  const addInformation = useCallback(
    (props: NotificationPropsBase) => {
      setNotification({ ...props, type: 'informational' });
    },
    [setNotification]
  );
  const addSuccess = useCallback(
    (props: NotificationPropsBase) => {
      setNotification({ ...props, type: 'success' });
    },
    [setNotification]
  );
  const addError = useCallback(
    (props: NotificationPropsBase) => {
      setNotification({ ...props, type: 'error' });
    },
    [setNotification]
  );

  const value = useMemo(
    () => ({
      addError,
      addInformation,
      addSuccess,
      addWarning,
      addNotification: setNotification,
      closeNotification,
    }),
    [
      addError,
      addInformation,
      addSuccess,
      addWarning,
      setNotification,
      closeNotification,
    ]
  );

  return (
    <NotificationContext.Provider value={value}>
      {gqlError.error && (
        <ErrorBoundaryFallback
          error={gqlError.error}
          key="gql-error"
          resetErrorBoundary={clearGraphQLError}
          level={gqlError.error.extensions?.level as SignalVariant}
        />
      )}
      {notifications?.length > 0 && notifications}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = (): NotificationContextValue => {
  const value = useContext(NotificationContext);

  if (!value) throw new Error('Got undefined notification context!');

  return value;
};
