import { Icon } from '@smart/react-components';
import { localeDictionary } from '@ui/library/helpers/date-locale';
import { format, getDay, parse, startOfWeek } from 'date-fns';
import { Formats, Messages, dateFnsLocalizer } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';

export const CALENDAR_VIEWS = {
  month: true,
  week: true,
  day: true,
};

export const currentDate = new Date();

export const dateLocalizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales: localeDictionary,
});

export const calendarFormats: Formats = {
  timeGutterFormat: 'HH:mm', // If this is removed there is a graphical bug
  dayHeaderFormat: (date, culture, localizer) =>
    localizer!.format(date, 'EEEE MMMM dd', culture),
};

export const CalendarViewHeader = () => {
  const { t } = useTranslation();

  const messages: Messages = {
    week: t('feature_calendar.general.week'),
    day: t('feature_calendar.general.day'),
    month: t('feature_calendar.general.month'),
    next: (<Icon icon="chevron-right" />) as any,
    previous: (<Icon icon="chevron-left" />) as any,
    today: t('feature_calendar.general.today'),
    showMore: (total: number) =>
      `+${total} ${t('feature_calendar.general.more')}`,
  };

  return messages;
};
