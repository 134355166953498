import { CustomerPrivate } from '@smart/adb-shared';
import { CarLine, CarModels } from '@ui/data-models/car/car.model';
import {
  TestDriveBookingCar,
  TestDriveBookingCurrent,
} from '@ui/data-models/test-drive/test-drive.model';
import {
  TestDriveBookingNavigation,
  TestDriveNavItem,
} from 'pages/test-drive/config';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export interface ITestDriveContext {
  currentTestDriveBooking: TestDriveBookingCurrent;
  navigation: TestDriveNavItem[];
  loading: boolean;
  setCurrentTestDriveBooking: (current: TestDriveBookingCurrent) => void;
  setNavigation: (navigation: TestDriveNavItem[]) => void;
  setLoading: (loading: boolean) => void;
  setModelForCurrentTestDrive: (carModel: CarModels) => void;
  setCustomerIds: (
    customerId: string,
    sfCustomerId: string,
    bpid?: string,
    sfOrgId?: string
  ) => void;
  setCarLineForCurrentTestDrive: (carLine: CarLine) => void;
  setCarForCurrentTestDrive: (car: TestDriveBookingCar) => void;
  setTestdriveEditSessionStorage: (
    testDrive: TestDriveBookingCurrent,
    currentNavigation: TestDriveNavItem[]
  ) => void;
  setSuperLightCustomer: (customer: CustomerPrivate) => void;
  superLightCustomer: CustomerPrivate | undefined;
}

const defaultCurrent: TestDriveBookingCurrent = {
  carLine: CarLine.PROPLUS,
  carModel: undefined,
  customerId: '',
  sfCustomerId: '',
};

const defaultTestDriveContext: ITestDriveContext = {
  currentTestDriveBooking: defaultCurrent,
  navigation: TestDriveBookingNavigation,
  loading: false,
  setCurrentTestDriveBooking: () => {},
  setNavigation: () => {},
  setLoading: () => {},
  setModelForCurrentTestDrive: () => {},
  setCustomerIds: () => {},
  setCarLineForCurrentTestDrive: () => {},
  setCarForCurrentTestDrive: () => {},
  setTestdriveEditSessionStorage: () => {},
  setSuperLightCustomer: () => {},
  superLightCustomer: undefined,
};

export const TestDriveContext = createContext<ITestDriveContext>(
  defaultTestDriveContext
);

export const TestDriveContextProvider = ({ children }: PropsWithChildren) => {
  const [currentTestDriveBooking, setCurrentTestDriveBooking] =
    useState<TestDriveBookingCurrent>(defaultCurrent);
  const [navigation, setNavigation] = useState<TestDriveNavItem[]>(
    TestDriveBookingNavigation
  );
  const [superLightCustomer, setSuperLightCustomer] =
    useState<CustomerPrivate>();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);

  const setTestdriveEditSessionStorage = useCallback(
    (
      testDrive: TestDriveBookingCurrent,
      currentNavigation: TestDriveNavItem[]
    ) => {
      sessionStorage.setItem(
        'adb_testdrive-edit',
        JSON.stringify({ testDrive, currentNavigation })
      );
    },
    []
  );

  const setModelForCurrentTestDrive = useCallback((carModel: CarModels) => {
    setCurrentTestDriveBooking((prev) => ({
      ...prev,
      carModel,
    }));
  }, []);

  const setCustomerIds = useCallback(
    (
      customerId: string,
      sfCustomerId: string,
      bpid?: string,
      sfOrgId?: string
    ) => {
      setCurrentTestDriveBooking((prev) => ({
        ...prev,
        customerId,
        sfCustomerId,
        bpid,
        sfOrgId,
      }));
    },
    []
  );

  const setCarLineForCurrentTestDrive = useCallback((carLine: CarLine) => {
    setCurrentTestDriveBooking((prev) => ({ ...prev, carLine }));
  }, []);

  const setCarForCurrentTestDrive = useCallback((car: TestDriveBookingCar) => {
    setCurrentTestDriveBooking((prev) => ({ ...prev, car }));
  }, []);

  const contextValue = useMemo(
    () => ({
      currentTestDriveBooking,
      navigation,
      loading,
      setCurrentTestDriveBooking,
      setNavigation,
      setLoading,
      setModelForCurrentTestDrive,
      setCustomerIds,
      setCarLineForCurrentTestDrive,
      setCarForCurrentTestDrive,
      setTestdriveEditSessionStorage,
      setSuperLightCustomer,
      superLightCustomer,
    }),
    [
      currentTestDriveBooking,
      navigation,
      loading,
      setCurrentTestDriveBooking,
      setNavigation,
      setLoading,
      setModelForCurrentTestDrive,
      setCustomerIds,
      setCarLineForCurrentTestDrive,
      setCarForCurrentTestDrive,
      setTestdriveEditSessionStorage,
      setSuperLightCustomer,
      superLightCustomer,
    ]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('from') !== 'test-drive') return;
    const testDriveEdit = sessionStorage.getItem('adb_testdrive-edit');
    if (testDriveEdit) {
      const { testDrive, currentNavigation } = JSON.parse(testDriveEdit);
      setCurrentTestDriveBooking(testDrive);
      setNavigation(currentNavigation);
      sessionStorage.removeItem('adb_testdrive-edit');
    }
  }, [location.search]);

  return (
    <TestDriveContext.Provider value={contextValue}>
      {children}
    </TestDriveContext.Provider>
  );
};

export const useTestDriveContext = () => {
  const context = useContext(TestDriveContext);
  if (!context) {
    throw new Error(
      'useTestDriveContext must be used within a TestDriveContextProvider'
    );
  }
  return context;
};
