import { AssetType, CarStatus } from '@smart/adb-shared';
import { Button, Link, Text } from '@smart/react-components';
import { CarsDocument } from 'graphql/queries/cars.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutlet } from 'hooks/outlet';
import { useTranslation } from 'react-i18next';
import AdbDialog from '../../AdbDialog/AdbDialog';
import { useModal } from '../../Modal';
import '../index.scss';
import { CarActivationProps } from './CarActivation.config';
import { useDeactivateCarMutation } from './queries.generated';

const BASE_CLASS = 'adb-car-deactivation';

const CarActivation = ({ car }: CarActivationProps) => {
  const { vin, licensePlateNumber, registrationDate, preRegistrationDate } =
    car;

  const isActive = car.status === CarStatus.Active;

  const { t } = useTranslation();
  const { registerModal, closeModal } = useModal();
  const outlet = useCurrentOutlet();

  const [deactivateCar, { loading }] = useDeactivateCarMutation({
    variables: {
      input: {
        vin,
        active: !isActive,
        ...(licensePlateNumber && { licensePlateNumber }),
        ...(registrationDate && { registrationDate }),
        ...(preRegistrationDate && { preRegistrationDate }),
      },
    },
    onCompleted: () => {
      closeModal();
    },
    onError: (error) => {
      closeModal();
      enhanceError({
        error,
        label: 'Failed to activate/deactivate car',
        displayMessage: error.message,
      });
    },
    refetchQueries: [
      {
        query: CarsDocument,
        variables: {
          input: {
            bpid: outlet?.bpId,
            type: AssetType.DemoVehicle,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const onClick = () => {
    const header = isActive
      ? t('outlet.showroom.demo_cars.confirm_deactivation_header')
      : t('outlet.showroom.demo_cars.confirm_activation_header');
    const content = isActive
      ? t('outlet.showroom.demo_cars.confirm_deactivation_content')
      : t('outlet.showroom.demo_cars.confirm_activation_content');
    registerModal(
      <AdbDialog
        id="deactivate-car-dialog"
        buttons={{
          primary: {
            label: t('outlet.showroom.demo_cars.dialog.confirm'),
            onClick: deactivateCar,
            isLoading: loading,
            disabled: loading,
          },
          secondary: {
            label: t('outlet.showroom.demo_cars.dialog.cancel'),
            onClick: closeModal,
          },
        }}
      >
        <AdbDialog.Header>
          <Text variant="hl-100">{header}</Text>
        </AdbDialog.Header>
        <AdbDialog.Content>{content}</AdbDialog.Content>
      </AdbDialog>
    );
  };

  return (
    <div className={BASE_CLASS}>
      <Link asChild>
        <Button onClick={onClick}>
          {isActive
            ? t('outlet.showroom.demo_cars.button.deactivate')
            : t('outlet.showroom.demo_cars.button.activate')}
        </Button>
      </Link>
    </div>
  );
};

export default CarActivation;
