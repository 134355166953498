import { Task } from '@smart/adb-shared';
import CancelDialog from '@smart/components-adb/calendar/AppointmentDialog/CancelDialog';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import RescheduleAppointmentForm from '@smart/components-adb/molecules/TaskItem/RescheduleAppointmentForm/RescheduleAppointmentForm';
import { Button, Text } from '@smart/react-components';
import { Row } from '@tanstack/react-table';
import {
  getDateXMonthsAfter,
  getDateXMonthsBefore,
} from '@ui/library/helpers/date';
import { useAgentContext } from 'contexts/agent-context';
import { useNotificationContext } from 'contexts/notification-context';
import { useConfirmAppointmentMutation } from 'graphql/queries/appointments.generated';
import { useTranslation } from 'react-i18next';
import { useAppointmentQuery } from '../queries.generated';
import './TestDriveActionsModal.scss';

const BASE_CLASS = 'adb-td-actions-modal';

interface ITestDriveRequestActionsModal {
  task: Row<Task>;
  onCompleted: () => void;
}

export const TestDriveRequestActionsModal = ({
  task,
  onCompleted,
}: ITestDriveRequestActionsModal) => {
  const { t } = useTranslation();
  const { registerModal } = useModal();
  const { addError } = useNotificationContext();
  const { agent } = useAgentContext();

  const [confirmTestDrive, { loading: confirming }] =
    useConfirmAppointmentMutation();

  const onConfirm = async () => {
    if (task.original.appointment?.id) {
      await confirmTestDrive({
        variables: {
          input: {
            ownerId: agent.id,
            appointmentId: task.original.appointment.id,
          },
        },
      }).then(() => onCompleted());
    } else {
      addError({
        label: t('general.error_messages.something_went_wrong'),
        message: t('feature_calendar.notification.error_description'),
      });
    }
  };

  const { data: appointmentData, loading: appointmentLoading } =
    useAppointmentQuery({
      variables: { input: { id: task.original.appointment?.id ?? '' } },
      skip: !task.original.appointment?.id,
    });

  const appointment = appointmentData?.appointment;

  return (
    <AdbDialog size="medium" id={BASE_CLASS} className={BASE_CLASS}>
      <AdbDialog.Header>
        <Text variant="hl-100">{t('task.lead.next_step_cta')}</Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <Button
          variant="primary"
          mode={200}
          onClick={onConfirm}
          disabled={confirming || !task.original.isAgentAssigned}
          loading={confirming}
        >
          <Button.Spinner />
          {t('feature_calendar.general.buttons.confirm')}
        </Button>
        <Button
          variant="primary"
          mode={200}
          onClick={() => {
            if (appointment) {
              registerModal(
                <AdbDialog id="reschedule-dialog">
                  <AdbDialog.Content>
                    <RescheduleAppointmentForm
                      appointment={appointment}
                      onClose={onCompleted}
                    />
                  </AdbDialog.Content>
                </AdbDialog>
              );
            }
          }}
          disabled={!task.original.isAgentAssigned || !appointment}
          loading={appointmentLoading}
        >
          <Button.Spinner />
          {t('feature_calendar.general.buttons.reschedule')}
        </Button>
        <Button
          variant="primary"
          mode={200}
          onClick={() =>
            registerModal(
              <CancelDialog
                appointmentId={task.original.appointment?.id ?? ''}
                calendarDateRange={[
                  getDateXMonthsBefore(2),
                  getDateXMonthsAfter(6),
                ]}
                onClose={onCompleted}
                type="cancel"
              />
            )
          }
          disabled={!task.original.isAgentAssigned}
        >
          {t('feature_calendar.general.buttons.decline')}
        </Button>
      </AdbDialog.Content>
    </AdbDialog>
  );
};
