/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeactivateCarMutationVariables = Types.Exact<{
  input: Types.UpdateCarInput;
}>;


export type DeactivateCarMutation = { __typename?: 'Mutation', updateCar: { __typename?: 'UpdateCarResponse', active?: boolean | null } };


export const DeactivateCarDocument = gql`
    mutation DeactivateCar($input: UpdateCarInput!) {
  updateCar(input: $input) {
    active
  }
}
    `;
export type DeactivateCarMutationFn = Apollo.MutationFunction<DeactivateCarMutation, DeactivateCarMutationVariables>;

/**
 * __useDeactivateCarMutation__
 *
 * To run a mutation, you first call `useDeactivateCarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateCarMutation, { data, loading, error }] = useDeactivateCarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateCarMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateCarMutation, DeactivateCarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateCarMutation, DeactivateCarMutationVariables>(DeactivateCarDocument, options);
      }
export type DeactivateCarMutationHookResult = ReturnType<typeof useDeactivateCarMutation>;
export type DeactivateCarMutationResult = Apollo.MutationResult<DeactivateCarMutation>;
export type DeactivateCarMutationOptions = Apollo.BaseMutationOptions<DeactivateCarMutation, DeactivateCarMutationVariables>;