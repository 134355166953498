import {
  ActivityAppointment,
  ActivityTask,
  Appointment,
  AppointmentStatus,
  AppointmentType,
  Case,
  Task,
  TaskCustomer,
  TaskStatus,
  TaskType,
} from '@smart/adb-shared';

interface TaskWithOptionalCustomer extends Omit<Task, 'customer'> {
  customer?: TaskCustomer;
}

export enum CardType {
  TASK = 'Task',
  APPOINTMENT = 'Appointment',
  ACTIVITY_APPOINTMENT = 'ActivityAppointment',
  ACTIVITY_TASK = 'ActivityTask',
  HANDOVER_TASK = 'HandoverTask',
  EMAIL = 'Email',
  CASE = 'Case',
}

export enum ExpertFilter {
  YOU = 'you',
  UNASSIGNED = 'unassigned',
}

export enum FilterType {
  TASK = 'task',
  EXPERT = 'expert',
  STATUS = 'status',
  STEP = 'step',
  NEW = 'new',
}

export enum TaskFilterType {
  ALL = 'All',
  TEST_DRIVE = 'Test Drive',
  TASK = 'Task',
}

export enum TaskFilterPriority {
  ALL = 'All',
  NORMAL = 'Normal',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export interface TaskCard extends TaskWithOptionalCustomer {
  cardType: CardType.TASK;
}
export interface HandoverTaskCard extends TaskWithOptionalCustomer {
  cardType: CardType.HANDOVER_TASK;
}

export interface AppointmentCard extends Appointment {
  cardType: CardType.APPOINTMENT;
}

export interface ActivityAppointmentCard extends ActivityAppointment {
  cardType: CardType.ACTIVITY_APPOINTMENT;
}

export interface ActivityTaskCard extends ActivityTask {
  cardType: CardType.ACTIVITY_TASK;
}

export interface CaseCard extends Case {
  cardType: CardType.CASE;
}

const getTaskCardType = (taskType?: TaskType | null) => {
  switch (taskType) {
    case TaskType.Handover:
      return CardType.HANDOVER_TASK;
    default:
      return CardType.TASK;
  }
};

export const mapTasks = (
  o: TaskWithOptionalCustomer[]
): (TaskCard | HandoverTaskCard)[] =>
  o.map((task) => ({
    ...task,
    cardType: getTaskCardType(task.type),
  }));

export const mapAppointments = (o: Appointment[]): AppointmentCard[] =>
  o.map((appointment) => ({
    ...appointment,
    cardType: CardType.APPOINTMENT,
  }));

export const mapCases = (o: Case[]): CaseCard[] =>
  o.map((activity) => ({
    ...activity,
    cardType: CardType.CASE,
  }));

export const filterOpenTasks = (tasks: TaskWithOptionalCustomer[]) =>
  tasks.filter((task) => task.status === TaskStatus.Open);

export const filterAppointments = (
  appointments: Appointment[],
  searchQuery: string,
  filterQuery: string
): Appointment[] =>
  appointments
    .filter(
      (appointment) =>
        (appointment.customer?.firstName &&
          appointment.customer?.lastName &&
          [appointment.customer.firstName, appointment.customer.lastName]
            .join(' ')
            .toLowerCase()
            .includes(searchQuery?.toLowerCase())) ||
        appointment.car?.licensePlate
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase())
    )
    .filter((appointment) =>
      filterQuery && filterQuery !== TaskFilterType.ALL
        ? appointment.type === filterQuery
        : appointment
    );

export const sortAndSlice = (
  arr: (TaskCard | HandoverTaskCard | AppointmentCard)[],
  slice?: boolean
): (TaskCard | HandoverTaskCard | AppointmentCard)[] => {
  const result = [...arr];
  result.sort((a, b) => {
    if (a.__typename === 'Task' && b.__typename === 'Task') {
      return (
        (b.requestedDate ? new Date(b.requestedDate).getTime() : 0) -
        (a.requestedDate ? new Date(a.requestedDate).getTime() : 0)
      );
    }
    if (a.__typename === 'Appointment' && b.__typename === 'Appointment') {
      return (
        (b.start ? new Date(b.start).getTime() : 0) -
        (a.start ? new Date(a.start).getTime() : 0)
      );
    }
    return 1;
  });
  if (slice) {
    return result.slice(0, 10);
  }
  return result;
};

export const filterOpenAppointments = (
  appointments: Appointment[] | undefined
): Appointment[] =>
  appointments
    ? appointments.filter((appointment) => {
        if (
          appointment.type === AppointmentType.TestDrive &&
          appointment.status === AppointmentStatus.Requested
        ) {
          return true;
        }
        return false;
      })
    : [];

export const filterNonServiceAppointments = (appointments?: Appointment[]) =>
  appointments?.filter(
    (appointment) => appointment.type !== AppointmentType.ServiceAppointment
  ) ?? [];

export const filterCalenderWeekAppointmensByExpertId = (
  selectedAgentId: string | undefined,
  appointments: Appointment[]
) =>
  selectedAgentId
    ? appointments
        .filter((appointment) => appointment?.expert?.id === selectedAgentId)
        .sort(
          (firstAppointment, secondAppointment) =>
            new Date(firstAppointment.start).getTime() -
            new Date(secondAppointment.start).getTime()
        )
    : [];

export const filterNonServiceOrHandoverAppointments = (
  appointments: Appointment[] | undefined
): Appointment[] =>
  appointments
    ? appointments.filter((appointment) => {
        if (
          appointment.type === AppointmentType.ServiceAppointment ||
          appointment.type === AppointmentType.Handover
        ) {
          return false;
        }
        return true;
      })
    : [];

export const filterYourAppointments = (
  appointments: Appointment[],
  agentId: string
): Appointment[] =>
  filterNonServiceOrHandoverAppointments(appointments).filter(
    (appointment: Appointment) =>
      !(
        !agentId ||
        !appointment.expert?.id ||
        appointment.expert.id !== agentId
      )
  );
