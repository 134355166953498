import { LANGUAGE_DEFAULT } from '@ui/translations';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const { BUILDKITE_COMMIT } = import.meta.env;

const languageDetector = new LanguageDetector(null, {
  order: ['navigator', 'localStorage', 'path'],
});

export const i18n = i18next
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      ...(BUILDKITE_COMMIT && {
        queryStringParams: { v: `${BUILDKITE_COMMIT.substring(0, 7)}` },
      }),
    },
    nsSeparator: '.',
    keySeparator: '.',
    fallbackLng: LANGUAGE_DEFAULT,
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    load: 'currentOnly',
  });
