import { useAuthContext } from 'contexts/auth-context';
import { useLanguageContext } from 'contexts/language-context';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { applicationRoutes } from 'routes';
import { Routes } from 'routes.config';
import LoadingIndicator from '../atoms/LoadingIndicator/LoadingIndicator';

export const AdbHelmet = () => {
  const { t } = useTranslation();
  const { language } = useLanguageContext();
  const location = useLocation();

  const { isAuthenticated } = useAuthContext();

  const helmetDefault = {
    titleTemplate: `%s - ${t('general.title')}`,
    defaultTitle: t('general.title'),
    description: t('general.description'),
  };

  const getATMScript = () => {
    switch (import.meta.env.DEPLOY_ENV) {
      case 'prod':
        return 'launch-4f1b77b39f83.min.js';
      case 'qa':
        return 'launch-9383796b7217-staging.min.js';
      default:
        return 'launch-b57388de2273-development.min.js';
    }
  };

  if (!isAuthenticated) {
    return <LoadingIndicator onFullPage />;
  }

  return (
    <Helmet
      titleTemplate={helmetDefault.titleTemplate}
      defaultTitle={helmetDefault.defaultTitle}
      htmlAttributes={{ lang: language }}
      async={false}
    >
      <script
        src={`https://assets.adobedtm.com/2c8f96943be2/8d46bc5e38f7/${getATMScript()}`}
        type="text/javascript"
      />
      {applicationRoutes.map((routeObject: Routes) => (
        <Fragment key={routeObject.path}>
          {routeObject.path === location.pathname && (
            <>
              <meta
                name={helmetDefault.description}
                content={t(`${routeObject.content}`)}
              />
              <title>{t(`${routeObject.title}`)}</title>
            </>
          )}
        </Fragment>
      ))}
    </Helmet>
  );
};
