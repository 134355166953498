import { getAssertedAuthCookie, useAuthContext } from 'contexts/auth-context';
import { SectionLayoutProps } from './SectionLayout.config';
import SectionHeader from './header';

const SectionLayout = ({
  navItems,
  darkMode,
  isCustomerLayout = false,
}: SectionLayoutProps) => {
  const { clearAuthorization, logout } = useAuthContext();

  const logOutAgent = async () => {
    const auth = getAssertedAuthCookie();
    logout(auth['agent-id']).then(() => clearAuthorization());
  };

  return (
    <SectionHeader
      navItems={navItems}
      logOutAgent={logOutAgent}
      darkMode={darkMode}
      isCustomerLayout={isCustomerLayout}
    />
  );
};

export default SectionLayout;
