import { ExtendedCar, PurchaseType } from '@smart/adb-shared';
import { DateFormats, getLocaleDate } from '@ui/library/helpers/date-locale';
import classNames from 'classnames';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AdbDynamicImage from '../AdbDynamicImage/AdbDynamicImage';
import { imageConfig4To3 } from '../AdbDynamicImage/AdbDynamicImage.config';
import { LengthType } from '../CarListItem/CarListItem.config';
import Features from '../CarListItem/Features/Features';
import Header from '../CarListItem/Header/Header';
import CarActivation from './CarActivation/CarActivation';
import CarInfleeter from './CarInfleeter/CarInfleeter';
import { CarCardProps, CarConfig, CarKeys } from './config';
import './index.scss';

const BASE_CLASS = 'adb-car-card';

const CarCard = ({
  car,
  uuid,
  headerText,
  saveButtonText,
  additionalClasses,
  disableActivation = false,
  children,
  enableAccordion = false,
}: CarCardProps) => {
  const [open, setOpen] = useState(!enableAccordion);
  const { t } = useTranslation();
  const { market } = useMarketContext();
  const { locale } = useLanguageContext();
  const { customerId } = useParams();

  const [updated, setUpdated] = useState<boolean>(false);

  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (updated && cardRef.current) {
      cardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setUpdated(false);
    }
  }, [updated]);

  const gridSize = LengthType.FOUR;

  const getProductFieldValue = (key: CarKeys, asset: ExtendedCar) => {
    const dateTimeFields = [
      'registrationDate',
      'preRegistrationDate',
      'purchaseDate',
      'installDate',
    ];
    let productValue: string | undefined;

    if (dateTimeFields.includes(key)) {
      const dateTimeFormatter = (
        format: DateFormats,
        dateTime?: string
      ): string | undefined =>
        dateTime
          ? getLocaleDate(new Date(dateTime).toString(), locale, format)
          : undefined;

      productValue =
        (asset as any)[key] &&
        dateTimeFormatter(DateFormats.DATE, (asset as any)[key]);
    }

    if (key === 'holdingPeriod') {
      productValue = asset.holdingPeriod?.split('.')[0];
    }

    if (key === 'purchaseType') {
      if (asset[key] === PurchaseType.Cash) {
        productValue = t('general.values.cash');
      }
    }

    return productValue ?? (asset as any)[key];
  };

  const getFeatures = () => {
    const selectedKeys = [
      'licensePlateNumber',
      'registrationDate',
      'preRegistrationDate',
    ];

    const filteredPurchasedProductKeys = Object.fromEntries(
      Object.entries(CarConfig).filter(([key]) => {
        if (car.purchaseType === PurchaseType.Cash && key === 'holdingPeriod')
          return false;

        if (market !== 'gb') {
          return key !== 'preRegistrationDate';
        }

        return car?.licensePlateNumber &&
          car?.registrationDate &&
          car?.preRegistrationDate
          ? key
          : !selectedKeys.includes(key);
      })
    );

    return Object.keys(filteredPurchasedProductKeys)?.map(
      (key: CarKeys | string) => ({
        key: t(filteredPurchasedProductKeys[key]),
        value: getProductFieldValue(key, car),
      })
    );
  };

  const renderFooter = () => {
    if (disableActivation) {
      return (
        !car.isInfleeted && (
          <CarInfleeter
            car={car}
            saveButtonText={saveButtonText}
            uuid={customerId ?? uuid}
            setUpdated={setUpdated}
          />
        )
      );
    }

    if (car.isInfleeted) {
      return <CarActivation car={car} />;
    }

    return (
      <CarInfleeter
        car={car}
        saveButtonText={saveButtonText}
        uuid={customerId ?? uuid}
        setUpdated={setUpdated}
      />
    );
  };

  return (
    <div ref={cardRef} className={classNames(BASE_CLASS, additionalClasses)}>
      {car.image && (
        <div className={`${BASE_CLASS}__image-wrap`}>
          <AdbDynamicImage
            imageUrl={car.image}
            imageAltText={headerText}
            imageConfig={imageConfig4To3}
            className={`${BASE_CLASS}__image`}
          />
        </div>
      )}
      <div className={`${BASE_CLASS}__header`}>
        <Header
          open={open}
          hasAccordion={enableAccordion}
          name={headerText}
          featuresCount={gridSize}
          handleAccordion={() => setOpen(!open)}
        />
      </div>
      <div className={`${BASE_CLASS}__content`}>
        <div
          className={`${BASE_CLASS}__feature-wrap`}
          style={{ gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}
        >
          {getFeatures().map((feature, index) => (
            <Features
              key={`${feature.key}-${car.vin}`}
              carItem={feature}
              index={index}
              featuresCount={gridSize}
              open
            />
          ))}
        </div>
        {open ? children : null}
        {renderFooter()}
      </div>
    </div>
  );
};

export default CarCard;
