import { Task, TaskLossReason } from '@smart/adb-shared';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { RadioGroup, Text, TextArea } from '@smart/react-components';
import { useNotificationContext } from 'contexts/notification-context';
import {
  AllTasksDocument,
  useCloseTaskMutation,
} from 'graphql/queries/tasks.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutlet } from 'hooks/outlet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TaskLossTranslationMap,
  useTaskLossReasonByMarket,
} from './CloseTaskModal.config';
import './CloseTaskModal.scss';

const BASE_CLASS = 'adb-close-task-modal';

const CloseTaskModal = ({
  task,
  closeModal,
}: {
  task: Task;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const { addSuccess } = useNotificationContext();
  const outlet = useCurrentOutlet();
  const [reason, setReason] = useState<TaskLossReason | undefined>();
  const [reasonText, setReasonText] = useState<string>('');
  const taskLossReasons = useTaskLossReasonByMarket();

  const [closeTask, { loading: isLoading }] = useCloseTaskMutation({
    onCompleted: () => {
      addSuccess({
        label: t('task.notification.task_completed_title'),
        message: t('task.notification.task_completed_description'),
      });
      closeModal();
    },
    onError: (error) => {
      enhanceError({
        error,
        label: t('task.notification.task_error_title'),
        displayMessage: t('task.notification.task_error_description'),
      });
    },
    refetchQueries: [
      {
        query: AllTasksDocument,
        variables: {
          input: {
            outletId: outlet?.mcsId,
          },
        },
      },
    ],
  });

  const radioButtons = (
    <RadioGroup
      name="radio-loss-reason"
      className={`${BASE_CLASS}__radio-group`}
      onValueChange={(lossReason: string) => {
        setReason(lossReason as TaskLossReason);
      }}
    >
      {taskLossReasons.map((r) => (
        <RadioGroup.Radio
          key={`checkbox-${r}`}
          id={`checkbox-${r}`}
          value={r}
          checked={r === reason}
        >
          {t(
            `task.task_lost_reason.${TaskLossTranslationMap[r as TaskLossReason]}`
          )}
        </RadioGroup.Radio>
      ))}
    </RadioGroup>
  );

  return (
    <AdbDialog
      id={BASE_CLASS}
      className={BASE_CLASS}
      buttons={{
        primary: {
          isLoading,
          onClick: () =>
            closeTask({
              variables: {
                input: {
                  lossReason: reason ?? TaskLossReason.Other,
                  closeDate: new Date().toISOString(),
                  taskId: task.id,
                  ecoOtherLossReason: reasonText,
                },
              },
            }),
          disabled: isLoading || !reason,
          label: t('task.finish'),
          iconName: 'arrow-right',
          iconPosition: 'right',
        },
      }}
    >
      <AdbDialog.Header>
        <Text variant="hl-100">{t('task.task_lost_reason.title')}</Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <div>
          <div className={`${BASE_CLASS}__content`}>{radioButtons}</div>
          <TextArea
            disabled={!reason}
            id="loss-reason-other"
            value={reasonText}
            onChange={(evt) => setReasonText(evt.target.value)}
            caption={`${reasonText.length}/250`}
            label={t('task.task_lost_reason.label')}
            maxLength={250}
          />
        </div>
      </AdbDialog.Content>
    </AdbDialog>
  );
};

export default CloseTaskModal;
