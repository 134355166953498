import SmartComLinkOut from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut';
import {
  buildLinkOutConfig,
  type LinkOutConfig,
} from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut.config';
import { Button } from '@smart/react-components';
import { SMART_COM_LINKS } from '@utils/configs/config';
import { useAgentContext } from 'contexts/agent-context';
import { useAuthContext } from 'contexts/auth-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useCustomerByIdQuery } from 'graphql/queries/customer.generated';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { TaskActionProps } from '../types';

export const CheckoutButton = ({
  task,
  disabled,
}: Omit<TaskActionProps, 'onComplete'>) => {
  const { t } = useTranslation();

  const { market: selectedAppMarket } = useMarketContext();
  const { agent } = useAgentContext();
  const { auth } = useAuthContext();
  const referrerPath = useLocation().pathname;

  const { locale: selectedAppLanguage } = useLanguageContext();

  const { customer, opportunityId } = task.original;
  const isLeadWithoutOpportunity = customer.sfCustomerId?.startsWith('00Q');

  const { data, loading: customerIsLoading } = useCustomerByIdQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        customerId: customer.uuid ?? '',
        sfCustomerId: customer.sfCustomerId ?? '',
        ...(customer.__typename === 'TaskBusinessCustomer' && {
          sfOrgId: customer.sfOrgId ?? '',
        }),
      },
    },
    skip: !customer.uuid || !customer.sfCustomerId || isLeadWithoutOpportunity,
  });

  const linkOutConfig: LinkOutConfig = buildLinkOutConfig({
    language: selectedAppLanguage,
    market: selectedAppMarket ?? '',
    outletId: agent?.outletId,
    referrerPath,
    customerUserId: customer?.userId,
    customerType: data?.customerById?.accountType,
    customerUUID: customer?.uuid,
    customerPreferredLang: data?.customerById?.preferredLanguage,
    ...(opportunityId && {
      opportunityId,
    }),
  });

  const taskCustomerIsFullAccount = customer.regType === 'Full';
  const isDisabled = customerIsLoading || !customer;
  const isCheckoutDisabled =
    isDisabled ||
    !taskCustomerIsFullAccount ||
    isLeadWithoutOpportunity ||
    disabled;

  return (
    <SmartComLinkOut
      redirectPath={SMART_COM_LINKS.customizer}
      config={{
        ...linkOutConfig,

        ...{
          accessToken: auth ? auth['access-token'] : undefined,
          idToken: auth ? auth['id-token'] : undefined,
          refreshToken: auth ? auth['refresh-token'] : undefined,
        },
      }}
      disabled={isCheckoutDisabled}
    >
      <Button
        disabled={isCheckoutDisabled}
        variant="primary"
        loading={customerIsLoading}
      >
        <Button.Spinner />
        {t('task.lead.start_checkout')}
      </Button>
    </SmartComLinkOut>
  );
};
