import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { Text } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import './PosIdentModal.scss';

const BASE_CLASS = 'adb-id-actions-modal';

const LoadingScreen = () => {
  const { t } = useTranslation();

  return (
    <div className={`${BASE_CLASS}__loading`}>
      <Text variant="hl-100" as="h2">
        {t('task.pos_ident.form.loading')}
      </Text>
      <LoadingIndicator className={`${BASE_CLASS}__spinner`} />
    </div>
  );
};

export default LoadingScreen;
