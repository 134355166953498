import { CustomerAccountType as GQLCustomerAccountType } from '@smart/adb-shared';

export const formatPriceByLocale = (
  locale: string,
  price?: number,
  currency?: string
): string => {
  if (!price || !currency) return '';

  const formattedSum = price.toLocaleString(locale, {
    style: 'currency',
    currency,
  });
  return formattedSum;
};

export const getFormattedRecyclingContribution = (
  locale: string,
  price: number,
  currency: string,
  customerType?: GQLCustomerAccountType
) => {
  const formattedPrice = formatPriceByLocale(locale, price, currency);

  if (customerType === GQLCustomerAccountType.Business) {
    return `${formattedPrice} VAT excl.`;
  }

  return `${formattedPrice} VAT incl.`;
};
