import { iot, mqtt } from 'aws-iot-device-sdk-v2';
import { useEffect, useState } from 'react';
import useAWSCredentials, { AwsCredentials } from './useAWSCredentials';

const connectWebsocket = (credentials: AwsCredentials) =>
  new Promise<mqtt.MqttClientConnection>((res, rej) => {
    const config =
      iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket()
        .with_clean_session(true)
        .with_client_id(`adb_pub_sub_(${new Date()})`)
        .with_endpoint(import.meta.env.IOT_ENDPOINT_ATS)
        .with_credentials(
          'eu-central-1',
          credentials.accessKeyId,
          credentials.secretAccessKey,
          credentials.sessionToken
        )
        .with_keep_alive_seconds(30)
        .build();

    config.use_websocket = true;
    const client = new mqtt.MqttClient();
    const connection = client.new_connection(config);

    connection.on('connect', () => {
      console.log('[useMqttSubscription]: connected');
      res(connection);
    });

    connection.on('message', (message) => {
      console.log('[useMqttSubscription]: message', message);
    });

    connection.on('error', (err) => {
      console.log('[useMqttSubscription]: connection error', err);
      rej(err);
    });

    connection.on('closed', (err) => {
      console.log('[useMqttSubscription]: connection closed', err);
    });

    connection.connect();
  });

const useMqttSubscription = <T,>({ topic }: { topic: string }) => {
  const [value, setValue] = useState<T>();
  const credentials = useAWSCredentials();

  useEffect(() => {
    if (credentials) {
      connectWebsocket(credentials).then(
        (connection: mqtt.MqttClientConnection) => {
          connection
            .subscribe(topic, mqtt.QoS.AtLeastOnce, (t, payload) => {
              console.log('I GOT A MESSAGE');
              const decoder = new TextDecoder('utf8');
              const message = decoder.decode(new Uint8Array(payload));
              setValue(JSON.parse(message));
              console.log(
                `Message received: topic="${t}" message="${message}"`
              );
            })
            .then((subscription) => {
              console.log(`subscribed to ${subscription.topic}`);
            });
        }
      );
    }
  }, [credentials, topic]);

  return { value, setValue };
};

export default useMqttSubscription;
