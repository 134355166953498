import CustomerDetails from './details/index';
import Documents from './documents';
import Orders from './orders';
import Overview from './overview/index';
import Products from './purchased-products';
import CustomerRegister from './register';
import CustomerSearch from './search';

export const customer = [
  {
    path: '/customer',
    component: CustomerSearch,
    content: 'navigation.dashboard.customers',
    title: 'navigation.dashboard.customers',
  },

  {
    path: '/customer/register',
    component: CustomerRegister,
    content: 'navigation.customer.register',
    title: 'navigation.customer.register',
  },
  {
    path: '/customer/overview/:customerId/:sfCustomerId',
    component: Overview,
    content: 'navigation.customer.overview',
    title: 'navigation.customer.overview',
  },
  {
    path: '/customer/overview/:customerId/:sfCustomerId/:bpid/:sfOrgId',
    component: Overview,
    content: 'navigation.customer.overview',
    title: 'navigation.customer.overview',
  },
  {
    path: '/customer/details/:customerId/:sfCustomerId',
    component: CustomerDetails,
    content: 'navigation.customer.details',
    title: 'navigation.customer.details',
  },
  {
    path: '/customer/details/:customerId/:sfCustomerId/:bpid/:sfOrgId',
    component: CustomerDetails,
    content: 'navigation.customer.details',
    title: 'navigation.customer.details',
  },
  {
    path: '/customer/documents/:customerId/:sfCustomerId',
    component: Documents,
    content: 'navigation.customer.documents',
    title: 'navigation.customer.documents',
  },
  {
    path: '/customer/documents/:customerId/:sfCustomerId/:bpid/:sfOrgId',
    component: Documents,
    content: 'navigation.customer.documents',
    title: 'navigation.customer.documents',
  },
  {
    path: '/customer/orders/:customerId/:sfCustomerId',
    component: Orders,
    content: 'navigation.customer.orders',
    title: 'navigation.customer.orders',
  },
  {
    path: '/customer/orders/:customerId/:sfCustomerId/:bpid/:sfOrgId',
    component: Orders,
    content: 'navigation.customer.orders',
    title: 'navigation.customer.orders',
  },
  {
    path: '/customer/purchased-products/:customerId/:sfCustomerId',
    component: Products,
    content: 'navigation.customer.products',
    title: 'navigation.customer.products',
  },
  {
    path: '/customer/purchased-products/:customerId/:sfCustomerId/:bpid/:sfOrgId',
    component: Products,
    content: 'navigation.customer.products',
    title: 'navigation.customer.products',
  },
];
