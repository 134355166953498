import { useTranslation } from 'react-i18next';
import { string } from 'yup';

export const CalendarAppointmentCar = () => {
  const { t } = useTranslation();

  const schemaObject = {
    carId: string().required(
      `${t('feature_calendar.form_fields.democar')}
            ${t('general.error_messages.validations.is_required')}`
    ),
  };

  return schemaObject;
};
