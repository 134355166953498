import EmptyLayout from '@smart/components-adb/atoms/EmptyDataLayout/EmptyLayout';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { Flex } from '@smart/react-components';
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { CarMapper } from '@ui/data-models/car/car.mapper';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { useTranslation } from 'react-i18next';
import AdbDynamicImage from '../AdbDynamicImage/AdbDynamicImage';
import { imageConfig4To3 } from '../AdbDynamicImage/AdbDynamicImage.config';
import Pagination from '../Pagination/Pagination';
import BaseInfo from './BaseInfo';
import CartDetail from './CartDetail';
import './CartItems.scss';
import { useCartsContext } from './CartItemsContext';
import CustomerDetails from './CustomerDetails';
import CartsSearch from './Search/Search';

const defaultSize = 300;

const BASE_CLASS = 'adb-cart-items';

const CartItems = () => {
  const { loading, allCarts, pagination } = useCartsContext();
  const { t } = useTranslation();

  const tableInstance = useReactTable({
    columns: [{ accessorKey: 'id' }],
    data: allCarts,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: false,
    getFilteredRowModel: getFilteredRowModel(),
    pageCount: pagination.totalPages ?? 0,
    manualPagination: true,
  });

  return (
    <Flex direction="column" height="full">
      <AdbLayout.Header>
        <CartsSearch />
      </AdbLayout.Header>
      <AdbLayout.Content backgroundColor="bg-level-2">
        {!loading && allCarts.length > 0 ? (
          tableInstance.getRowModel().rows.map((row) => (
            <Flex
              className={`${BASE_CLASS}__item-content`}
              key={row.original.ald.code}
            >
              <Flex width="1/5">
                <Flex height="fit-content">
                  <AdbDynamicImage
                    imageUrl={CarMapper.getImageURL(row.original.ald.code)}
                    imageAltText={row.original.ald.code}
                    imageConfig={imageConfig4To3}
                  />
                </Flex>
              </Flex>
              <Flex width="full" direction="column" rowGap={400}>
                <BaseInfo detail={row.original.ald} />
                <CartDetail detail={row.original.cartDetails} />
                <CustomerDetails detail={row.original.customerDetails} />
              </Flex>
            </Flex>
          ))
        ) : (
          <div key="loading" className={`${BASE_CLASS}__loading`}>
            <LoadingIndicator loading={loading}>
              <EmptyLayout message={t('orders.ald_carts.empty_msg')} />
            </LoadingIndicator>
          </div>
        )}
      </AdbLayout.Content>
      {!loading && (
        <AdbLayout.Footer backgroundColor="bg-level-2">
          <Pagination instance={tableInstance} defaultSize={defaultSize} />
        </AdbLayout.Footer>
      )}
    </Flex>
  );
};

export default CartItems;
