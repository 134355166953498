import { MarketCode } from '@utils/market/types';
import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { GetMaxLengthValidationMsg } from '../helpers/utils';

export const VatRegistrationNumberSchema = (market: MarketCode) => {
  const { t } = useTranslation();

  // regex used below are mentioned in - https://smarteurope.atlassian.net/browse/SMAR-43982
  const VALID_VAT_REGISTRATION_PATTERNS: Partial<Record<MarketCode, string[]>> =
    {
      de: [
        '^(DE[0-9]{9})$',
        '^(?=([0-9]|/){11,14}$)(?:[0-9]/?){11}$',
        '^(?=([0-9]|/){13,16}$)(?:[0-9]/?){13}$',
      ],
      fr: ['^(FR[A-Z0-9]{11})$'],
      es: ['^(ES[A-Z0-9]{9})$', '^[A-Z0-9]{9}$'],
      it: ['^(IT[A-Z0-9]{11})$', ' ^[A-Z0-9]{16}$', '^(SM[0-9]{5})$'],
      ch: ['^(CH[A-Z0-9]{10,50})$', '^[A-Z0-9]{9}$'],
      at: ['[A-Z0-9]{2}-[A-Z0-9]{3}/[A-Z0-9]{4}', 'ATU[A-Z0-9]{8}'],
    };

  const VALID_VAT_REGISTRATION_PATTERNS_HUMAN_READABLE: Partial<
    Record<MarketCode, string[]>
  > = {
    at: ['ATU12345678', '12-345/6789'],
  };

  const defaultPattern = /^[A-Z0-9]*$/;

  /*
   * @function getAllowedVatRegExp
   * This function takes in param as market code
   * returns the corresponding regex by combining them ( if more than 1)
   */
  const getAllowedVatRegExp = (marketCode: MarketCode): RegExp => {
    const pattern = VALID_VAT_REGISTRATION_PATTERNS[marketCode];
    if (pattern) {
      return new RegExp(
        `^(?:${VALID_VAT_REGISTRATION_PATTERNS[marketCode]?.join('|')})$`
      );
    }
    return defaultPattern;
  };

  const getHumanReadablePatternHint = (marketCode: MarketCode): string => {
    const humanReadablePatternHint =
      VALID_VAT_REGISTRATION_PATTERNS_HUMAN_READABLE[marketCode];
    return humanReadablePatternHint
      ? t('general.error_messages.validations.input_hint', {
          format: humanReadablePatternHint.join(', '),
        })
      : '';
  };

  const humanReadablePatternHint = getHumanReadablePatternHint(market);

  let schemaObject = {};

  if (market) {
    switch (market) {
      case 'de':
      case 'gb':
        schemaObject = {
          vatRegistrationNumber: string()
            .matches(
              getAllowedVatRegExp(market),
              `${t('general.error_messages.validations.invalid')} ${t(
                'form_fields.business.vat'
              )} ${humanReadablePatternHint ? `- ${humanReadablePatternHint}` : ''} `
            )
            .max(
              50,
              GetMaxLengthValidationMsg(t('form_fields.business.vat'), 50)
            ),
        };
        break;
      default:
        schemaObject = {
          vatRegistrationNumber: string()
            .required(
              `${t('form_fields.business.vat')} ${t(
                'general.error_messages.validations.is_required'
              )}`
            )
            .matches(
              getAllowedVatRegExp(market),
              `${t('general.error_messages.validations.invalid')} ${t(
                'form_fields.business.vat'
              )} ${humanReadablePatternHint ? `- ${humanReadablePatternHint}` : ''} `
            )
            .max(
              50,
              GetMaxLengthValidationMsg(t('form_fields.business.vat'), 50)
            ),
        };
        break;
    }
  }

  return schemaObject;
};
