import { ExtendedCar } from '@smart/adb-shared';
import { AdbDatePicker } from '@smart/components-adb/adb-required-label/AdbDatePicker';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import { Button } from '@smart/react-components';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import { format } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { useModal } from '../../Modal';
import '../index.scss';
import { ConfirmModal } from './ConfirmModal';
import { HandoverFormValues } from './config';

const BASE_CLASS = 'adb-car-activation';

const HandoverForm = ({ car }: { car: ExtendedCar }) => {
  const { vin, installDate: initialInstallDate } = car;
  const { t } = useTranslation();
  const { registerModal } = useModal();
  const demoOrderingEnabled = useFeatureFlag({
    key: 'demo-vehicle-ordering',
    defaultValue: false,
  });

  if (!demoOrderingEnabled) return null;

  const initialValues = {
    installDate: initialInstallDate ?? '',
  };

  const hasInstallDate = !!initialValues.installDate;

  const onSubmit = (values: HandoverFormValues) => {
    registerModal(<ConfirmModal {...values} vin={vin} />);
  };

  const DateOrNull = (date: string) => (date ? new Date(date) : null);
  const validationSchema = object().shape({
    installDate: string().required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(props: FormikProps<{ installDate: string }>) => {
        const {
          handleSubmit,
          errors,
          touched,
          dirty,
          values,
          setFieldValue,
          setFieldTouched,
        } = props;

        const onInstallDateChange = (date: Date | null) => {
          setFieldTouched('installDate', true);
          setFieldValue(
            'installDate',
            date ? format(new Date(date), 'yyyy-MM-dd') : ''
          );
        };

        const submitDisabled = Boolean(!dirty || errors.installDate);

        return hasInstallDate ? null : (
          <form autoComplete="on" onSubmit={handleSubmit}>
            <div className={`${BASE_CLASS}__form`}>
              <div className={`${BASE_CLASS}__form-input`}>
                <AdbDatePicker
                  showIcon
                  id={`install_date-${vin}`}
                  name="installDate"
                  selected={DateOrNull(values.installDate)}
                  label={t('car.assets.handover_date')}
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                  onChange={onInstallDateChange}
                  schema={validationSchema}
                  required
                />
                {errors.installDate && touched.installDate && (
                  <ErrorField errorMsg={errors.installDate} />
                )}
              </div>
              <div className={`${BASE_CLASS}__form-actions`}>
                <Button
                  variant="primary"
                  type="submit"
                  mode={200}
                  disabled={submitDisabled}
                >
                  {t('customer.purchased_products.buttons.save')}
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default HandoverForm;
