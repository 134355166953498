import { AppointmentStatus, AppointmentType } from '@smart/adb-shared';
import { Text } from '@smart/react-components';
import { getDaysInCurrentMonth, getTimeFormat } from '@ui/library/helpers/date';
import { getCustomerRoute } from '@utils/helpers/route';
import classNames from 'classnames';
import { useMarketContext } from 'contexts/market-context';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AdbLink from '../../atoms/AdbLink/AdbLink';
import { CalendarWeekDaysProps, Weekdays } from './CalendarWeekDays.config';
import './CalendarWeekDays.scss';

const BASE_CLASS = 'adb-week-days';

const getWeekdays = (lang: string) =>
  (Weekdays as any)[lang] ?? Weekdays['en-GB'];

const CalendarWeekDays = ({
  selected,
  start,
  appointments,
  handleDateSelection = () => {},
  handleAppointmentClick = () => {},
  language,
}: CalendarWeekDaysProps) => {
  const { t } = useTranslation();
  const { market } = useMarketContext();

  const [selectedDate, setSelectedDate] = useState<number>(
    new Date(selected).getDate()
  );

  const filteredAppointments = appointments
    ? appointments
        .filter((item) => {
          const value = new Date(item.start).getDate() === selectedDate;
          return value;
        })
        .sort(
          (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
        )
    : [];

  // used to calculate the date from ISOString to date in number using the index
  const getWeekDates = (date: string, increment: number): number => {
    const startDate = new Date(date);
    const calculatedDate = startDate.getDate() + increment;

    const noOfDaysInMonth =
      calculatedDate >= 28 && getDaysInCurrentMonth(startDate);

    if (noOfDaysInMonth && calculatedDate > noOfDaysInMonth) {
      return calculatedDate - noOfDaysInMonth;
    }

    return calculatedDate;
  };

  // used to set the selected date based on selection from the index
  // calls the handleDateSelection function
  const handleWeekDateSelection = (index: number): void => {
    const startDate = new Date(start);
    startDate.setDate(startDate.getDate() + index);

    const dateSelected = startDate.toISOString();

    setSelectedDate(startDate.getDate());
    handleDateSelection(dateSelected);
  };

  const getTitle = (title: string, carId?: string): string =>
    carId ? `${t(title)} : ${carId}` : t(title);

  const navigate = useNavigate();

  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}__days`}>
        {getWeekdays(language).map((day: string, index: number) => {
          if (index > 7) return null;
          return (
            <div
              role="button"
              aria-label={`day-${day}`}
              onClick={() => handleWeekDateSelection(index)}
              onKeyDown={() => handleWeekDateSelection(index)}
              tabIndex={0}
              key={day}
              className={classNames(`${BASE_CLASS}__content`, {
                [`${BASE_CLASS}__content--selected`]:
                  getWeekDates(start, index) === selectedDate,
              })}
            >
              <Text
                variant="p-200"
                as="p"
                className={`${BASE_CLASS}__weeks-text`}
              >
                {day[0]}
              </Text>
              <Text variant="p-200" as="p">
                {getWeekDates(start, index)}
              </Text>
            </div>
          );
        })}
      </div>

      {filteredAppointments.length > 0 && (
        <div className={`${BASE_CLASS}__appointments`}>
          {filteredAppointments.map((appointment) => (
            <div
              role="button"
              key={`your_appointments_${appointment.id}`}
              onClick={() => {
                navigate('/calendar', {
                  state: {
                    appointmentId: appointment.id,
                    start: new Date(appointment.start),
                  },
                });
              }}
              onKeyDown={() => handleAppointmentClick()}
              tabIndex={0}
              className={`${BASE_CLASS}__appointment-wrap`}
            >
              {appointment?.start && appointment?.end && (
                <div
                  className={classNames(`${BASE_CLASS}__appointment-time`, {
                    [`${BASE_CLASS}__appointment-time--pending`]:
                      appointment.status === AppointmentStatus.Requested,
                  })}
                >
                  <Text
                    variant="cap-200"
                    as="p"
                    className={`${BASE_CLASS}__appointment-time--bold`}
                  >
                    {getTimeFormat(new Date(appointment?.start))}
                  </Text>
                  {market === 'se' ? t('dashboard.to') : '-'}
                  <Text
                    variant="cap-200"
                    as="p"
                    className={`${BASE_CLASS}__appointment-time--bold`}
                  >
                    {getTimeFormat(new Date(appointment?.end))}
                  </Text>
                </div>
              )}

              <div className={`${BASE_CLASS}__appointment-details`}>
                <div>
                  {appointment.type && appointment.title && (
                    // if appointment type is "Handover" then show vin number else show license plate number for Test Drive
                    <Text variant="cap-200" as="p">
                      {getTitle(
                        appointment.title,
                        appointment.type === AppointmentType.Handover
                          ? appointment.car?.carId
                          : (appointment.car?.licensePlate ?? '')
                      )}
                    </Text>
                  )}
                </div>

                {!!appointment?.customer?.firstName && (
                  <div
                    role="button"
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    tabIndex={0}
                    className={`${BASE_CLASS}__name-text`}
                    aria-label={[
                      appointment.customer?.firstName,
                      appointment.customer?.lastName,
                    ].join(' ')}
                  >
                    <AdbLink
                      variant="hyperlink-200"
                      title={[
                        appointment.customer?.firstName,
                        appointment.customer?.lastName,
                      ].join(' ')}
                      path={getCustomerRoute(appointment.customer)}
                      additionalClasses={`${BASE_CLASS}__name-link`}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {filteredAppointments.length === 0 && (
        <div className={`${BASE_CLASS}__no-items`}>
          <Text variant="hl-300">0</Text>
          <Text
            variant="cap-300"
            as="p"
            className={`${BASE_CLASS}__no-items-info`}
          >
            {t('dashboard.appointments')}{' '}
            {t('dashboard.right_now').toLowerCase()}
          </Text>
        </div>
      )}
    </div>
  );
};

export default CalendarWeekDays;
