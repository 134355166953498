import { Text } from '@smart/react-components';
import { useCustomerContext } from 'contexts/customer-context';
import { useNavigate } from 'react-router-dom';
import AdbIcon from '../../atoms/AdbIcon/AdbIcon';
import {
  IconBackground,
  IconBackgroundSize,
  IconColor,
} from '../../atoms/AdbIcon/AdbIcon.config';
import AdbLink from '../../atoms/AdbLink/AdbLink';
import CaseModal from '../CaseModal/CaseModal';
import { useModal } from '../Modal';
import { DashboardItemProps, DashboardItemType } from './DashboardItem.config';
import './DashboardItem.scss';

const BASE_CLASS = 'adb-dashboard-item';

const DashboardItem = ({
  id,
  title,
  itemType,
  description = '',
  icon,
  iconColor,
  iconHasBorder,
  backgroundColor,
  additionalContent,
  isItemClickable = true,
  topRightIcon,
}: DashboardItemProps) => {
  const navigate = useNavigate();
  const { customer } = useCustomerContext();
  const { registerModal } = useModal();

  const onItemClick = (item: string) => {
    if (isItemClickable) {
      if (itemType === DashboardItemType.REQUEST) {
        navigate(`/tasks?search=${item}`, {
          state: {
            idToBeExpanded: item,
            initialTab: 'all',
          },
        });
      } else if (itemType === DashboardItemType.TASK) {
        navigate(`/tasks?search=${item}`, {
          state: {
            idToBeExpanded: item,
            initialTab: 'all',
          },
        });
      } else if (itemType === DashboardItemType.CASE) {
        registerModal(
          <CaseModal caseId={item} customerId={customer?.uuid ?? ''} />
        );
      }
    }
  };

  const dashboardDetails = (
    <>
      {topRightIcon && (
        <div className={`${BASE_CLASS}__top_right_icon`}>
          <AdbIcon
            icon={topRightIcon}
            backgroundColor={IconBackground.TRANSPARENT}
            color={IconColor.BLACK}
            backgroundSize={IconBackgroundSize.MEDIUM}
          />
        </div>
      )}
      <AdbIcon
        icon={icon}
        backgroundColor={backgroundColor}
        color={iconColor}
        iconHasBorder={iconHasBorder}
        backgroundSize={IconBackgroundSize.MEDIUM}
      />
      <div className={`${BASE_CLASS}__details`}>
        {isItemClickable ? (
          <AdbLink variant="hyperlink-200" title={title} notLink />
        ) : (
          <Text
            variant="cap-300"
            as="p"
            className={`${BASE_CLASS}__details-title`}
          >
            {title}
          </Text>
        )}
        <Text
          variant="cap-300"
          as="p"
          className={`${BASE_CLASS}__details-description`}
        >
          {description}
        </Text>
        {additionalContent && additionalContent}
      </div>
    </>
  );

  const onClick = () => onItemClick(id);
  const onKeyDown = () => {};

  return isItemClickable ? (
    <div
      role="button"
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={0}
      className={BASE_CLASS}
    >
      {dashboardDetails}
    </div>
  ) : (
    <div className={BASE_CLASS}>{dashboardDetails}</div>
  );
};

export default DashboardItem;
