import { McsAgent, Outlet } from '@smart/adb-shared';

export enum ExpertActionType {
  SET_EXPERTS = 'CALENDAR/SET EXPERTS',
  SET_CURRENT_EXPERT = 'CALENDAR/SET CURRENT EXPERT',
  SET_OUTLET = 'CALENDAR/SET OUTLET',
}

export type ExpertAction =
  | { type: ExpertActionType.SET_EXPERTS; experts: McsAgent[] }
  | { type: ExpertActionType.SET_CURRENT_EXPERT; currentExpert: McsAgent }
  | { type: ExpertActionType.SET_OUTLET; outlet: Outlet };
