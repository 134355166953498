import { Icon } from '@smart/react-components';
import classnames from 'classnames';
import { AdbIconProps } from './AdbIcon.config';
import './AdbIcon.scss';

const BASE_CLASS = 'adb-icon-wrapper';

const AdbIcon = ({
  icon,
  backgroundSize = 'default',
  color,
  size,
  backgroundColor,
  iconHasBorder,
  disabled,
  additionalClasses,
}: AdbIconProps) => {
  const classes = classnames(
    BASE_CLASS,
    `${BASE_CLASS}--${color}`,

    additionalClasses,
    {
      [`${BASE_CLASS}--bg-${backgroundColor}`]: backgroundColor && !disabled,
      [`${BASE_CLASS}--bg-disabled`]: disabled,
      [`${BASE_CLASS}--bg-radius ${BASE_CLASS}--bg-${backgroundSize}`]:
        backgroundColor,
      [`${BASE_CLASS}--border ${BASE_CLASS}--border-${color}`]: iconHasBorder,
      [`${BASE_CLASS}--${backgroundSize}`]: backgroundSize,
    }
  );

  return (
    <div className={classes} data-testid={icon}>
      <Icon icon={icon} className={`${BASE_CLASS}--${size}`} />
    </div>
  );
};

export default AdbIcon;
