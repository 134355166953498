import { Text } from '@smart/react-components';
import { ErrorFieldProps } from './ErrorField.config';
import './ErrorField.scss';

const BASE_CLASS = 'adb-error-field';

const ErrorField = ({ errorMsg }: ErrorFieldProps) => {
  if (!errorMsg) {
    return null;
  }

  return (
    <Text variant="cap-300" as="p" className={BASE_CLASS}>
      {errorMsg}
    </Text>
  );
};

export default ErrorField;
