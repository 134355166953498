import AdbLink from '@smart/components-adb/atoms/AdbLink/AdbLink';
import { Button } from '@smart/react-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FooterListItemProps } from './FocusFooter.config';
import './FocusFooter.scss';

const BASE_CLASS = 'adb-focus-footer';

const FocusFooter = ({
  backButtonLabel,
  backButtonRoute,
  footerFirstJSX,
  footerSecondJSX,
  nextButtonLabel,
  isNextButtonDisabled = false,
  isNextButtonLoading = false,
  nextButtonHandler,
  nextButtonRoute,
  darkMode = false,
  isRequiredFooterSeparator = true,
  footerRight = null,
}: FooterListItemProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const nextButtonClick = () => {
    if (nextButtonHandler) nextButtonHandler();
    if (nextButtonRoute) navigate(nextButtonRoute, { replace: true });
  };

  return (
    <div
      className={classnames(BASE_CLASS, {
        'dark-theme': darkMode,
        [`${BASE_CLASS}__border-grey`]: darkMode && isRequiredFooterSeparator,
        [`${BASE_CLASS}__border-black`]: !darkMode && isRequiredFooterSeparator,
      })}
    >
      <div className={`${BASE_CLASS}__inner`}>
        <div className={`${BASE_CLASS}__back`}>
          {backButtonRoute ? (
            <AdbLink
              path={backButtonRoute}
              title={backButtonLabel ?? t('general.labels.back')}
              icon={{ icon: 'arrow-left' }}
              iconPosition="left"
            />
          ) : (
            (footerFirstJSX ?? '')
          )}
        </div>

        <div className={`${BASE_CLASS}__middle`}>
          {backButtonRoute ? footerFirstJSX : footerSecondJSX}
        </div>

        <div className={`${BASE_CLASS}__items`}>
          {nextButtonLabel && (
            <Button
              loading={isNextButtonLoading}
              disabled={isNextButtonDisabled}
              mode={200}
              variant="primary"
              onClick={nextButtonClick}
            >
              <Button.Spinner />
              {nextButtonLabel}
            </Button>
          )}
          {footerRight}
        </div>
      </div>
    </div>
  );
};

export default FocusFooter;
