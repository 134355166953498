import { Maybe } from '@smart/adb-shared';
import { AdbLocale } from '@ui/library/helpers/date-locale';
import { MarketCode } from '@utils/market/types';

export const truncateLanguage = (language: AdbLocale) =>
  language.substring(0, 2) ?? '';

/**
 * @type LinkOutConfig
 * Config Prop types for <SmartComLinkOut>
 */
export type LinkOutConfig = {
  language: string;
  market: MarketCode;
  outletId?: string;
  referrerPath?: string;
  currentEnv?: string;
  customerUserId?: Maybe<string>;
  customerPreferredLang?: Maybe<string>;
  customerType?: Maybe<string>;
  customerUUID?: Maybe<string>;
  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
  demoVehicle?: 'true';
  showroomVehicle?: 'true';
  opportunityId?: string;
};

export const buildLinkOutConfig = (input: LinkOutConfig): LinkOutConfig => ({
  customerUserId: input.customerUserId,
  currentEnv: import.meta.env.DEPLOY_ENV,
  language: truncateLanguage((input.language as AdbLocale) ?? ''),
  market: input.market ?? '',
  referrerPath: input.referrerPath,
  outletId: input.outletId,
  customerPreferredLang: input.customerPreferredLang,
  customerType: input.customerType,
  customerUUID: input.customerUUID,
  accessToken: input.accessToken,
  idToken: input.idToken,
  refreshToken: input.refreshToken,
  demoVehicle: input.demoVehicle,
  showroomVehicle: input.showroomVehicle,
  opportunityId: input.opportunityId,
});

/**
 * @type SmartComLinkOutProps
 * Prop types for <SmartComLinkOut>
 */
export type SmartComLinkOutProps = {
  children: JSX.Element;
  config?: LinkOutConfig;
  redirectPath?: string;
  disabled?: boolean;
};

/**
 * @type LinkOut
 * To be used as an extension type for props
 */
export type LinkOut = {
  isLinkOut?: boolean;
  redirectPath?: string;
};

/**
 * @enum CurrentEnvironment
 * Enum for current deploy environment
 */
export enum CurrentEnvironment {
  prod = '',
  dev = 'dev.',
  qa = 'stage.',
}
