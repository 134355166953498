import {
  AppointmentType,
  CustomerBusiness,
  CustomerPrivate,
} from '@smart/adb-shared';
import {
  AdbLocale,
  getCustomFormattedDate,
} from '@ui/library/helpers/date-locale';
import { addMinutes } from 'date-fns';
import { CalendarAppointment } from '../config';

export type CreateLightCustomerValues = {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
};

export type CreateAppointmentFormValues = {
  customerName: string;
  carId: string;
  expertId: string;
  appointmentType: AppointmentType;
  startDate: string;
  endDate: string;
  searchedCustomer: string;
};

export type CreateAppointmentInitialData = {
  appointmentType: AppointmentType;
  orderNumber?: string;
  carId: string;
  endDate: string;
  outletId: string;
  searchedCustomer: string;
  startDate: string;
  expertId?: string;
  customerFirstName?: string;
  customerLastName?: string;
  customerPhoneNumber?: string;
  customerEmail?: string;
  customerId?: string;
  customer?: CustomerPrivate | CustomerBusiness | null;
};

export type CreateAppointmentDialogProps = {
  initialData: CreateAppointmentInitialData;
  calendarDateRange: [Date, Date];
  appointment?: CalendarAppointment;
  setSelectedAppointment?: (appointment: CalendarAppointment) => void;
  taskId?: string;
  initialShowLightReg?: boolean;
  onClose?: () => void;
};

export type AppointmentCarSelectOption = {
  label: string;
  value: string;
};

export const getInitialAppointmentTime = (date: Date): Date => {
  const minutes = date.getMinutes();
  if (minutes <= 25) {
    date.setMinutes(30);
    return date;
  }
  if (minutes > 25 && minutes <= 55) {
    date.setMinutes(0);
    date.setHours(date.getHours() + 1);
    return date;
  }

  date.setMinutes(30);
  date.setHours(date.getHours() + 1);
  return date;
};

export const initialData = ({
  locale,
  customerId,
  appointmentType,
  carId,
}: {
  locale: AdbLocale;
  customerId?: string;
  appointmentType?: AppointmentType;
  carId?: string | undefined | null;
}) => ({
  searchedCustomer: '',
  outletId: '',
  appointmentType: appointmentType ?? AppointmentType.TestDrive,
  carId: carId ?? '',
  startDate: getCustomFormattedDate(
    getInitialAppointmentTime(new Date()),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    locale
  ),
  endDate: getCustomFormattedDate(
    getInitialAppointmentTime(addMinutes(new Date(), 30)),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    locale
  ),
  customerId,
  expertId: '',
});

export const isHandoverAppointment = (
  appointmentType: AppointmentType
): boolean => appointmentType === AppointmentType.Handover;

/**
 * @method isTestDriveAppointment
 * check if appointment is test drive appointment
 *
 * @param appointmentType
 * @returns boolean
 */
export const isTestDriveAppointment = (
  appointmentType: AppointmentType
): boolean => appointmentType === AppointmentType.TestDrive;
