/**
 * @enum AuthErrorType
 * Allowed auth error messages displayed to user
 */
export enum AuthErrorType {
  ACCESS_DENIED = 'access_denied',
  SOMETHING_WRONG = 'something_went_wrong',
  TOKENS_EXPIRED = 'tokens_expired',
  NO_ROLES_ASSIGNED = 'no_roles_assigned',
  USER_INACTIVE_CDC = 'user_inactive_cdc',
  USER_INACTIVE_MCS = 'user_inactive_mcs',
  NETWORK_ERROR = 'network_error',
  INVALID_GRANT = 'invalid_grant',
  INVALID_USER_ACCOUNT = 'invalid_user_account',
  ECOM_TOKEN_ERROR = 'ecom_token_error',
  USER_MISMATCH_ERROR = 'user_mismatch_error',
  ACCESS_TOKEN_ERROR = 'access_token_error',
}

/**
 * @type AuthAgentIDTokenResponsePayload
 * request payload to fetch account info
 */
export type AuthAgentIDTokenResponsePayload = {
  aud: string;
  at_hash: string;
  auth_time: number;
  exp: number;
  iss: string;
  iat: number;
  nonce: string;
  sub: string;
};
