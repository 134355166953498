import { Document } from '@smart/adb-shared';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Flex, Text } from '@smart/react-components';
import { useNotificationContext } from 'contexts/notification-context';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './EmbeddedSigning.scss';
import { useLoadDocuSignUrlQuery } from './queries.generated';
import useScript from './useScript';

declare global {
  interface Window {
    DocuSign?: any;
  }
}
type DocuSignSessionEndType =
  | 'signing_complete'
  | 'cancel'
  | 'decline'
  | 'exception'
  | 'fax_pending'
  | 'session_timeout'
  | 'ttl_expired'
  | 'viewing_complete';

export type Signer = {
  signerName: string;
  signerEmail: string;
};
export const EmbeddedSigning = ({
  document,
  signer,
  onSignCompleted,
}: {
  document: Document;
  signer: Signer;
  onSignCompleted?: () => void;
}) => {
  const { t } = useTranslation();
  const { addError } = useNotificationContext();

  const { closeModal } = useModal();
  const [signingCompleted, setSigningCompleted] = useState<boolean>(false);

  const { id: documentId, name: documentName } = document;
  const { signerEmail, signerName } = signer;

  const script = useScript(import.meta.env.DMP_DOCUSIGN_JS_LIB, {
    removeOnUnmount: true,
  });

  useEffect(() => {
    const handleEvents = (event: MessageEvent) => {
      if (
        event.data &&
        event.data.sender === '@embedded/signing-ui' &&
        event.data.eventType === 'sessionEnd'
      ) {
        switch (event.data.content.sessionEndType as DocuSignSessionEndType) {
          case 'signing_complete':
            // After signing should send some data somewhere
            setSigningCompleted(true);
            onSignCompleted?.();
            break;
          case 'cancel':
          case 'decline':
          case 'exception':
          case 'fax_pending':
          case 'session_timeout':
          case 'ttl_expired':
          case 'viewing_complete':
          default:
            closeModal();
            break;
        }

        window.removeEventListener('message', handleEvents);
      }
    };

    window.addEventListener('message', handleEvents);
    return () => window.removeEventListener('message', handleEvents);
  });

  const { loading, data } = useLoadDocuSignUrlQuery({
    variables: {
      input: {
        documentId,
        signerEmail,
        signerName,
      },
    },
    fetchPolicy: 'network-only',
    skip: script !== 'ready' || !documentId || !signerEmail || !signerName,
    onError: () => {
      addError({
        label: t('customer.documents.notification.error_title'),
        message: t('customer.documents.notification.error_document_sign_url'),
      });
      closeModal();
    },
  });

  const isLoading = script === 'loading' || loading;

  useEffect(() => {
    async function loadDocuSignUrl() {
      if (
        window.DocuSign &&
        data &&
        data.loadSignUrlDocuSign?.url &&
        data.loadSignUrlDocuSign?.clientId
      ) {
        const signingConfiguration = {
          url: data.loadSignUrlDocuSign.url,
          displayFormat: `focused`,
        };

        try {
          const docusign = await window.DocuSign.loadDocuSign(
            data.loadSignUrlDocuSign.clientId
          );

          const signing = docusign.signing(signingConfiguration);

          signing.mount(`#signing-ceremony`);
        } catch (e: any) {
          addError({
            label: t('customer.documents.notification.error_title'),
            message: t(
              'customer.documents.notification.error_document_sign_url'
            ),
          });
          closeModal();
        }
      }
    }
    if (script === 'ready' && data) {
      loadDocuSignUrl();
    }
  }, [addError, closeModal, data, documentId, script, t]);

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!loading && !signingCompleted && <div id="signing-ceremony" />}
      {signingCompleted && (
        <Flex direction="column">
          <Text>{`${t('customer.documents.headers.name')}: ${documentName}`}</Text>
          <Text>
            {`${t('general.labels.status')}: ${t('customer.documents.table.status.signed')}`}
          </Text>
        </Flex>
      )}
    </>
  );
};
