import { useTranslation } from 'react-i18next';
import { string } from 'yup';

import { GetMaxLengthValidationMsg } from '../helpers/utils';

export const RegionSchema = (required = false) => {
  const { t } = useTranslation();

  return {
    regionCode: required
      ? string()
          .required(
            `${t('form_fields.basic.region')} ${t(
              'general.error_messages.validations.is_required'
            )}`
          )
          .max(80, GetMaxLengthValidationMsg(t('form_fields.basic.region'), 80))
      : string().max(
          80,
          GetMaxLengthValidationMsg(t('form_fields.basic.region'), 80)
        ),
  };
};
