export type DocumentsSearchProps = {
  customerId?: string;
};

export interface FilterTypeValue {
  displayName: string;
  queryValue: string;
  type:
    | DocumentFilters.CATEGORY
    | DocumentFilters.STATUS
    | DocumentFilters.TYPE;
}

export enum DocumentFilters {
  TYPE = 'TYPE',
  CATEGORY = 'CATEGORY',
  STATUS = 'STATUS',
}
