export const REGEX_FORMS = {
  numberExpression: /^\d+$/,
  stringCharacters: {
    en: /^[A-Za-z.]+$/,
    de: /^[A-zÀ-ÿ-]+$/,
    default: /^[a-zA-Z\u00c0-\u017e-]+$/,
  },
  emailValidationString: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
  stringCharWithSpaceOnly: /^[A-Za-z\s]+$/,
  stringCharWithSpaceAndCommaOnly: /^[A-Za-z\s,]+$/,
  alphaNumericCharWithSpaceOnly: /^[a-zA-Z0-9\s]+$/,
  alphaNumericCharWithSpaceAndDash: /^[a-z\d\-_\s]+$/i,
  bankIBANFormatOnly:
    /\b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b/,
};
