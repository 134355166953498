import {
  SimActivationStatus,
  SimStatusErrorResponse,
  SimStatusResponse,
} from '@smart/adb-shared';

export const isErrorResponse = (
  response: SimStatusResponse
): response is SimStatusErrorResponse =>
  response.__typename === 'SimStatusErrorResponse';

export enum SimLifecycle {
  LivePeriod = 'Live period',
  SellingPeriod = 'Selling period',
}

export const isActivatable = (lifecycle: string, status: SimActivationStatus) =>
  lifecycle === SimLifecycle.SellingPeriod ||
  status === SimActivationStatus.Suspend;
