import { AppointmentType, ProductType } from '@smart/adb-shared';
import { Flex, Text } from '@smart/react-components';
import {
  getDateXMonthsAfter,
  getDateXMonthsBefore,
} from '@ui/library/helpers/date';
import { DateFormats, getLocaleDate } from '@ui/library/helpers/date-locale';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useAllAppointmentsQuery } from 'graphql/queries/appointments.generated';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Actions from '../CarListItem/Actions/Actions';
import { ActionItemType } from '../CarListItem/CarListItem.config';
import { isWallBoxItem } from './isWallboxItem';
import OrderItemFeature from './OrderItemFeature';
import { OrderItemSection } from './OrderItems.config';

const BASE_CLASS = 'adb-order-items';

const HandoverDetails = ({ orderDetail }: OrderItemSection) => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const navigate = useNavigate();
  const { agent } = useAgentContext();

  const handleRescheduleHandover = () => {
    navigate('/calendar');
  };

  const orderItemWithVehicleType = orderDetail?.items?.find(
    (order) => order.productType === ProductType.Vehicle
  );

  const { data: appointmentsData, loading: appointmentsLoading } =
    useAllAppointmentsQuery({
      variables: {
        input: {
          customerId: orderDetail?.customer?.gigyaId,
          endDateTime: getDateXMonthsAfter(2).toISOString(),
          startDateTime: getDateXMonthsBefore(2).toISOString(),
          carId: orderDetail?.vin,
          appointmentType: AppointmentType.Handover,
          outletId: agent.outletId,
        },
      },
      skip: !orderDetail?.vin,
    });

  const appointments = appointmentsData?.allAppointments.appointments ?? [];

  const renderScheduledDate = () => {
    if (appointmentsLoading) {
      return 'loading';
    }
    return appointments.length === 1 ? (
      <Link
        state={{
          key: '/calendar',
          appointmentId: appointments[0].id,
          start: appointments[0].start,
        }}
        to="/calendar"
      >
        {getLocaleDate(appointments[0].start, locale, DateFormats.DATE)}
      </Link>
    ) : (
      '-'
    );
  };

  return (
    <Flex direction="column" width="full" gap={300}>
      <Text variant="lbl-200">{t('orders.handover_details.title')}</Text>
      <Flex
        alignContent="space-between"
        width="full"
        wrap="wrap"
        gap={300}
        className={`${BASE_CLASS}__features`}
      >
        {orderDetail && isWallBoxItem(orderDetail) ? (
          <OrderItemFeature
            title={t('orders.handover_details.handover_location')}
            text={`${orderDetail?.deliveryAddress?.street ?? ''}, ${
              orderDetail?.deliveryAddress?.postalCode ?? ''
            } ${orderDetail?.deliveryAddress?.town ?? ''}`}
          />
        ) : (
          <OrderItemFeature
            title={t('orders.handover_details.current_location')}
            text={orderDetail?.currentLocation ?? '-'}
          />
        )}
        <OrderItemFeature
          title={t('orders.preferred_delivery_date')}
          text={
            (orderDetail?.requestedDeliveryDate &&
              getLocaleDate(
                orderDetail?.requestedDeliveryDate,
                locale,
                DateFormats.DATE
              )) ??
            '-'
          }
        />
        <OrderItemFeature
          title={t('orders.scheduled_delivery_date')}
          text={renderScheduledDate()}
        />
        <OrderItemFeature
          title={t('orders.handover_details.handover_location')}
          text={`${orderDetail?.deliveryAddress?.street ?? ''}, ${
            orderDetail?.deliveryAddress?.postalCode ?? ''
          } ${orderDetail?.deliveryAddress?.town ?? ''}`}
        />
        <OrderItemFeature
          title={t('orders.handover_details.registration_date')}
          text={
            (orderItemWithVehicleType &&
              orderItemWithVehicleType.handover?.registrationDate &&
              getLocaleDate(
                orderItemWithVehicleType.handover.registrationDate,
                locale,
                DateFormats.DATE
              )) ??
            '-'
          }
        />
        <OrderItemFeature
          title={t('orders.handover_details.plate_number')}
          text={
            (orderItemWithVehicleType &&
              orderItemWithVehicleType.handover?.licensePlate) ??
            '-'
          }
        />
      </Flex>
      {orderDetail?.deliveryStatus !== 'HANDED_OVER' && (
        <Actions
          actionItems={[
            {
              key: t('orders.handover_details.reschedule_handover'),
              type: ActionItemType.LINK,
              onClick: handleRescheduleHandover,
            },
          ]}
        />
      )}
    </Flex>
  );
};

export default HandoverDetails;
