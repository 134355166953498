import { ApolloError } from '@apollo/client';
import { Customer } from '@smart/adb-shared';
import { useCustomerByIdQuery } from 'graphql/queries/customer.generated';
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

interface CustomerContextValue {
  customer?: Customer;
  customerIsLoading: boolean;
  customerLoadingError?: ApolloError;
  prevListingRoute: string;
  setPrevListingRoute: Dispatch<SetStateAction<string>>;
}

export const CustomerContext = React.createContext<CustomerContextValue>({
  customer: undefined,
  customerIsLoading: false,
  prevListingRoute: '',
  setPrevListingRoute: () => {},
});

export const CustomerContextProvider = ({ children }: PropsWithChildren) => {
  const { customerId, sfCustomerId, sfOrgId } = useParams();
  const [prevListingRoute, setPrevListingRoute] = useState('');

  const {
    data,
    loading: customerIsLoading,
    error: customerLoadingError,
  } = useCustomerByIdQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        customerId: customerId ?? '',
        sfCustomerId: sfCustomerId ?? '',
        sfOrgId: sfOrgId ?? '',
      },
    },
    skip: !customerId || !sfCustomerId,
  });

  if (customerLoadingError) {
    console.error('Error loading customer by id:', customerLoadingError);
  }

  const value = useMemo(
    () => ({
      customer: data?.customerById,
      customerIsLoading,
      customerLoadingError,
      prevListingRoute,
      setPrevListingRoute,
    }),
    [
      data?.customerById,
      customerIsLoading,
      customerLoadingError,
      prevListingRoute,
      setPrevListingRoute,
    ]
  );

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerContext = () => {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error('useCustomerContext must be used within a CustomerContext');
  }
  return context;
};
