import { Button } from '@smart/react-components';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './404.scss';

export function ErrorPage404() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>404 {t('page_404.title')}</title>
        <meta name="description" content={t('page_404.description')} />
      </Helmet>
      <div className="not-found__wrapper">
        <h1 className="not-found__text">{t('page_404.headline')}</h1>

        <Button variant="secondary" onClick={() => navigate(-1)}>
          <Button.Icon icon="arrow-left" aria-label="arrow-left" />
          {t('general.labels.back')}
        </Button>
      </div>
    </>
  );
}
