import { convertMarketIdToCountryCode } from '@utils/helpers/market';
import { MarketCode } from './types';

/**
 * get market domain for the provided market
 *
 * @param market string
 * @returns string
 */
export const getMarketDomain = (market: MarketCode): string => {
  const { SITE_DOMAIN } = import.meta.env;
  const BASE_DOMAIN = SITE_DOMAIN ?? 'adb.smart.com';
  const marketCode = convertMarketIdToCountryCode(market);

  return `${marketCode}.${BASE_DOMAIN}`;
};
