import { AppointmentTimeSchema } from '@ui/validations/schemas/AppointmentTime';
import { DrivingLicenseNoSchema } from '@ui/validations/schemas/DrivingLicenseNo';
import { ReturnMileageSchema } from '@ui/validations/schemas/ReturnMileage';
import { MarketCode } from '@utils/market/types';
import { object } from 'yup';

const TestDriveContractReturnSchema = (market: MarketCode) => {
  const drivingLicenseNo = DrivingLicenseNoSchema(market, true);
  const returnMileage = ReturnMileageSchema(true);
  const appointmentTime = AppointmentTimeSchema(market, true);

  return object().shape({
    ...drivingLicenseNo,
    ...returnMileage,
    ...appointmentTime,
  });
};

export default TestDriveContractReturnSchema;
