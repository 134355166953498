import { Task } from '@smart/adb-shared';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { Checkbox, Text } from '@smart/react-components';
import { useAgentContext } from 'contexts/agent-context';
import { useNotificationContext } from 'contexts/notification-context';
import { useCompleteAppointmentMutation } from 'graphql/queries/appointments.generated';
import { AllTasksDocument } from 'graphql/queries/tasks.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutlet } from 'hooks/outlet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CompleteHandoverModal.scss';

const BASE_CLASS = 'adb-complete-handover-modal';

const CompleteHandoverModal = ({
  task,
  closeModal,
}: {
  task: Task;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const { agent } = useAgentContext();
  const outlet = useCurrentOutlet();

  const { addSuccess } = useNotificationContext();

  const [handoverDocumentConfirmed, setHandoverDocumentConfirmed] =
    useState(false);

  const [completeAppointment, { loading: isLoading }] =
    useCompleteAppointmentMutation({
      variables: {
        input: {
          appointmentId: task.appointment?.id ?? '',
          ownerId: agent?.id ?? '',
        },
      },
      onCompleted: () => {
        addSuccess({
          label: t('task.notification.task_completed_title'),
          message: t('task.notification.task_completed_description'),
        });
        closeModal();
      },
      onError: (error) => {
        enhanceError({
          error,
          label: t('task.notification.task_error_title'),
          displayMessage: t('task.notification.task_error_description'),
        });
      },
      refetchQueries: [
        {
          query: AllTasksDocument,
          variables: {
            input: {
              outletId: outlet?.mcsId,
            },
          },
        },
      ],
    });

  return (
    <AdbDialog
      id={BASE_CLASS}
      className={BASE_CLASS}
      buttons={{
        primary: {
          isLoading,
          onClick: completeAppointment,
          disabled: isLoading || !handoverDocumentConfirmed,
          label: t('feature_calendar.general.buttons.confirm'),
        },
        secondary: {
          disabled: isLoading,
          onClick: closeModal,
          label: t('feature_calendar.general.buttons.cancel'),
        },
      }}
    >
      <AdbDialog.Header>
        <Text variant="hl-100">
          {t(
            'feature_calendar.calendar_dialog.confirm_handover_complete_heading'
          )}
        </Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <div className={`${BASE_CLASS}__content`}>
          <Checkbox
            id="confirm_handover_document_signed"
            name="confirm_handover_document_signed"
            value="confirm_handover_document_signed"
            onCheckedChange={(status: boolean) =>
              setHandoverDocumentConfirmed(status)
            }
            checked={handoverDocumentConfirmed}
          >
            {t(
              `feature_calendar.calendar_dialog.confirm_handover_document_signed`
            )}
          </Checkbox>
          {t(
            'feature_calendar.calendar_dialog.confirm_handover_complete_description'
          )}
        </div>
      </AdbDialog.Content>
    </AdbDialog>
  );
};

export default CompleteHandoverModal;
