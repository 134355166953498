/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LightRegistrationMutationVariables = Types.Exact<{
  input: Types.LightRegistrationInput;
}>;


export type LightRegistrationMutation = { __typename?: 'Mutation', lightRegistration: { __typename?: 'LightRegistrationResponse', uuid: string, sfCustomerId: string } };


export const LightRegistrationDocument = gql`
    mutation LightRegistration($input: LightRegistrationInput!) {
  lightRegistration(input: $input) {
    uuid
    sfCustomerId
  }
}
    `;
export type LightRegistrationMutationFn = Apollo.MutationFunction<LightRegistrationMutation, LightRegistrationMutationVariables>;

/**
 * __useLightRegistrationMutation__
 *
 * To run a mutation, you first call `useLightRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLightRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lightRegistrationMutation, { data, loading, error }] = useLightRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLightRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<LightRegistrationMutation, LightRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LightRegistrationMutation, LightRegistrationMutationVariables>(LightRegistrationDocument, options);
      }
export type LightRegistrationMutationHookResult = ReturnType<typeof useLightRegistrationMutation>;
export type LightRegistrationMutationResult = Apollo.MutationResult<LightRegistrationMutation>;
export type LightRegistrationMutationOptions = Apollo.BaseMutationOptions<LightRegistrationMutation, LightRegistrationMutationVariables>;