import { Task } from '@smart/adb-shared';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import UploadIdForm from './UploadIdForm';

const BASE_CLASS = 'adb-id-actions-modal';

interface IUploadIdModal {
  task: Task;
  onComplete: () => void;
}

const UploadIdModal = ({ task, onComplete }: IUploadIdModal) => (
  <AdbDialog id={BASE_CLASS} size="large" className={BASE_CLASS}>
    <AdbDialog.Content>
      <UploadIdForm task={task} onComplete={onComplete} />
    </AdbDialog.Content>
  </AdbDialog>
);

export default UploadIdModal;
