import classNames from 'classnames';
import { useState } from 'react';
import AdbDynamicImage from '../AdbDynamicImage/AdbDynamicImage';
import { imageConfig4To3 } from '../AdbDynamicImage/AdbDynamicImage.config';
import Actions from './Actions/Actions';
import ActionsWithText from './ActionsWithText/ActionsWithText';
import {
  CarDetailsKey,
  CarListItemProps,
  LengthType,
} from './CarListItem.config';
import './CarListItem.scss';
import Features from './Features/Features';
import Header from './Header/Header';

const BASE_CLASS = 'adb-car-list-item';

const CarListItem = ({
  id,
  name = '',
  imageSrc = '',
  imageAltText,
  carDetails = [],
  availableBadge = [],
  carInfo = undefined,
  additionalClasses = '',
  actionItems = [],
  children,
  slice = LengthType.FOUR,
  showAllFeatureItems = false,
  actionItemsWithText = undefined,
  hasAccordion = true,
  isOpen = false,
  imageShare = '20',
  showBorder = false,
  handleAccordionProp,
}: CarListItemProps) => {
  const [open, setOpen] = useState<boolean>(isOpen);

  const featuresCount = carDetails?.length ?? LengthType.ZERO;
  const showFeatures = (!imageSrc && open) || imageSrc;

  const handleAccordion = () => {
    setOpen(!open);

    if (handleAccordionProp) {
      handleAccordionProp(!open);
    }
  };

  const getKey = (key: CarDetailsKey) => {
    if (typeof key === 'string') {
      return `${key}-${id}`;
    }
    if (!key.text) {
      throw new Error('No text found!');
    }
    return `${key.text}-${id}`;
  };

  return (
    <div
      className={classNames(
        BASE_CLASS,
        additionalClasses,
        `${BASE_CLASS}__grid-${imageShare}`,
        {
          [`${BASE_CLASS}__grid-${imageShare}`]: imageSrc,
          [`${BASE_CLASS}__no-image`]: !imageSrc,
          [`${BASE_CLASS}__no-image--show-border`]: !imageSrc && showBorder,
          [`${BASE_CLASS}--closed`]: !open && featuresCount > LengthType.FOUR,
        }
      )}
    >
      {imageSrc && (
        <div
          className={classNames(`${BASE_CLASS}__image-wrap`, {
            [`${BASE_CLASS}__image-wrap--closed`]:
              !open && featuresCount > LengthType.FOUR,
          })}
        >
          <AdbDynamicImage
            imageUrl={imageSrc}
            imageAltText={imageAltText ?? name.toString()}
            imageConfig={imageConfig4To3}
            className={`${BASE_CLASS}__image`}
          />
        </div>
      )}
      <div className={`${BASE_CLASS}__header`}>
        <Header
          open={open}
          hasAccordion={hasAccordion}
          handleAccordion={handleAccordion}
          featuresCount={featuresCount}
          name={name}
          availableBadge={availableBadge}
          carInfo={carInfo}
        >
          {children}
        </Header>
      </div>
      <div className={`${BASE_CLASS}__content`}>
        {showFeatures && (
          <div
            className={classNames(`${BASE_CLASS}__content-wrap`, {
              [`${BASE_CLASS}__content-wrap--position`]:
                actionItems.length > LengthType.ONE && !actionItemsWithText,
              [`${BASE_CLASS}__content-wrap--text-with-items`]:
                actionItemsWithText,
              [`${BASE_CLASS}__image-wrap--closed`]:
                !open && featuresCount > LengthType.FOUR,
            })}
          >
            <div
              className={classNames(`${BASE_CLASS}__feature-wrap`, {
                [`${BASE_CLASS}__feature-wrap--mobile`]:
                  !open && featuresCount <= LengthType.FOUR,
              })}
              style={{ gridTemplateColumns: `repeat(${slice}, 1fr)` }}
            >
              {(featuresCount > LengthType.ZERO && open) || showAllFeatureItems
                ? carDetails.map((carItem, index) => (
                    <Features
                      key={getKey(carItem.key)}
                      carItem={carItem}
                      index={index}
                      featuresCount={featuresCount}
                      open={open}
                      loading={carItem.loading}
                    />
                  ))
                : carDetails
                    .slice(LengthType.ZERO, slice)
                    .map((carItem, index) => (
                      <Features
                        key={getKey(carItem.key)}
                        carItem={carItem}
                        index={index}
                        featuresCount={featuresCount}
                        open={open}
                        loading={carItem.loading}
                      />
                    ))}
            </div>

            {actionItemsWithText ? (
              <ActionsWithText open={open} {...actionItemsWithText} />
            ) : (
              <Actions actionItems={actionItems} />
            )}
          </div>
        )}

        {open && children}
      </div>
    </div>
  );
};

export default CarListItem;
