import { MarketCode } from '@utils/market/types';
import { useTranslation } from 'react-i18next';
import { date } from 'yup';

export const DateOfBirthSchema = (
  validationMarket: MarketCode,
  required = false
) => {
  const { t } = useTranslation();

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'de':
        schemaObject = {
          dateOfBirth: required
            ? date()
                .max(
                  new Date(),
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.date_of_birth'
                  )}`
                )
                .required(
                  `${t('form_fields.basic.date_of_birth')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
            : date().max(
                new Date(),
                `${t('general.error_messages.validations.invalid')} ${t(
                  'form_fields.basic.date_of_birth'
                )}`
              ),
        };
        break;
      default:
        schemaObject = {
          dateOfBirth: required
            ? date()
                .max(
                  new Date(),
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.date_of_birth'
                  )}`
                )
                .required(
                  `${t('form_fields.basic.date_of_birth')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
            : date().max(
                new Date(),
                `${t('general.error_messages.validations.invalid')} ${t(
                  'form_fields.basic.date_of_birth'
                )}`
              ),
        };
        break;
    }
  }

  return schemaObject;
};
