/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllEmailsQueryVariables = Types.Exact<{
  input: Types.AllEmailsInput;
}>;


export type AllEmailsQuery = { __typename?: 'Query', allEmails: { __typename?: 'AllEmailsResponse', emails: Array<{ __typename?: 'Email', threadId: string, type?: Types.EmailType | null, sender: string, receipient: string, subject: string, date: string, lastModifiedBy: Types.SenderType, body: string }> } };


export const AllEmailsDocument = gql`
    query AllEmails($input: AllEmailsInput!) {
  allEmails(input: $input) {
    emails {
      threadId
      type
      sender
      receipient
      subject
      date
      lastModifiedBy
      body
    }
  }
}
    `;

/**
 * __useAllEmailsQuery__
 *
 * To run a query within a React component, call `useAllEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEmailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllEmailsQuery(baseOptions: Apollo.QueryHookOptions<AllEmailsQuery, AllEmailsQueryVariables> & ({ variables: AllEmailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllEmailsQuery, AllEmailsQueryVariables>(AllEmailsDocument, options);
      }
export function useAllEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllEmailsQuery, AllEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllEmailsQuery, AllEmailsQueryVariables>(AllEmailsDocument, options);
        }
export function useAllEmailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllEmailsQuery, AllEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllEmailsQuery, AllEmailsQueryVariables>(AllEmailsDocument, options);
        }
export type AllEmailsQueryHookResult = ReturnType<typeof useAllEmailsQuery>;
export type AllEmailsLazyQueryHookResult = ReturnType<typeof useAllEmailsLazyQuery>;
export type AllEmailsSuspenseQueryHookResult = ReturnType<typeof useAllEmailsSuspenseQuery>;
export type AllEmailsQueryResult = Apollo.QueryResult<AllEmailsQuery, AllEmailsQueryVariables>;