import { Icon, Text } from '@smart/react-components';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import {
  TableBodyProps,
  TableDataProps,
  TableHeaderCustomProps,
  TableProps,
  TableRowProps,
} from './TableComponents.config';
import './TableComponents.scss';

const BASE_CLASS = 'adb-table';

const Table = ({ children, additionalClasses }: TableProps) => (
  <table className={classNames(BASE_CLASS, additionalClasses)}>
    {children}
  </table>
);

const TableHead = ({ children }: PropsWithChildren) => (
  <thead className={`${BASE_CLASS}__head`}>{children}</thead>
);

const TableBody = ({ children, additionalClasses }: TableBodyProps) => (
  <tbody className={classNames(`${BASE_CLASS}__body`, additionalClasses)}>
    {children}
  </tbody>
);

const TableRow = ({
  children,
  gridColumns,
  className,
  onClick,
}: TableRowProps) => (
  <tr
    className={classNames(
      `${BASE_CLASS}__row`,
      gridColumns && gridColumns,
      className && className
    )}
    onClick={onClick}
  >
    {children}
  </tr>
);

const TableHeader = ({
  type,
  children,
  enableSorting,
  textVariant = 'p-100',
  onClick,
}: TableHeaderCustomProps) => (
  <th
    className={classNames(`${BASE_CLASS}__header`, {
      [`${BASE_CLASS}__header-radio`]: type === 'isRadio',
      [`${BASE_CLASS}__header-clickable`]: onClick !== undefined,
    })}
    onClick={onClick}
  >
    <Text variant={textVariant} as="p">
      {children}
    </Text>
    {enableSorting && (
      <Icon
        icon="sort-default"
        className={`${BASE_CLASS}__header-sort-icon`}
        mode={100}
      />
    )}
  </th>
);

const TableData = ({ children, type, classes }: TableDataProps) => (
  <td
    className={classNames(`${BASE_CLASS}__data`, {
      [`${BASE_CLASS}__data-radio`]: type === 'radio',
      [`${BASE_CLASS}__data-icon`]: type === 'icon',
      [`${classes}`]: !!classes,
    })}
  >
    {type ? (
      children
    ) : (
      <Text variant="p-100" as="span">
        {children}
      </Text>
    )}
  </td>
);

export { Table, TableBody, TableData, TableHead, TableHeader, TableRow };
