export const LANGUAGE_DEFAULT = 'en-GB';

enum LocalesConfig {
  'en-US',
  'en-GB',
  'de-DE',
  'de-AT',
  'de-CH',
  'fr-CH',
  'it-CH',
  'es-ES',
  'fr-FR',
  'fr-BE',
  'it-IT',
  'nl-NL',
  'nl-BE',
  'pt-PT',
  'sv-SE',
}

export type LanguageCode = keyof typeof LocalesConfig;
