import { Flex, Icon, Spinner, Text } from '@smart/react-components';
import { ReactNode } from 'react';
import AdbTooltip from '../AdbTooltip/AdbTooltip';
import './OrderItems.scss';

interface OrderItemFeatureI {
  title: string;
  text: string | ReactNode;
  tooltip?: {
    title: string;
    message: ReactNode;
  };
  extraText?: string;
  loading?: boolean;
}

const BASE_CLASS = 'adb-order-items';

const OrderItemFeature = ({
  title,
  text,
  tooltip,
  extraText,
  loading,
}: OrderItemFeatureI) => (
  <Flex direction="column" rowGap={50}>
    <Text variant="cap-300" color="c-mid-contrast">
      <Flex gap={50}>
        {title}
        {tooltip && (
          <div className={`${BASE_CLASS}__item-tooltip`}>
            <AdbTooltip title={tooltip.title} message={tooltip.message}>
              <Icon icon="info-legal" />
            </AdbTooltip>
          </div>
        )}
      </Flex>
    </Text>
    {loading && <Spinner size="xs" />}
    {!loading && (
      <Text variant="cap-300" as="p">
        {text}
      </Text>
    )}
    {extraText && (
      <Text variant="cap-300" as="p">
        {extraText}
      </Text>
    )}
  </Flex>
);

export default OrderItemFeature;
