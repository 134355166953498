import { TaskLossReason } from '@smart/adb-shared';
import { MarketCode } from '@utils/market/types';
import { useMarketContext } from 'contexts/market-context';

export const TaskLossTranslationMap: Record<TaskLossReason, string> = {
  Checkout_Too_Complex: 'checkout_too_complex',
  Lost_To_Competitor: 'lost_to_competitor',
  No_Budget_Lost_Funding: 'no_budget_lost_funding',
  No_Decision_Non_Responsive: 'no_decision_non_responsive',
  Other: 'other',
  Postponed: 'postponed',
  Price: 'price',
  Return: 'return',
  Wrong_Leasing_Provider: 'wrong_leasing_provider',
  Bad_Timing: 'bad_timing',
  Better_Offers_Elsewhere: 'better_offers_elsewhere',
  Budget_Concerns: 'budget_concerns',
  Change_in_Circumstances: 'change_in_circumstances',
  Changed_Mind: 'changed_mind',
  Lack_of_Interest: 'lack_of_interest',
  Lack_of_Trust: 'lack_of_trust',
  Poor_Customer_Service: 'poor_customer_service',
  Quality_Concerns: 'quality_concerns',
  Research_and_Reviews: 'research_and_reviews',
  Successful_Checkout: 'successful_checkout',
};

const mappedByMarket: Partial<Record<MarketCode, TaskLossReason[]>> = {
  de: [
    TaskLossReason.ChangedMind,
    TaskLossReason.CheckoutTooComplex,
    TaskLossReason.LostToCompetitor,
    TaskLossReason.NoDecisionNonResponsive,
    TaskLossReason.Price,
    TaskLossReason.QualityConcerns,
    TaskLossReason.WrongLeasingProvider,
    TaskLossReason.SuccessfulCheckout,
    TaskLossReason.Other,
  ],
  es: [
    TaskLossReason.BetterOffersElsewhere,
    TaskLossReason.BudgetConcerns,
    TaskLossReason.ChangeInCircumstances,
    TaskLossReason.ChangedMind,
    TaskLossReason.CheckoutTooComplex,
    TaskLossReason.LackOfInterest,
    TaskLossReason.LackOfTrust,
    TaskLossReason.LostToCompetitor,
    TaskLossReason.NoDecisionNonResponsive,
    TaskLossReason.Other,
    TaskLossReason.PoorCustomerService,
    TaskLossReason.Postponed,
    TaskLossReason.Price,
    TaskLossReason.QualityConcerns,
    TaskLossReason.SuccessfulCheckout,
  ],
  it: [
    TaskLossReason.BadTiming,
    TaskLossReason.BetterOffersElsewhere,
    TaskLossReason.ChangeInCircumstances,
    TaskLossReason.ChangedMind,
    TaskLossReason.CheckoutTooComplex,
    TaskLossReason.LackOfInterest,
    TaskLossReason.NoDecisionNonResponsive,
    TaskLossReason.Other,
    TaskLossReason.Postponed,
    TaskLossReason.Price,
    TaskLossReason.QualityConcerns,
    TaskLossReason.SuccessfulCheckout,
  ],
  gb: [
    TaskLossReason.SuccessfulCheckout,
    TaskLossReason.LostToCompetitor,
    TaskLossReason.CheckoutTooComplex,
    TaskLossReason.QualityConcerns,
    TaskLossReason.Price,
    TaskLossReason.NoDecisionNonResponsive,
    TaskLossReason.NoBudgetLostFunding,
    TaskLossReason.Other,
  ],
  pt: [
    TaskLossReason.BadTiming,
    TaskLossReason.BetterOffersElsewhere,
    TaskLossReason.BudgetConcerns,
    TaskLossReason.ChangeInCircumstances,
    TaskLossReason.ChangedMind,
    TaskLossReason.CheckoutTooComplex,
    TaskLossReason.LackOfInterest,
    TaskLossReason.LackOfTrust,
    TaskLossReason.LostToCompetitor,
    TaskLossReason.NoBudgetLostFunding,
    TaskLossReason.NoDecisionNonResponsive,
    TaskLossReason.Other,
    TaskLossReason.PoorCustomerService,
    TaskLossReason.Postponed,
    TaskLossReason.Price,
    TaskLossReason.QualityConcerns,
    TaskLossReason.SuccessfulCheckout,
    TaskLossReason.WrongLeasingProvider,
  ],
  at: [
    TaskLossReason.BadTiming,
    TaskLossReason.BetterOffersElsewhere,
    TaskLossReason.BudgetConcerns,
    TaskLossReason.ChangedMind,
    TaskLossReason.CheckoutTooComplex,
    TaskLossReason.LackOfTrust,
    TaskLossReason.NoDecisionNonResponsive,
    TaskLossReason.Other,
    TaskLossReason.PoorCustomerService,
    TaskLossReason.QualityConcerns,
    TaskLossReason.ResearchAndReviews,
    TaskLossReason.SuccessfulCheckout,
  ],
};

export const useTaskLossReasonByMarket = () => {
  const { market } = useMarketContext();
  const reasons = mappedByMarket[market] ?? Object.values(TaskLossReason);

  return reasons
    .sort((a) => (a === 'Successful_Checkout' ? 1 : -1))
    .sort((a) => (a === 'Other' ? 1 : -1));
};
