import AdbNotification from '@smart/components-adb/molecules/AdbNotification/AdbNotification';
import { SignalVariant } from '@smart/components-adb/molecules/AdbNotification/AdbNotification.config';
import { Button, Link } from '@smart/react-components';
import { AuthErrorType } from '@store/auth/types';
import { GetSupportEmail } from '@utils/configs/config';
import { useAgentContext } from 'contexts/agent-context';
import { useCurrentOutlet } from 'hooks/outlet';
import useErrorMessage from 'hooks/useErrorMessage';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './errorBoundaryFallback.scss';

const BASE_CLASS = 'adb-error-boundary-fallback';

const formatSupportEmailBody = (input: {
  clipboardError: string;
  agent?: string;
  outlet?: string;
}) => {
  const { DEPLOY_ENV, BUILDKITE_BRANCH, BUILDKITE_COMMIT } = import.meta.env;

  const body = `Please describe your request as detailed as possible and add the steps to reproduce the bug you encountered.\n
    Please be aware to not share any customer credentials or sensitive information.
    \n
    \n
    ----------------PLEASE ADD BELOW DATA-----------
    User: ${input.agent ?? ''}\n
    Outlet: ${input.outlet ?? ''}\n
    URL: ${window.location.href}\n
    Browser: ${window.navigator.userAgent}\n
    Screenshot: \n
    Error message: ${input.clipboardError}\n
    \n
    Environment: ${DEPLOY_ENV ?? 'N/A'}\n
    Branch: ${BUILDKITE_BRANCH ?? 'N/A'}\n
    Commit hash: ${BUILDKITE_COMMIT ?? 'N/A'}\n
    `;

  return encodeURI(body);
};

const ReloadNotification = ({ error }: { error: any }) => {
  const { name } = useErrorMessage(error);
  const { t } = useTranslation();

  return (
    <div className={BASE_CLASS}>
      <AdbNotification
        label={name}
        variant="informational"
        text={
          <div>
            <p className={`${BASE_CLASS}__msg`}>
              {t('general.error_messages.reload_browser')}
            </p>

            <div className={`${BASE_CLASS}__actions`}>
              <Button
                variant="primary"
                onClick={() => {
                  window.location.reload();
                }}
              >
                {t('general.error_messages.reload')}
              </Button>
            </div>
          </div>
        }
        onClose={() => {}}
        persist
        isVisible
      />
    </div>
  );
};

type ErrorBoundaryFallbackProps = {
  // eslint-disable-next-line react/require-default-props
  level?: SignalVariant;
} & FallbackProps;

const ErrorBoundaryFallback = ({
  error,
  resetErrorBoundary,
  level = 'error',
}: ErrorBoundaryFallbackProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { agent: selectedAgent } = useAgentContext();
  const outlet = useCurrentOutlet();
  const { code, message, clipboard, cause, name } = useErrorMessage(error);

  const isOutdatedVersionError = message.startsWith(
    'Failed to fetch dynamically imported module'
  );

  const isAccountErrorCode =
    code === AuthErrorType.NO_ROLES_ASSIGNED ||
    code === AuthErrorType.INVALID_GRANT ||
    code === AuthErrorType.INVALID_USER_ACCOUNT;

  const isRefreshTokenError =
    code === AuthErrorType.ECOM_TOKEN_ERROR ||
    code === AuthErrorType.USER_MISMATCH_ERROR ||
    code === AuthErrorType.ACCESS_TOKEN_ERROR;

  useEffect(() => {
    if (isAccountErrorCode || isRefreshTokenError)
      navigate(`/auth/error/${code}`, {
        replace: true,
      });
  }, [code, isAccountErrorCode, isRefreshTokenError, navigate]);

  if (isAccountErrorCode || isRefreshTokenError) return null;

  const supportUrl = `mailto:${GetSupportEmail()}?subject=${t(
    'support.email_subject'
  )}&body=${formatSupportEmailBody({
    clipboardError: clipboard,
    agent: selectedAgent?.email,
    outlet: outlet?.name,
  })}`;

  return isOutdatedVersionError ? (
    <ReloadNotification error={error} />
  ) : (
    <div className={BASE_CLASS}>
      <AdbNotification
        label={name}
        variant={level}
        text={
          <div>
            <p className={`${BASE_CLASS}__msg`}>{message}</p>
            {cause && <p className={`${BASE_CLASS}__cause`}>- {cause}</p>}

            <div className={`${BASE_CLASS}__actions`}>
              <Button
                variant="primary"
                onClick={() => {
                  navigator.clipboard.writeText(clipboard);
                }}
              >
                {t('general.error_messages.copy_error')}
              </Button>

              <Button asChild variant="primary">
                <Link href={supportUrl}>{t('support.support_text')}</Link>
              </Button>
            </div>
          </div>
        }
        onClose={resetErrorBoundary}
        persist
        isVisible
      />
    </div>
  );
};

export { ErrorBoundaryFallback };
