import { CarDetails } from '@smart/adb-shared';
import EmptyLayout from '@smart/components-adb/atoms/EmptyDataLayout/EmptyLayout';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import DateSlots from '@smart/components-adb/molecules/DateSlots/DateSlots';
import FilterDropdown from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown';
import { FilterProp } from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown.config';
import { CarLine, CarModels } from '@ui/data-models/car/car.model';
import {
  TestDriveBookingCar,
  TestDriveBookingSlot,
} from '@ui/data-models/test-drive/test-drive.model';
import { getDateFromToday } from '@ui/library/helpers/date';
import {
  getNavigationItem,
  handleNavigationStepLinks,
  handleRequiredSteps,
  stepStatusHandler,
} from '@utils/helpers/test-drive';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useTestDriveContext } from 'contexts/test-drive-context';
import FocusLayout from 'layouts/focus-layout/FocusLayout';
import {
  CarSelectItem,
  TestDriveBookingSteps,
  TestDriveCarCurrentFilter,
  TestDriveCarFilterTypes,
  mapCarSelectItem,
  mapTestDriveCarFilters,
  testDriveModels,
} from 'pages/test-drive/config';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTestDriveTimeSlotsQuery } from '../queries.generated';
import CarDetailsList from './car-details/CarDetailsList';
import './index.scss';
import StockFilter from './stock-filter/StockFilter';

const BASE_CLASS = 'adb-time-car-wrapper';

/**
 * CarTimeSelection component
 * @returns <CarTimeSelection> ReactNode
 */
const CarTimeSelection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useLanguageContext();
  const {
    navigation,
    setCurrentTestDriveBooking,
    currentTestDriveBooking,
    setNavigation,
    setModelForCurrentTestDrive,
    setCarLineForCurrentTestDrive,
  } = useTestDriveContext();

  const {
    carModel,
    carLine,
    slot,
    car,
    customerId,
    sfCustomerId,
    bpid,
    sfOrgId,
  } = currentTestDriveBooking;

  useEffect(() => {
    if (!carModel) {
      setModelForCurrentTestDrive(CarModels.HASHTAG_ONE);
    }
  }, [carModel, setModelForCurrentTestDrive]);

  /**
   * ---------------
   * Navigation
   * ---------------
   */

  useEffect(() => {
    handleRequiredSteps(navigation, [TestDriveBookingSteps.CAR], navigate);
  }, [navigate, navigation]);

  /**
   * ------------------------
   * Select car and time slot
   * ------------------------
   */

  // state variable to set the selected time slot
  const [currentBookingSlot, setCurrentBookingSlot] = useState<
    TestDriveBookingSlot | undefined
  >(slot);

  const [currentBookingDate, setCurrentBookingDate] = useState<string>(
    slot && slot.date
      ? new Date(slot.date).toISOString()
      : getDateFromToday(0, 10).toISOString()
  );

  // state variable to set the selected car model Id
  const [currentTestDriveCar, setCurrentTestDriveCar] = useState<
    TestDriveBookingCar | undefined
  >(car);

  const handleSlotSelection = (newSlot: TestDriveBookingSlot) => {
    setCurrentBookingSlot(newSlot);
  };

  const handleDateSelection = (newDate: string) => {
    setCurrentBookingSlot(undefined);
    setCurrentBookingDate(newDate);
  };

  const handleCarSelection = (newTestDriveCar: TestDriveBookingCar) => {
    setCurrentBookingSlot(undefined);
    setCurrentTestDriveCar(newTestDriveCar);
  };

  const { agent } = useAgentContext();
  const agentOutletId = agent?.outletId;

  const onLineSelect = (selectedFilter: TestDriveCarCurrentFilter) => {
    setCurrentBookingSlot(undefined);
    setCurrentTestDriveCar(undefined);
    setCarLineForCurrentTestDrive(
      selectedFilter.lineValue?.queryValue as CarLine
    );
  };

  const { data, loading } = useTestDriveTimeSlotsQuery({
    variables: {
      input: {
        outletId: agentOutletId,
        model: carModel ?? '',
      },
    },
    skip: !carModel || !agentOutletId,
    fetchPolicy: 'network-only',
  });

  const testDriveCars = useMemo(
    () =>
      data?.testDriveTimeSlots.slotDetails &&
      data?.testDriveTimeSlots.carDetails
        ? mapCarSelectItem(
            data.testDriveTimeSlots.slotDetails,
            data.testDriveTimeSlots.carDetails as CarDetails[]
          )
        : [],
    [data?.testDriveTimeSlots.carDetails, data?.testDriveTimeSlots.slotDetails]
  );

  const lineFilters = useMemo(
    () => mapTestDriveCarFilters(testDriveCars),
    [testDriveCars]
  );

  // Set current line to the first available in the filters for current model
  useEffect(() => {
    if (!lineFilters.filters.includes(carLine)) {
      setCarLineForCurrentTestDrive(lineFilters.filters[0]);
    }
  }, [carLine, lineFilters, setCarLineForCurrentTestDrive]);

  const onModelSelect = (selectedFilter: FilterProp) => {
    setCurrentBookingSlot(undefined);
    setCurrentTestDriveCar(undefined);
    const value = selectedFilter.queryValue as CarModels;
    setModelForCurrentTestDrive(value);
  };

  /**
   * Utility function to check a test drive car passes the applied filters check
   */
  const hasFilteredValue = (
    testDriveCar: CarSelectItem,
    filter: TestDriveCarCurrentFilter
  ): boolean => {
    if (filter.name === TestDriveCarFilterTypes.DATE) {
      return (
        testDriveCar.timeSlots.findIndex((s) => {
          const slotDate = new Date(s.date);
          const filterDate = filter.dateValue && new Date(filter.dateValue);
          const isAvailableSlot = s.timeSlots.some(
            (timeSlot) => timeSlot.isAvailable
          );

          return (
            isAvailableSlot &&
            filterDate &&
            slotDate.getDate() === filterDate.getDate() &&
            slotDate.getMonth() === filterDate.getMonth()
          );
        }) >= 0
      );
    }

    if (filter.name === TestDriveCarFilterTypes.LINE) {
      return testDriveCar.line === filter.lineValue?.queryValue;
    }

    return true;
  };

  const getFilteredTestDriveCars = (
    cars: CarSelectItem[],
    filters: TestDriveCarCurrentFilter[]
  ): CarSelectItem[] => {
    if (
      filters.length < 1 ||
      filters.reduce((values, n) => values + n, '').length < 1
    ) {
      return cars;
    }

    const filteredCars = cars.filter((testDriveCar) => {
      for (const filter of filters) {
        if (
          (filter.lineValue || filter.dateValue) &&
          !hasFilteredValue(testDriveCar, filter)
        ) {
          return false;
        }
      }

      return true;
    });

    return filteredCars;
  };

  const filteredTestDriveCars = getFilteredTestDriveCars(testDriveCars, [
    {
      name: TestDriveCarFilterTypes.LINE,
      lineValue: {
        displayName: carLine,
        queryValue: carLine,
      },
    },
    {
      name: TestDriveCarFilterTypes.DATE,
      dateValue: currentBookingDate,
    },
  ]);

  /**
   * ---------------------------------------------
   * Test drive car and slot selection dispatch
   * ---------------------------------------------
   */

  const onSaveSelections = () => {
    let currentData = currentTestDriveBooking;

    if (currentBookingSlot) {
      currentData = {
        ...currentTestDriveBooking,
        slot: currentBookingSlot,
      };
    }

    /**
     * Dispatch selected test drive car
     */
    if (
      currentTestDriveCar &&
      currentTestDriveCar?.testDriveCarId !== car?.testDriveCarId
    ) {
      setCurrentTestDriveBooking({
        ...currentData,
        car: currentTestDriveCar,
      });

      /**
       * Dispatch test drive navigation steps status change
       */
      const stepsStatus = {
        [TestDriveBookingSteps.TIME]: true,
      };
      const updatedNavList = stepStatusHandler(navigation, stepsStatus);
      setNavigation(updatedNavList);
    }
  };

  const getNextButtonRoute = (): string => {
    if (!customerId || !sfCustomerId) {
      return getNavigationItem(navigation, TestDriveBookingSteps.ACCOUNT).path;
    }
    if (!bpid || !sfOrgId) {
      return `/test-drive/confirmation/${customerId}/user-details/${sfCustomerId}`;
    }
    return `/test-drive/confirmation/${customerId}/user-details/${sfCustomerId}/${bpid}/${sfOrgId}`;
  };

  /**
   * ---------------
   * Page content
   * ---------------
   */

  return (
    <FocusLayout
      navList={handleNavigationStepLinks(navigation, customerId, sfCustomerId)}
      nextButtonLabel={t('test_drive.time.buttons.next')}
      isNextButtonDisabled={!(currentTestDriveCar && currentBookingSlot)}
      backButtonRoute={
        getNavigationItem(navigation, TestDriveBookingSteps.CAR).path
      }
      nextButtonHandler={onSaveSelections}
      nextButtonRoute={getNextButtonRoute()}
    >
      <div className={BASE_CLASS}>
        <div className={`${BASE_CLASS}__dates-wrapper`}>
          <DateSlots
            locale={locale}
            onDaySelection={handleDateSelection}
            selectedDate={currentBookingDate}
            showCalenderLink
          />
        </div>
        {!loading && testDriveCars.length > 0 ? (
          <div className={`${BASE_CLASS}__cars`}>
            <div className={`${BASE_CLASS}__filter-time`}>
              <FilterDropdown
                label={`${t('test_drive.general.model.model')} #${carModel}`}
                items={testDriveModels}
                selectedFilter={
                  carModel && {
                    queryValue: carModel,
                    displayName: carModel,
                  }
                }
                onSelect={onModelSelect}
              />
              <StockFilter
                filters={[lineFilters]}
                currentSelection={{
                  lineValue: {
                    displayName: carLine,
                    queryValue: carLine,
                  },
                }}
                handleFilterSelection={onLineSelect}
              />
            </div>
            <CarDetailsList
              testDriveCars={filteredTestDriveCars}
              selectedTestDriveCar={currentTestDriveCar}
              selectedDate={currentBookingDate}
              selectedSlot={currentBookingSlot}
              handleCarSelection={handleCarSelection}
              handleSlotSelection={handleSlotSelection}
            />
          </div>
        ) : (
          <div className={`${BASE_CLASS}__loading`}>
            <LoadingIndicator loading={loading} onFullPage>
              <EmptyLayout />
            </LoadingIndicator>
          </div>
        )}
      </div>
    </FocusLayout>
  );
};

export default CarTimeSelection;
