import { AddressFull, AddressInfo } from '@smart/adb-shared';
import { TypeAheadPropList } from '@smart/components-adb/molecules/TypeAhead/TypeAhead.config';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ValidatorForm from './ValidatorForm/ValidatorForm';
import './address-validator.scss';
import { CONFIG_FALLBACK } from './defaults/config';
import { LABELS_FALLBACK } from './defaults/labels';
import {
  useAddressFullLazyQuery,
  useAddressesInfoLazyQuery,
} from './queries.generated';
import { getAllowedAddresses } from './utils';

const BASE_CLASS = 'adb-address-validator';

interface AddressValidatorProps {
  onValidatedAddress: (updatedAddress: AddressFull) => void;
}

export const AddressValidator = ({
  onValidatedAddress,
}: AddressValidatorProps) => {
  const { market } = useMarketContext();
  const { t } = useTranslation();
  const { locale } = useLanguageContext();

  const [searchKey, setSearchKey] = useState('');

  const [
    runAddressInfoQuery,
    { loading: addressesInfoLoading, data: addressesInfo },
  ] = useAddressesInfoLazyQuery();

  const [
    runAddressFullQuery,
    { loading: addressFullLoading, data: addressFull },
  ] = useAddressFullLazyQuery();

  const [listItems, setListItems] = useState<TypeAheadPropList[] | []>([]);
  const hintMessage = useRef('');

  const labels = {
    ...LABELS_FALLBACK,
    hintMessage: t('form_fields.address_validator.hint'),
    input: t('form_fields.address_validator.label'),
    errorMessageMinLength: t('form_fields.address_validator.enter_3_char'),
  };

  const getAddressesInfoBySearchInput = (input: string) => {
    setSearchKey(input);
    if (input.trim() && !addressesInfoLoading) {
      runAddressInfoQuery({
        variables: {
          input: {
            countries: market ?? CONFIG_FALLBACK.countries,
            language: locale,
            text: input,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (addressesInfo?.addressesInfo) {
      const addressesInfoItems =
        (addressesInfo?.addressesInfo ?? [])
          ? getAllowedAddresses(addressesInfo?.addressesInfo as AddressInfo[])
          : [];

      if (addressesInfoItems === undefined || addressesInfoItems.length === 0) {
        setListItems([]);
        hintMessage.current = labels.hintMessage;
      } else {
        setListItems(addressesInfoItems);
        hintMessage.current = '';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressesInfo?.addressesInfo]);

  const getFullAddressAndGeoCode = async (suggestion: TypeAheadPropList) => {
    const { id, attributes } = suggestion;

    if (attributes?.type === 'Address') {
      runAddressFullQuery({
        variables: {
          id: String(id),
        },
      });
    }
  };

  useEffect(() => {
    if (!addressFullLoading && addressFull?.addressFull) {
      onValidatedAddress(addressFull.addressFull);
    }
  }, [addressFull, addressFullLoading, onValidatedAddress]);

  return (
    <div className={BASE_CLASS}>
      <ValidatorForm
        searchKey={searchKey}
        onChange={(input) => getAddressesInfoBySearchInput(input)}
        onSelect={(suggestion: TypeAheadPropList) =>
          !addressFullLoading && getFullAddressAndGeoCode(suggestion)
        }
        inputLabel={labels?.input}
        listItems={listItems}
        hintMessage={hintMessage.current}
        minimumLengthErrorMessageLabel={labels?.errorMessageMinLength}
      />
    </div>
  );
};
