import { getPostalCodeRegexp } from '@smart/utils';
import { MarketCode } from '@utils/market/types';
import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { GetMaxLengthValidationMsg } from '../helpers/utils';

export const PostalCodeSchema = (
  validationMarket: MarketCode,
  required = false
) => {
  const { t } = useTranslation();

  const getMarketPostalCodeRegex = (): RegExp =>
    getPostalCodeRegexp(validationMarket);

  let schemaObject = {};

  if (!validationMarket)
    throw new Error('No market provided for validating postal code');

  if (!getMarketPostalCodeRegex()) {
    throw new Error(
      `Could not find postal code regex for market ${validationMarket}`
    );
  }

  switch (validationMarket) {
    case 'de':
      schemaObject = {
        postalCode: required
          ? string()
              .required(
                `${t('form_fields.basic.postal_code')} ${t(
                  'general.error_messages.validations.is_required'
                )}`
              )
              .matches(
                getMarketPostalCodeRegex(),
                `${t('form_fields.basic.postal_code')} ${t(
                  'general.error_messages.validations.invalid'
                )}`
              )
              .max(
                20,
                GetMaxLengthValidationMsg(
                  t('form_fields.basic.postal_code'),
                  20
                )
              )
          : string()
              .matches(
                getMarketPostalCodeRegex(),
                `${t('form_fields.basic.postal_code')} ${t(
                  'general.error_messages.validations.invalid'
                )}`
              )
              .max(
                20,
                GetMaxLengthValidationMsg(
                  t('form_fields.basic.postal_code'),
                  20
                )
              ),
      };
      break;
    default:
      schemaObject = {
        postalCode: required
          ? string()
              .required(
                `${t('form_fields.basic.postal_code')} ${t(
                  'general.error_messages.validations.is_required'
                )}`
              )
              .matches(
                getMarketPostalCodeRegex(),
                `${t('general.error_messages.validations.invalid')} ${t(
                  'form_fields.basic.postal_code'
                )} `
              )
              .max(
                20,
                GetMaxLengthValidationMsg(
                  t('form_fields.basic.postal_code'),
                  20
                )
              )
          : string()
              .matches(
                getMarketPostalCodeRegex(),
                `${t('general.error_messages.validations.invalid')} ${t(
                  'form_fields.basic.postal_code'
                )} `
              )
              .max(
                20,
                GetMaxLengthValidationMsg(
                  t('form_fields.basic.postal_code'),
                  20
                )
              ),
      };
      break;
  }

  return schemaObject;
};
