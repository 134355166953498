import { AppointmentType } from '@smart/adb-shared';
import { useTranslation } from 'react-i18next';
import { CalendarAppointmentDateTime } from 'schemas/CalendarAppointmentDateTime';
import { CalendarAppointmentCar } from 'schemas/CalendarAppointmentDemoCar';
import { CalendarAppointmentType } from 'schemas/CalendarAppointmentType';
import { object } from 'yup';

const GetStartEndDateBasedOnAppointmentType = (
  appointmentType?: AppointmentType
) => {
  const startEndDate = CalendarAppointmentDateTime();
  const { startDate } = startEndDate;
  const { t } = useTranslation();

  return {
    ...startEndDate,
    ...(appointmentType === AppointmentType.Handover && {
      startDate: startDate.min(
        new Date(),
        t('general.error_messages.before_current_time')
      ),
    }),
  };
};

const CreateAppointmentSchema = (apt?: AppointmentType) => {
  const appointmentType = CalendarAppointmentType();
  const carId = CalendarAppointmentCar();
  const startEndDate = GetStartEndDateBasedOnAppointmentType(apt);

  return object().shape({
    ...appointmentType,
    ...carId,
    ...startEndDate,
  });
};

export default CreateAppointmentSchema;
