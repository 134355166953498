import { CarStatus, McsAgent } from '@smart/adb-shared';
import KebabMenu from '@smart/components-adb/calendar/Sidebar/CalendarAccordion/KebabMenu/KebabMenu';
import {
  Accordion,
  Checkbox,
  CheckboxGroup,
  Flex,
} from '@smart/react-components';
import { FilterType } from '@store/calendar/types';
import { useTranslation } from 'react-i18next';
import { useCalendarContext } from '../../../../contexts/calendar-context';
import { getColor } from '../../Appointments/Appointment.config';
import { BASE_CLASS } from '../../config';
import type { CalendarAccordionProps } from './CalendarAccordion.config';

const BASE_CLASS_SIDEBAR = `${BASE_CLASS}__sidebar`;

const CalendarAccordion = ({ setIsDrawerOpen }: CalendarAccordionProps) => {
  const { t } = useTranslation();
  const { experts, demoCars, filter, setFilter } = useCalendarContext();

  const shouldShowExpertsAccordion = !!experts && experts.length > 0;
  const shouldShowDemoCarAccordion = !!demoCars && demoCars.length > 0;

  const isChecked = (value: string): boolean =>
    filter.some((f) => f.value === value);

  const onClickCheckbox = (value: string, type: FilterType) => {
    if (isChecked(value)) {
      setFilter(filter.filter((f) => f.value !== value));
    } else {
      setFilter([...filter, { value, type }]);
    }
  };

  return (
    <Accordion
      type="multiple"
      className={`${BASE_CLASS_SIDEBAR}__accordion`}
      defaultValue={[FilterType.Expert]}
    >
      {shouldShowExpertsAccordion && (
        <Accordion.Item
          value={FilterType.Expert}
          title={`${t('feature_calendar.general.smart_expert')}`}
          className={`${BASE_CLASS_SIDEBAR}__accordion--item`}
        >
          <CheckboxGroup
            onValueChange={(value) =>
              onClickCheckbox(value as string, FilterType.Expert)
            }
          >
            {experts.map((expert: McsAgent, i) => (
              <Checkbox
                key={expert.gigyaId}
                value={expert.gigyaId!}
                checked={isChecked(expert.gigyaId!)}
                style={{
                  ...(isChecked(expert.gigyaId!) && {
                    backgroundColor: getColor(i).border,
                  }),
                  borderColor: getColor(i).border,
                }}
              >
                {`${expert.firstName} ${expert.lastName}`}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </Accordion.Item>
      )}
      {shouldShowDemoCarAccordion && (
        <Accordion.Item
          value={FilterType.Car}
          title={t('feature_calendar.general.demo_vehicles')}
          className={`${BASE_CLASS_SIDEBAR}__accordion--item`}
        >
          <CheckboxGroup
            onValueChange={(value) =>
              onClickCheckbox(value as string, FilterType.Car)
            }
          >
            {demoCars.map((demoCar, i) => {
              const carActive = demoCar.status === CarStatus.Active;
              return (
                <Flex
                  key={demoCar.carId}
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ height: 24 }}
                >
                  <Checkbox
                    value={demoCar.carId!}
                    checked={isChecked(demoCar.carId!)}
                    disabled={!carActive}
                    style={{
                      ...(demoCar.status === CarStatus.Active
                        ? {
                            ...(isChecked(demoCar.carId!) && {
                              backgroundColor: getColor(i).border,
                            }),
                            borderColor: getColor(i).border,
                          }
                        : {
                            borderColor: 'var(--c-disabled)',
                          }),
                    }}
                  >
                    {demoCar.licensePlateNumber}
                  </Checkbox>
                  <KebabMenu
                    demoCar={demoCar}
                    setIsDrawerOpen={setIsDrawerOpen}
                  />
                </Flex>
              );
            })}
          </CheckboxGroup>
        </Accordion.Item>
      )}
    </Accordion>
  );
};

export default CalendarAccordion;
