/**
 * @interface Car
 * Car information
 */
export interface Car {
  carId: string;
  image: string;
  line: CarLine | string;
  name: string;
  type: CarType | string;
  features?: CarFeatures;
  longName?: string;
  model?: CarModel | string;
  pn18?: string;
  vehicleCondition?: string;
  vehicleType?: VehicleType | string;
}

enum VehicleType {
  DEMO_VEHICLE = 'DemoVehicle',
}

/**
 * @interface CarFeatures
 * Car features information
 */
interface CarFeatures {
  exterior: string;
  accessories?: string;
  battery?: string;
  distance?: string;
  driveline?: string;
  interior?: string;
  mark?: string;
  mileage?: string;
  power?: string;
  range?: string;
  roof?: string;
  steeringWheelPosition?: string;
  transmission?: string;
  yearEdition?: string;
}

/**
 * @enum CarLine
 * Available car lines
 */
export enum CarLine {
  STANDARD = 'Standard',
  ADVANTAGE = 'Advantage',
  OTHER = 'Other',
  PRO = 'Pro',
  PROPLUS = 'Pro+',
  PREMIUM = 'Premium',
  PULSE = 'Pulse',
  BRABUS = 'BRABUS',
  PURE = 'Pure',
  PUREPLUS = 'Pure+',
}

export enum CarModels {
  HASHTAG_ONE = '1',
  HASHTAG_THREE = '3',
}

/**
 * @enum CarType
 * Available car types
 */
export enum CarType {
  SUV = 'SUV',
}

export enum CarModel {
  SMART_1_PRO = 'smart #1 Pro',
  SMART_1_PROPLUS = 'smart #1 Pro+',
  SMART_1_PREMIUM = 'smart #1 Premium',
  SMART_1_PULSE = 'smart #1 Pulse',
  SMART_1_BRABUS = 'smart #1 BRABUS',
  SMART_3_PRO = 'smart #3 Pro',
  SMART_3_PROPLUS = 'smart #3 Pro+',
  SMART_3_PREMIUM = 'smart #3 Premium',
  SMART_3_PULSE = 'smart #3 Pulse',
  SMART_3_BRABUS = 'smart #3 BRABUS',
}

/**
 * @const FALLBACK_IMG_URL
 * fallback image url
 */
export const FALLBACK_IMG_URL =
  'https://s7.future.smart.com/is/image/smarteuropestage/smart/smart-dam/digital/products/cars/hx1/shared/fallback-images/smart_symbol_Logo.png';
