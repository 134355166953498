import { ExpertActionType } from './expert/action';
import { SettingActionType } from './setting/action';
import { CalendarAction, CalendarContextBaseState } from './types';

/**
 * Root reducer for the calendar context store
 *
 * @param state CalendarContextBaseState
 * @param action CalendarAction
 * @returns CalendarContextBaseState
 */
export const reducers = (
  state: CalendarContextBaseState,
  action: CalendarAction
): CalendarContextBaseState => {
  switch (action.type) {
    case ExpertActionType.SET_EXPERTS:
      return {
        ...state,
        experts: action.experts,
      };

    case ExpertActionType.SET_OUTLET:
      return {
        ...state,
        outlet: action.outlet,
      };

    case SettingActionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case SettingActionType.SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };

    case SettingActionType.SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };

    case SettingActionType.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };

    case SettingActionType.SET_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: action.isActionLoading,
      };

    default:
      return state;
  }
};
