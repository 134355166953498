import { OrderType } from '@smart/adb-shared';
import { OrdersContextProvider } from '@smart/components-adb/molecules/OrderItems/order-items-context';
import OrderItems from '@smart/components-adb/molecules/OrderItems/OrderItems';
import { useCustomerContext } from 'contexts/customer-context';
import CustomerLayout from 'layouts/customer-layout/CustomerLayout';

const Orders = () => {
  const { customer } = useCustomerContext();

  return (
    <CustomerLayout>
      <OrdersContextProvider
        customer={customer}
        includeOrderTypes={[
          OrderType.CourtesyCar,
          OrderType.CustomerCar,
          OrderType.Downpayment,
          OrderType.Fallback,
          OrderType.Fleet,
          OrderType.Gd,
          OrderType.Empty,
          OrderType.DemoCar,
          OrderType.ShowroomCar,
        ]}
      >
        <OrderItems customer={customer} />
      </OrdersContextProvider>
    </CustomerLayout>
  );
};

export default Orders;
