import {
  DefaultPrivacyLevel,
  RumInitConfiguration,
  datadogRum,
} from '@datadog/browser-rum';

const {
  DATADOG_RUM_APPLICATION_ID,
  DATADOG_RUM_CLIENT_TOKEN,
  CURRENT_ENV,
  DEPLOY_ENV,
  BUILDKITE_COMMIT,
} = import.meta.env;

if (CURRENT_ENV !== 'local' && DEPLOY_ENV !== 'dev') {
  const config: RumInitConfiguration = {
    applicationId: DATADOG_RUM_APPLICATION_ID,
    clientToken: DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'agent-dashboard',
    env: DEPLOY_ENV ?? 'prod', // Reading DEPLOY_ENV since CURRENT_ENV is not set in AWS..
    version: BUILDKITE_COMMIT,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    allowedTracingUrls: [
      // A list of request URLs used to inject tracing headers.
      (url: string) =>
        url.includes('adb.smart.com') ||
        url.includes('adb.srv.smart.com') ||
        url.includes('execute-api.eu-central-1.amazonaws.com'),
    ],
  };

  datadogRum.init({
    ...config,
  });
}
