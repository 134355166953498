import FilterDropdown from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown';
import { FilterProp } from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown.config';
import {
  TestDriveCarCurrentFilter,
  TestDriveCarFilter,
  TestDriveCarFilterTypes,
} from 'pages/test-drive/config';
import { useTranslation } from 'react-i18next';
import './StockFilter.scss';

const BASE_CLASS = 'adb-test-drive-stock-filter';

export interface StockFilterProps {
  filters: TestDriveCarFilter[];
  currentSelection: TestDriveCarCurrentFilter | undefined;
  handleFilterSelection: (selectedFilter: TestDriveCarCurrentFilter) => void;
}

const StockFilter = ({
  filters,
  currentSelection,
  handleFilterSelection,
}: StockFilterProps) => {
  const { t } = useTranslation();

  const getFilterOptions = (filterOptions: string[]): FilterProp[] =>
    filterOptions?.map((data: string) => ({
      displayName: t(
        `test_drive.general.model.${data.toLowerCase().replace(/ /g, '_')}`
      ),
      queryValue: data,
    }));

  const onFilterSelection = (
    selectFilter: FilterProp | undefined,
    type: TestDriveCarFilterTypes
  ) => {
    handleFilterSelection({
      name: type,
      lineValue: selectFilter,
    });
  };

  return (
    <div className={BASE_CLASS}>
      {filters.map((filter) => {
        const formattedFilters = getFilterOptions(filter?.filters);
        return (
          <div key={filter.label} className={`${BASE_CLASS}__select-wrapper`}>
            <FilterDropdown
              label={t(`test_drive.general.model.${filter.label}`)}
              items={formattedFilters}
              selectedFilter={currentSelection?.lineValue}
              onSelect={(e) => onFilterSelection(e, filter.label)}
              showSelectedFilter
            />
          </div>
        );
      })}
    </div>
  );
};

export default StockFilter;
