import classNames from 'classnames';
import {
  ListItemProps,
  OrderedListProps,
  UnOrderedListProps,
} from './List.config';
import './List.scss';

const BASE_CLASS = 'adb-list';

const ListItem = ({ content, additionalClasses }: ListItemProps) => (
  <li className={classNames(`${BASE_CLASS}__item`, additionalClasses)}>
    {content}
  </li>
);

const UnOrderedList = ({ additionalClasses, children }: UnOrderedListProps) => (
  <ul className={classNames(BASE_CLASS, additionalClasses)}>{children}</ul>
);

const List = ({ children, additionalClasses }: OrderedListProps) => (
  <ol className={classNames(BASE_CLASS, additionalClasses)}>{children}</ol>
);

export default List;
export { ListItem, UnOrderedList };
