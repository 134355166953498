import { SimStatusSuccessResponse } from '@smart/adb-shared';
import ActivatableSim from './ActivatableSim';
import ActiveSim from './ActiveSim';
import { SimLifecycle, isActivatable } from './utils';

const SimStatus = ({
  statusResponse,
  vin,
}: {
  statusResponse: SimStatusSuccessResponse;
  vin: string;
}) => {
  const {
    data: { lifecycle, packages: simPackages, status, startTime },
  } = statusResponse;

  if (lifecycle === SimLifecycle.LivePeriod) {
    return <ActiveSim simPackages={simPackages} startTime={startTime} />;
  }

  if (isActivatable(lifecycle, status)) {
    return <ActivatableSim vin={vin} />;
  }

  return null;
};

export default SimStatus;
