import {
  CustomerAccountType,
  CustomerRegistrationInput,
  Outlet,
} from '@smart/adb-shared';
import { getValidRandomPassword } from '@ui/library/helpers/password';
import {
  BusinessCustomerFormValues,
  PrivateCustomerFormValues,
} from './details/helper';

export const getCustomerPayload = (
  updatedFormValues: PrivateCustomerFormValues | BusinessCustomerFormValues,
  outlet: Outlet | undefined,
  newsletterConsent?: boolean
): CustomerRegistrationInput => {
  const customerPayload: CustomerRegistrationInput = {
    address: {
      street: updatedFormValues.street ?? '',
      regionCode: updatedFormValues.regionCode ?? '',
      town: updatedFormValues.town ?? '',
      postalCode: updatedFormValues.postalCode ?? '',
      countryCode: updatedFormValues.countryCode ?? '',
    },
    firstName: updatedFormValues.firstName,
    lastName: updatedFormValues.lastName,
    market: updatedFormValues.market ?? '',
    mobileNumber: updatedFormValues.mobileNumber ?? '',
    outletId: outlet?.bpId ?? '',
    preferredLanguage: updatedFormValues?.preferredLanguage ?? '',
    recordType: updatedFormValues.accountType ?? CustomerAccountType.Private,
    titleCode: updatedFormValues.titleCode ?? '',
    userId: updatedFormValues.userId ?? '',
    ...(updatedFormValues.accountType === CustomerAccountType.Private && {
      consents: {
        ...(newsletterConsent && {
          subscriptions: [
            {
              name: 'smart_NLwithDOI_EU',
              isSubscribed: newsletterConsent,
            },
          ],
        }),
        preferences: [
          {
            name: 'smart_termsOfService_EU',
            isConsentGranted: true,
          },
          {
            name: 'smart_privacyPolicy_EU',
            isConsentGranted: true,
          },
          {
            name: 'smart_ecosystem_EU',
            isConsentGranted: true,
          },
        ],
      },
    }),
    ...(updatedFormValues.accountType === CustomerAccountType.Private && {
      otp: getValidRandomPassword(),
    }),
    ...(updatedFormValues.__typename === 'CustomerBusiness' &&
      updatedFormValues?.companyName && {
        companyName: updatedFormValues.companyName,
      }),
    ...(updatedFormValues.__typename === 'CustomerBusiness' &&
      updatedFormValues?.vatRegistrationNumber && {
        vatRegistrationNumber: updatedFormValues.vatRegistrationNumber,
      }),
    ...(updatedFormValues.__typename === 'CustomerBusiness' && {
      status: 'approved',
    }),
    ...(updatedFormValues.__typename === 'CustomerBusiness' &&
      updatedFormValues.bpid && {
        bpid: updatedFormValues.bpid,
      }),
  };

  return customerPayload;
};
