import { IconBackground } from '@smart/components-adb/atoms/AdbIcon/AdbIcon.config';
import { isProd } from '@utils/helpers/environment';
import { useTranslation } from 'react-i18next';
import SimStatusContainer from './SimStatusContainer';

const SimStatusError = () => {
  const { t } = useTranslation();

  return (
    <SimStatusContainer
      icon="error"
      iconBackground={IconBackground.WARNING}
      title={
        isProd()
          ? t('customer.purchased_products.sim.error_message')
          : t('customer.purchased_products.sim.only_in_production')
      }
    />
  );
};

export default SimStatusError;
