import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
} from 'react-router-dom';

/**
 * Makes sure that search params are saved when navigating
 */
const useNavigateWithParams = () => {
  const { search } = useLocation();
  const routerNavigate = useNavigate();

  const navigate = (to: To, options: NavigateOptions) =>
    routerNavigate(search ? `${to}${search}` : to, options);

  return navigate;
};

export default useNavigateWithParams;
