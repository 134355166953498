import { Button } from '@smart/react-components';
import { useScreenSize } from 'hooks/useScreenSize';
import { useTranslation } from 'react-i18next';

const BASE_CLASS = 'feedback__button';

export const FeedbackButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  return (
    <Button
      data-testid="adb-feedback-button"
      variant="secondary"
      mode={200}
      type="button"
      onClick={onClick}
      className={`${BASE_CLASS}`}
    >
      {!isMobile && t('feedback.button')}
      <Button.Icon icon="reviews-empty" />
    </Button>
  );
};
