import { MarketCode } from '@utils/market/types';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useAgentContext } from './agent-context';
import { useUrlMarketContext } from './url-market-context';

interface MarketContextState {
  market: MarketCode;
}

export const MarketContext = React.createContext<
  MarketContextState | undefined
>(undefined);

export const MarketContextProvider = ({ children }: PropsWithChildren) => {
  const { agent } = useAgentContext();
  const { market: urlMarket, setMarket } = useUrlMarketContext();

  const value = useMemo(
    () => ({
      market: agent?.market as MarketCode,
    }),
    [agent]
  );

  useEffect(() => {
    if (value.market) {
      setMarket(value.market);
    }
  }, [value.market, setMarket, urlMarket]);

  return (
    <MarketContext.Provider value={value}>{children}</MarketContext.Provider>
  );
};

export const useMarketContext = () => {
  const value = React.useContext(MarketContext);

  if (!value) throw new Error('Got undefined market context!');

  return value;
};
