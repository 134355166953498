import { CustomerType } from '@smart/adb-shared';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import SmartComLinkOut from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut';
import {
  LinkOutConfig,
  buildLinkOutConfig,
} from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut.config';
import { TypeAhead } from '@smart/components-adb/molecules/TypeAhead/TypeAhead';
import { TypeAheadPropList } from '@smart/components-adb/molecules/TypeAhead/TypeAhead.config';
import { Text } from '@smart/react-components';
import { useAgentContext } from 'contexts/agent-context';
import { useAuthContext } from 'contexts/auth-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useAllCustomersQuery } from 'graphql/queries/customers.generated';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const BASE_CLASS = 'adb-configurator-prompt-modal';

const StockLinkOutModal = ({
  redirectPath,
  isExplore = false,
}: {
  redirectPath: string;
  isExplore?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useLanguageContext();
  const { auth } = useAuthContext();
  const { agent } = useAgentContext();
  const { market: selectedAppMarket } = useMarketContext();
  const referrerPath = useLocation().pathname;

  const [isLoading, setIsLoading] = useState(false);

  const { closeModal } = useModal();

  const baseLinkOutConfig: LinkOutConfig = buildLinkOutConfig({
    language: locale,
    market: selectedAppMarket ?? '',
    outletId: agent?.outletId,
    referrerPath,
  });
  const [linkOutConfig, setLinkOutConfig] =
    useState<LinkOutConfig>(baseLinkOutConfig);

  const [searchCustomerQuery, setSearchCustomerQuery] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { data: nonLeadCustomers, loading: outletCustomersLoading } =
    useAllCustomersQuery({
      variables: {
        filter: {
          types: [CustomerType.Contact],
          isActive: true,
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (nonLeadCustomers?.allCustomers.customers && searchCustomerQuery) {
      setErrorMessage(t('feature_calendar.general.no_result'));
    } else {
      setErrorMessage('');
    }
  }, [searchCustomerQuery, t, nonLeadCustomers]);

  const selectSuggestion = useCallback(
    (suggestion: TypeAheadPropList): void => {
      if (nonLeadCustomers?.allCustomers.customers) {
        const selection = nonLeadCustomers.allCustomers.customers.find(
          (customer) => customer.uuid === suggestion.id
        );

        setLinkOutConfig({
          ...baseLinkOutConfig,
          customerUserId: selection?.userId,
          customerType: selection?.accountType,
          customerUUID: selection?.uuid,
          customerPreferredLang: selection?.preferredLanguage,
        });
      }
    },
    [nonLeadCustomers, baseLinkOutConfig]
  );

  const linkOutRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const linkOutWithCustomerRef =
    useRef() as React.MutableRefObject<HTMLButtonElement>;

  return (
    <AdbDialog
      id={BASE_CLASS}
      className={BASE_CLASS}
      buttons={{
        primary: {
          onClick: () => {
            setIsLoading(true);
            linkOutWithCustomerRef.current.click();
          },
          label: isExplore
            ? t('dashboard.customizer_prompt.configurator_w_customer', {
                customer:
                  linkOutConfig.customerUserId ||
                  t('general.labels.customer').toLowerCase(),
              })
            : t('stock.linkout_modal.select_customer'),
          isLoading: outletCustomersLoading || isLoading,
          disabled: outletCustomersLoading || !linkOutConfig.customerUUID,
        },
        secondary: {
          onClick: () => {
            closeModal();

            if (isExplore) {
              linkOutRef.current.click();
            } else {
              navigate('/customer/register');
            }
          },
          label: isExplore
            ? t('test_drive.return.customizer')
            : t('customer.search.buttons.create_customer'),
        },
      }}
      size="medium"
    >
      <AdbDialog.Header>
        <Text variant="hl-100">
          {isExplore
            ? t('dashboard.customizer_prompt.title')
            : t('stock.linkout_modal.title')}
        </Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <TypeAhead
          list={
            nonLeadCustomers?.allCustomers.customers.map((customer) => ({
              id: customer.uuid ?? undefined,
              title: `${customer?.firstName} ${customer?.lastName} - ${customer?.userId}`,
            })) as TypeAheadPropList[]
          }
          name="searchedCustomer"
          delay={400}
          onChange={setSearchCustomerQuery}
          onSelect={selectSuggestion}
          label={t('feature_calendar.calendar_dialog.search')}
          searchKey={searchCustomerQuery}
          errorMessageMinLength={t('general.labels.enter_3_char')}
          errorMessage={errorMessage}
          customClass={`${BASE_CLASS}__section-typeahead`}
          disabled={outletCustomersLoading}
        />

        <SmartComLinkOut
          redirectPath={redirectPath}
          config={{
            ...linkOutConfig,
            accessToken: auth ? auth['access-token'] : undefined,
            idToken: auth ? auth['id-token'] : undefined,
            refreshToken: auth ? auth['refresh-token'] : undefined,
          }}
          disabled={outletCustomersLoading}
        >
          <button ref={linkOutWithCustomerRef} style={{ display: 'none' }}>
            -
          </button>
        </SmartComLinkOut>

        <SmartComLinkOut
          redirectPath={redirectPath}
          config={{
            ...baseLinkOutConfig,
            accessToken: auth ? auth['access-token'] : undefined,
            idToken: auth ? auth['id-token'] : undefined,
            refreshToken: auth ? auth['refresh-token'] : undefined,
          }}
          disabled={outletCustomersLoading}
        >
          <button ref={linkOutRef} style={{ display: 'none' }}>
            -
          </button>
        </SmartComLinkOut>
      </AdbDialog.Content>
    </AdbDialog>
  );
};

export { StockLinkOutModal };
