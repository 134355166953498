import { AppointmentType, CarDetails } from '@smart/adb-shared';
import AdbLink from '@smart/components-adb/atoms/AdbLink/AdbLink';
import { useCreateAppointmentMutation } from '@smart/components-adb/calendar/CreateAppointmentDialog/queries.generated';
import { Text } from '@smart/react-components';
import { TestDriveBookingCurrent } from '@ui/data-models/test-drive/test-drive.model';
import { DateFormats, getLocaleDate } from '@ui/library/helpers/date-locale';
import {
  getNavigationItem,
  handleNavigationStepLinks,
  stepStatusHandler,
} from '@utils/helpers/test-drive';
import { useAgentContext } from 'contexts/agent-context';
import { useCustomerContext } from 'contexts/customer-context';
import { useLanguageContext } from 'contexts/language-context';
import { useTestDriveContext } from 'contexts/test-drive-context';
import FocusLayout from 'layouts/focus-layout/FocusLayout';
import {
  TestDriveBookingSteps,
  mapCarSelectItem,
} from 'pages/test-drive/config';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTestDriveTimeSlotsQuery } from '../../queries.generated';
import './TestDriveSummary.scss';

const BASE_CLASS = 'adb-summary';

const TestDriveSummary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useLanguageContext();
  const {
    currentTestDriveBooking,
    navigation,
    setNavigation,
    setCurrentTestDriveBooking,
    superLightCustomer,
  } = useTestDriveContext();
  const { customer } = useCustomerContext();

  const navData = navigation;
  const { agent } = useAgentContext();
  const outletId = agent?.outletId;

  const expertId = agent?.id;

  const currentCustomer = superLightCustomer ?? customer;

  /**
   * ------------------------------------------------------------
   * Test drive booking information
   * ------------------------------------------------------------
   */

  const { data } = useTestDriveTimeSlotsQuery({
    variables: {
      input: {
        outletId,
        model: currentTestDriveBooking.carModel ?? '',
      },
    },
    skip: !currentTestDriveBooking.carModel || !outletId,
    onError: (error) => {
      console.log({ error });
    },
    fetchPolicy: 'network-only',
  });

  const testDriveCars = useMemo(
    () =>
      data?.testDriveTimeSlots.slotDetails &&
      data?.testDriveTimeSlots.carDetails
        ? mapCarSelectItem(
            data.testDriveTimeSlots.slotDetails,
            data.testDriveTimeSlots.carDetails as CarDetails[]
          )
        : [],
    [data?.testDriveTimeSlots.carDetails, data?.testDriveTimeSlots.slotDetails]
  );

  const selectedCar = testDriveCars.find(
    (car) => car.carId === currentTestDriveBooking.car?.carId
  );

  // selected data
  const displayDate = currentTestDriveBooking.slot?.date
    ? getLocaleDate(
        new Date(currentTestDriveBooking.slot.date).toString(),
        locale,
        DateFormats.DATE
      )
    : '';

  const [
    createAppointment,
    { data: tdAppointmentData, loading: tdAppointmentLoading },
  ] = useCreateAppointmentMutation();

  /**
   * ------------------------------------------------------------
   * Navigation
   * ------------------------------------------------------------
   */

  const testDriveData: TestDriveBookingCurrent = {
    ...currentTestDriveBooking,
  };

  const sendTestDriveBooking = async (booking: TestDriveBookingCurrent) => {
    const stepStatus = {
      [TestDriveBookingSteps.CONFIRMATION]: true,
    };
    const updatedNavList = stepStatusHandler(navData, stepStatus);
    setNavigation(updatedNavList);

    await createAppointment({
      variables: {
        input: {
          externalContactId: currentCustomer?.uuid ?? '',
          ...(customer?.__typename === 'CustomerBusiness' &&
            customer?.bpid && {
              externalAccountId: customer.bpid,
            }),
          outletId,
          expertId,
          appointmentType: AppointmentType.TestDrive,
          carId: booking.car?.carId ?? '',
          startDateTime: booking.slot?.pickUpTime ?? '',
          endDateTime: booking.slot?.dropOffTime ?? '',
          isFromADBCalendar: false,
          phone: currentCustomer?.mobileNumber,
        },
      },
    });

    setCurrentTestDriveBooking({
      ...booking,
    });
  };

  useEffect(() => {
    if (tdAppointmentData && !tdAppointmentLoading) {
      navigate(
        [
          `/test-drive/confirmation/success`,
          customer?.__typename === 'CustomerBusiness'
            ? `/${customer.bpid}/${customer.sfOrgId}`
            : '',
        ].join('')
      );
    }
  }, [
    navigate,
    tdAppointmentData,
    tdAppointmentLoading,
    customer,
    currentCustomer,
  ]);

  /**
   * ------------------------------------------------------------
   * Page Content
   * ------------------------------------------------------------
   */

  return (
    <FocusLayout
      navList={handleNavigationStepLinks(
        navigation,
        currentCustomer?.uuid,
        currentCustomer?.sfCustomerId ?? undefined
      )}
      nextButtonLabel={t('test_drive.summary.buttons.confirm')}
      isNextButtonLoading={tdAppointmentLoading}
      backButtonRoute={`/test-drive/confirmation/${currentCustomer?.uuid}/user-details/${currentCustomer?.sfCustomerId}`}
      nextButtonHandler={() => sendTestDriveBooking(testDriveData)}
    >
      <div className={BASE_CLASS}>
        <Text variant="hl-300" className={`${BASE_CLASS}__heading`} as="h1">
          {t('test_drive.summary.heading')}
        </Text>
        <div className={`${BASE_CLASS}__details`}>
          <div className={`${BASE_CLASS}__details-item`}>
            <Text
              variant="hl-300"
              as="h3"
              className={`${BASE_CLASS}__details-heading`}
            >
              {t('test_drive.summary.car')}
            </Text>
            <div className={`${BASE_CLASS}__details-item--description`}>
              <Text variant="lbl-100" as="p">
                {selectedCar?.longName ?? selectedCar?.name}
              </Text>
              <Text variant="cap-200" as="p">
                {`${t('car.features.exterior')}: `}
                {selectedCar?.features?.exterior}
              </Text>
              <Text variant="cap-200" as="p">
                {`${t('car.features.interior')}: `}
                {selectedCar?.features?.interior}
              </Text>
              <Text variant="cap-200" as="p">
                {`${t('car.assets.license_plate')}: `}
                {currentTestDriveBooking.car?.licensePlate}
              </Text>
            </div>
          </div>
          <div>
            <AdbLink
              path={getNavigationItem(navData, TestDriveBookingSteps.CAR).path}
              title={t('test_drive.summary.buttons.edit')}
            />
          </div>
          <div className={`${BASE_CLASS}__details-item`}>
            <Text
              variant="hl-300"
              as="h3"
              className={`${BASE_CLASS}__details-heading`}
            >
              {t('test_drive.summary.time')}
            </Text>
            <div className={`${BASE_CLASS}__details-item--description`}>
              <Text variant="lbl-100" as="p">
                {displayDate}
              </Text>
              <Text variant="cap-200" as="p">
                {currentTestDriveBooking.slot?.pickUpTime &&
                  t('test_drive.time.pick_up', {
                    time: getLocaleDate(
                      currentTestDriveBooking.slot?.pickUpTime,
                      locale
                    ),
                  })}
              </Text>
              <Text variant="cap-200" as="p">
                {currentTestDriveBooking.slot?.dropOffTime &&
                  t('test_drive.time.drop_off', {
                    time: getLocaleDate(
                      currentTestDriveBooking.slot?.dropOffTime,
                      locale
                    ),
                  })}
              </Text>
            </div>
          </div>
          <div>
            <AdbLink
              path={getNavigationItem(navData, TestDriveBookingSteps.TIME).path}
              title={t('test_drive.summary.buttons.edit')}
            />
          </div>
          <div className={`${BASE_CLASS}__details-item`}>
            <Text
              variant="hl-300"
              as="h3"
              className={`${BASE_CLASS}__details-heading`}
            >
              {t('test_drive.summary.customer')}
            </Text>
            <div className={`${BASE_CLASS}__details-item--description`}>
              <Text variant="lbl-100" as="p">
                {currentCustomer?.firstName} {currentCustomer?.lastName}
              </Text>
              <Text variant="cap-200" as="p">
                {currentCustomer?.userId}
              </Text>
              <Text variant="cap-200" as="p">
                {currentCustomer?.address?.street}
              </Text>
              <Text variant="cap-200" as="p">
                {currentCustomer?.address?.postalCode}{' '}
                {currentCustomer?.address?.town}
              </Text>
            </div>
          </div>
          <div>
            <AdbLink
              path={
                getNavigationItem(navData, TestDriveBookingSteps.ACCOUNT).path
              }
              title={t('test_drive.summary.buttons.edit')}
            />
          </div>
        </div>
      </div>
    </FocusLayout>
  );
};

export default TestDriveSummary;
