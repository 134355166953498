import { Button, Text } from '@smart/react-components';
import classNames from 'classnames';
import { HeaderProps } from '../CaseModal.config';
import './Header.scss';

const Header = ({
  conversationType = 'Email',
  title,
  closeHistory,
}: HeaderProps) => {
  const BASE_CLASS = 'adb-conversation-header';

  return (
    <div className={classNames(BASE_CLASS)}>
      <div className={`${BASE_CLASS}__content`}>
        <div className={`${BASE_CLASS}__content--left`}>
          {title && title !== '' && (
            <>
              <Text
                variant="cap-300"
                as="p"
                className={`${BASE_CLASS}__content--left--type`}
              >
                {conversationType}
              </Text>
              <Text
                variant="hl-100"
                className={`${BASE_CLASS}__content-left-title`}
              >
                {title}
              </Text>
            </>
          )}
        </div>
        <div className={`${BASE_CLASS}__content--right`}>
          <Button
            aria-label="Close conversation history"
            variant="ghost"
            mode={200}
            onClick={closeHistory}
          >
            <Button.Icon
              icon="close-without-circle"
              aria-label="close-without-circle"
            />
          </Button>
        </div>
      </div>
      <div className={`${BASE_CLASS}__divider`} />
    </div>
  );
};

export default Header;
