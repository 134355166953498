const { API_GATEWAY_BASE_URL } = import.meta.env;

export const ENDPOINTS = {
  AUTH: {
    LOAD_AUTH_TOKENS: `${API_GATEWAY_BASE_URL}/auth/tokens`,
  },
  DOCUMENTS: {
    SEND_UPLOAD_DOCUMENT: `${API_GATEWAY_BASE_URL}/document/upload`,
  },
};
