import {
  Appointment,
  AppointmentType,
  ExtendedCar,
  McsAgent,
  Outlet,
} from '@smart/adb-shared';
import { HttpError } from '@ui/data-models/error/error.model';
import { AdbLocale } from '@ui/library/helpers/date-locale';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { CustomerAction } from './customer/action';
import { DemoCarAction } from './demo-car/action';
import { ExpertAction } from './expert/action';
import { SettingAction } from './setting/action';
import { NotificationState } from './setting/types';

export enum FilterType {
  Expert = 'expert',
  Car = 'car',
}

export interface Filter {
  type: FilterType;
  value: string;
}

export type CalendarContextBaseState = {
  isLoading: boolean;
  appointments: Appointment[];
  experts: McsAgent[];
  demoCars: ExtendedCar[];
  language: string;
  locale: AdbLocale;
  outlet?: Outlet;
  notification?: NotificationState;
  isActionLoading: boolean;
  updatedDemoCarStatusSuccess?: boolean;
  updatedDemoCarStatusError?: HttpError;
  filter: Filter[];
  setFilter: Dispatch<SetStateAction<Filter[]>>;
};

export type CalendarContextState = CalendarContextBaseState & {
  dispatch: (action: any) => void;
  onClose?: () => void;
};

export type CalendarProps = {
  onClose?: () => void;
  children?: ReactNode;
};

export type CalendarModalProps = {
  customerId: string;
  appointmentType?: AppointmentType;
  carId?: string | undefined | null;
  preSelectedAppointmentId?: string;
  preSelectedStartTime?: string | Date;
};

export type AppointmentsProps = Partial<CalendarModalProps>;

export type CalendarAction =
  | DemoCarAction
  | ExpertAction
  | SettingAction
  | CustomerAction;
