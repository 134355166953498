import {
  CustomerAccountType,
  MileageUnit,
  OrderPaymentStatus,
  OrderPaymentType,
  PayerType,
  ProductType,
} from '@smart/adb-shared';
import AdbIcon from '@smart/components-adb/atoms/AdbIcon/AdbIcon';
import { IconColor } from '@smart/components-adb/atoms/AdbIcon/AdbIcon.config';
import { Flex, Text } from '@smart/react-components';
import {
  OrderPaymentStatusFilterValues,
  getOrderPaymentTypeFilterValues,
} from '@ui/data-models/facet/facet.model';
import {
  formatPriceByLocale,
  getFormattedRecyclingContribution,
} from '@ui/data-models/order/helper';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useTranslation } from 'react-i18next';
import { OrderLeasingDetailsForm } from '../CarListItem/OrderLeasingDetailsForm/OrderLeasingDetailsForm';
import { isWallBoxItem } from './isWallboxItem';
import OrderItemFeature from './OrderItemFeature';
import { OrderItemSection } from './OrderItems.config';
import { translateLabel } from './translateLabel';

const BASE_CLASS = 'adb-order-items';

const PaymentDetails = ({ orderDetail }: OrderItemSection) => {
  const { locale } = useLanguageContext();
  const { t } = useTranslation();
  const { market } = useMarketContext();
  const orderItemWithVehicleType = orderDetail?.items?.find(
    (order) => order.productType === ProductType.Vehicle
  );

  const isThirdPartyLeasingOrder =
    orderDetail?.paymentType === OrderPaymentType.ThirdPartyLeasing;
  const isThirdPartyFinancingOrder =
    orderDetail?.paymentType === OrderPaymentType.ThirdPartyFinancing;
  const isThirdPartyLeasingOrFinancingOrder =
    isThirdPartyFinancingOrder || isThirdPartyLeasingOrder;
  const isLeasingOrder = orderDetail?.paymentType === OrderPaymentType.Leasing;

  const paymentCarDetails = () => {
    if (
      orderItemWithVehicleType &&
      orderItemWithVehicleType?.payment?.leasingId
    ) {
      const firstPayer = isThirdPartyLeasingOrFinancingOrder
        ? orderDetail?.paymentOverview?.find(
            (p) => p?.payer?.payerType === PayerType.Payer1
          )
        : undefined;
      const secondPayer = isThirdPartyLeasingOrFinancingOrder
        ? orderDetail?.paymentOverview?.find(
            (p) => p?.payer?.payerType === PayerType.Payer2
          )
        : undefined;

      const details = [
        {
          key: t('orders.payment_details.price'),
          value: formatPriceByLocale(
            locale,
            orderDetail?.totalPrice?.value ?? 0,
            orderDetail?.totalPrice?.currencyIso ?? ''
          ),
        },
        ...(isLeasingOrder
          ? [
              {
                key: t('orders.payment_details.leasing_id'),
                value: orderItemWithVehicleType.payment?.leasingId ?? '',
              },
              {
                key: t('orders.payment_details.leasing_rate'),
                value:
                  String(
                    orderItemWithVehicleType.payment?.leasingRate?.value
                  ) ?? '',
              },
              {
                key: t('orders.payment_details.mileage'),
                value:
                  orderItemWithVehicleType.payment?.mileage ||
                  orderItemWithVehicleType.payment?.mileageUnit
                    ? `${orderItemWithVehicleType.payment.mileage ?? ''} / ${
                        orderItemWithVehicleType.payment?.mileageUnit ?? ''
                      }`
                    : '',
              },
              {
                key: t('orders.payment_details.period'),
                value: String(orderItemWithVehicleType.payment?.period) ?? '',
              },
            ]
          : []),
        ...(firstPayer
          ? [
              ...(isThirdPartyLeasingOrder
                ? [
                    {
                      key: t('orders.payment_details.1st_payer'),
                      value: firstPayer.payer?.name ?? '',
                    },
                  ]
                : []),
              {
                key: t('orders.payment_details.1st_payer_amount'),
                value: formatPriceByLocale(
                  locale,
                  firstPayer.totalPrice?.value ?? 0,
                  firstPayer.totalPrice?.currencyIso ?? ''
                ),
              },
            ]
          : []),
        ...(secondPayer
          ? [
              ...(isThirdPartyFinancingOrder
                ? [
                    {
                      key: t('orders.payment_details.2nd_payer'),
                      value: secondPayer.payer?.name ?? '',
                    },
                  ]
                : []),
              {
                key: t('orders.payment_details.2nd_payer_amount'),
                value: formatPriceByLocale(
                  locale,
                  secondPayer.totalPrice?.value ?? 0,
                  secondPayer.totalPrice?.currencyIso ?? ''
                ),
              },
            ]
          : []),
        ...(isThirdPartyLeasingOrFinancingOrder
          ? [
              ...(orderDetail?.mainDriverName
                ? [
                    {
                      key: t('orders.payment_details.main_driver_name'),
                      value: orderDetail?.mainDriverName ?? '',
                    },
                  ]
                : []),
              ...(orderDetail?.cartId
                ? [
                    {
                      key: t('orders.payment_details.offer_number'),
                      value: orderDetail?.cartId ?? '',
                    },
                  ]
                : []),
              ...(orderDetail?.mainDriverEmail
                ? [
                    {
                      key: t('orders.payment_details.main_driver_email'),
                      value: orderDetail?.mainDriverEmail,
                    },
                  ]
                : []),
              ...(orderDetail?.mainDriverComment
                ? [
                    {
                      key: t('orders.payment_details.main_driver_comment'),
                      value: orderDetail?.mainDriverComment,
                    },
                  ]
                : []),
            ]
          : []),
        ...(isThirdPartyFinancingOrder
          ? [
              ...(orderItemWithVehicleType.payment?.leasingId
                ? [
                    {
                      key: t('orders.payment_details.contract_id'),
                      value: orderItemWithVehicleType.payment?.leasingId,
                    },
                  ]
                : []),
            ]
          : []),
      ];

      if (orderDetail?.promotions) {
        details.push({
          key: t('orders.payment_details.discount_name'),
          value: orderDetail?.promotions[0]?.name ?? '',
        });
        details.push({
          key: t('orders.payment_details.discount_amount'),
          value: orderDetail?.promotions[0]?.discount.formattedPrice ?? '',
        });
      }

      return details;
    }

    const cashbuyArray = [
      {
        key: t('orders.payment_details.price'),
        value: formatPriceByLocale(
          locale,
          orderDetail?.totalPriceWithTax?.value ?? 0,
          orderDetail?.totalPriceWithTax?.currencyIso ?? ''
        ),
      },
    ];

    if (orderDetail?.customer?.customerType === CustomerAccountType.Business) {
      cashbuyArray[0].value = formatPriceByLocale(
        locale,
        orderDetail?.totalPrice?.value ?? 0,
        orderDetail?.totalPrice?.currencyIso ?? ''
      );
    }

    if (orderDetail?.vehiclePrice)
      cashbuyArray.push({
        key: t('orders.payment_details.vehicle_price'),
        value: formatPriceByLocale(
          locale,
          orderDetail.vehiclePrice.value,
          orderDetail.vehiclePrice.currencyIso
        ),
      });

    if (orderDetail?.accessoryPrice)
      cashbuyArray.push({
        key: t('orders.payment_details.accessory_price'),
        value: formatPriceByLocale(
          locale,
          orderDetail.accessoryPrice.value,
          orderDetail.accessoryPrice.currencyIso
        ),
      });

    if (orderDetail?.recyclingContribution)
      cashbuyArray.push({
        key: t('orders.payment_details.recycling_contribution_price'),
        value: getFormattedRecyclingContribution(
          locale,
          orderDetail.recyclingContribution.value ?? 0,
          orderDetail.recyclingContribution.currencyIso ?? '',
          orderDetail.customer?.customerType
        ),
      });

    if (orderDetail?.additionalCosts)
      cashbuyArray.push({
        key: t('orders.payment_details.additional_costs'),
        value: formatPriceByLocale(
          locale,
          orderDetail?.additionalCosts.value,
          orderDetail?.additionalCosts.currencyIso
        ),
      });

    if (orderDetail?.promotions) {
      cashbuyArray.push({
        key: t('orders.payment_details.discount_name'),
        value: orderDetail?.promotions[0]?.name ?? '',
      });
      cashbuyArray.push({
        key: t('orders.payment_details.discount_amount'),
        value: formatPriceByLocale(
          locale,
          Number(orderDetail?.promotions[0]?.discount.value) ?? 0,
          orderDetail?.promotions[0]?.discount.currencyISO ?? ''
        ),
      });
    }

    return cashbuyArray;
  };

  const renderOrderLeasingDetailsForm = () =>
    isThirdPartyLeasingOrder && orderItemWithVehicleType ? (
      <OrderLeasingDetailsForm
        orderId={orderDetail?.orderId ?? undefined}
        initialValues={{
          leasingId: orderItemWithVehicleType.payment?.leasingId ?? '',
          leasingRate:
            orderItemWithVehicleType.payment?.leasingRate?.value ?? 0,
          mileage: orderItemWithVehicleType.payment?.mileage ?? 0,
          period: Number(
            orderItemWithVehicleType.payment?.period?.toString() ?? ''
          ),
          currencyIso:
            orderItemWithVehicleType.payment?.leasingRate?.currencyIso ?? '',
          mileageUnit:
            orderItemWithVehicleType.payment?.mileageUnit === 'Kilometers'
              ? MileageUnit.Km
              : MileageUnit.Miles,
        }}
      />
    ) : null;

  return (
    <Flex direction="column" width="full" gap={300}>
      <Flex columnGap={100}>
        <Text variant="lbl-200">{t('orders.payment_details.title')}</Text>
        {orderDetail?.paymentStatus === OrderPaymentStatus.Notpaid && (
          <AdbIcon icon="warning" color={IconColor.ERROR} />
        )}
      </Flex>
      <Flex
        alignContent="space-between"
        width="full"
        wrap="wrap"
        gap={300}
        className={`${BASE_CLASS}__features`}
      >
        <OrderItemFeature
          title={t('orders.payment_details.payment_status')}
          text={
            orderDetail?.paymentStatus
              ? translateLabel(
                  OrderPaymentStatusFilterValues,
                  orderDetail?.paymentStatus,
                  t
                )
              : '-'
          }
        />
        {orderDetail && (
          <OrderItemFeature
            title={t('orders.payment_details.payment_type')}
            text={
              !isWallBoxItem(orderDetail)
                ? translateLabel(
                    getOrderPaymentTypeFilterValues(market),
                    orderDetail?.paymentType,
                    t
                  )
                : translateLabel(
                    OrderPaymentStatusFilterValues,
                    orderDetail?.paymentStatus,
                    t
                  )
            }
          />
        )}
        {paymentCarDetails().map((car) => (
          <OrderItemFeature key={car.key} title={car.key} text={car.value} />
        ))}
      </Flex>
      {renderOrderLeasingDetailsForm()}
    </Flex>
  );
};

export default PaymentDetails;
