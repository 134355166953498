import { Flex, Text } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import OrderItemFeature from '../OrderItems/OrderItemFeature';
import { CartDetailsSection } from './CartItems.config';
import './CartItems.scss';

const BASE_CLASS = 'adb-cart-items';

const OrderDetail = ({ detail }: CartDetailsSection) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" width="full" gap={300}>
      <Text variant="lbl-200">{t('orders.ald_carts.cart_detail')}</Text>
      <Flex
        alignContent="space-between"
        width="full"
        wrap="wrap"
        gap={300}
        className={`${BASE_CLASS}__features`}
      >
        <OrderItemFeature
          title={t('car.features.model')}
          text={detail?.model}
        />
        <OrderItemFeature title={t('car.assets.line')} text={detail?.line} />
        <OrderItemFeature
          title={t('car.features.exterior')}
          text={detail?.exterior}
        />
        <OrderItemFeature
          title={t('car.features.interior')}
          text={detail?.innertior}
        />
        <OrderItemFeature
          title={t('car.features.battery')}
          text={detail?.battery}
        />
        <OrderItemFeature title={t('car.features.roof')} text={detail?.roof} />
      </Flex>
    </Flex>
  );
};

export default OrderDetail;
