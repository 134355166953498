import { McsAgent, Outlet } from '@smart/adb-shared';
import { Dispatch } from 'react';
import { CalendarAction } from '../types';
import { ExpertActionType } from './action';

/**
 * Set currently outletId to the context store
 *
 * @param dispatch Dispatch<CalendarAction>
 * @param outletId string
 */
export const setOutlet = (
  dispatch: Dispatch<CalendarAction>,
  outlet: Outlet
) => {
  dispatch({
    type: ExpertActionType.SET_OUTLET,
    outlet,
  });
};

/**
 * Set list of experts
 *
 * @param dispatch Dispatch<CalendarAction>
 * @param experts Agent[]
 */
export const setExperts = (
  dispatch: Dispatch<CalendarAction>,
  experts: McsAgent[]
) => {
  dispatch({
    type: ExpertActionType.SET_EXPERTS,
    experts,
  });
};
