import { Button, IconButton } from '@smart/react-components';

export const RatingButton = ({
  selected,
  onClick,
}: {
  selected: boolean;
  onClick: () => void;
}) => (
  <IconButton
    variant="accent"
    onClick={onClick}
    data-testid="adb-feedback-rate"
  >
    <Button.Icon
      aria-label={selected ? 'reviews-full' : 'reviews-empty'}
      icon={selected ? 'reviews-full' : 'reviews-empty'}
    />
  </IconButton>
);
