import { useAgentDashboardNavigation } from '../../pages/navigation';
import SectionLayout from '../section-layout/SectionLayout';
import { DashboardLayoutProps } from './DashboardLayout.config';
import './DashboardLayout.scss';

const BASE_CLASS = 'adb-dashboard-layout';

const DashboardLayout = ({ children, darkMode }: DashboardLayoutProps) => {
  const navItems = useAgentDashboardNavigation();

  return (
    <div className={BASE_CLASS}>
      <SectionLayout navItems={navItems} darkMode={darkMode} />
      <div className={`${BASE_CLASS}__children`}>{children}</div>
    </div>
  );
};

export default DashboardLayout;
