import { CarDetails } from '@smart/adb-shared';
import EmptyLayout from '@smart/components-adb/atoms/EmptyDataLayout/EmptyLayout';
import { ListItem, UnOrderedList } from '@smart/components-adb/atoms/List/List';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import AdbDynamicImage from '@smart/components-adb/molecules/AdbDynamicImage/AdbDynamicImage';
import { imageConfig16To9 } from '@smart/components-adb/molecules/AdbDynamicImage/AdbDynamicImage.config';
import { Button, IconButton, Text } from '@smart/react-components';
import { CarLine, CarModels } from '@ui/data-models/car/car.model';
import {
  getNavigationItem,
  handleNavigationStepLinks,
  stepStatusHandler,
} from '@utils/helpers/test-drive';
import { useAgentContext } from 'contexts/agent-context';
import { useTestDriveContext } from 'contexts/test-drive-context';
import FocusLayout from 'layouts/focus-layout/FocusLayout';
import {
  TestDriveBookingSteps,
  filterDuplicates,
  mapCarSelectItem,
  testDriveModels,
} from 'pages/test-drive/config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useTestDriveTimeSlotsQuery } from '../queries.generated';
import './CarSelection.scss';

const BASE_CLASS = 'adb-car-selection';

const CarSelection = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const locationState = location.state as {
    customerId?: string;
    sfCustomerId?: string;
    bpid?: string;
    sfOrgId?: string;
  };
  const {
    currentTestDriveBooking,
    navigation,
    setNavigation,
    setModelForCurrentTestDrive,
    setCustomerIds,
    setCarLineForCurrentTestDrive,
  } = useTestDriveContext();

  const { agent } = useAgentContext();

  // select from the store the dealer Id of the currently authenticated agent
  const agentOutletId = agent?.outletId;

  const [preselectedModel, setPreselectedModel] = useState<boolean>(
    !!currentTestDriveBooking.carModel
  );

  const { data, loading } = useTestDriveTimeSlotsQuery({
    variables: {
      input: {
        outletId: agentOutletId,
        model: currentTestDriveBooking.carModel ?? '',
      },
    },
    skip: !currentTestDriveBooking.carModel || !agentOutletId,
    fetchPolicy: 'network-only',
  });

  const testDriveCars = useMemo(
    () =>
      data?.testDriveTimeSlots.slotDetails &&
      data?.testDriveTimeSlots.carDetails
        ? filterDuplicates(
            mapCarSelectItem(
              data.testDriveTimeSlots.slotDetails,
              data.testDriveTimeSlots.carDetails as CarDetails[]
            )
          )
        : [],
    [data?.testDriveTimeSlots.carDetails, data?.testDriveTimeSlots.slotDetails]
  );

  const getCurrentSelectedLine = useCallback(() => {
    const selectedLine = testDriveCars.findIndex(
      (carLine) => carLine.line === currentTestDriveBooking.carLine
    );
    const line =
      currentTestDriveBooking.carLine &&
      testDriveCars?.length > 0 &&
      selectedLine > -1
        ? selectedLine
        : 0;

    return line;
  }, [currentTestDriveBooking.carLine, testDriveCars]);

  const getCurrentSelectedModelExists = useCallback(() => {
    const existingModel = testDriveModels.find(
      (model) => model.queryValue === currentTestDriveBooking.carModel
    );

    const selectedModel = testDriveCars.findIndex(
      (model) => model.model === existingModel?.displayName
    );

    const model = selectedModel > -1;

    return model;
  }, [currentTestDriveBooking, testDriveCars]);

  // state variable to set the selected tab
  const [selectedTab, setSelectedTab] = useState<number>(
    getCurrentSelectedLine()
  );

  useEffect(() => {
    setSelectedTab(getCurrentSelectedLine());
  }, [getCurrentSelectedLine]);

  useEffect(() => {
    if (!currentTestDriveBooking.carModel) {
      setModelForCurrentTestDrive(CarModels.HASHTAG_ONE);
    }

    if (
      !preselectedModel &&
      !loading &&
      !getCurrentSelectedModelExists() &&
      currentTestDriveBooking.carModel
    ) {
      setModelForCurrentTestDrive(testDriveModels[1].queryValue as CarModels);
      setPreselectedModel(true);
    }
  }, [
    currentTestDriveBooking.carModel,
    getCurrentSelectedModelExists,
    loading,
    preselectedModel,
    setModelForCurrentTestDrive,
  ]);

  useEffect(() => {
    if (locationState?.customerId && locationState?.sfCustomerId) {
      setCustomerIds(
        locationState.customerId,
        locationState.sfCustomerId,
        locationState.bpid,
        locationState.sfOrgId
      );
    }
  }, [locationState, setCustomerIds]);

  const setDataToStore = () => {
    setCarLineForCurrentTestDrive(testDriveCars[selectedTab]?.line as CarLine);
    const stepsStatus = {
      [TestDriveBookingSteps.CAR]: true,
      [TestDriveBookingSteps.TIME]: false,
    };

    const updatedNavList = stepStatusHandler(navigation, stepsStatus);

    setNavigation(updatedNavList);
  };

  /**
   *  This screen shows the details of car that user will select for test drive
   *  It includes image & specification of the car
   */
  return (
    <FocusLayout
      navList={handleNavigationStepLinks(
        navigation,
        currentTestDriveBooking.customerId,
        currentTestDriveBooking.sfCustomerId
      )}
      nextButtonLabel={t('test_drive.car_selection.buttons.next')}
      nextButtonHandler={setDataToStore}
      nextButtonRoute={
        getNavigationItem(navigation, TestDriveBookingSteps.TIME).path
      }
      isNextButtonDisabled={loading}
      isRequiredFooterSeparator={false}
    >
      <div className={BASE_CLASS}>
        {!loading && testDriveCars.length > 0 ? (
          <>
            <Text className={`${BASE_CLASS}__heading`} variant="hl-300">
              {t('test_drive.car_selection.choose_your_smart')}
            </Text>
            <nav className={`${BASE_CLASS}__tabs`}>
              <UnOrderedList>
                {testDriveCars.map((carLine, index) => (
                  <ListItem
                    additionalClasses={
                      selectedTab === index
                        ? `${BASE_CLASS}__tabs--selected`
                        : `${BASE_CLASS}__tabs--not-selected`
                    }
                    content={
                      carLine.line ? (
                        <Button
                          variant="ghost"
                          onClick={() => setSelectedTab(index)}
                          id={carLine.line}
                        >
                          {t(
                            `test_drive.general.model.${carLine.line
                              .toLowerCase()
                              .replace(/ /g, '_')}`
                          )}
                        </Button>
                      ) : null
                    }
                    key={carLine.line}
                  />
                ))}
              </UnOrderedList>
            </nav>
            <nav className={`${BASE_CLASS}__tabs`}>
              <UnOrderedList>
                {testDriveModels.map((model) => (
                  <ListItem
                    additionalClasses={
                      currentTestDriveBooking.carModel === model.queryValue
                        ? `${BASE_CLASS}__tabs--selected`
                        : `${BASE_CLASS}__tabs--not-selected`
                    }
                    content={
                      <Button
                        variant="ghost"
                        onClick={() =>
                          setModelForCurrentTestDrive(
                            model.queryValue as CarModels
                          )
                        }
                        id={model.queryValue}
                      >
                        {model.displayName}
                      </Button>
                    }
                    key={model.queryValue}
                  />
                ))}
              </UnOrderedList>
            </nav>
            <div className={`${BASE_CLASS}__details`}>
              <div
                className={`${BASE_CLASS}__details-btn ${BASE_CLASS}__details-btn--left`}
              >
                {selectedTab > 0 && (
                  <IconButton
                    aria-label="left button"
                    variant="secondary"
                    onClick={() => setSelectedTab(selectedTab - 1)}
                    id="test-drive__car__slide-left"
                  >
                    <Button.Icon icon="arrow-left" aria-label="arrow-left" />
                  </IconButton>
                )}
              </div>
              <div className={`${BASE_CLASS}__picture-wrap`}>
                {testDriveCars[selectedTab]?.image && (
                  <AdbDynamicImage
                    imageUrl={testDriveCars[selectedTab].image ?? ''}
                    imageConfig={imageConfig16To9}
                    imageAltText={testDriveCars[selectedTab].name ?? '-'}
                    className={`${BASE_CLASS}__picture`}
                  />
                )}
              </div>

              <div
                className={`${BASE_CLASS}__details-btn ${BASE_CLASS}__details-btn--right`}
              >
                {selectedTab < testDriveCars.length - 1 && (
                  <IconButton
                    aria-label="right button"
                    variant="secondary"
                    onClick={() => setSelectedTab(selectedTab + 1)}
                    id="test-drive__car__slide-right"
                  >
                    <Button.Icon icon="arrow-right" aria-label="arrow-right" />
                  </IconButton>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={`${BASE_CLASS}__loading`}>
            <LoadingIndicator loading={loading} onFullPage>
              <EmptyLayout />
            </LoadingIndicator>
          </div>
        )}
      </div>
    </FocusLayout>
  );
};

export default CarSelection;
