import { createMediaUrl } from '@smart/aem-utils';
import { FALLBACK_IMG_URL } from './car.model';

export class CarMapper {
  /**
   * Check if the product is a wallbox item based on its product code
   *
   * @param productCode string
   * @returns boolean
   */
  static isWallboxItem = (productCode: string) => productCode.startsWith('ABB');

  /**
   * Get image url from the pn18 number of car
   *
   * @param pn18_code string
   * @returns image url string
   */
  static getImageURL(code: string): string {
    const mediaUrl = createMediaUrl('image', '', {
      envName: 'prod',
      preview: false,
    });

    // Return a static fallback image URL for cars if there is no valid corresponding image URL based on pn18
    if (code === '') return FALLBACK_IMG_URL;

    return this.isWallboxItem(code)
      ? `${mediaUrl}${code}`
      : `${mediaUrl}SMART_${code}_BB`;
  }
}
