import { Flex, Text } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import OrderItemFeature from '../OrderItems/OrderItemFeature';
import { CustomerDetailsSection } from './CartItems.config';
import './CartItems.scss';

const BASE_CLASS = 'adb-cart-items';

const CustomerDetail = ({ detail }: CustomerDetailsSection) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" width="full" gap={300}>
      <Text variant="lbl-200">{t('orders.customer_details.title')}</Text>
      <Flex
        alignContent="space-between"
        width="full"
        wrap="wrap"
        gap={300}
        className={`${BASE_CLASS}__features`}
      >
        <OrderItemFeature
          title={t('orders.customer_details.customer_name')}
          text={detail?.customerName}
        />
        <OrderItemFeature
          title={t('orders.customer_details.smart_id')}
          text={detail?.smartId}
        />
        <OrderItemFeature
          title={t('orders.customer_details.phone')}
          text={detail?.phone}
        />
        <OrderItemFeature
          title={t('orders.customer_details.location')}
          text={detail?.location}
        />
        <OrderItemFeature
          title={t('orders.customer_details.id_type')}
          text={detail?.idType}
        />
        <OrderItemFeature
          title={t('orders.customer_details.id_country')}
          text={detail?.country}
        />
        <OrderItemFeature
          title={t('orders.customer_details.id_number')}
          text={detail?.idNumber}
        />
        <OrderItemFeature
          title={t('orders.customer_details.customer_type')}
          text={detail?.customerType}
        />
      </Flex>
    </Flex>
  );
};

export default CustomerDetail;
