import { MarketCode } from '@utils/market/types';

export function convertMarketIdToCountryCode(marketCode: MarketCode) {
  if (marketCode === 'gb') {
    return 'uk';
  }
  return marketCode;
}

export function getCurrentMarket(hostname: string): MarketCode | null {
  if (
    hostname.toLowerCase() === 'adb.smart.com' ||
    hostname.toLowerCase() === 'qa.adb.srv.smart.com' ||
    hostname.toLowerCase() === 'dev.adb.srv.smart.com'
  ) {
    return null;
  }
  const marketRegex = /(?:www\.)?([a-z]{2})\./i;

  const match = hostname.match(marketRegex);

  if (match && match[1] !== undefined && match[1] !== '') {
    const hostMarket = match[1];
    if (hostMarket === 'uk') return 'gb';

    return hostMarket as MarketCode;
  }

  return null;
}
