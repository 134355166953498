import { Text } from '@smart/react-components';
import { useTranslation } from 'react-i18next';

const BASE_CLASS = 'adb-tasks';

const TaskBadge = () => {
  const { t } = useTranslation();
  return (
    <div className={`${BASE_CLASS}__badge`}>
      <Text variant="cap-300" as="p">
        {t('task.new')}
      </Text>
    </div>
  );
};

export default TaskBadge;
