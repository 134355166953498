import { TaskStatus, TaskType } from '@smart/adb-shared';
import Requests from '@smart/components-adb/organisms/Requests/Requests';
import { useAllTasksSimpleQuery } from 'graphql/queries/tasks.generated';
import { useCurrentOutletId } from 'hooks/outlet';
import {
  CardType,
  CaseCard,
  HandoverTaskCard,
  TaskCard,
  mapCases,
  mapTasks,
} from 'pages/tasks/config';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAllActivitiesQuery } from './queries.generated';

const getDateFieldByCardType = (c: CaseCard | HandoverTaskCard | TaskCard) => {
  if (c.cardType === CardType.CASE) {
    return c.lastModifiedDate;
  }
  if (c.cardType === CardType.HANDOVER_TASK) {
    return c.requestedDate ?? new Date();
  }
  if (c.cardType === CardType.TASK) {
    return c.scheduledDate ?? new Date();
  }
  return new Date();
};

const sortRequestsAndTasks = (
  a: HandoverTaskCard | TaskCard | CaseCard,
  b: HandoverTaskCard | TaskCard | CaseCard
) => {
  if (a.status === TaskStatus.Completed || a.status === TaskStatus.Cancelled) {
    return 1;
  }
  if (b.status === TaskStatus.Completed || b.status === TaskStatus.Cancelled) {
    return -1;
  }
  const firstDate = getDateFieldByCardType(a);
  const secondDate = getDateFieldByCardType(b);
  if (!firstDate || !secondDate) {
    return 0;
  }
  return new Date(firstDate).valueOf() - new Date(secondDate).valueOf();
};

const CustomerRequests = () => {
  const { t } = useTranslation();

  const { customerId } = useParams();
  const outlet = useCurrentOutletId();

  const { data: tasks, loading: tasksLoading } = useAllTasksSimpleQuery({
    variables: {
      input: {
        outletId: outlet?.mcsId,
      },
    },
    skip: !outlet?.mcsId,
  });

  const { data: activities, loading: activitiesIsLoading } =
    useAllActivitiesQuery({
      variables: { input: { customerId: customerId ?? '' } },
      skip: !customerId,
    });

  const filteredTasks =
    tasks?.allTasks.tasks.filter(
      (task) =>
        (task.type === TaskType.Handover ||
          task.type === TaskType.Lead ||
          task.type === TaskType.TestDrive) &&
        task.customer.uuid === customerId
    ) ?? [];

  const cases = activities?.allActivities.cases;

  const requests = [...mapTasks(filteredTasks), ...mapCases(cases ?? [])];

  requests.sort(sortRequestsAndTasks);

  const requestsLoading = activitiesIsLoading || tasksLoading;

  return (
    <Requests
      requests={requests}
      title={`${t('customer.overview.tasks')} & ${t('dashboard.requests')}`}
      loading={requestsLoading}
    />
  );
};

export default CustomerRequests;
