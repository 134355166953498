/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAppointmentMutationVariables = Types.Exact<{
  input: Types.CreateAppointmentInput;
}>;


export type CreateAppointmentMutation = { __typename?: 'Mutation', createAppointment: { __typename?: 'UpdateAppointmentResponse', appointmentId: string } };

export type RescheduleAppointmentMutationVariables = Types.Exact<{
  input: Types.RescheduleAppointmentInput;
}>;


export type RescheduleAppointmentMutation = { __typename?: 'Mutation', rescheduleAppointment: { __typename?: 'UpdateAppointmentResponse', appointmentId: string } };


export const CreateAppointmentDocument = gql`
    mutation CreateAppointment($input: CreateAppointmentInput!) {
  createAppointment(input: $input) {
    appointmentId
  }
}
    `;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<CreateAppointmentMutation, CreateAppointmentMutationVariables>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppointmentMutation, CreateAppointmentMutationVariables>(CreateAppointmentDocument, options);
      }
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>;
export const RescheduleAppointmentDocument = gql`
    mutation RescheduleAppointment($input: RescheduleAppointmentInput!) {
  rescheduleAppointment(input: $input) {
    appointmentId
  }
}
    `;
export type RescheduleAppointmentMutationFn = Apollo.MutationFunction<RescheduleAppointmentMutation, RescheduleAppointmentMutationVariables>;

/**
 * __useRescheduleAppointmentMutation__
 *
 * To run a mutation, you first call `useRescheduleAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleAppointmentMutation, { data, loading, error }] = useRescheduleAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleAppointmentMutation, RescheduleAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleAppointmentMutation, RescheduleAppointmentMutationVariables>(RescheduleAppointmentDocument, options);
      }
export type RescheduleAppointmentMutationHookResult = ReturnType<typeof useRescheduleAppointmentMutation>;
export type RescheduleAppointmentMutationResult = Apollo.MutationResult<RescheduleAppointmentMutation>;
export type RescheduleAppointmentMutationOptions = Apollo.BaseMutationOptions<RescheduleAppointmentMutation, RescheduleAppointmentMutationVariables>;