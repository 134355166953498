/**
 * Customers base state
 * --------------------------------
 */

export enum CustomerHeroBanner {
  COMPACT = 'Compact',
  FULL = 'Full',
}

export enum CustomerSearchType {
  LOCAL = 'Local',
  GLOBAL = 'Global',
  DMS = 'Dms',
}
