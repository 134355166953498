import { PropsWithChildren } from 'react';

import { TextProps } from '@smart/react-components';

import { getFormattedFiltersWithDisplayName } from '@ui/library/helpers/filters';
import { FilterProp } from '../../FilterDropdown/FilterDropdown.config';

export interface TableProps extends PropsWithChildren {
  additionalClasses?: string;
}

export interface TableBodyProps extends PropsWithChildren {
  additionalClasses?: string;
}

export interface TableRowProps extends PropsWithChildren {
  gridColumns?: string;
  onClick?: () => void;
  className?: string;
}

export interface TableHeaderCustomProps extends PropsWithChildren {
  onClick?: (event: unknown) => void;
  type?: string;
  enableSorting?: boolean;
  textVariant?: TextProps['variant'];
}

export interface TableDataProps extends PropsWithChildren {
  type?: 'link' | 'radio' | 'icon' | 'selfcontained' | undefined;
  classes?: string;
}

/**
 * ------------------------------------------------------------
 * Table page sizes
 * ------------------------------------------------------------
 */

/**
 * @const TABLE_PAGE_SIZES
 * Const for table page sizes
 */
export const TABLE_PAGE_SIZES = ['20', '40', '60', '80', '100', '300'];

/**
 * @const getTablePageSizeOptions
 * Get formatted table page options with displayname and query value
 * @returns FilterProp[]
 */
export const getTablePageSizeOptions = (): FilterProp[] =>
  getFormattedFiltersWithDisplayName(TABLE_PAGE_SIZES);
