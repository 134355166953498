import SearchFilterPanel from '@smart/components-adb/atoms/SearchFilterPanel/SearchFilterPanel';
import AdbSearchInput from '@smart/components-adb/molecules/AdbSearchInput/AdbSearchInput';
import FilterDropdown from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown';
import FilterDropdownMultiple from '@smart/components-adb/molecules/FilterDropdownMultiple/FilterDropdownMultiple';
import { Button, Tag } from '@smart/react-components';
import {
  FacetSortByValue,
  FacetValue,
  STOCK_SORT_BY_VALUES,
} from '@ui/data-models/facet/facet.model';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StockSearchProps } from './Search.config';
import './Search.scss';

const BASE_CLASS = 'adb-search';

const StockSearch = ({
  onQueryUpdate,
  defaultSortBy,
  facets,
}: StockSearchProps) => {
  const { t } = useTranslation();

  const [showFacets, setShowFacets] = useState<boolean>(false);
  const [currentFilters, setCurrentFilters] = useState<FacetValue[]>([]);
  const [currentSortBy, setCurrentSortBy] = useState<
    FacetSortByValue | undefined
  >();
  const [currentFilterQuery, setCurrentFilterQuery] = useState<string>('');
  const [textSearch, setTextSearch] = useState<string>('');

  const updateFilters = (
    filters: FacetValue[],
    defaultQuery: string,
    add: boolean
  ): void => {
    setCurrentFilters(filters);

    const filterQuery = add
      ? currentFilterQuery.concat(defaultQuery, '')
      : currentFilterQuery.replace(defaultQuery, '');

    setCurrentFilterQuery(filterQuery);
    onQueryUpdate(
      textSearch,
      currentSortBy?.queryValue ?? defaultSortBy,
      filterQuery
    );
  };

  // Function to remove a filter from the filter query
  const removeFilter = (filterItem: FacetValue): void => {
    currentFilters.splice(
      currentFilters.findIndex((filter) => filter.name === filterItem.name),
      1
    );
    updateFilters(currentFilters, filterItem.initialQueryValue, false);
  };

  // Function to update sort by query
  const updateSortBy = (sortByValue: FacetSortByValue | undefined) => {
    setCurrentSortBy(sortByValue);
    onQueryUpdate(
      textSearch,
      sortByValue?.queryValue ?? defaultSortBy,
      currentFilterQuery
    );
  };

  // Function to update text search query
  const updateTextSearch = (searchQuery: string): void => {
    setTextSearch(searchQuery);
    onQueryUpdate(
      searchQuery,
      currentSortBy?.queryValue ?? defaultSortBy,
      currentFilterQuery
    );
  };

  // Function format the filter name with translation
  const getFormattedFiltersWithTranslation = (
    filters: FacetSortByValue[]
  ): FacetSortByValue[] =>
    filters.map((item) => ({
      displayName: t(`stock.sort.${item.displayName}`),
      queryValue: item.queryValue,
    }));

  return (
    <>
      <SearchFilterPanel
        searchBar={
          <AdbSearchInput
            onSearch={(searchQuery) =>
              updateTextSearch(searchQuery ? `"${searchQuery}"` : '')
            }
            label={t('stock.search')}
            value={textSearch}
          />
        }
        filters={
          facets.length ? (
            <Button
              variant="ghost"
              type="button"
              onClick={() => setShowFacets(!showFacets)}
            >
              {showFacets
                ? t('stock.buttons.hide_filter')
                : t('stock.buttons.show_filter')}
              <Button.Icon icon="filter" aria-label="filter" />
            </Button>
          ) : null
        }
        darkMode
      />
      {showFacets && facets && (
        <div className={BASE_CLASS}>
          <div className={`${BASE_CLASS}__filters`}>
            {facets.map((facet) => (
              <div className={`${BASE_CLASS}__filters-item`} key={facet.name}>
                <FilterDropdownMultiple
                  filterName={t(`stock.facets.${facet.code}`)}
                  filterValues={facet.values as FacetValue[]}
                  onSelect={(filters, defaultQuery, add) =>
                    updateFilters(filters, defaultQuery, add)
                  }
                  currentFilters={currentFilters}
                  key={facet.code}
                />
              </div>
            ))}
            <FilterDropdown
              label={t('stock.sort.sort_by')}
              items={getFormattedFiltersWithTranslation(STOCK_SORT_BY_VALUES)}
              onSelect={(filter) => updateSortBy(filter)}
              selectedFilter={currentSortBy}
            />
          </div>

          {(currentFilters.length > 0 || currentSortBy) && (
            <div className={`${BASE_CLASS}__tags`}>
              {currentFilters.length > 0 &&
                currentFilters.map((currentFilter) => (
                  <div
                    className={`${BASE_CLASS}__tags-item`}
                    key={currentFilter.initialQueryValue}
                  >
                    <Tag
                      open
                      onOpenChange={() => removeFilter(currentFilter)}
                      key={currentFilter.name}
                    >
                      {`${t(`stock.facets.${currentFilter.code}`)}: ${
                        currentFilter.displayName
                      }`}
                      <Tag.Close
                        aria-label={`remove filter ${currentFilter.displayName}`}
                      />
                    </Tag>
                  </div>
                ))}

              {currentSortBy && (
                <div className={`${BASE_CLASS}__tags-item`}>
                  <Tag
                    open
                    onOpenChange={() => updateSortBy(undefined)}
                    key={currentSortBy.displayName}
                  >
                    {`${t('stock.sort.sort_by')}: ${currentSortBy.displayName}`}
                    <Tag.Close
                      aria-label={`remove sort ${currentSortBy.displayName}`}
                    />
                  </Tag>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StockSearch;
