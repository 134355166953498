import { IconProps } from '@smart/react-components';
import { ReactNode } from 'react';
import { IconBackground, IconColor } from '../../atoms/AdbIcon/AdbIcon.config';

/**
 * @type DashboardItemProps
 * Prop types for <DashboardItem>
 */

export type DashboardItemProps = {
  id: string;
  title: string;
  description?: string;
  icon: IconProps['icon'];
  iconColor?: IconColor;
  iconHasBorder?: boolean;
  backgroundColor?: IconBackground;
  additionalContent?: ReactNode;
  itemType?: DashboardItemType;
  isItemClickable?: boolean;
  topRightIcon?: IconProps['icon'];
};

/**
 * @type DashboardItemType
 * Type for DashboardItem
 * @enum DashboardItemType
 */
export enum DashboardItemType {
  TASK = 'Task',
  REQUEST = 'Request',
  CASE = 'Case',
}
