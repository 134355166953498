import { usePreferredLanguagesQuery } from '@features/PreferredLanguageSelect/queries.generated';
import {
  CustomerAccountType,
  CustomerRecordType,
  CustomerType,
} from '@smart/adb-shared';
import { CountryIsoCode } from '@utils/market/types';
import { useMarketContext } from 'contexts/market-context';
import { Formik } from 'formik';
import { useState } from 'react';
import {
  BusinessCustomerFormValues,
  CustomerFormTypes,
  PrivateCustomerFormValues,
  SharedInfoProps,
  formatPhoneNumber,
  handleCustomerDetails,
} from '../helper';
import { usePreferredOptionsQuery } from '../queries.generated';
import { BasicInfoProps } from './BasicInfo.config';
import BasicInfoSchema from './BasicInfoSchema';
import Form from './Form';
import { useRegionsQuery } from './queries.generated';

const BasicInfo = ({
  customerDetails,
  handleCustomerDetailSubmit,
  bindSubmitForm,
  checkDataValidity,
  formFooter,
  radioButtonTab,
  isNotAccordion,
  title,
  consent,
  onConsentChange,
  disableAllFields = false,
  convertToFullAccount,
}: BasicInfoProps) => {
  const { getCustomerDetail } = handleCustomerDetails(customerDetails);
  const { market } = useMarketContext();

  const { data: preferredOptions } = usePreferredOptionsQuery();
  const { data: regions } = useRegionsQuery();
  const { data: languages } = usePreferredLanguagesQuery();

  const defaultTitleCode =
    preferredOptions?.preferredOptions.titleCode?.[0]?.value ?? '';
  const defaultRegionCode = regions?.regions[0].value ?? '';
  const defaultCountryCode = CountryIsoCode[market] ?? '';
  const defaultPreferredLanguage = languages?.preferredLanguages[0].value;

  const sharedInfo: SharedInfoProps = {
    firstName: getCustomerDetail('firstName') ?? '',
    lastName: getCustomerDetail('lastName'),
    userId: getCustomerDetail('userId'),
    street: getCustomerDetail('street'),
    town: getCustomerDetail('town'),
    countryCode: getCustomerDetail('countryCode', defaultCountryCode),
    postalCode: getCustomerDetail('postalCode'),
    regionCode: getCustomerDetail('regionCode', defaultRegionCode),
    mobileNumber: formatPhoneNumber(getCustomerDetail('mobileNumber'), market),
    titleCode: getCustomerDetail('titleCode', defaultTitleCode),
    uuid: getCustomerDetail('uuid'),
    market: getCustomerDetail('market', market),
    recordType: getCustomerDetail('recordType') as CustomerRecordType,
    type: getCustomerDetail('type') as CustomerType,
    preferredLanguage: getCustomerDetail(
      'preferredLanguage',
      defaultPreferredLanguage
    ),
  };

  const initialPrivateValues: PrivateCustomerFormValues = {
    ...sharedInfo,
    accountType: CustomerAccountType.Private,
  };

  const initialBusinessValues: BusinessCustomerFormValues = {
    ...sharedInfo,
    sfOrgId: getCustomerDetail('sfOrgId'),
    bpid: getCustomerDetail('bpid'),
    companyName: getCustomerDetail('companyName'),
    companyRegistrationNumber: getCustomerDetail('companyRegistrationNumber'),
    accountType: CustomerAccountType.Business,
    vatRegistrationNumber: getCustomerDetail('vatRegistrationNumber'),
  };

  const initialValues =
    customerDetails.accountType === CustomerAccountType.Private
      ? initialPrivateValues
      : initialBusinessValues;
  const [accountType, setAccountType] = useState(
    initialValues.accountType ?? CustomerAccountType.Private
  );

  const validationSchema = BasicInfoSchema(market, accountType);

  const onSubmit = (
    formValues: BusinessCustomerFormValues | PrivateCustomerFormValues
  ) => {
    handleCustomerDetailSubmit(
      formValues,
      formValues.accountType === CustomerAccountType.Private
        ? CustomerFormTypes.BASIC_INFO_PRIVATE
        : CustomerFormTypes.BASIC_INFO_BUSINESS
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form
        bindSubmitForm={bindSubmitForm}
        checkDataValidity={checkDataValidity}
        formFooter={formFooter}
        radioButtonTab={radioButtonTab}
        convertToFullAccount={convertToFullAccount}
        isNotAccordion={isNotAccordion}
        title={title}
        consent={consent}
        onConsentChange={onConsentChange}
        disableAllFields={disableAllFields}
        setAccountType={setAccountType}
      />
    </Formik>
  );
};

export default BasicInfo;
