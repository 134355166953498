import { StockCar } from '@smart/adb-shared';
import CarListItem from '@smart/components-adb/molecules/CarListItem/CarListItem';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button, Link, Text } from '@smart/react-components';
import { SMART_COM_LINKS } from '@utils/configs/config';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useTranslation } from 'react-i18next';
import { StockLinkOutModal } from '../StockLinkOutModal/StockLinkOutModal';
import '../stock.scss';
import { StockItemsProps } from './StockItems.config';

const BASE_CLASS = 'adb-stock';

const StockItems = ({ stockItems }: StockItemsProps) => {
  const { t } = useTranslation();

  const { registerModal } = useModal();

  const market = useMarketContext();
  const selectedAppMarket = market.market;

  const { agent } = useAgentContext();

  const { locale } = useLanguageContext();

  const shouldDisableLinkOut = (redirectPath?: string): boolean =>
    !locale || !selectedAppMarket || !redirectPath || !agent?.outletId;

  const getCarName = (car: StockCar): string => {
    if (car.name && car.status?.stockLevelStatus) {
      return `${car.name} / ${t(`stock.${car.status.stockLevelStatus}`)}`;
    }

    return car.name ?? '-';
  };
  const stockCars = stockItems.map((car) => ({
    id: car.id,
    name: getCarName(car),
    imageSrc: car.image,
    openAccordion: true,
    carDetails: [
      {
        key: t('car.assets.earliest_lead_time'),
        value: car.status?.earliestBeautifiedLeadTime ?? '',
      },
      {
        key: `${t('car.features.exterior')} / ${t('car.features.roof')}`,
        value: `${car.features?.exterior ?? '-'} / ${
          car.features?.roof ?? '-'
        }`,
      },
      {
        key: t('car.features.interior'),
        value: car.features?.interior ?? '-',
      },
      {
        key: t('car.features.year_edition'),
        value: car.features?.yearEdition ?? '-',
      },
      {
        key: t('car.features.range'),
        value: car.features?.range ?? '-',
      },
      {
        key: t('car.features.battery'),
        value: car.features?.battery ?? '-',
      },
      {
        key: t('car.assets.line'),
        value: car.features?.driveline ?? '-',
      },
    ],
    actionItemsWithText: {
      text: (
        <div className={`${BASE_CLASS}__price-tag`}>
          {car.price && (
            <div className={`${BASE_CLASS}__text`}>
              <Text variant="cap-300" className={`${BASE_CLASS}__text-from`}>
                {t('stock.from')}
              </Text>
              <Text variant="lbl-100">{car.price?.formattedValue} </Text>
            </div>
          )}

          <div className={`${BASE_CLASS}__divider`} />
          <div className={`${BASE_CLASS}__text`}>
            <Text variant="cap-300" className={`${BASE_CLASS}__text-from`}>
              {t('stock.from')}
            </Text>
            <Text variant="lbl-100">
              {car.leasingPrice?.formattedValue ?? '- '}
            </Text>
            <Text variant="cap-300" className={`${BASE_CLASS}__text-from`}>
              {t('stock.mo')}
            </Text>
          </div>
        </div>
      ),
      children: (
        <>
          <Link asChild>
            <Button
              type="button"
              disabled={shouldDisableLinkOut(SMART_COM_LINKS.stocks)}
              onClick={() => {
                registerModal(
                  <StockLinkOutModal
                    redirectPath={`${SMART_COM_LINKS.stocks}?pn18=${car.id}`}
                    isExplore
                  />
                );
              }}
            >
              {t('stock.buttons.explore')}
            </Button>
          </Link>
          <Button
            variant="primary"
            mode={200}
            disabled={shouldDisableLinkOut(SMART_COM_LINKS.productDetails)}
            onClick={() => {
              registerModal(
                <StockLinkOutModal
                  redirectPath={`${SMART_COM_LINKS.productDetails}?pn18=${car.id}`}
                />
              );
            }}
          >
            {t('stock.buttons.order')}
          </Button>
        </>
      ),
    },
  }));

  if (!stockCars || stockCars.length <= 0) return null;

  return (
    <>
      {stockCars.map((item) => (
        <CarListItem
          key={item.id}
          {...item}
          additionalClasses={`${BASE_CLASS}__item`}
          imageShare="30"
          isOpen
          hasAccordion={false}
        />
      ))}
    </>
  );
};

export default StockItems;
