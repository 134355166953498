import { Maybe, OrderStatus, OrderSummary } from '@smart/adb-shared';
import { OrderSortByQueryValue } from '@ui/data-models/facet/facet.model';

/**
 * Helper for sorting order by status
 */
const orderStatusesOrder: Partial<Record<OrderStatus, number>> = {
  [OrderStatus.Cancelling]: 1,
  [OrderStatus.Cancelled]: 2,
};

/**
 * Sort orders based on order status
 * @param a OrderItem
 * @param b OrderItem
 * @returns number
 */
export const sortByOrderStatus = (
  a: OrderStatus | undefined | null,
  b: OrderStatus | undefined | null
) => {
  if (!a || !b) return 0;

  const statusA = orderStatusesOrder[a];
  const statusB = orderStatusesOrder[b];

  if (!statusA && !statusB) {
    return 0;
  }

  if (!statusA) {
    return 1;
  }

  if (!statusB) {
    return -1;
  }

  return statusA - statusB;
};

/**
 * Function that converts a `date` or `undefined` to a `number`.

 * If passing an undefinded value it returns NaN (a number)
 * @param date string | undefined
 * @returns number
 */
const dateify = (date: string | Maybe<string> | undefined): number =>
  !date ? NaN : new Date(date).getTime();

/**
 * Helper function to return orders that matches hasDeliveryDate based on sortQuery
 * @param sortQuery string
 * @param order Order
 * @param hasDeliveryDate boolean
 * @returns Order
 */
export const filterOrdersByDeliveryDateData = (
  sortQuery: string,
  order: OrderSummary,
  hasDeliveryDate: boolean
) => {
  switch (sortQuery) {
    case OrderSortByQueryValue.DELIVERY_DATE_DESC:
    case OrderSortByQueryValue.DELIVERY_DATE_ASC:
      return hasDeliveryDate ? order.deliveryDate : !order.deliveryDate;

    case OrderSortByQueryValue.PREFERRED_DELIVERY_DATE_DESC:
    case OrderSortByQueryValue.PREFERRED_DELIVERY_DATE_ASC:
      return hasDeliveryDate
        ? order.requestedDeliveryDate
        : !order.requestedDeliveryDate;

    default:
      return null;
  }
};

/**
 * Helper function to sort orders based on sortQuery
 * @param sortQuery string
 * @param firstItem Order
 * @param secondItem Order
 * @returns number
 */
export const sortOrdersByDate =
  (sortQuery: string) =>
  (firstItem: OrderSummary, secondItem: OrderSummary) => {
    switch (sortQuery) {
      case OrderSortByQueryValue.DELIVERY_DATE_DESC:
        return (
          dateify(secondItem.deliveryDate) - dateify(firstItem.deliveryDate)
        );

      case OrderSortByQueryValue.DELIVERY_DATE_ASC:
        return (
          dateify(firstItem.deliveryDate) - dateify(secondItem.deliveryDate)
        );

      case OrderSortByQueryValue.PREFERRED_DELIVERY_DATE_DESC:
        return (
          dateify(secondItem.requestedDeliveryDate) -
          dateify(firstItem.requestedDeliveryDate)
        );

      case OrderSortByQueryValue.PREFERRED_DELIVERY_DATE_ASC:
        return (
          dateify(firstItem.requestedDeliveryDate) -
          dateify(secondItem.requestedDeliveryDate)
        );

      default:
        return 0;
    }
  };
