import { TestDriveSlotTimeOfDay } from '@smart/adb-shared';
import Badge from '@smart/components-adb/atoms/Badge/Badge';
import { BadgeThemes } from '@smart/components-adb/atoms/Badge/Badge.config';
import AdbDynamicImage from '@smart/components-adb/molecules/AdbDynamicImage/AdbDynamicImage';
import { imageConfig1To1 } from '@smart/components-adb/molecules/AdbDynamicImage/AdbDynamicImage.config';
import { RadioGroup, Text } from '@smart/react-components';
import { TestDriveBookingSlot } from '@ui/data-models/test-drive/test-drive.model';
import classNames from 'classnames';
import { CarSelectItem } from 'pages/test-drive/config';
import { useTranslation } from 'react-i18next';
import TimeSlotSelectionPanel from '../../time-slots-panel/TimeSlotsPanel';
import './CarDetailsListItem.scss';

const BASE_CLASS = 'adb-time-car-list-item';

export interface CarDetailsListItemProps {
  testDriveCar: CarSelectItem;
  selectedDate?: string;
  selectedSlot?: TestDriveBookingSlot;
  additionalClasses?: string;
  active?: boolean;
  selectedCarID?: string;
  onClick?: () => void;
  handleSlotSelection: (slot: TestDriveBookingSlot) => void;
}

/**
 * CarDetailsListItem component
 * @param props CarDetailsListItemProps
 * @returns <CarDetailsListItem> ReactNode
 */
const CarDetailsListItem = (props: CarDetailsListItemProps) => {
  const {
    testDriveCar,
    additionalClasses = '',
    active = false,
    onClick,
    handleSlotSelection,
    selectedDate = '',
    selectedSlot,
    selectedCarID = '',
  } = props;
  const { t } = useTranslation();

  // Setting class names for the component root container
  const classes = classNames(BASE_CLASS, additionalClasses, {
    [`${BASE_CLASS}--active`]: active,
  });

  /**
   * Test drive slots filtered for the selected date
   */
  const slots = testDriveCar.timeSlots.find((slot) => {
    const slotDate = new Date(slot.date);
    const filterDate = selectedDate && new Date(selectedDate);

    return (
      filterDate &&
      slotDate.getDate() === filterDate.getDate() &&
      slotDate.getMonth() === filterDate.getMonth()
    );
  });

  /**
   * Badges for when test drive cars are available in the times of the day
   */
  const availabilityBadges = !!slots?.timeOfDay?.length && (
    <div className={`${BASE_CLASS}__availability-badges`}>
      {slots?.timeOfDay?.includes(TestDriveSlotTimeOfDay.Morning) && (
        <div className={`${BASE_CLASS}__availability-badges-item`}>
          <Badge theme={BadgeThemes.SUCCESS} />
          <Text variant="cap-300" as="p">
            {t('test_drive.time.availabilities.morning')}
          </Text>
        </div>
      )}
      {slots?.timeOfDay?.includes(TestDriveSlotTimeOfDay.Midday) && (
        <div className={`${BASE_CLASS}__availability-badges-item`}>
          <Badge theme={BadgeThemes.SUCCESS} />
          <Text variant="cap-300" as="p">
            {t('test_drive.time.availabilities.midday')}
          </Text>
        </div>
      )}
      {slots?.timeOfDay?.includes(TestDriveSlotTimeOfDay.Evening) && (
        <div className={`${BASE_CLASS}__availability-badges-item`}>
          <Badge theme={BadgeThemes.SUCCESS} />
          <Text variant="cap-300" as="p">
            {t('test_drive.time.availabilities.evening')}
          </Text>
        </div>
      )}
    </div>
  );

  /**
   * Test drive car features
   */
  const details = (
    <div className={`${BASE_CLASS}__details`}>
      {testDriveCar?.features?.exterior && (
        <div className={`${BASE_CLASS}__detail`}>
          <Text
            variant="cap-300"
            as="p"
            className={`${BASE_CLASS}__detail-heading`}
          >
            {t('car.features.exterior')}
          </Text>
          <Text variant="cap-300" as="p">
            {testDriveCar?.features.exterior}
          </Text>
        </div>
      )}
      {testDriveCar?.features?.interior && (
        <div className={`${BASE_CLASS}__detail`}>
          <Text
            variant="cap-300"
            as="p"
            className={`${BASE_CLASS}__detail-heading`}
          >
            {t('car.features.interior')}
          </Text>
          <Text variant="cap-300" as="p">
            {testDriveCar?.features.interior}
          </Text>
        </div>
      )}
      {testDriveCar?.licensePlate && (
        <div className={`${BASE_CLASS}__detail`}>
          <Text
            variant="cap-300"
            as="p"
            className={`${BASE_CLASS}__detail-heading`}
          >
            {t('car.assets.license_plate')}
          </Text>
          <Text variant="cap-300" as="p">
            {testDriveCar?.licensePlate}
          </Text>
        </div>
      )}
      {testDriveCar?.features?.range && (
        <div className={`${BASE_CLASS}__detail`}>
          <Text
            variant="cap-300"
            as="p"
            className={`${BASE_CLASS}__detail-heading`}
          >
            {t('car.features.range')}
          </Text>
          <Text variant="cap-300" as="p">
            {testDriveCar?.features.range}
          </Text>
        </div>
      )}
      {testDriveCar?.features?.battery && (
        <div className={`${BASE_CLASS}__detail`}>
          <Text
            variant="cap-300"
            as="p"
            className={`${BASE_CLASS}__detail-heading`}
          >
            {t('car.features.battery')}
          </Text>
          <Text variant="cap-300" as="p">
            {testDriveCar?.features.battery}
          </Text>
        </div>
      )}
      {testDriveCar?.features?.distance && (
        <div className={`${BASE_CLASS}__detail`}>
          <Text
            variant="cap-300"
            as="p"
            className={`${BASE_CLASS}__detail-heading`}
          >
            {t('car.features.kilometer')}
          </Text>
          <Text variant="cap-300" as="p">
            {testDriveCar?.features.distance}
          </Text>
        </div>
      )}
      {testDriveCar?.features?.mark && (
        <div className={`${BASE_CLASS}__detail`}>
          <Text
            variant="cap-300"
            as="p"
            className={`${BASE_CLASS}__detail-heading`}
          >
            {t('car.features.mark')}
          </Text>
          <Text variant="cap-300" as="p">
            {testDriveCar?.features.mark}
          </Text>
        </div>
      )}
    </div>
  );

  /**
   * Time slots for the test drive car
   */
  const timeSlots = slots?.timeSlots && (
    <TimeSlotSelectionPanel
      timeSlots={slots.timeSlots}
      selectedTimeSlot={selectedSlot}
      onTimeSlotSelected={handleSlotSelection}
    />
  );

  /**
   * Component content
   */
  return (
    <div className={classes} role="button" tabIndex={0}>
      <div className={`${BASE_CLASS}__content`}>
        <RadioGroup
          name="radio-car-select"
          className={`${BASE_CLASS}__content__radio-group`}
          onClick={onClick}
        >
          <RadioGroup.Radio
            value={testDriveCar?.testDriveCarId}
            checked={testDriveCar?.testDriveCarId === selectedCarID}
          />
        </RadioGroup>
        {testDriveCar?.image && (
          <AdbDynamicImage
            imageUrl={testDriveCar.image}
            imageAltText={testDriveCar.name ?? '-'}
            imageConfig={imageConfig1To1}
            className={`${BASE_CLASS}__picture`}
          />
        )}
        <div className={`${BASE_CLASS}__car-details`}>
          <div className={`${BASE_CLASS}__main-details`}>
            <Text variant="lbl-100" as="p">
              {testDriveCar?.longName ?? testDriveCar?.name}
            </Text>
            {availabilityBadges}
          </div>

          {details}
          {active && timeSlots}
        </div>
      </div>
    </div>
  );
};

export default CarDetailsListItem;
