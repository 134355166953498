import { AppointmentType } from '@smart/adb-shared';
import DashboardPlaceholder from '@smart/components-adb/organisms/DashboardPlaceholder/DashboardPlaceholder';
import {
  AppointmentCard,
  CardType,
  HandoverTaskCard,
  TaskCard,
} from 'pages/tasks/config';
import { useTranslation } from 'react-i18next';
import Handover from './Appointment/Handover';
import TestDrive from './Appointment/TestDrive';
import Task from './Task/Task';
import { TasksProps } from './YourTasks.config';

const YourTasks = ({ tasks, title, link = undefined, loading }: TasksProps) => {
  const { t } = useTranslation();

  const renderAppointment = (appointment: AppointmentCard) => {
    switch (appointment.type) {
      case AppointmentType.Handover:
        return <Handover task={appointment} key={appointment.id} />;
      case AppointmentType.TestDrive:
        return <TestDrive task={appointment} key={appointment.id} />;
      default:
        return null;
    }
  };

  const renderCard = (task: TaskCard | HandoverTaskCard | AppointmentCard) => {
    switch (task.cardType) {
      case CardType.TASK: // Fall-through
      case CardType.HANDOVER_TASK: {
        return <Task task={task} key={task.id} />;
      }
      case CardType.APPOINTMENT:
        return renderAppointment(task);

      default:
        return null;
    }
  };

  return (
    <DashboardPlaceholder
      title={title}
      link={link}
      noItemsTitle={t('dashboard.tasks')}
      loading={loading}
      items={tasks.length > 0 && tasks.map((task) => renderCard(task))}
    />
  );
};

export default YourTasks;
