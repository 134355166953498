import { Text } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import '../../CreateDocumentType/CreateDocumentType.scss';

const BASE_CLASS = 'adb-create-document';

type ChecklistHeaderType = 'customer' | 'agent';

const ChecklistHeader = ({ id }: { id: ChecklistHeaderType }): JSX.Element => {
  const { t } = useTranslation();

  const labels = {
    customer: t('form_fields.document_handover_contract.checklist_for_client'),
    agent: t('form_fields.document_handover_contract.checklist_for_agent'),
  };

  return (
    <div className={`${BASE_CLASS}__checkbox_title`}>
      <Text variant="p-200" color="c-high-contrast" as="p">
        {labels[id]}
      </Text>
    </div>
  );
};

export default ChecklistHeader;
