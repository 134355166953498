import { Locale, format, formatRelative, isValid } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';

const locales = [
  'de-DE',
  'en-GB',
  'en-US',
  'sv-SE',
  'de-AT',
  'fr-BE',
  'nl-BE',
  'fr-FR',
  'it-IT',
  'nl-NL',
  'pt-PT',
  'es-ES',
  'de-CH',
  'fr-CH',
  'it-CH',
] as const;

export type AdbLocale = (typeof locales)[number];

const countryCodes = [
  'DE',
  'GB',
  'US',
  'SE',
  'AT',
  'BE',
  'FR',
  'IT',
  'NL',
  'PT',
  'ES',
  'CH',
] as const;

type CountryCode = (typeof countryCodes)[number];

type LocaleType = {
  [key in AdbLocale]: Locale;
};

const { de, deAT, enGB, enUS, es, fr, frCH, it, nl, nlBE, pt, sv } =
  dateFnsLocales;

// locale dictionary to collect list of local types
export const localeDictionary: LocaleType = {
  'en-US': enUS,
  'en-GB': enGB,
  'de-DE': de,
  'de-AT': deAT,
  'fr-BE': fr,
  'fr-FR': fr,
  'fr-CH': frCH,
  'it-IT': it,
  'nl-NL': nl,
  'nl-BE': nlBE,
  'pt-PT': pt,
  'es-ES': es,
  'de-CH': de,
  'it-CH': it,
  'sv-SE': sv,
};

// type of date format - referred from date-fns
// format patterns from - https://date-fns.org/v2.26.0/docs/format  (v2.26.0)

const dateTimeFormat = 'Pp';
const dateFormat = 'P';

export enum DateFormats {
  DATE = 'date',
  DATETIME = 'dateTime',
}

export const isValidDate = (value: string | null | undefined) =>
  value !== undefined && value !== null && isValid(new Date(value));

export const getLocaleDate = (
  dateToBeFormatted: string | number,
  locale: AdbLocale,
  type = DateFormats.DATETIME
): string => {
  const formatType = type === DateFormats.DATE ? dateFormat : dateTimeFormat;

  // format date based on the market value and return formatted values based on the format type
  const formattedDate = format(
    typeof dateToBeFormatted === 'number'
      ? dateToBeFormatted
      : new Date(dateToBeFormatted),
    formatType,
    {
      locale: localeDictionary[locale],
    }
  );

  return formattedDate;
};

export const getCustomFormattedDate = (
  dateTimeToBeFormatted: Date,
  formateStyle: string,
  locale: AdbLocale
): string =>
  format(dateTimeToBeFormatted, formateStyle, {
    locale: localeDictionary[locale],
  });

export const getRelativeFormattedDate = (
  dateTimeToBeFormatted: Date,
  locale: AdbLocale
): string =>
  formatRelative(dateTimeToBeFormatted, new Date(), {
    locale: localeDictionary[locale],
  });

export const getLocaleDateFromDate = (
  dateToBeFormatted: Date | undefined,
  locale: AdbLocale,
  type = DateFormats.DATETIME
): string | undefined =>
  dateToBeFormatted
    ? getLocaleDate(dateToBeFormatted.toISOString(), locale, type)
    : undefined;

export const getLocaleDateFromString = (
  dateToBeFormatted: string,
  locale: AdbLocale,
  type = DateFormats.DATETIME
): string | undefined =>
  dateToBeFormatted
    ? getLocaleDate(dateToBeFormatted, locale, type)
    : undefined;

export const getTimeStamp = (
  currentDateTime: Date,
  locale: AdbLocale
): string =>
  `${getCustomFormattedDate(
    currentDateTime,
    'dd.MM.yyy',
    locale
  )}-${getCustomFormattedDate(currentDateTime, 'HH:mm', locale)}`;

export const setHour = (date: Date, hour: number): string =>
  new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hour + 1,
    0,
    0
  ).toISOString();

export function getCountryCode(locale: AdbLocale): CountryCode {
  return locale.split('-')[2] as CountryCode;
}
