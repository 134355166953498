import { OrderPaymentStatus } from '@smart/adb-shared';
import { FacetSortByValue } from '@ui/library/helpers/filters';

export const translateLabel = (
  filterValues: FacetSortByValue[],
  valueToTranslate: OrderPaymentStatus | string | undefined | null,
  t: (key: string) => string
): string => {
  if (!valueToTranslate) return '';

  const foundString = filterValues.find(
    (item) => item.queryValue === valueToTranslate
  )?.displayName;

  if (!foundString) return valueToTranslate;

  return t(`orders.filter.${foundString}`);
};
