import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button } from '@smart/react-components';
import { CustomerDetailsState } from 'pages/customer/details/helper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TaskActionProps } from '../types';
import { getCustomerPath } from '../utils';

export const ConvertLeadButton = ({
  task,
  disabled,
}: Omit<TaskActionProps, 'onComplete'>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { closeModal } = useModal();

  const { customer } = task.original;

  const isLeadWithoutOpportunity = customer.sfCustomerId?.startsWith('00Q');
  const taskCustomerIsFullAccount = task.original.customer.regType === 'Full';
  const isDisabled =
    !customer ||
    taskCustomerIsFullAccount ||
    isLeadWithoutOpportunity ||
    disabled;

  return (
    <Button
      disabled={isDisabled}
      onClick={() => {
        closeModal();
        navigate(getCustomerPath(customer, 'details'), {
          state: {
            convertToFullAccount: true,
          },
        } as CustomerDetailsState);
      }}
      variant="primary"
    >
      {t('task.lead.convert_account_cta')}
    </Button>
  );
};
