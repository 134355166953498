import { ADB_COOKIES } from '@utils/cookie/adb-cookies';
import { setCookie } from '@utils/cookie/cookie-configurator';
import { getCurrentMarket } from '@utils/helpers/market';
import { MarketCode } from '@utils/market/types';
import React, { PropsWithChildren, useMemo, useState } from 'react';

interface MarketContextState {
  market: MarketCode | null;
  setMarket: (market: MarketCode) => void;
}

const UrlMarketContext = React.createContext<MarketContextState | undefined>(
  undefined
);

export const UrlMarketContextProvider = ({ children }: PropsWithChildren) => {
  const [marketState, setMarketState] = useState(
    getCurrentMarket(window.location.hostname)
  );

  const value = useMemo(
    () => ({
      market: marketState,
      setMarket: (market: MarketCode) => {
        setMarketState(market);
        setCookie(ADB_COOKIES.MARKET, market);
      },
    }),
    [marketState, setMarketState]
  );

  return (
    <UrlMarketContext.Provider value={value}>
      {children}
    </UrlMarketContext.Provider>
  );
};

export const useUrlMarketContext = () => {
  const value = React.useContext(UrlMarketContext);

  if (!value) throw new Error('Got undefined market context!');

  return value;
};
