import {
  AdbNotificationProps,
  SignalVariant,
} from '@smart/components-adb/molecules/AdbNotification/AdbNotification.config';
import { Notification } from '@smart/react-components';
import { IconCategories } from '@smart/react-components/dist/components/icon/icon';
import classnames from 'classnames';
import { useEffect } from 'react';
import './AdbNotification.scss';

const BASE_CLASS = 'adb-notification';

const getIcon = (variant: SignalVariant): IconCategories => {
  switch (variant) {
    case 'error':
      return 'error';
    case 'informational':
      return 'info-legal';
    case 'warning':
      return 'warning';
    case 'success':
      return 'successful-checkmark';
    default:
      throw new Error(`Unknown SignalVariant: ${variant}`);
  }
};

/**
 * AdbLink component
 *
 * @param AdbNotificationProps AdbNotificationProps
 * @returns <AdbNotification> ReactNode
 */
const AdbNotification = ({
  variant,
  text,
  label,
  onClose,
  additionalClasses,
  timeoutTime = 10000,
  persist = false,
  isVisible,
}: AdbNotificationProps) => {
  const clickOutside = (event: React.MouseEvent) => {
    event.stopPropagation();
    const target = event.target as HTMLDivElement;
    const isOutside =
      target.classList.contains(BASE_CLASS) ||
      target.classList.contains(`${BASE_CLASS}__notification-wrapper`);
    if (isOutside) {
      onClose();
    }
  };

  useEffect(() => {
    if (isVisible && !persist) {
      setTimeout(() => {
        onClose();
      }, timeoutTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutTime, isVisible]);

  return (
    <div
      className={classnames(BASE_CLASS, additionalClasses)}
      onClick={clickOutside}
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
    >
      {isVisible && (
        <div className={`${BASE_CLASS}__notification-wrapper`}>
          <Notification
            icon={getIcon(variant)}
            closeIcon="close-without-circle"
            onOpenChange={onClose}
            className={classnames(`${BASE_CLASS}__notification`, variant)}
          >
            <Notification.Headline>{label}</Notification.Headline>
            <Notification.Text>{text}</Notification.Text>
          </Notification>
        </div>
      )}
    </div>
  );
};

export default AdbNotification;
