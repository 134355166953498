import { PositionSchema } from '@ui/validations/schemas/Position';
import { MarketCode } from '@utils/market/types';
import { object } from 'yup';

const PrimaryContactInfoSchema = (validationMarket: MarketCode) =>
  object().shape({
    ...PositionSchema(validationMarket),
  });

export default PrimaryContactInfoSchema;
