import { TextInput, TextInputProps } from '@smart/react-components';
import { AdbSchemaProps, getFieldLabel } from './helper';

type AdbTextInputProps = TextInputProps & AdbSchemaProps;

export const AdbTextInput = (props: AdbTextInputProps) => {
  const { schema, name, label } = props;

  return <TextInput {...props} label={getFieldLabel(schema, name, label)} />;
};
