import { Text } from '@smart/react-components';
import {
  TestDriveBookingCar,
  TestDriveBookingSlot,
} from '@ui/data-models/test-drive/test-drive.model';
import { CarSelectItem } from 'pages/test-drive/config';
import { useTranslation } from 'react-i18next';
import './CarDetailsList.scss';
import CarDetailsListItem from './car-details-list-item/CarDetailsListItem';

const BASE_CLASS = 'adb-time-car-list';

export type CarDetailsListProps = {
  testDriveCars?: CarSelectItem[];
  selectedDate: string;
  selectedSlot?: TestDriveBookingSlot;
  selectedTestDriveCar?: TestDriveBookingCar;
  handleCarSelection: (testDriveCar: TestDriveBookingCar) => void;
  handleSlotSelection: (slot: TestDriveBookingSlot) => void;
};

/**
 * CarDetailsList component
 * @param CarDetailsListProps CarDetailsListProps
 * @returns <CarDetailsList> ReactNode
 */
const CarDetailsList = ({
  testDriveCars = [],
  selectedDate,
  selectedSlot = undefined,
  selectedTestDriveCar = undefined,
  handleCarSelection,
  handleSlotSelection,
}: CarDetailsListProps) => {
  const { t } = useTranslation();

  const carData = testDriveCars?.map((testDriveCar) => (
    <CarDetailsListItem
      testDriveCar={testDriveCar}
      selectedDate={selectedDate}
      selectedSlot={selectedSlot}
      handleSlotSelection={handleSlotSelection}
      active={
        testDriveCar.testDriveCarId === selectedTestDriveCar?.testDriveCarId
      }
      selectedCarID={selectedTestDriveCar?.testDriveCarId}
      key={testDriveCar.testDriveCarId + selectedSlot}
      onClick={() => {
        if (testDriveCar?.licensePlate) {
          handleCarSelection({
            carId: testDriveCar.carId,
            licensePlate: testDriveCar.licensePlate,
            testDriveCarId: testDriveCar.testDriveCarId,
          });
        }
      }}
    />
  ));

  const noCarsFound = (
    <Text variant="cap-100" as="p" className={`${BASE_CLASS}__no-records`}>
      {t('test_drive.time.no_cars_found')}
    </Text>
  );

  return (
    <div className={BASE_CLASS}>
      {testDriveCars && Object.keys(testDriveCars).length > 0
        ? carData
        : noCarsFound}
    </div>
  );
};

export default CarDetailsList;
