import classNames from 'classnames';
import { useInitialIntersection } from 'hooks/useInitialIntersection';
import { useCallback, useRef, useState } from 'react';
import {
  AdbDynamicImageProps,
  aspectRatioMapping,
  generateSrc,
  generateSrcSet,
} from './AdbDynamicImage.config';
import './AdbDynamicImage.scss';

const BASE_CLASS = 'adb-dynamic-image';
const base64Pixel =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const maxHeight = 2160;

const AdbDynamicImage = ({
  imageUrl,
  imageAltText,
  imageConfig,
  className,
}: AdbDynamicImageProps) => {
  const ref = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => isInView && setIsLoaded(true);
  const handleIntersection = useCallback(() => setIsInView(true), []);

  useInitialIntersection(ref, handleIntersection, `100%`);

  return (
    <img
      ref={ref}
      className={classNames(
        `${BASE_CLASS}`,
        {
          [`${BASE_CLASS}--loading`]: !isLoaded && isInView,
        },
        className
      )}
      alt={imageAltText}
      onLoad={handleLoad}
      src={
        isInView
          ? generateSrc({
              url: imageUrl,
              height: maxHeight,
              width: maxHeight / aspectRatioMapping[imageConfig.aspectRatio],
              aspectRatio: imageConfig.aspectRatio,
            })
          : base64Pixel
      }
      srcSet={
        isInView
          ? generateSrcSet({
              maxHeight,
              aspectRatio: imageConfig.aspectRatio,
              url: imageUrl,
            })
          : base64Pixel
      }
      data-testid="dynamicmediaimage-component"
    />
  );
};

export default AdbDynamicImage;
