import { AssetType, CarFilter } from '@smart/adb-shared';
import { calendarContextInitialState } from '@store/calendar/configs';
import { setOutlet } from '@store/calendar/expert';
import { reducers } from '@store/calendar/reducers';
import { setLanguage, setLocale } from '@store/calendar/setting';
import {
  CalendarContextState,
  CalendarProps,
  Filter,
  FilterType,
} from '@store/calendar/types';
import { useCarsQuery } from 'graphql/queries/cars.generated';
import { useOutletAgentsQuery } from 'graphql/queries/outlet.generated';
import { useCurrentOutlet } from 'hooks/outlet';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useAgentContext } from './agent-context';
import { useLanguageContext } from './language-context';

export const CalendarContext = createContext({} as CalendarContextState);

const CalendarContextProvider = (calendarContextProps: CalendarProps) => {
  const [state, dispatch] = useReducer(reducers, calendarContextInitialState);

  const { locale, language } = useLanguageContext();
  const { agent } = useAgentContext();

  const [filter, setFilter] = useState<Filter[]>([
    { type: FilterType.Expert, value: agent.id },
  ]);

  const outlet = useCurrentOutlet();
  const { children, onClose } = calendarContextProps;

  const { data } = useCarsQuery({
    variables: {
      input: {
        type: AssetType.DemoVehicle,
        bpid: outlet?.bpId,
      },
      filter: CarFilter.InFleeted,
    },
    fetchPolicy: 'network-only',
    skip: !outlet?.bpId,
  });
  const demoCars = data?.getCars.products;

  const { data: agentsData } = useOutletAgentsQuery({
    variables: {
      outletId: outlet?.mcsId ?? '',
    },
    skip: !outlet?.mcsId || !agent,
  });

  useEffect(() => {
    if (calendarContextProps && agent && outlet) {
      setLanguage(dispatch, language);
      setLocale(dispatch, locale);
      setOutlet(dispatch, outlet);
    }
  }, [calendarContextProps, dispatch, agent, language, locale, outlet]);

  const value = useMemo(
    () => ({
      ...state,
      filter,
      setFilter,
      onClose,
      dispatch,
      experts: agentsData?.outletAgents ?? [],
      demoCars: demoCars ?? [],
    }),
    [state, onClose, demoCars, agentsData, filter]
  );

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
};

/**
 * @returns hook to use the calendar context
 */
const useCalendarContext = () => useContext(CalendarContext);

/**
 * @exports
 */
export { CalendarContextProvider, useCalendarContext };
