import { PropsWithChildren, ReactNode } from 'react';

export enum BadgeThemes {
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
  GREY = 'grey',
  DARK_GREY = 'dark-grey',
  BLACK = 'black',
  CUSTOM = 'custom',
  SUCCESS = 'success',
}

export enum BadgeShape {
  PILL = 'pill',
  ROUND_SQUARE = 'round-square',
  SQUARE = 'square',
  CIRCLE = 'circle',
}

export interface BadgeProps extends PropsWithChildren {
  text?: ReactNode;
  shape?: BadgeShape;
  theme?: BadgeThemes;
  backgroundColor?: string;
  color?: string;
  additionalClasses?: string;
  darkMode?: boolean;
}
