import { AppointmentType } from '@smart/adb-shared';
import {
  AdbLocale,
  getCustomFormattedDate,
} from '@ui/library/helpers/date-locale';
import { CalendarAppointment } from '../config';
import { CreateAppointmentInitialData } from '../CreateAppointmentDialog/CreateAppointmentDialog.config';

/**
 * Get initial data for the reschedule appointment form
 *
 * @param data Appointment
 * @param outletId string
 * @returns CreateAppointmentFormInitialData
 */
export const getRescheduleAppointmentFormInitialData = (
  data: CalendarAppointment,
  outletGigyaId: string,
  locale: AdbLocale
): CreateAppointmentInitialData => ({
  appointmentType: data.type ?? AppointmentType.TestDrive,
  customerFirstName: data.customer?.firstName,
  customerLastName: data.customer?.lastName,
  carId: data.car?.carId ?? '',
  endDate: getCustomFormattedDate(
    data.end,
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    locale
  ),
  expertId: data.expert?.id ?? '',
  customerId: data.customer?.uuid,
  outletId: outletGigyaId,
  searchedCustomer: data.customer?.uuid ?? '',
  startDate: getCustomFormattedDate(
    data.start,
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    locale
  ),
});
