import {
  Button,
  Dialog,
  Drawer,
  Flex,
  IconButton,
} from '@smart/react-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CalendarAccordion from '../CalendarAccordion/CalendarAccordion';
import { SidebarProps } from '../Sidebar.config';
import './SideDrawer.scss';

const BASE_CLASS = 'adb-mobile-hamburger';

const SideDrawer = ({ onCreateAppointment }: SidebarProps) => {
  const { t } = useTranslation();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <>
      <Drawer
        title="Mobile menu"
        trigger={
          <div className={`${BASE_CLASS}__menu`}>
            <IconButton
              mode={300}
              variant="primary"
              onClick={() => setIsDrawerOpen(true)}
            >
              <IconButton.Icon icon="burger-menu" />
            </IconButton>
          </div>
        }
        open={isDrawerOpen}
        position="left"
      >
        <Flex
          direction="column"
          height="full"
          padding={400}
          justifyContent="space-between"
          alignItems="center"
        >
          <Dialog.Text>
            <CalendarAccordion setIsDrawerOpen={setIsDrawerOpen} />
          </Dialog.Text>
          <Dialog.Close asChild onClick={() => setIsDrawerOpen(false)}>
            <Button width="full" mode={300} variant="secondary">
              {t('feature_calendar.general.buttons.close')}
            </Button>
          </Dialog.Close>
        </Flex>
      </Drawer>
      <div className={`${BASE_CLASS}__appointment`}>
        <Button
          mode={200}
          type="button"
          variant="primary"
          onClick={onCreateAppointment}
        >
          <Button.Icon icon="plus" />
          {t('feature_calendar.general.appointment')}
        </Button>
      </div>
    </>
  );
};

export default SideDrawer;
