import { Customer, CustomerType } from '@smart/adb-shared';
import { CustomerSearchType } from '@store/customers/defaults';
import { SetStateAction, useEffect } from 'react';

import { Button } from '@smart/react-components';
import { useAgentContext } from 'contexts/agent-context';
import { useNotificationContext } from 'contexts/notification-context';
import { useCustomerOutletInvolvementMutation } from 'graphql/queries/customer.generated';
import { AllCustomersDocument } from 'graphql/queries/customers.generated';
import { useTranslation } from 'react-i18next';

interface ConnectToOutletButtonProps {
  customer: Customer;
  setCurrentSearchType: (value: SetStateAction<CustomerSearchType>) => void;
}

const ConnectToOutletButton = ({
  customer,
  setCurrentSearchType,
}: ConnectToOutletButtonProps) => {
  const { agent: selectedAgent } = useAgentContext();
  const isNonLeadBusinessCustomer =
    customer.__typename === 'CustomerBusiness' &&
    !(customer.type === CustomerType.Lead);
  const customerId = isNonLeadBusinessCustomer ? customer.bpid : customer.uuid;

  const refetchQueries = [
    {
      query: AllCustomersDocument,
    },
  ];

  const [connectToOutlet, { data, loading }] =
    useCustomerOutletInvolvementMutation({
      variables: {
        input: {
          customerId: customerId ?? '',
          bpId: selectedAgent?.outletId ?? '',
        },
      },
      refetchQueries,
    });

  const { t } = useTranslation();
  const { addSuccess, addError } = useNotificationContext();

  useEffect(() => {
    if (data) {
      if (data?.customerOutletInvolvement.success) {
        addSuccess({
          label: t('customer.register.success'),
          message: t('customer.register.outlet_association_success'),
        });

        setCurrentSearchType(CustomerSearchType.LOCAL);
      } else {
        addError({
          label: t('general.error_messages.something_went_wrong'),
          message: data.customerOutletInvolvement.error ?? '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Button
      onClick={() => connectToOutlet()}
      variant="secondary"
      disabled={loading}
    >
      {t('customer.search.buttons.connect')}
    </Button>
  );
};

export { ConnectToOutletButton };
