/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePurchasedProductMutationVariables = Types.Exact<{
  input: Types.UpdateCarInput;
}>;


export type UpdatePurchasedProductMutation = { __typename?: 'Mutation', updateCar: { __typename?: 'UpdateCarResponse', active?: boolean | null } };


export const UpdatePurchasedProductDocument = gql`
    mutation UpdatePurchasedProduct($input: UpdateCarInput!) {
  updateCar(input: $input) {
    active
  }
}
    `;
export type UpdatePurchasedProductMutationFn = Apollo.MutationFunction<UpdatePurchasedProductMutation, UpdatePurchasedProductMutationVariables>;

/**
 * __useUpdatePurchasedProductMutation__
 *
 * To run a mutation, you first call `useUpdatePurchasedProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchasedProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchasedProductMutation, { data, loading, error }] = useUpdatePurchasedProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePurchasedProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePurchasedProductMutation, UpdatePurchasedProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePurchasedProductMutation, UpdatePurchasedProductMutationVariables>(UpdatePurchasedProductDocument, options);
      }
export type UpdatePurchasedProductMutationHookResult = ReturnType<typeof useUpdatePurchasedProductMutation>;
export type UpdatePurchasedProductMutationResult = Apollo.MutationResult<UpdatePurchasedProductMutation>;
export type UpdatePurchasedProductMutationOptions = Apollo.BaseMutationOptions<UpdatePurchasedProductMutation, UpdatePurchasedProductMutationVariables>;