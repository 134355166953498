import SmartComLinkOut from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut';
import {
  LinkOutConfig,
  buildLinkOutConfig,
} from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut.config';
import { Button } from '@smart/react-components';
import { SMART_COM_LINKS } from '@utils/configs/config';
import { useAgentContext } from 'contexts/agent-context';
import { useAuthContext } from 'contexts/auth-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { VehicleOrderButtonType } from './VehicleOrderButton.config';

const VehicleOrderButton = ({ type, variant }: VehicleOrderButtonType) => {
  const { locale } = useLanguageContext();
  const { agent } = useAgentContext();
  const { market: selectedAppMarket } = useMarketContext();
  const { pathname: referrerPath } = useLocation();
  const { auth } = useAuthContext();
  const { t } = useTranslation();

  const linkOutConfig: LinkOutConfig = buildLinkOutConfig({
    language: locale,
    market: selectedAppMarket ?? '',
    outletId: agent?.outletId,
    referrerPath,
    ...(type === 'showroom' && {
      showroomVehicle: 'true',
    }),
    ...(type === 'demo' && {
      demoVehicle: 'true',
    }),
    accessToken: auth ? auth['access-token'] : undefined,
    idToken: auth ? auth['id-token'] : undefined,
    refreshToken: auth ? auth['refresh-token'] : undefined,
    customerUUID: agent?.id,
    customerUserId: agent?.email,
  });

  const buttonText =
    type === 'showroom'
      ? t('outlet.showroom.order_button')
      : t('outlet.showroom.demo_cars.button.order');

  return (
    <SmartComLinkOut
      redirectPath={SMART_COM_LINKS.stockSearch}
      config={linkOutConfig}
      disabled={false}
    >
      <Button variant={variant} mode={200} disabled={false}>
        {buttonText}
        <Button.Icon icon="car" />
      </Button>
    </SmartComLinkOut>
  );
};

export default VehicleOrderButton;
