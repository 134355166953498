import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { GetMaxLengthValidationMsg } from '../helpers/utils';

export const TownSchema = (required = false) => {
  const { t } = useTranslation();

  return {
    town: required
      ? string().required(
          `${t('form_fields.basic.town')} ${t(
            'general.error_messages.validations.is_required'
          )}`
        )
      : string().max(
          40,
          GetMaxLengthValidationMsg(t('form_fields.basic.town'), 40)
        ),
  };
};
