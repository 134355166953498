import { Task } from '@smart/adb-shared';
import { BASE_CLASS } from '@smart/components-adb/calendar/config';
import {
  TableData,
  TableRow,
} from '@smart/components-adb/molecules/Table/TableComponents/TableComponents';
import { Icon, Text } from '@smart/react-components';
import { Row, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import { useState } from 'react';
import TaskBadge from './TaskBadge';
import { isNewTask, taskType, toggleExpand } from './tasktable.config';
import TaskTableDetails from './TaskTableDetails';

const TaskTableRow = ({ task }: { task: Row<Task> }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const chevron = isExpanded ? 'chevron-up' : 'chevron-down';

  return (
    <>
      <TableRow
        key={task.id}
        onClick={() => setIsExpanded(!isExpanded)}
        className={classNames('adb-table__row--clickable', {
          'adb-table__row--expanded': isExpanded,
        })}
      >
        {task.getVisibleCells().map((cell) => {
          if (cell.column.id === toggleExpand) {
            return (
              <td key={cell.id} className={`${BASE_CLASS}__justify_end`}>
                <Icon aria-label="Expand row details" icon={chevron} />
              </td>
            );
          }

          if (cell.column.id === taskType) {
            return (
              <TableData key={cell.id} type="selfcontained">
                <Text variant="p-100" as="p">
                  <strong>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </strong>
                </Text>
                {isNewTask(task.original) && <TaskBadge />}
              </TableData>
            );
          }

          return (
            <TableData key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableData>
          );
        })}
      </TableRow>

      {isExpanded && <TaskTableDetails task={task} />}
    </>
  );
};

export default TaskTableRow;
