import { Button, Text } from '@smart/react-components';
import {
  AuthAgentIDTokenResponsePayload,
  AuthErrorType,
} from '@store/auth/types';
import { getAssertedAuthCookie, useAuthContext } from 'contexts/auth-context';
import { jwtDecode as jwt } from 'jwt-decode';
import SplashLayout from 'layouts/splash-layout/SplashLayout';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import './index.scss';

const BASE_CLASS = 'adb-auth-error';

const AuthError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clearAuthorization, logout } = useAuthContext();
  const { errorType: paramsError } = useParams();

  const errorTypeString = paramsError;
  const errorType: AuthErrorType | undefined = errorTypeString
    ? (errorTypeString as AuthErrorType)
    : undefined;

  const { auth: authTokens } = useAuthContext();

  const handleCtaClick = () => {
    const auth = getAssertedAuthCookie();
    logout(auth['agent-id']).then(() => clearAuthorization());
  };

  const onLogout = async () => {
    const idToken = authTokens?.['id-token'];
    const jwtToken: AuthAgentIDTokenResponsePayload | undefined = idToken
      ? jwt(idToken)
      : undefined;

    try {
      const auth = getAssertedAuthCookie();
      const agentId = jwtToken?.sub ?? auth['agent-id'];
      if (agentId) {
        await logout(agentId);
      }
    } finally {
      clearAuthorization();
    }
  };

  useEffect(() => {
    if (
      errorType !== AuthErrorType.TOKENS_EXPIRED &&
      errorType !== AuthErrorType.NETWORK_ERROR &&
      errorType !== AuthErrorType.INVALID_USER_ACCOUNT
    ) {
      navigate('/', { replace: true });
    }
  }, [navigate, errorType]);

  const shouldDisplayLoginOptions =
    errorType !== AuthErrorType.NO_ROLES_ASSIGNED &&
    errorType !== AuthErrorType.USER_INACTIVE_CDC &&
    errorType !== AuthErrorType.USER_INACTIVE_MCS &&
    errorType !== AuthErrorType.INVALID_USER_ACCOUNT;

  return (
    <SplashLayout>
      <div role="alert" className={`${BASE_CLASS}__main`}>
        {errorType && (
          <Text variant="lbl-200" as="p">
            {t(`auth.error_page.${errorType}`)}
          </Text>
        )}
        {shouldDisplayLoginOptions && (
          <Text variant="lbl-200" as="p">
            {t('auth.error_page.please_login_again')}
          </Text>
        )}

        <div className={`${BASE_CLASS}__buttons`}>
          {shouldDisplayLoginOptions && (
            <Button type="button" variant="primary" onClick={handleCtaClick}>
              <Button.Icon icon="arrow-left" aria-label="arrow-left" />
              {t('auth.error_page.back_to_home')}
            </Button>
          )}
          <Button type="button" variant="secondary" onClick={onLogout}>
            {t('dashboard.notifications.sign_out')}
          </Button>
        </div>
      </div>
    </SplashLayout>
  );
};
export default AuthError;
