import { IconProps } from '@smart/react-components';

export type AdbIconProps = {
  icon: IconProps['icon'];
  color?: IconColor;
  size?: 'small' | 'large';
  backgroundColor?: IconBackground;
  backgroundSize?: IconBackgroundSize | 'default';
  additionalClasses?: string;
  iconHasBorder?: boolean;
  disabled?: boolean;
};

export enum IconBackgroundSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  X_LARGE = 'x-large',
}

export enum IconColor {
  WHITE = 'white',
  BLACK = 'black',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum IconBackground {
  WHITE = 'white',
  BLACK = 'black',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  GREY = 'grey',
  TRANSPARENT = 'transparent',
}
