import { Button, Link } from '@smart/react-components';
import { ReactNode } from 'react';
import {
  ActionItemType,
  ActionType,
  ActionsProps,
} from '../CarListItem.config';
import './Actions.scss';

const BASE_CLASS = 'adb-list-actions';

const getButton = (item: ActionType): ReactNode => {
  if (item.type === ActionItemType.LINK) {
    return (
      <Link asChild>
        <Button onClick={item.onClick} loading={!!item.isLoading}>
          {item.key}
        </Button>
      </Link>
    );
  }
  return (
    <Button
      mode={item.buttonSize ?? 200}
      variant={item.buttonType ?? 'primary'}
      onClick={item.onClick}
    >
      {item.key}
    </Button>
  );
};

const Actions = ({ actionItems = [] }: ActionsProps) => (
  <div className={BASE_CLASS}>
    {actionItems.map((item) => (
      <div key={item.key}>{getButton(item)}</div>
    ))}
  </div>
);
export default Actions;
