import Carts from './carts';
import Orders from './index';

export const orders = [
  {
    path: '/orders',
    component: Orders,
    title: 'navigation.orders.orders',
  },
  {
    path: '/carts',
    component: Carts,
    title: 'navigation.test_drive.car',
  },
];
