import { OrderType } from '@smart/adb-shared';
import { OrdersContextProvider } from '@smart/components-adb/molecules/OrderItems/order-items-context';
import OrderItems from '@smart/components-adb/molecules/OrderItems/OrderItems';

const Orders = () => (
  <OrdersContextProvider
    includeOrderTypes={[
      OrderType.CourtesyCar,
      OrderType.CustomerCar,
      OrderType.Downpayment,
      OrderType.Fallback,
      OrderType.Fleet,
      OrderType.Gd,
      OrderType.Empty,
    ]}
  >
    <OrderItems />
  </OrdersContextProvider>
);

export default Orders;
