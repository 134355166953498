import Section from '@smart/components-adb/atoms/Section/Section';
import { CreateLightAccountForm } from '@smart/components-adb/calendar/CreateAppointmentDialog/CreateLightAccountForm';
import { Button } from '@smart/react-components';
import {
  getNavigationItem,
  handleNavigationStepLinks,
} from '@utils/helpers/test-drive';
import { useTestDriveContext } from 'contexts/test-drive-context';
import FocusLayout from 'layouts/focus-layout/FocusLayout';
import { TestDriveBookingSteps } from 'pages/test-drive/config';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './index.scss';

const BASE_CLASS = 'adb-light-customer';

const CreateLightUser = () => {
  const { t } = useTranslation();
  const { navigation, setSuperLightCustomer } = useTestDriveContext();
  const submitRef = useRef<HTMLFormElement>(null);

  const navigate = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(false);

  const onCreated = (
    uuid: string,
    firstName: string,
    lastName: string,
    userId: string,
    mobileNumber: string
  ) => {
    setSuperLightCustomer({
      firstName,
      lastName,
      userId,
      uuid,
      mobileNumber,
    });
    navigate('/test-drive/confirmation/summary');
  };
  const onLoading = (loading: boolean) => {
    setLoading(loading);
  };

  return (
    <FocusLayout
      navList={handleNavigationStepLinks(navigation, undefined, undefined)}
      backButtonRoute={
        getNavigationItem(navigation, TestDriveBookingSteps.ACCOUNT).path
      }
      footerRight={
        <div className="customer_footer_right_buttons">
          <Button
            loading={isLoading}
            disabled={isLoading}
            mode={200}
            variant="primary"
            onClick={() => submitRef?.current?.requestSubmit()}
          >
            <Button.Spinner />
            {t('test_drive.account.buttons.create_customer')}
          </Button>
        </div>
      }
    >
      <Section expanded additionalClasses={BASE_CLASS}>
        <CreateLightAccountForm
          submitRef={submitRef}
          onLoading={onLoading}
          onCreated={onCreated}
          submitEnabled={false}
        />
      </Section>
    </FocusLayout>
  );
};

export default CreateLightUser;
