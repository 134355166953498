import {
  Appointment,
  AppointmentType,
  CustomerAccountType,
  CustomerRegType,
} from '@smart/adb-shared';
import EntryPoint from '@smart/components-adb/atoms/EntryPoint/EntryPoint';
import { EntryPointProps } from '@smart/components-adb/atoms/EntryPoint/EntryPoint.config';
import AdbTooltip from '@smart/components-adb/molecules/AdbTooltip/AdbTooltip';
import SmartComLinkOut from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut';
import {
  buildLinkOutConfig,
  type LinkOutConfig,
} from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut.config';
import { Text } from '@smart/react-components';
import { CustomerHeroBanner } from '@store/customers/defaults';
import {
  getDateXMonthsAfter,
  getDateXMonthsBefore,
} from '@ui/library/helpers/date';
import { useAgentContext } from 'contexts/agent-context';
import { useAuthContext } from 'contexts/auth-context';
import { useCustomerContext } from 'contexts/customer-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useAllAppointmentsQuery } from 'graphql/queries/appointments.generated';
import CustomerLayout from 'layouts/customer-layout/CustomerLayout';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import NextAppointment from '../../dashboard/NextAppointment/NextAppointment';
import CustomerRequests from './CustomerRequests';

import {
  OverviewState,
  getEntryPoints,
  getFleetCustomerEntryPoints,
  getOutletCustomerEntryPoints,
} from './Overview.config';
import './Overview.scss';

const BASE_CLASS = 'adb-customer-overview';

/**
 * Customer management overview component
 */
const OverView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useAuthContext();

  const { customer, customerIsLoading, prevListingRoute, setPrevListingRoute } =
    useCustomerContext();

  const { market: selectedAppMarket } = useMarketContext();
  const { agent } = useAgentContext();

  const { locale: selectedAppLanguage } = useLanguageContext();

  const referrerPath = useLocation().pathname;
  const state = useLocation().state as OverviewState;

  useEffect(() => {
    if (!state) return;
    if (state.prevPage === prevListingRoute) return;

    setPrevListingRoute(state.prevPage);
  }, [prevListingRoute, setPrevListingRoute, state]);

  const getNextFutureAppointment = (
    customerAppointments: Appointment[]
  ): Appointment | undefined => {
    const futureAppointments = customerAppointments.filter(
      (appointment) =>
        appointment?.start && new Date(appointment.start) > new Date()
    );
    const nextAppointment = futureAppointments.reduce(
      (prev, current) =>
        prev.start && current.start && prev.start < current.start
          ? prev
          : current,
      futureAppointments[0]
    );
    return nextAppointment;
  };

  const getNextAppointmentTitle = (title: string, carId?: string): string =>
    carId ? `${t(title)} : ${carId}` : t(title);

  const linkOutConfig: LinkOutConfig = buildLinkOutConfig({
    language: selectedAppLanguage,
    market: selectedAppMarket ?? '',
    outletId: agent?.outletId,
    referrerPath,
    customerUserId: customer?.userId,
    customerType: customer?.accountType,
    customerUUID: customer?.uuid,
    customerPreferredLang: customer?.preferredLanguage,
  });

  const shouldDisableLinkOut = (redirectPath?: string): boolean =>
    !selectedAppLanguage ||
    !selectedAppMarket ||
    !redirectPath ||
    !agent?.outletId;

  const shouldDisableTestDrive = (entryPoint: EntryPointProps) =>
    !!(
      entryPoint.id === 'new_test_drive' && customer?.accountType === 'Outlet'
    );

  const isSuperLiteAccount = customer?.regType === CustomerRegType.Lite;

  const shouldDisableForSuperLiteAccount = (
    entryPoint: EntryPointProps
  ): boolean => entryPoint.id !== 'new_test_drive' && isSuperLiteAccount;

  const { data: appointments, loading: appointmentsIsLoading } =
    useAllAppointmentsQuery({
      variables: {
        input: {
          startDateTime: getDateXMonthsBefore(2).toISOString(),
          endDateTime: getDateXMonthsAfter(2).toISOString(),
          outletId: agent?.outletId ?? '',
          customerId: customer?.uuid,
        },
      },
      skip: !agent?.outletId || !customer?.uuid,
    });

  const nextAppointment =
    !appointmentsIsLoading && appointments
      ? getNextFutureAppointment(appointments.allAppointments.appointments)
      : undefined;

  const links = useMemo(() => {
    switch (customer?.accountType) {
      case CustomerAccountType.Fleet:
        return getFleetCustomerEntryPoints(t);

      case CustomerAccountType.Outlet:
        return getOutletCustomerEntryPoints(t);

      default:
        return getEntryPoints(t);
    }
  }, [customer?.accountType, t]);

  return (
    <CustomerLayout bannerType={CustomerHeroBanner.FULL}>
      <div className={BASE_CLASS}>
        <div className={`${BASE_CLASS}__span-7`}>
          <CustomerRequests />
        </div>
        <div className={`${BASE_CLASS}__entry-points`}>
          {links?.map((entryPoint) =>
            entryPoint.isLinkOut ? (
              <SmartComLinkOut
                redirectPath={entryPoint.redirectPath}
                config={{
                  ...linkOutConfig,
                  ...(entryPoint.isLinkOut && {
                    accessToken: auth ? auth['access-token'] : undefined,
                    idToken: auth ? auth['id-token'] : undefined,
                    refreshToken: auth ? auth['refresh-token'] : undefined,
                    ...(entryPoint.id === 'demo_stock_search' && {
                      demoVehicle: 'true',
                    }),
                    ...(entryPoint.id === 'showroom_stock_search' && {
                      showroomVehicle: 'true',
                    }),
                  }),
                }}
                key={entryPoint.name}
                disabled={
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  entryPoint.disabled ||
                  shouldDisableLinkOut(entryPoint.redirectPath) ||
                  customerIsLoading ||
                  !customer ||
                  shouldDisableForSuperLiteAccount(entryPoint)
                }
              >
                <AdbTooltip
                  className={`${BASE_CLASS}__tooltip`}
                  disabled={!isSuperLiteAccount}
                  title={t('customer.overview.tooltip.title')}
                  message={t('customer.overview.tooltip.message')}
                >
                  <EntryPoint
                    {...{
                      ...entryPoint,
                      disabled:
                        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                        entryPoint.disabled ||
                        shouldDisableLinkOut(entryPoint.redirectPath) ||
                        customerIsLoading ||
                        !customer ||
                        shouldDisableForSuperLiteAccount(entryPoint),
                    }}
                    reduceMargin
                  />
                </AdbTooltip>
              </SmartComLinkOut>
            ) : (
              <EntryPoint
                {...entryPoint}
                disabled={shouldDisableTestDrive(entryPoint)}
                key={entryPoint.name}
                reduceMargin
                isClickable
              />
            )
          )}
        </div>
        <div className={`${BASE_CLASS}__span-3`}>
          <NextAppointment
            start={
              nextAppointment?.start
                ? new Date(nextAppointment.start)
                : undefined
            }
            end={
              nextAppointment?.end ? new Date(nextAppointment.end) : undefined
            }
            name={
              <Text variant="cap-300" as="p">
                {`${nextAppointment?.customer?.firstName} ${nextAppointment?.customer?.lastName}`}
              </Text>
            }
            title={getNextAppointmentTitle(
              nextAppointment?.title ?? '',
              nextAppointment?.type === AppointmentType.Handover
                ? nextAppointment?.car?.carId
                : (nextAppointment?.car?.licensePlate ?? '')
            )}
            onClick={() => {
              navigate('/calendar', {
                state: {
                  appointmentId: nextAppointment?.id,
                  start: nextAppointment?.start,
                },
              });
            }}
            loading={appointmentsIsLoading || customerIsLoading}
            exists={!!nextAppointment}
          />
        </div>
      </div>
    </CustomerLayout>
  );
};

export default OverView;
