import { OrderType } from '@smart/adb-shared';
import { OrdersContextProvider } from '@smart/components-adb/molecules/OrderItems/order-items-context';
import OrderItems from '@smart/components-adb/molecules/OrderItems/OrderItems';
import '../outlet-cars/outlet-cars.scss';

const DemoCarsOrderStatus = () => (
  <OrdersContextProvider
    includeOrderTypes={[OrderType.DemoCar, OrderType.ShowroomCar]}
  >
    <OrderItems />
  </OrdersContextProvider>
);

export default DemoCarsOrderStatus;
