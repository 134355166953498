import { CarDetails, TestDriveSlots } from '@smart/adb-shared';
import { FilterProp } from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown.config';
import { CarLine } from '@ui/data-models/car/car.model';
import { FacetSortByValue } from '@ui/library/helpers/filters';

/**
 * @enum TestDriveCarFilterTypes
 * Allowed filter types for the test drive cars
 */
export enum TestDriveCarFilterTypes {
  LINE = 'line', // filter options type = CarLine
  DATE = 'date', // filter options type = string
}

/**
 * @type TestDriveCarFilter
 * Filter for the test drive cars
 */
export type TestDriveCarFilter = {
  label: TestDriveCarFilterTypes;
  filters: CarLine[];
  placeholder?: string;
};

/**
 * @type TestDriveCarCurrentFilter
 * Current filter applied for the test drive cars
 */
export type TestDriveCarCurrentFilter = {
  name?: TestDriveCarFilterTypes;
  lineValue?: FilterProp;
  dateValue?: string;
  modelValue?: FilterProp;
};

export interface CarSelectItem
  extends Omit<TestDriveSlots, '__typename'>,
    Omit<CarDetails, '__typename'> {}

export const mapCarSelectItem = (
  slots: TestDriveSlots[],
  cars: CarDetails[]
) => {
  const testDriveCars: CarSelectItem[] = [];

  slots
    .filter((slot) => !!slot.licensePlate)
    .forEach((slot) => {
      const car = cars.find((o) => o.carId === slot.testDriveCarId);

      if (car) {
        testDriveCars.push({ ...slot, ...car });
      }
    });

  return testDriveCars;
};

export const mapTestDriveCarFilters = (
  cars: CarSelectItem[]
): TestDriveCarFilter => {
  const carLines = cars
    .flatMap((car) => car.line ?? '-')
    .filter((line) => line !== '-') as CarLine[];

  return {
    label: TestDriveCarFilterTypes.LINE,
    filters: [...new Set(carLines)],
    placeholder: 'all',
  };
};

export const filterDuplicates = (
  testDriveCars: CarSelectItem[]
): CarSelectItem[] => {
  const carLines: CarSelectItem[] = [];

  testDriveCars.forEach((testDriveCar) => {
    const carLineIndex = carLines.findIndex(
      (carLine) => carLine.line === testDriveCar.line
    );

    if (carLineIndex === -1) {
      carLines.push({
        ...testDriveCar,
      });
    }
  });

  return carLines;
};

export const testDriveModels: FacetSortByValue[] = [
  { displayName: '#1', queryValue: '1' },
  { displayName: '#3', queryValue: '3' },
];

/**
 * @enum TestDriveBookingSteps
 * Allowed navigation steps for the test drive booking flow
 */
export enum TestDriveBookingSteps {
  CAR = 'Car',
  TIME = 'Time',
  ACCOUNT = 'Account',
  CONFIRMATION = 'Confirmation',
}

/**
 * @type TestDriveNavItem
 * Navigation item for the the test drive booking flow
 */
export type TestDriveNavItem = {
  path: string;
  name: TestDriveBookingSteps;
  order: number;
  title: string;
  status: boolean;
  subPath?: string[];
};

export const TestDriveBookingNavigation: TestDriveNavItem[] = [
  {
    title: 'navigation.test_drive.car',
    path: '/test-drive/car-selection',
    name: TestDriveBookingSteps.CAR,
    order: 1,
    status: false,
  },
  {
    title: 'navigation.test_drive.time',
    path: '/test-drive/time/car-time-selection',
    name: TestDriveBookingSteps.TIME,
    order: 2,
    status: false,
  },
  {
    title: 'navigation.test_drive.account',
    path: '/test-drive/confirmation/user-details',
    name: TestDriveBookingSteps.ACCOUNT,
    order: 3,
    status: false,
    subPath: [
      '/test-drive/confirmation/:customerId/user-details/:sfCustomerId',
      '/test-drive/confirmation/user-details/new-customer',
      '/test-drive/confirmation/user-details/new-light-customer',
    ],
  },
  {
    title: 'navigation.test_drive.confirmation',
    path: '/test-drive/confirmation/summary/:customerId/:sfCustomerId',
    name: TestDriveBookingSteps.CONFIRMATION,
    order: 4,
    status: false,
  },
];
