import { Button } from '@smart/react-components';
import { useEffect } from 'react';

export const OneTrustCookieButton = () => {
  const scriptId = 'ot-sdk-script';
  const cookieDomain =
    import.meta.env.DEPLOY_ENV === 'prod'
      ? 'bc11c6a7-5d08-414d-9b5f-a0159cb39d45'
      : 'bc11c6a7-5d08-414d-9b5f-a0159cb39d45-test';

  useEffect(() => {
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.id = scriptId;
      script.setAttribute('data-domain-script', cookieDomain);
      script.setAttribute('data-document-language', 'true');
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    return undefined;
  }, [cookieDomain]);

  return (
    <Button
      id="ot-sdk-btn"
      className="ot-sdk-show-settings"
      type="button"
      variant="secondary"
    >
      Cookie Settings
    </Button>
  );
};
