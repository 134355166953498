import { StockCar } from '@smart/adb-shared';
import EmptyLayout from '@smart/components-adb/atoms/EmptyDataLayout/EmptyLayout';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import Pagination from '@smart/components-adb/molecules/Pagination/Pagination';
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMarketContext } from 'contexts/market-context';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useStocksQuery } from './queries.generated';
import StockCarsSearch from './Search/Search';
import './stock.scss';
import StockItems from './StockItems/StockItems';

const BASE_CLASS = 'adb-stock';

const Stock = () => {
  const { t } = useTranslation();
  const { market } = useMarketContext();
  const [params] = useSearchParams();

  const defaultSort = 'relevance';
  const defaultQuery = ':productType:VEHICLE:stockLevelStatus:inStock';

  const [sort, setSort] = useState<string>(defaultSort);
  const [query, setQuery] = useState<string>(`:${sort}${defaultQuery}`);

  const page = Number(params.get('page') ?? 1);
  const pageSize = Number(params.get('size') ?? 20);

  const onQueryUpdate = (
    freeTextSearch: string,
    sortQuery: string,
    filterQuery: string
  ) => {
    setQuery(`${freeTextSearch}:${sortQuery}${filterQuery}${defaultQuery}`);
    setSort(sortQuery);
  };

  const { data, loading } = useStocksQuery({
    variables: {
      input: {
        currentPage: page - 1,
        fields: 'FULL',
        marketId: market,
        sort,
        query,
        pageSize,
      },
    },
    skip: !market,
  });

  const pageCount = data?.stock.pagination?.totalPages ?? 0;
  const cars = data?.stock?.cars ?? [];
  const facets = data?.stock.facets ?? [];

  const tableInstance = useReactTable({
    columns: [{ accessorKey: 'id' }],
    data: cars,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: false,
    getFilteredRowModel: getFilteredRowModel(),
    pageCount,
    manualPagination: true,
  });

  const carRows = tableInstance
    .getRowModel()
    .rows.map((row) => row.original) as StockCar[];

  return (
    <div className={BASE_CLASS}>
      <AdbLayout.Header additionalClasses={`${BASE_CLASS}__header`}>
        <StockCarsSearch
          onQueryUpdate={onQueryUpdate}
          defaultSortBy={defaultSort}
          facets={facets}
        />
      </AdbLayout.Header>
      <AdbLayout.Content
        backgroundColor="bg-level-2"
        additionalClasses={`${BASE_CLASS}__scrollable-content`}
      >
        {!loading && cars.length > 0 ? (
          <StockItems stockItems={carRows} />
        ) : (
          <div className={`${BASE_CLASS}__loading`}>
            <LoadingIndicator loading={loading} onFullPage>
              <EmptyLayout message={t('stock.empty_msg')} />
            </LoadingIndicator>
          </div>
        )}
      </AdbLayout.Content>
      {!loading && (
        <AdbLayout.Footer backgroundColor="bg-level-2">
          <Pagination instance={tableInstance} />
        </AdbLayout.Footer>
      )}
    </div>
  );
};

export default Stock;
