/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoadSignUrlQueryVariables = Types.Exact<{
  input: Types.LoadSignUrlInput;
}>;


export type LoadSignUrlQuery = { __typename?: 'Query', loadSignURL: { __typename?: 'LoadSignURLResult', signURL: string } };


export const LoadSignUrlDocument = gql`
    query LoadSignURL($input: LoadSignURLInput!) {
  loadSignURL(input: $input) {
    signURL
  }
}
    `;

/**
 * __useLoadSignUrlQuery__
 *
 * To run a query within a React component, call `useLoadSignUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSignUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSignUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadSignUrlQuery(baseOptions: Apollo.QueryHookOptions<LoadSignUrlQuery, LoadSignUrlQueryVariables> & ({ variables: LoadSignUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadSignUrlQuery, LoadSignUrlQueryVariables>(LoadSignUrlDocument, options);
      }
export function useLoadSignUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadSignUrlQuery, LoadSignUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadSignUrlQuery, LoadSignUrlQueryVariables>(LoadSignUrlDocument, options);
        }
export function useLoadSignUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoadSignUrlQuery, LoadSignUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoadSignUrlQuery, LoadSignUrlQueryVariables>(LoadSignUrlDocument, options);
        }
export type LoadSignUrlQueryHookResult = ReturnType<typeof useLoadSignUrlQuery>;
export type LoadSignUrlLazyQueryHookResult = ReturnType<typeof useLoadSignUrlLazyQuery>;
export type LoadSignUrlSuspenseQueryHookResult = ReturnType<typeof useLoadSignUrlSuspenseQuery>;
export type LoadSignUrlQueryResult = Apollo.QueryResult<LoadSignUrlQuery, LoadSignUrlQueryVariables>;