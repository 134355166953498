import Documents from '@smart/components-adb/documents';
import { DocumentsContextProvider } from '@smart/components-adb/documents/documents-context';
import { useCustomerContext } from 'contexts/customer-context';
import CustomerLayout from 'layouts/customer-layout/CustomerLayout';

const CustomerDocuments = () => {
  const { customer, customerIsLoading } = useCustomerContext();

  return (
    <DocumentsContextProvider
      customer={customer}
      customerLoading={customerIsLoading}
    >
      <CustomerLayout>
        <Documents />
      </CustomerLayout>
    </DocumentsContextProvider>
  );
};

export default CustomerDocuments;
