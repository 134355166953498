import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import { Checkbox } from '@smart/react-components';
import '../../CreateDocumentType/CreateDocumentType.scss';
import { ChecklistItemProps } from './Checklist.config';

const BASE_CLASS = 'adb-create-document';

const ChecklistItem = ({
  onCheckedChange,
  value,
  error,
  touched,
  id,
  label,
}: ChecklistItemProps) => (
  <div className={`${BASE_CLASS}__form-checkbox`}>
    <Checkbox
      key={id}
      id={id}
      name={id}
      className={`${BASE_CLASS}__consent`}
      checked={value}
      onCheckedChange={onCheckedChange}
      value=""
    >
      {label}
    </Checkbox>
    {error && touched && <ErrorField errorMsg={error} />}
  </div>
);

export default ChecklistItem;
