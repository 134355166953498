/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OutletAgentQueryVariables = Types.Exact<{
  agentId: Types.Scalars['ID']['input'];
}>;


export type OutletAgentQuery = { __typename?: 'Query', outletAgent: { __typename?: 'GigyaAgent', email: string, firstName: string, fullName: string, id: string, isActive?: boolean | null, lastName: string, market: string, outletId: string, roles: Array<string>, address: { __typename?: 'Address', countryCode?: string | null, postalCode?: string | null, regionCode?: string | null, street?: string | null, town?: string | null } } };


export const OutletAgentDocument = gql`
    query OutletAgent($agentId: ID!) {
  outletAgent(agentId: $agentId) {
    email
    firstName
    fullName
    id
    isActive
    lastName
    market
    outletId
    roles
    address {
      countryCode
      postalCode
      regionCode
      street
      town
    }
  }
}
    `;

/**
 * __useOutletAgentQuery__
 *
 * To run a query within a React component, call `useOutletAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletAgentQuery({
 *   variables: {
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useOutletAgentQuery(baseOptions: Apollo.QueryHookOptions<OutletAgentQuery, OutletAgentQueryVariables> & ({ variables: OutletAgentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutletAgentQuery, OutletAgentQueryVariables>(OutletAgentDocument, options);
      }
export function useOutletAgentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletAgentQuery, OutletAgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutletAgentQuery, OutletAgentQueryVariables>(OutletAgentDocument, options);
        }
export function useOutletAgentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OutletAgentQuery, OutletAgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OutletAgentQuery, OutletAgentQueryVariables>(OutletAgentDocument, options);
        }
export type OutletAgentQueryHookResult = ReturnType<typeof useOutletAgentQuery>;
export type OutletAgentLazyQueryHookResult = ReturnType<typeof useOutletAgentLazyQuery>;
export type OutletAgentSuspenseQueryHookResult = ReturnType<typeof useOutletAgentSuspenseQuery>;
export type OutletAgentQueryResult = Apollo.QueryResult<OutletAgentQuery, OutletAgentQueryVariables>;