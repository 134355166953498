import {
  Customer,
  OrderPaymentStatus,
  OrderPaymentType,
  OrderStatus,
  OrderSummary,
} from '@smart/adb-shared';
import AdbIcon from '@smart/components-adb/atoms/AdbIcon/AdbIcon';
import { IconColor } from '@smart/components-adb/atoms/AdbIcon/AdbIcon.config';
import {
  Accordion,
  Flex,
  Spacer,
  Spinner,
  Text,
} from '@smart/react-components';
import { CarMapper } from '@ui/data-models/car/car.mapper';
import {
  getOrderPaymentTypeFilterValues,
  OrderPaymentStatusFilterValues,
} from '@ui/data-models/facet/facet.model';
import { DateFormats, getLocaleDate } from '@ui/library/helpers/date-locale';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { TFunction } from 'i18next';
import { useCustomerOrderLazyQuery } from 'pages/customer/orders/queries.generated';
import { useOutletOrderLazyQuery } from 'pages/orders/queries.generated';
import { Fragment, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AdbDynamicImage from '../AdbDynamicImage/AdbDynamicImage';
import { imageConfig4To3 } from '../AdbDynamicImage/AdbDynamicImage.config';
import AdditionalInterests from './AdditionalInterests';
import CopyText from './CopyText/CopyText';
import CustomerDetails from './CustomerDetails';
import HandoverDetails from './HandoverDetails';
import OrderDetail from './OrderDetail';
import OrderItemFeature from './OrderItemFeature';
import './OrderItems.scss';
import PaymentDetails from './PaymentDetails';
import { translateLabel } from './translateLabel';

const BASE_CLASS = 'adb-order-items';

interface OrderItemI {
  order: OrderSummary;
  customer?: Customer;
}

enum HeaderSectionType {
  text = 'text',
  copyable = 'copyable',
}

interface HeaderSection {
  type: HeaderSectionType;
  value: string;
}

const renderTranslatedOrderTransportStatuses = (
  t: TFunction<'translation', undefined>
) => {
  const statuses = t('orders.transport_status', {
    returnObjects: true,
  }) as { [key: string]: string };
  const uniqueStatuses = [...new Set(Object.values(statuses))];

  return (
    <Flex direction="column">
      {Object.entries(uniqueStatuses).map(([key, value]) => (
        <span key={key}>{value}</span>
      ))}
    </Flex>
  );
};

const OrderItem = ({ order, customer }: OrderItemI) => {
  const { t } = useTranslation();
  const { market } = useMarketContext();
  const { locale } = useLanguageContext();
  const location = useLocation();
  const { agent } = useAgentContext();

  const [selected, setSelected] = useState('');

  const isCustomerOrders = location.pathname.startsWith('/customer');
  const isCarOrder = !!order.lineCode;

  const [getCustomerOrder, { data: customerData, loading: customerLoading }] =
    useCustomerOrderLazyQuery();
  const [getOutletOrder, { data: outletData, loading: outletLoading }] =
    useOutletOrderLazyQuery();

  const loading = customerLoading || outletLoading;

  const orderDetail = outletData?.outletOrder ?? customerData?.customerOrder;

  const sections: HeaderSection[] = [
    { type: HeaderSectionType.text, value: order.productName ?? '-' },
    {
      type: HeaderSectionType.copyable,
      value: order.lineCode ? order.code : '-',
    },
    {
      type: HeaderSectionType.copyable,
      value: order.vin ?? t('orders.vin_not_assigned'),
    },
    {
      type: HeaderSectionType.text,
      value: order.status
        ? t(`orders.order_status.${order.status.toLocaleLowerCase()}`)
        : '-',
    },
  ].filter((section) => section.value !== '-');

  const renderHeaderTitle = () => (
    <Flex columnGap={100} alignItems="center">
      <Text variant="lbl-200" style={{ lineHeight: '1rem' }}>
        <Flex columnGap={100}>
          {sections.map((item, index, array) => (
            <Fragment key={`${order.code}-${item.value}`}>
              <span>
                {item.type === 'copyable' ? (
                  <CopyText key={item.value} text={item.value}>
                    <Text variant="lbl-200" style={{ lineHeight: '1rem' }}>
                      {item.value}
                    </Text>
                  </CopyText>
                ) : (
                  item.value
                )}
              </span>
              <span>{index === array.length - 1 ? '' : '/'}</span>
            </Fragment>
          ))}
        </Flex>
      </Text>
      {order.paymentStatus === OrderPaymentStatus.Notpaid && (
        <AdbIcon icon="warning" color={IconColor.ERROR} />
      )}
      {loading && <Spinner size="md" />}
    </Flex>
  );

  const renderHeader = (): ReactNode => {
    const imageUrl = CarMapper.getImageURL(order.productCode ?? '');

    return (
      <Flex width="full">
        <Flex width="1/5">
          <Flex height="fit-content">
            <AdbDynamicImage
              imageUrl={imageUrl}
              imageAltText={order.productCode ?? order.code}
              imageConfig={imageConfig4To3}
            />
          </Flex>
        </Flex>
        <Flex direction="column" width="full" gap={300}>
          {renderHeaderTitle()}
          <Flex
            alignContent="space-between"
            width="full"
            wrap="wrap"
            gap={300}
            className={`${BASE_CLASS}__features`}
          >
            <OrderItemFeature
              title={t('orders.customer_details.customer')}
              text={order.customerName}
            />
            {(order.paymentType === OrderPaymentType.Leasing ||
              order.paymentType === OrderPaymentType.Finance) &&
            market === 'gb' ? null : (
              <OrderItemFeature
                title={t('orders.payment_details.payment_status')}
                text={
                  order.paymentStatus
                    ? translateLabel(
                        OrderPaymentStatusFilterValues,
                        order.paymentStatus,
                        t
                      )
                    : ''
                }
              />
            )}
            <OrderItemFeature
              title={t('orders.payment_details.payment_type')}
              text={translateLabel(
                getOrderPaymentTypeFilterValues(market),
                order.paymentType,
                t
              )}
            />
            {order.paymentType === OrderPaymentType.Leasing ||
            (order.paymentType === OrderPaymentType.Finance && isCarOrder) ? (
              <OrderItemFeature
                title={t('orders.order_details.ald_status')}
                text={
                  order?.aldJourneyStatus
                    ? t(
                        `orders.order_details.ald_journey_status.${order.aldJourneyStatus.toLowerCase()}`
                      )
                    : t('orders.not_available')
                }
              />
            ) : null}
            {isCarOrder && (
              <OrderItemFeature
                title={t('orders.arrival_at_agent')}
                text={
                  (order.deliveryDate &&
                    getLocaleDate(
                      order.deliveryDate,
                      locale,
                      DateFormats.DATE
                    )) ??
                  '-'
                }
              />
            )}
            {isCarOrder && (
              <OrderItemFeature
                title={t('orders.preferred_delivery_date')}
                text={
                  (order.requestedDeliveryDate &&
                    getLocaleDate(
                      order.requestedDeliveryDate,
                      locale,
                      DateFormats.DATE
                    )) ??
                  '-'
                }
              />
            )}
            {isCarOrder && (
              <OrderItemFeature
                title={t('orders.beautified_delivery_status')}
                text={
                  order?.beautifiedDeliveryStatus ?? t('orders.not_available')
                }
                tooltip={{
                  title: t('orders.all_possible_statuses'),
                  message: (
                    <div>
                      <span>{t('orders.transport_status.X111')}</span> <br />
                      <span>{t('orders.order_status.handed_over')}</span> <br />
                    </div>
                  ),
                }}
              />
            )}
            {isCarOrder && (
              <OrderItemFeature
                title={t('orders.transportation_status')}
                text={
                  order.status === OrderStatus.HandedOver
                    ? t('orders.filter.handed_over')
                    : t([
                        `orders.transport_status.${order.extaStatus}`,
                        'orders.not_available',
                      ])
                }
                tooltip={{
                  title: t('orders.transportation_status'),
                  message: renderTranslatedOrderTransportStatuses(t),
                }}
              />
            )}
            {isCarOrder && (
              <OrderItemFeature
                title={t('orders.current_location')}
                text={
                  order.status === OrderStatus.HandedOver
                    ? '-'
                    : t([
                        `orders.transport_location.${order.extaStatus}`,
                        'orders.not_available',
                      ])
                }
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const onValueChange = async (orderId: string) => {
    if (!orderId) {
      return setSelected('');
    }
    if (isCustomerOrders && !customer?.userId) {
      throw new Error('Could not find customer userId');
    }
    if (isCustomerOrders && customer?.userId) {
      getCustomerOrder({
        variables: {
          input: {
            customerId: customer?.userId,
            userId: agent?.email ?? '',
            orderId,
          },
        },
      }).then(() => {
        setSelected(orderId);
      });
    } else {
      getOutletOrder({
        variables: {
          input: {
            userId: agent?.email ?? '',
            orderId,
          },
        },
      }).then(() => {
        setSelected(orderId);
      });
    }
    return () => {};
  };

  const renderContent = () => (
    <Flex className={`${BASE_CLASS}__item-content`}>
      <Flex width="1/5">{null}</Flex>
      <Flex width="full" direction="column" rowGap={400}>
        <OrderDetail orderDetail={orderDetail} />
        <AdditionalInterests orderDetail={orderDetail} />
        <CustomerDetails orderDetail={orderDetail} />
        <PaymentDetails orderDetail={orderDetail} />
        <HandoverDetails orderDetail={orderDetail} />
        <Spacer height={50} />
      </Flex>
    </Flex>
  );

  return (
    <Accordion
      type="single"
      className={`${BASE_CLASS}__orders`}
      onValueChange={onValueChange}
      value={selected}
    >
      <Accordion.Item
        className={`${BASE_CLASS}__item`}
        title={renderHeader()}
        value={order.code}
      >
        <div>{renderContent()}</div>
      </Accordion.Item>
    </Accordion>
  );
};

export default OrderItem;
