/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SimStatusQueryVariables = Types.Exact<{
  vin: Types.Scalars['String']['input'];
}>;


export type SimStatusQuery = { __typename?: 'Query', simStatus: { __typename?: 'SimStatusErrorResponse', status: Types.ResponseStatus, data: { __typename?: 'SimStatusErroResponseData', message: string } } | { __typename?: 'SimStatusSuccessResponse', status: Types.ResponseStatus, data: { __typename?: 'SimStatusSuccessResponseData', status: Types.SimActivationStatus, lifecycle: string, startTime: string, packages?: Array<{ __typename?: 'SimPackage', name: string, id: string }> | null } } };

export type SimActivateMutationVariables = Types.Exact<{
  vin: Types.Scalars['String']['input'];
}>;


export type SimActivateMutation = { __typename?: 'Mutation', simActivate: { __typename?: 'SimActivationResponse', status: Types.ResponseStatus, data: { __typename?: 'SimActivationResponseData', vin: string, workflowId: string } } };


export const SimStatusDocument = gql`
    query SimStatus($vin: String!) {
  simStatus(vin: $vin) {
    ... on SimStatusSuccessResponse {
      status
      data {
        status
        lifecycle
        startTime
        packages {
          name
          id
        }
      }
    }
    ... on SimStatusErrorResponse {
      status
      data {
        message
      }
    }
  }
}
    `;

/**
 * __useSimStatusQuery__
 *
 * To run a query within a React component, call `useSimStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimStatusQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useSimStatusQuery(baseOptions: Apollo.QueryHookOptions<SimStatusQuery, SimStatusQueryVariables> & ({ variables: SimStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimStatusQuery, SimStatusQueryVariables>(SimStatusDocument, options);
      }
export function useSimStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimStatusQuery, SimStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimStatusQuery, SimStatusQueryVariables>(SimStatusDocument, options);
        }
export function useSimStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SimStatusQuery, SimStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SimStatusQuery, SimStatusQueryVariables>(SimStatusDocument, options);
        }
export type SimStatusQueryHookResult = ReturnType<typeof useSimStatusQuery>;
export type SimStatusLazyQueryHookResult = ReturnType<typeof useSimStatusLazyQuery>;
export type SimStatusSuspenseQueryHookResult = ReturnType<typeof useSimStatusSuspenseQuery>;
export type SimStatusQueryResult = Apollo.QueryResult<SimStatusQuery, SimStatusQueryVariables>;
export const SimActivateDocument = gql`
    mutation SimActivate($vin: String!) {
  simActivate(vin: $vin) {
    status
    data {
      vin
      workflowId
    }
  }
}
    `;
export type SimActivateMutationFn = Apollo.MutationFunction<SimActivateMutation, SimActivateMutationVariables>;

/**
 * __useSimActivateMutation__
 *
 * To run a mutation, you first call `useSimActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simActivateMutation, { data, loading, error }] = useSimActivateMutation({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useSimActivateMutation(baseOptions?: Apollo.MutationHookOptions<SimActivateMutation, SimActivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SimActivateMutation, SimActivateMutationVariables>(SimActivateDocument, options);
      }
export type SimActivateMutationHookResult = ReturnType<typeof useSimActivateMutation>;
export type SimActivateMutationResult = Apollo.MutationResult<SimActivateMutation>;
export type SimActivateMutationOptions = Apollo.BaseMutationOptions<SimActivateMutation, SimActivateMutationVariables>;