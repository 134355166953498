import { getBusinessHours } from '@smart/components-adb/calendar/config';
import { useMarketContext } from 'contexts/market-context';
import { isWithinInterval } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { AnyObject, DateSchema, date, ref } from 'yup';

type CalenderAppointmentDateTimeSchema = {
  startDate: DateSchema<Date | undefined, AnyObject, undefined, ''>;
  endDate: DateSchema<Date | undefined, AnyObject, undefined, ''>;
};

export const CalendarAppointmentDateTime =
  (): CalenderAppointmentDateTimeSchema => {
    const { t } = useTranslation();
    const { market } = useMarketContext();

    return {
      startDate: date()
        .required(
          `${t('feature_calendar.form_fields.start_date')} ${t(
            'general.error_messages.validations.is_required'
          )}`
        )
        .test(
          'within-business-hours',
          t('general.error_messages.within_business_hours'),
          (dateTime) => {
            const businessHours = getBusinessHours(market, dateTime);

            return isWithinInterval(dateTime, {
              start: businessHours.min,
              end: businessHours.max,
            });
          }
        ),
      endDate: date()
        .required(
          `${t('feature_calendar.form_fields.end_date')} ${t(
            'general.error_messages.validations.is_required'
          )}`
        )
        .min(ref('startDate'), t('general.error_messages.before_start_time'))
        .test(
          'within-business-hours',
          t('general.error_messages.within_business_hours'),
          (dateTime) => {
            const businessHours = getBusinessHours(market, dateTime);

            return isWithinInterval(dateTime, {
              start: businessHours.min,
              end: businessHours.max,
            });
          }
        ),
    };
  };
