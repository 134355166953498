import { Address } from '@smart/adb-shared';
import { ProductType } from '../product/product.model';

import { CarLine, CarModel } from '../car/car.model';

export enum OrderStatus {
  CREATED = 'created',
  CONFIRMED = 'confirmed',
  HANDED_OVER = 'handed_over',
  CANCELLING = 'cancelling',
  CANCELLED = 'cancelled',
  PARTIAL_CANCELLED = 'partial_cancelled',
}

/**
 * @interface OrderSummary
 * Order history information
 */
export interface OrderSummary {
  code: string;
  orderId?: string;
  customerName: string;
  deliveryDate: string;
  image: string;
  paymentType?: string;
  productName: CarModel | string;
  requestedDeliveryDate?: string;
  beautifiedDeliveryStatus?: string;
  paymentStatus?: OrderPaymentStatus;
  status: OrderStatus;
  deliveryStatus?: string;
  deliveryLocation?: string;
  vin?: string;
  lineCode?: CarLine | string;
}

/**
 * @interface OrderItem
 * details specific to order items
 */
interface OrderItem {
  handover: {
    licensePlate?: string;
    registrationDate?: string;
  };
  payment: {
    leasingId?: string;
    leasingRate?: string;
    mileage?: number;
    mileageUnit?: string;
    paymentType?: string;
    period?: number;
  };
  features?: OrderCategory[];
  productType?: ProductType;
  productName?: string;
}

/**
 * @interface OrderDetails
 * Order Details information
 */
interface OrderDetails {
  createdDate?: string;
  currentLocation?: string;
  customer?: {
    email: string;
    gigyaId: string;
    idType: string;
    name: string;
    location?: string;
    idCountry?: string;
    phone?: string;
    customerType?: string;
    idNumber?: string;
    taxCode?: string;
  };
  deliveryAddress?: Address;
  items?: OrderItem[];
  orderId?: string;
  orderPreferences?: OrderPreference[];
  paymentStatus?: OrderPaymentStatus;
  paymentType?: string;
  source?: string;
  additionalCosts?: string;
  accessoryPrice?: string;
  vehiclePrice?: string;
  totalPrice?: string;
  totalPriceWithTax?: string;
  requestedDeliveryDate?: string;
}

/**
 * @interface Order
 * complete Order Details information
 */
export interface Order extends OrderSummary, OrderDetails {}

/**
 * @interface OrderPaymentStatus
 * Main API response for the payment status values
 */
export enum OrderPaymentStatus {
  PAID = 'PAID',
  NOTPAID = 'NOTPAID',
}

/**
 * @interface BeautifiedDeliveryStatus
 * Main API response for the beautified delivery status values
 */
export enum BeautifiedDeliveryStatus {
  CONFIRMED = 'Confirmed',
  IN_DELIVERY = 'In delivery',
  FINAL_PREPARATION_FOR_HANDOVER = 'Final preparation for handover',
  HANDED_OVER = 'HANDED OVER',
}

/**
 * @interface CurrentLocation
 * Main API response for the current location values
 */
export enum CurrentLocation {
  CHINA_PORT = 'China PORT',
  ZEEBRUGGE_PORT = 'Zeebrugge PORT/VPC',
  IN_TRANSIT_CN_TO_EU = 'In Transit CN Port to EU port',
  IN_TRANSIT_VPC_TO_VLC = 'In Transit VPC to VLC',
  IN_TRANSIT_VPC_VLC_TO_AGENT = 'In Transit VPC/VLC to Agent',
  COUNTRY_VLC = 'Country VLC',
  AGENT = 'Agent',
}

/**
 * @interface OrderCategory
 * Main API response for OrderCategory data
 */
interface OrderCategory {
  categoryType?: OrderCategoryType;
  code: string;
  name: string;
}

/**
 * @interface OrderCategoryType
 * Main API response for the Categories type information
 */
enum OrderCategoryType {
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  BATTERY = 'BATTERY',
  ROOF = 'ROOF',
  MODEL = 'MODEL',
}

/**
 * @interface OrderPreferenceType
 * Main API response for the OrderPreference type information
 */
enum OrderPreferenceType {
  TRADEIN = 'tradeIn',
  HOMEDELIVERY = 'Homedelivery',
  WINTERWHEELS = 'winterWheels',
  TRAILERHITCH = 'trailerHitch',
}

/**
 * @interface OrderPreference
 * Main API response for the Order Preference information
 */
interface OrderPreference {
  id: OrderPreferenceType;
  option: boolean;
  title?: string;
}
