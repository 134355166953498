import { CalendarContextBaseState } from './types';

export const calendarContextInitialState: CalendarContextBaseState = {
  isLoading: false,
  appointments: [],
  experts: [],
  demoCars: [],
  language: '',
  notification: undefined,
  outlet: undefined,
  isActionLoading: false,
  locale: 'en-US',
  filter: [],
  setFilter: () => {},
};
