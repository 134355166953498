import { MarketCode } from '@utils/market/types';
import { useTranslation } from 'react-i18next';
import { date } from 'yup';

export const RegistrationDateSchema = (
  validationMarket: MarketCode,
  required = false
) => {
  const { t } = useTranslation();
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  switch (validationMarket) {
    default:
      return {
        registrationDate: required
          ? date()
              .max(
                today,
                `${t('general.error_messages.validations.invalid')} ${t(
                  'form_fields.outlet.registration_date'
                )}`
              )
              .required(
                `${t('form_fields.outlet.registration_date')} ${t(
                  'general.error_messages.validations.is_required'
                )}`
              )
          : date(),
      };
  }
};
