import { MarketCode } from '@utils/market/types';
import { useTranslation } from 'react-i18next';
import { string } from 'yup';

export const LicensePlateSchema = (market: MarketCode) => {
  const { t } = useTranslation();
  switch (market) {
    case 'fr':
      return {
        licensePlateNumber: string()
          .required(
            `${t('form_fields.outlet.license_plate')} ${t(
              'general.error_messages.validations.is_required'
            )}`
          )
          .matches(
            /^[A-Z]{2}-[0-9]{3}-[A-Z]{2}$/g,
            `${t('general.error_messages.validations.invalid')} ${t(
              'form_fields.outlet.license_plate'
            )} `
          ),
      };
    default:
      return {
        licensePlateNumber: string().required(
          `${t('form_fields.outlet.license_plate')} ${t(
            'general.error_messages.validations.is_required'
          )}`
        ),
      };
  }
};
