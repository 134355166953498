import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button } from '@smart/react-components';
import CloseTaskModal from './CloseTaskModal';
import { TaskActionProps } from './types';

export const CloseTaskButton = ({
  task,
  label,
  disabled,
}: Omit<TaskActionProps & { label: string }, 'onComplete'>) => {
  const { registerModal, closeModal } = useModal();

  return (
    <Button
      variant="primary"
      onClick={() =>
        registerModal(
          <CloseTaskModal task={task.original} closeModal={closeModal} />
        )
      }
      disabled={disabled}
    >
      {label}
    </Button>
  );
};
