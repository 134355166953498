import Account from 'pages/test-drive/booking/account';
import CarSelection from 'pages/test-drive/booking/car-selection';
import TestDriveSuccess from 'pages/test-drive/booking/confirmation/success';
import TestDriveSummary from 'pages/test-drive/booking/confirmation/summary';
import CarTimeSelection from 'pages/test-drive/booking/time';
import { Routes } from 'routes.config';
import TestDrive from '.';
import CreateLightUser from './booking/account/create-light-user';
import UserDetails from './booking/account/user-details';

export const testDrive: Routes[] = [
  {
    path: '/test-drive',
    component: TestDrive,
    content: 'navigation.test_drive.car',
    title: 'navigation.test_drive.car',
  },
  {
    path: '/test-drive/car-selection',
    component: CarSelection,
    content: 'navigation.test_drive.car',
    title: 'navigation.test_drive.car',
  },
  {
    path: '/test-drive/time/car-time-selection',
    component: CarTimeSelection,
    content: 'navigation.test_drive.time',
    title: 'navigation.test_drive.time',
  },
  {
    path: '/test-drive/confirmation/user-details',
    component: Account,
    content: 'navigation.test_drive.account',
    title: 'navigation.test_drive.account',
  },
  {
    path: `/test-drive/confirmation/user-details/new-customer`,
    component: UserDetails,
    content: 'navigation.dashboard.customers',
    title: 'navigation.dashboard.customers',
  },
  {
    path: `/test-drive/confirmation/user-details/new-light-customer`,
    component: CreateLightUser,
    content: 'navigation.dashboard.customers',
    title: 'navigation.dashboard.customers',
  },
  {
    path: `/test-drive/confirmation/:customerId/user-details/:sfCustomerId/:bpid?/:sfOrgId?`,
    component: UserDetails,
    content: 'navigation.dashboard.customers',
    title: 'navigation.dashboard.customers',
  },
  {
    path: '/test-drive/confirmation/summary/:customerId/:sfCustomerId/:bpid?/:sfOrgId?',
    component: TestDriveSummary,
    content: 'navigation.test_drive.summary',
    title: 'navigation.test_drive.summary',
  },
  {
    path: '/test-drive/confirmation/summary',
    component: TestDriveSummary,
    content: 'navigation.test_drive.summary',
    title: 'navigation.test_drive.summary',
  },
  {
    path: '/test-drive/confirmation/success/:bpid?/:sfOrgId?',
    component: TestDriveSuccess,
    content: 'navigation.test_drive.summary',
    title: 'navigation.test_drive.summary',
  },
];
