import { AssetType, CarStatus } from '@smart/adb-shared';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Text } from '@smart/react-components';
import { useNotificationContext } from 'contexts/notification-context';
import { CarsDocument } from 'graphql/queries/cars.generated';
import { useCurrentOutlet } from 'hooks/outlet';
import { useTranslation } from 'react-i18next';
import type { DemoCarsAvailabilityDialogProps } from './AvailabilityDialog.config';
import { useUpdatePurchasedProductMutation } from './queries/updateDemoCar.generated';

const DemoCarsAvailabilityDialog = ({
  currentDemoCar,
}: DemoCarsAvailabilityDialogProps) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();

  const outlet = useCurrentOutlet();
  const { addError, addSuccess } = useNotificationContext();

  const [updateProduct, { loading }] = useUpdatePurchasedProductMutation();

  const updateCarStatus = async () => {
    if (!currentDemoCar || !currentDemoCar.status) return;

    await updateProduct({
      variables: {
        input: {
          vin: currentDemoCar.carId,
          licensePlateNumber: currentDemoCar.licensePlateNumber ?? '',
          active: currentDemoCar.status === CarStatus.Inactive,
        },
      },
      refetchQueries: [
        {
          query: CarsDocument,
          variables: {
            input: {
              type: AssetType.DemoVehicle,
              bpid: outlet?.bpId,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        addSuccess({
          label:
            currentDemoCar.status === CarStatus.Active
              ? t('feature_calendar.notification.deactivated_democar_title')
              : t('feature_calendar.notification.activated_democar_title'),
          message:
            currentDemoCar.status === CarStatus.Active
              ? t(
                  'feature_calendar.notification.deactivated_democar_description'
                )
              : t(
                  'feature_calendar.notification.activated_democar_description'
                ),
        });
      },
      onError: () => {
        addError({
          label: t('feature_calendar.notification.error_title'),
          message: t('feature_calendar.notification.error_description'),
        });
      },
    });

    closeModal();
  };

  /**
   * @returns DemoCarsAvailabilityDialog
   * ----------------------------------------
   */
  return (
    <AdbDialog
      id="demo-car-availability-dialog"
      buttons={{
        primary: {
          label: t('feature_calendar.general.buttons.confirm'),
          onClick: updateCarStatus,
          isLoading: loading,
        },
        secondary: {
          label: t('feature_calendar.general.buttons.close'),
          onClick: closeModal,
        },
      }}
    >
      <AdbDialog.Header>
        <Text variant="hl-100" as="h1">
          {currentDemoCar?.status === CarStatus.Active
            ? t('feature_calendar.calendar_dialog.deactivation')
            : t('feature_calendar.calendar_dialog.activation')}
        </Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <Text variant="cap-100" as="p">
          {currentDemoCar?.status === CarStatus.Active
            ? t('feature_calendar.calendar_dialog.confirm_deactivation_heading')
            : t('feature_calendar.calendar_dialog.confirm_activation_heading')}
        </Text>
      </AdbDialog.Content>
    </AdbDialog>
  );
};

export default DemoCarsAvailabilityDialog;
