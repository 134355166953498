import { CustomerBusiness, CustomerPrivate } from '@smart/adb-shared';
import {
  BusinessCustomerFormValues,
  PrivateCustomerFormValues,
} from 'pages/customer/details/helper';

/**
 * function isCustomerFormDataValid
 * @param customer
 * @returns boolean depending on the validity of the new form
 */
export const isCustomerFormDataValid = (
  customer: PrivateCustomerFormValues | BusinessCustomerFormValues
): boolean => {
  const isCommonInfoValid =
    customer &&
    customer.firstName &&
    customer.lastName &&
    customer.postalCode &&
    customer.mobileNumber &&
    customer.street &&
    customer.titleCode &&
    customer.town &&
    customer.userId;

  if (customer.__typename === 'CustomerBusiness') {
    return !!(
      isCommonInfoValid &&
      customer.companyName &&
      customer.vatRegistrationNumber
    );
  }

  return !!isCommonInfoValid;
};

/**
 * function isCustomerEditFormDataValid
 * @param customer
 * @returns boolean depending on the validity of the edit form
 */
export const isCustomerEditFormDataValid = (
  customer: CustomerPrivate | CustomerBusiness
): boolean => {
  const isCommonInfoValid =
    customer &&
    customer.userId &&
    customer.firstName &&
    customer.lastName &&
    customer.mobileNumber &&
    customer.titleCode &&
    customer.address?.postalCode &&
    customer.address?.street &&
    customer.address?.town;

  if (customer.__typename === 'CustomerBusiness') {
    return !!(
      isCommonInfoValid &&
      customer.companyName &&
      customer.vatRegistrationNumber
    );
  }

  return !!isCommonInfoValid;
};

/**
 * function mapCustomerAddressValues
 * @param formValues
 * @returns address in the format required to set the customer private/business data to state
 */

export const mapCustomerAddressValues = (
  formValues: PrivateCustomerFormValues | BusinessCustomerFormValues
): CustomerPrivate | CustomerBusiness => ({
  ...formValues,
  address: {
    countryCode: formValues?.countryCode,
    postalCode: formValues?.postalCode,
    regionCode: formValues?.regionCode,
    street: formValues?.street,
    town: formValues?.town,
  },
});
