import { CarStatus, ExtendedCar } from '@smart/adb-shared';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button, Flex, IconButton, Text } from '@smart/react-components';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import DemoCarsAvailabilityDialog from '../../AvailabilityDialog/AvailabilityDialog';
import './KebabMenu.scss';

const BASE_CLASS = 'adb-kebab-menu';

interface KebabMenuProps {
  demoCar: ExtendedCar;
  setIsDrawerOpen?: Dispatch<SetStateAction<boolean>>;
}

const KebabMenu = ({ demoCar, setIsDrawerOpen }: KebabMenuProps) => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const { registerModal } = useModal();

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.className === `${BASE_CLASS}__background`) {
      setOpen(false);
    }
  };

  const onClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const carActive = demoCar.status === CarStatus.Active;

  return (
    <div className={`${BASE_CLASS}__wrapper`} ref={ref}>
      <IconButton variant="ghost" mode={200} onClick={onClick}>
        <Button.Icon icon="kebab-menu-more" />
      </IconButton>
      {open &&
        createPortal(
          <div className={`${BASE_CLASS}__background`}>
            <div
              className={`${BASE_CLASS}__content`}
              style={{
                top:
                  ref.current!.getBoundingClientRect().bottom + window.scrollY,
                left:
                  ref.current!.getBoundingClientRect().right -
                  198 +
                  window.scrollX,
              }}
            >
              <Flex direction="column" padding={200} rowGap={300}>
                <Flex direction="column">
                  <Text>{demoCar.name}</Text>
                  <Text>{demoCar.exterior}</Text>
                  <Text>{demoCar.interior}</Text>
                </Flex>
                <div>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setOpen(false);
                      if (setIsDrawerOpen) {
                        setIsDrawerOpen(false);
                      }
                      registerModal(
                        <DemoCarsAvailabilityDialog currentDemoCar={demoCar} />
                      );
                    }}
                  >
                    <Button.Icon icon={carActive ? 'alert' : 'status-good'} />
                    {carActive
                      ? `${t('feature_calendar.general.buttons.deactivate')}`
                      : `${t('feature_calendar.general.buttons.activate')}`}
                  </Button>
                </div>
              </Flex>
            </div>
          </div>,
          document.querySelector('#root')!
        )}
    </div>
  );
};

export default KebabMenu;
