import '@utils/datadog-rum';
import '@utils/i18n';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from 'reportWebVitals';
import 'utils/axios-interceptors';
import App from './app';
import LoadingIndicator from './components/atoms/LoadingIndicator/LoadingIndicator';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const root = createRoot(MOUNT_NODE);

root.render(
  <HelmetProvider>
    <React.StrictMode>
      <Suspense fallback={<LoadingIndicator onFullPage />}>
        <App />
      </Suspense>
    </React.StrictMode>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
