import { Outlet } from '@smart/adb-shared';
import { AgentContext, useAgentContext } from 'contexts/agent-context';
import { useOutletQuery } from 'graphql/queries/outlet.generated';
import React from 'react';

export const useCurrentOutlet = (): Outlet | undefined => {
  const agentCtx = React.useContext(AgentContext);
  const agent = agentCtx?.agent;
  const { data } = useOutletQuery({
    variables: {
      bpId: agent?.outletId ?? '',
    },
    skip: !agent?.outletId,
  });
  return data?.outlet;
};

export const useCurrentOutletId = ():
  | { bpId: string; mcsId: string }
  | undefined => {
  const { agent } = useAgentContext();

  let localMcsId;
  if (agent?.outletId) localMcsId = localStorage.getItem(agent.outletId);

  const { data } = useOutletQuery({
    variables: {
      bpId: agent?.outletId ?? '',
    },
    skip: !!localMcsId || !agent?.outletId,
  });

  const mcsId = data?.outlet.mcsId ?? localMcsId;
  if (!agent?.outletId || !mcsId) return undefined;

  if (!localMcsId) {
    localStorage.setItem(agent.outletId, mcsId);
  }

  return {
    bpId: agent.outletId,
    mcsId,
  };
};
