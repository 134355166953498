/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StocksQueryVariables = Types.Exact<{
  input: Types.StockInput;
}>;


export type StocksQuery = { __typename?: 'Query', stock: { __typename?: 'StockResponse', facets: Array<{ __typename?: 'Facet', code?: string | null, name?: string | null, values?: Array<{ __typename?: 'FacetValue', code?: string | null, displayName?: string | null, initialQueryValue?: string | null, name?: string | null } | null> | null }>, pagination?: { __typename?: 'StockPagination', totalPages?: number | null } | null, cars: Array<{ __typename?: 'StockCar', id: string, image: string, name: string, features?: { __typename?: 'CarFeatures', battery?: string | null, driveline: string, exterior: string, interior: string, range?: string | null, roof?: string | null, yearEdition: string } | null, status?: { __typename?: 'StockStatus', earliestBeautifiedLeadTime?: string | null, stockLevelStatus?: string | null } | null, price?: { __typename?: 'ProductPrice', formattedValue?: string | null } | null, leasingPrice?: { __typename?: 'ProductPrice', formattedValue?: string | null } | null }> } };


export const StocksDocument = gql`
    query Stocks($input: StockInput!) {
  stock(input: $input) {
    facets {
      code
      name
      values {
        code
        displayName
        initialQueryValue
        name
      }
    }
    pagination {
      totalPages
    }
    cars {
      features {
        battery
        driveline
        exterior
        interior
        range
        roof
        yearEdition
      }
      id
      image
      name
      status {
        earliestBeautifiedLeadTime
        stockLevelStatus
      }
      price {
        formattedValue
      }
      leasingPrice {
        formattedValue
      }
    }
  }
}
    `;

/**
 * __useStocksQuery__
 *
 * To run a query within a React component, call `useStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStocksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStocksQuery(baseOptions: Apollo.QueryHookOptions<StocksQuery, StocksQueryVariables> & ({ variables: StocksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StocksQuery, StocksQueryVariables>(StocksDocument, options);
      }
export function useStocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StocksQuery, StocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StocksQuery, StocksQueryVariables>(StocksDocument, options);
        }
export function useStocksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StocksQuery, StocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StocksQuery, StocksQueryVariables>(StocksDocument, options);
        }
export type StocksQueryHookResult = ReturnType<typeof useStocksQuery>;
export type StocksLazyQueryHookResult = ReturnType<typeof useStocksLazyQuery>;
export type StocksSuspenseQueryHookResult = ReturnType<typeof useStocksSuspenseQuery>;
export type StocksQueryResult = Apollo.QueryResult<StocksQuery, StocksQueryVariables>;