import { MarketCode } from './market/types';

export const getCurrencyByMarket = (market: MarketCode) => {
  switch (market) {
    case 'se':
      return 'SEK';
    case 'gb':
      return 'GBP';
    case 'ch':
      return 'CHF';
    default:
      return 'EUR';
  }
};
