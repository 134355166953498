import {
  AdbSchemaProps,
  getFieldLabel,
} from '@components/adb-required-label/helper';
import { Select } from '@smart/react-components';
import type { SelectOptionProps } from 'features/features.config';
import { useTranslation } from 'react-i18next';
import { usePreferredLanguagesQuery } from './queries.generated';

type PreferredLanguageSelectOptionProps = SelectOptionProps & AdbSchemaProps;

const PreferredLanguageSelect = ({
  value,
  handleChange,
  disabled,
  schema,
}: PreferredLanguageSelectOptionProps) => {
  const { t } = useTranslation();
  const { data } = usePreferredLanguagesQuery();

  const selectedValue =
    value.length === 0 && data?.preferredLanguages
      ? data.preferredLanguages[0].value
      : value;

  return (
    <Select
      id="language"
      name="preferredLanguage"
      label={getFieldLabel(
        schema,
        'regionCode',
        t('form_fields.communication.preferred_language_communication')
      )}
      value={selectedValue}
      onSelectionChange={handleChange}
      items={data?.preferredLanguages ?? []}
      disabled={disabled}
    >
      {(items) =>
        items.map((item) => <Select.Item key={item.value} {...item} />)
      }
    </Select>
  );
};

export default PreferredLanguageSelect;
