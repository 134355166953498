import { IdType } from './config';

export const getFrontFileNameByIdType = (idType: IdType): string => {
  switch (idType) {
    case 'foreign-id':
      return 'foreign-id';
    case 'german-id':
      return 'front-of-german-id-card';
    case 'passport':
      return 'passport';
    default:
      throw new Error(`No document type mapped for idType ${idType}`);
  }
};

export const getBackFileNameByIdType = (idType: IdType): string => {
  switch (idType) {
    case 'foreign-id':
    case 'passport':
      return 'proof-of-address';
    case 'german-id':
      return 'back-of-german-id-card';
    default:
      throw new Error(`No document type mapped for idType ${idType}`);
  }
};

export const getFrontFileCaptionByIdType = (idType: IdType): string => {
  switch (idType) {
    case 'foreign-id':
      return 'task.pos_ident.form.foreign_passport_front_upload';
    case 'passport':
      return 'task.pos_ident.form.german_passport_front_upload';
    case 'german-id':
      return 'task.pos_ident.form.id_front_upload';
    default:
      return 'task.pos_ident.form.id_front_upload';
  }
};

export const getBackFileCaptionByIdType = (idType: IdType): string => {
  switch (idType) {
    case 'foreign-id':
      return 'task.pos_ident.form.foreign_passport_back_upload';
    case 'passport':
      return 'task.pos_ident.form.german_passport_back_upload';
    case 'german-id':
      return 'task.pos_ident.form.id_back_upload';
    default:
      return 'task.pos_ident.form.id_back_upload';
  }
};
