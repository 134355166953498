/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CarsQueryVariables = Types.Exact<{
  input: Types.CarsInput;
  filter?: Types.InputMaybe<Types.CarFilter>;
}>;


export type CarsQuery = { __typename?: 'Query', getCars: { __typename?: 'CarResponse', products: Array<{ __typename?: 'ExtendedCar', id: string, status: Types.CarStatus, isInfleeted: boolean, orderNumber?: string | null, type: Types.AssetType, vin: string, carId: string, image: string, countryOfRegistration?: string | null, exterior?: string | null, holdingPeriod?: string | null, installDate?: string | null, interior?: string | null, licensePlateNumber?: string | null, line?: string | null, modelName?: string | null, name?: string | null, purchaseDate?: string | null, purchaseType?: Types.PurchaseType | null, registrationDate?: string | null, preRegistrationDate?: string | null, transmission?: string | null, year?: string | null, features?: { __typename?: 'ExtendedCarFeatures', exterior: string, interior?: string | null, steeringWheelPosition?: string | null, transmission?: string | null } | null }> } };


export const CarsDocument = gql`
    query Cars($input: CarsInput!, $filter: CarFilter) {
  getCars(input: $input, filter: $filter) {
    products {
      id
      status
      isInfleeted
      orderNumber
      type
      vin
      carId
      image
      countryOfRegistration
      exterior
      holdingPeriod
      installDate
      interior
      licensePlateNumber
      line
      modelName
      name
      purchaseDate
      purchaseType
      registrationDate
      preRegistrationDate
      transmission
      year
      features {
        exterior
        interior
        steeringWheelPosition
        transmission
      }
    }
  }
}
    `;

/**
 * __useCarsQuery__
 *
 * To run a query within a React component, call `useCarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCarsQuery(baseOptions: Apollo.QueryHookOptions<CarsQuery, CarsQueryVariables> & ({ variables: CarsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarsQuery, CarsQueryVariables>(CarsDocument, options);
      }
export function useCarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarsQuery, CarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarsQuery, CarsQueryVariables>(CarsDocument, options);
        }
export function useCarsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CarsQuery, CarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CarsQuery, CarsQueryVariables>(CarsDocument, options);
        }
export type CarsQueryHookResult = ReturnType<typeof useCarsQuery>;
export type CarsLazyQueryHookResult = ReturnType<typeof useCarsLazyQuery>;
export type CarsSuspenseQueryHookResult = ReturnType<typeof useCarsSuspenseQuery>;
export type CarsQueryResult = Apollo.QueryResult<CarsQuery, CarsQueryVariables>;