import { MileageUnit as MileageUnitGql } from '@smart/adb-shared';
import { AdbTextInput } from '@smart/components-adb/adb-required-label/AdbTextInput';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import { Button } from '@smart/react-components';
import { useNotificationContext } from 'contexts/notification-context';
import { Formik } from 'formik';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { OrderLeasingDetailsSchema } from './OrderLeasingDetailsSchema';
import { useAddOrderLeasingDetailsMutation } from './queries.generated';

import './orderLeasingDetailsForm.scss';

const BASE_CLASS = 'adb-order-leasing-details-form';

enum FieldId {
  LeasingId = 'leasingId',
  LeasingRate = 'leasingRate',
  Mileage = 'mileage',
  MileageUnit = 'mileageUnit',
  CurrencyIso = 'currencyIso',
  Period = 'period',
}

type FormValues = {
  [FieldId.LeasingId]: string;
  [FieldId.LeasingRate]: number;
  [FieldId.Mileage]: number;
  [FieldId.MileageUnit]: MileageUnitGql;
  [FieldId.CurrencyIso]: string;
  [FieldId.Period]: number;
};

interface OrderLeasingDetailsFormInput {
  initialValues: FormValues;
  orderId: string;
}

const getFormFields = (
  t: TFunction
): {
  id: FieldId;
  name: FieldId;
  label: string;
}[] => [
  {
    id: FieldId.LeasingId,
    name: FieldId.LeasingId,
    label: t('orders.payment_details.contract_id'),
  },
  {
    id: FieldId.LeasingRate,
    name: FieldId.LeasingRate,
    label: t('orders.payment_details.leasing_rate'),
  },
  {
    id: FieldId.Mileage,
    name: FieldId.Mileage,
    label: t('orders.payment_details.mileage'),
  },
  {
    id: FieldId.Period,
    name: FieldId.Period,
    label: t('orders.payment_details.period'),
  },
];

const OrderLeasingDetailsForm = ({
  initialValues,
  orderId,
}: OrderLeasingDetailsFormInput) => {
  const { t } = useTranslation();

  const [addOrderLeasingDetails, { loading }] =
    useAddOrderLeasingDetailsMutation();

  const { addSuccess } = useNotificationContext();

  const formFields = getFormFields(t);

  const onSubmit = async (formValues: FormValues) => {
    try {
      await addOrderLeasingDetails({
        variables: {
          input: {
            ...formValues,
            leasingRate: Number(formValues.leasingRate),
            mileage: Number(formValues.mileage),
            period: Number(formValues.period),
            currencyIso: initialValues.currencyIso || 'EUR',
            orderId,
          },
        },
      });

      addSuccess({
        label: t('orders.order_details.leasing_details.form_success_title'),
        message: t(
          'orders.order_details.leasing_details.form_success_description'
        ),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const validationSchema = OrderLeasingDetailsSchema();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        const submitDisabled = Boolean(!dirty);

        return (
          <form autoComplete="on" onSubmit={handleSubmit}>
            <div className={`${BASE_CLASS}__form`}>
              {formFields.map((f) => (
                <div className={`${BASE_CLASS}__form-input`} key={f.id}>
                  <AdbTextInput
                    {...f}
                    type="text"
                    value={values[f.id]}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    schema={validationSchema}
                  />
                  {errors.leasingId && touched.leasingId && (
                    <ErrorField errorMsg={errors.leasingId} />
                  )}
                </div>
              ))}
              <div className={`${BASE_CLASS}__form-actions`}>
                <Button
                  variant="primary"
                  type="submit"
                  mode={200}
                  disabled={submitDisabled}
                  loading={loading}
                >
                  <Button.Spinner />
                  {t('customer.details.buttons.save')}
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export { OrderLeasingDetailsForm };
