import { MarketCode } from '@utils/market/types';
import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { GetMaxLengthValidationMsg } from '../helpers/utils';

export const PositionSchema = (
  validationMarket: MarketCode,
  required = false
) => {
  const { t } = useTranslation();

  const positionRegex = /^[A-Za-z.]+$/g;

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'de':
        schemaObject = {
          position: required
            ? string()
                .required(
                  `${t('form_fields.primary_contact_person.position')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  positionRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.primary_contact_person.position'
                  )} `
                )
                .max(
                  100,
                  GetMaxLengthValidationMsg(
                    t('form_fields.primary_contact_person.position'),
                    100
                  )
                )
            : string()
                .matches(
                  positionRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.primary_contact_person.position'
                  )} `
                )
                .max(
                  100,
                  GetMaxLengthValidationMsg(
                    t('form_fields.primary_contact_person.position'),
                    100
                  )
                ),
        };
        break;
      default:
        schemaObject = {
          position: required
            ? string()
                .required(
                  `${t('form_fields.primary_contact_person.position')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  positionRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.primary_contact_person.position'
                  )} `
                )
                .max(
                  100,
                  GetMaxLengthValidationMsg(
                    t('form_fields.primary_contact_person.position'),
                    100
                  )
                )
            : string()
                .matches(
                  positionRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.primary_contact_person.position'
                  )} `
                )
                .max(
                  100,
                  GetMaxLengthValidationMsg(
                    t('form_fields.primary_contact_person.position'),
                    100
                  )
                ),
        };
        break;
    }
  }

  return schemaObject;
};
