import {
  ContractType,
  Document,
  DocumentStatus,
  DocumentType,
  Maybe,
  Task,
  TaskCustomer,
  TaskStatus,
} from '@smart/adb-shared';
import CreateDocumentType from '@smart/components-adb/documents/CreateDocumentType/CreateDocumentType';
import { useDocumentsContext } from '@smart/components-adb/documents/documents-context';
import { Signer } from '@smart/components-adb/documents/SignDocument/DMP/EmbeddedSigning';
import { updateDocumentInGqlCache } from '@smart/components-adb/documents/SignDocument/DMP/gqlCache';
import SignDocument from '@smart/components-adb/documents/SignDocument/SignDocument';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button, ButtonProps } from '@smart/react-components';
import { AgentContextProvider } from 'contexts/agent-context';
import { useCustomerByIdQuery } from 'graphql/queries/customer.generated';
import { TFunction } from 'i18next';
import { Suspense, lazy, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SearchDocumentsDocument,
  useAppointmentQuery,
  useSearchDocumentsQuery,
} from '../queries.generated';

const ViewDocument = lazy(
  () => import('@smart/components-adb/documents/ViewDocument/ViewDocument')
);

const getLatestHandoverDocument = (documents?: Document[]) => {
  if (!documents) return undefined;
  const filteredDocuments = [...documents]
    .sort(
      (a, b) =>
        new Date(b.dateModified).getTime() - new Date(a.dateModified).getTime()
    )
    .filter((document) =>
      [
        DocumentType.HANDOVER_CONTRACT,
        DocumentType.HANDOVER_DOCUMENT_CASH_BUY,
        DocumentType.HANDOVER_DOCUMENT_ALD,
      ].includes(document.type as DocumentType)
    );

  // These series of if statements is here to make sure that we priotize the document furthest
  // into the handover process. So even if the latest document has an ERROR we'll use an older
  // document with a status of SIGNED.
  let selectedDocument = filteredDocuments.find(
    (document) => document.status === DocumentStatus.SIGNED
  );
  if (!selectedDocument) {
    selectedDocument = filteredDocuments.find(
      (document) => document.status === DocumentStatus.SIGN_IN_PROGRESS
    );
  }
  if (!selectedDocument) {
    selectedDocument = filteredDocuments.find(
      (document) => document.status === DocumentStatus.SUCCESS
    );
  }
  if (!selectedDocument) {
    selectedDocument = filteredDocuments.find(
      (document) => document.status === DocumentStatus.ERROR
    );
  }
  return selectedDocument;
};

const getActionButton = (
  t: TFunction<'translation', undefined>,
  status?: Maybe<DocumentStatus | undefined>
) => {
  if (!status) {
    return {
      label: t('task.handover.create_documents'),
      props: {},
    };
  }

  const documentActions: Record<DocumentStatus, string | any> = {
    [DocumentStatus.ERROR]: {
      label: t('customer.documents.create.load_document_error'),
      props: {
        iconName: 'warning--small',
        iconPosition: 'left',
        disabled: true,
      },
    },
    [DocumentStatus.SIGN_IN_PROGRESS]: {
      label: t('task.handover.sign_documents'),
      props: {},
    },
    [DocumentStatus.SUCCESS]: {
      label: t('task.handover.sign_documents'),
      props: {},
    },
    [DocumentStatus.STARTED]: undefined,
    [DocumentStatus.MULTIPLE_SIGNATURES]: {
      label: t('task.handover.view_documents'),
    },
    [DocumentStatus.SIGNED]: {
      label: t('task.handover.view_documents'),
    },
  };

  return documentActions[status];
};

export const DocumentButton = ({
  task,
  orderNumber,
  customer,
}: {
  task: Task;
  orderNumber: string;
  customer: TaskCustomer;
}) => {
  const { t } = useTranslation();
  const { registerModal, closeModal } = useModal();
  const { source } = useDocumentsContext();
  const appointmentWithIdExists = useMemo(
    () => !!task.appointment?.id,
    [task.appointment?.id]
  );

  const {
    data: appointmentData,
    loading: appointmentLoading,
    refetch: appointmentRefetch,
  } = useAppointmentQuery({
    variables: { input: { id: task.appointment?.id ?? '' } },
    skip: !appointmentWithIdExists,
    fetchPolicy: 'network-only',
  });

  const appointment = appointmentData?.appointment;

  useEffect(() => {
    if (appointmentWithIdExists) appointmentRefetch();
  }, [appointmentWithIdExists, appointmentRefetch]);

  const {
    data: customerData,
    loading: customerIsLoading,
    error: customerError,
  } = useCustomerByIdQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        customerId: customer.uuid ?? '',
        sfCustomerId: customer.sfCustomerId ?? '',
        ...(customer.__typename === 'TaskBusinessCustomer' && {
          sfOrgId: customer.sfOrgId ?? '',
        }),
      },
    },
    skip: !customer.uuid || !customer.sfCustomerId,
  });

  const searchInput = {
    customerId: customer.uuid,
    transactionId: appointment?.id,
    docSystem: source,
  };

  const {
    data,
    loading: documentIsLoading,
    error: documentsError,
    refetch,
  } = useSearchDocumentsQuery({
    variables: { input: searchInput },
    skip: !appointment?.id || !customer.uuid,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const documents = data?.documentSearch.documents;
  const document = getLatestHandoverDocument(documents);
  const signer: Signer = {
    signerEmail: customer?.userId ?? '',
    signerName: `${customer?.firstName} ${customer?.lastName}`,
  };

  const documentError =
    documentsError ||
    document?.status === DocumentStatus.ERROR ||
    customerError;

  // eslint-disable-next-line no-nested-ternary
  const documentStatus = documentError
    ? DocumentStatus.ERROR
    : (document?.status as DocumentStatus);

  const isLoading =
    documentIsLoading || customerIsLoading || appointmentLoading;
  return (
    <Button
      disabled={task.status !== TaskStatus.Completed && !task.isAgentAssigned}
      {...(getActionButton(t, documentStatus).props as ButtonProps)}
      {...(!document &&
        source && {
          onClick: () => {
            if (!customerData) return;
            registerModal(
              <CreateDocumentType
                docSystem={source}
                appointments={appointment ? [appointment] : []}
                selectedCustomerById={customerData.customerById}
                typeOfContract={ContractType.HANDOVER_CONTRACT}
                orderNumber={orderNumber}
                onCompleted={refetch}
              />
            );
          },
          loading: isLoading,
          disabled: !appointmentWithIdExists,
        })}
      {...(document &&
        source &&
        (documentStatus === DocumentStatus.SUCCESS ||
          documentStatus === DocumentStatus.SIGN_IN_PROGRESS) && {
          onClick: () => {
            registerModal(
              <Suspense>
                <SignDocument
                  signer={signer}
                  document={document}
                  onDMPSignCompleted={() => {
                    updateDocumentInGqlCache({
                      query: SearchDocumentsDocument,
                      documentId: document.id ?? '',
                      input: searchInput,
                      document: { status: DocumentStatus.SIGNED },
                    });
                  }}
                  onClose={() => {
                    closeModal();
                  }}
                  docSystem={source}
                />
              </Suspense>
            );
          },
          loading: isLoading,
        })}
      {...(document &&
        document.status === DocumentStatus.SIGNED && {
          onClick: async () => {
            registerModal(
              <Suspense>
                <AgentContextProvider>
                  <ViewDocument
                    document={document}
                    customerEmail={customer.userId}
                    docSystem={source}
                  />
                </AgentContextProvider>
              </Suspense>
            );
          },
        })}
      variant="primary"
    >
      {getActionButton(t, documentStatus).label}
    </Button>
  );
};
