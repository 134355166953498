import { ExtendedCar } from '@smart/adb-shared';
import { PropsWithChildren } from 'react';

export interface CarCardProps extends PropsWithChildren {
  car: ExtendedCar;
  headerText: string;
  saveButtonText: string;
  additionalClasses?: string;
  actions?: React.ReactElement;
  uuid?: string;
  disableActivation?: boolean;
  enableAccordion?: boolean;
}

export const CarConfig = {
  vin: 'car.assets.vin',
  exterior: 'car.features.exterior',
  interior: 'car.features.interior',
  transmission: 'car.assets.transmission',
  year: 'car.assets.year',
  purchaseDate: 'car.assets.purchase_date',
  purchaseType: 'car.assets.purchase_type',
  holdingPeriod: 'car.assets.holding_period',
  countryOfRegistration: 'car.assets.country_of_registration',
  installDate: 'car.assets.handover_date',
  preRegistrationDate: 'car.assets.pre_registration_date',
  registrationDate: 'car.assets.registration_date',
  licensePlateNumber: 'car.assets.license_plate',
};

export type CarKeys = string | keyof ExtendedCar | 'contact';
