import { Task, TaskType } from '@smart/adb-shared';
import { Row } from '@tanstack/react-table';
import { HandoverTaskDetails } from '../Handover/HandoverTaskDetails';
import { LeadTaskDetails } from '../Lead/LeadTaskDetails';
import { PosIdentTaskDetails } from '../PosIdent/PosIdentTaskDetails';
import { TestDriveTaskDetails } from '../TestDrive/TestDriveTaskDetails';

const TaskTableDetails = ({ task }: { task: Row<Task> }) => {
  const type = task?.original.type;

  if (type === TaskType.Handover) return <HandoverTaskDetails task={task} />;
  if (type === TaskType.Lead) return <LeadTaskDetails task={task} />;
  if (type === TaskType.TestDrive) return <TestDriveTaskDetails task={task} />;
  if (type === TaskType.PosIdentCheck)
    return <PosIdentTaskDetails task={task} />;

  return null;
};

export default TaskTableDetails;
