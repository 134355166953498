import {
  Address,
  AddressFull,
  Customer,
  CustomerBusiness,
  CustomerData,
  CustomerPrivate,
} from '@smart/adb-shared';
import { SelectItemProps } from '@smart/react-components';
import { MarketCode, MarketCodePhonePrefixMap } from '@utils/market/types';

export type CustomerDetailsState = {
  state?: {
    convertToFullAccount: boolean;
  };
};

export type SharedInfoProps = Omit<CustomerData, 'address'> &
  Omit<Address, '__typename'>;

export type PrivateCustomerFormValues = Omit<CustomerPrivate, 'address'> &
  Omit<Address, '__typename'>;

export type BusinessCustomerFormValues = Omit<CustomerBusiness, 'address'> &
  Omit<Address, '__typename'>;

/**
 * CustomerBasicInfoForm
 * Require this type to flatten the address as expected by Formik flat structure.
 */

export type CustomerBasicForm = Omit<Customer, 'address'> & Address;

/**
 * @type CustomerPrivateNewForm
 * type of the new private customer registration form
 */
export type CustomerPrivateNewForm = Customer;

/**
 * @type CustomerBusinessNewForm
 * type of the new business customer registration form
 */
export type CustomerBusinessNewForm = Omit<
  CustomerBusiness,
  | 'businessAdditionalInfo'
  | 'systemInfo'
  | 'businessCompanyInfo'
  | 'businessPrimaryContactInfo'
  | 'id'
  | 'address'
  | 'consents'
  | 'currentCar'
  | 'purchaseModel'
  | 'getCars'
  | 'orders'
  | 'address'
> &
  Address;

export interface BusinessAdditionalInfoFormValues {
  daimlerFrameworkAgreement: string;
  fleetSize: string;
  amountCars: string;
  frameworkAgreementNumber: string;
}

export interface BusinessPrimaryContactInfoFormValues {
  position: string;
}

export interface BusinessCompanyInfoFormValues {
  legalForm: string;
  commercialRegisterNo: string;
  documentCheck: string;
}

export interface PrivateAdditionalInfoFormValues {
  dateOfBirth: string;
  placeOfBirth: string;
  nationality: string;
  idNumber: string;
  idType: string;
  countryOfId: string;
}

export interface PrivateCommunicationInfoFormValues {
  preferredTime: string;
  communicationPreferences: {
    Phone: boolean;
    Email: boolean;
    SMS: boolean;
    Direct_Mail: boolean;
    Other: boolean;
  };
}

/**
 * @type CustomerEditFormSection
 * type of customer edit form by section
 */
export type CustomerEditFormSection =
  | Omit<Customer, '__typename'>
  | PrivateCommunicationInfoFormValues
  | PrivateAdditionalInfoFormValues
  | BusinessAdditionalInfoFormValues
  | BusinessCompanyInfoFormValues
  | BusinessPrimaryContactInfoFormValues;

/**
 * IFunction is a place holder for functions used by formik
 */
interface IFunction {
  (): void;
}

/**
 * @enum Customer Form types
 */
export enum CustomerFormTypes {
  BASIC_INFO_BUSINESS = 'BasicInfoBusiness',
  BASIC_INFO_PRIVATE = 'BasicInfoPrivate',
  ADDITIONAL_INFO_BUSINESS = 'AddInfoBusiness',
  ADDITIONAL_INFO_PRIVATE = 'AddInfoPrivate',
  COMPANY_INFO_BUSINESS = 'CompanyInfoBusiness',
  CONTACT_INFO_BUSINESS = 'ContactInfoBusiness',
  CONTACT_INFO_PRIVATE = 'ContactInfoPrivate',
  PERSONAL_INFO_PRIVATE = 'PersonalInfoPrivate',
}

/**
 * @type CustomerForm - Used in Customer Details (Edit)
 * returns handler for submit, reset, set draft form data along with footer & layout (accordion) function customer edit forms
 */
export type CustomerForm = {
  handleCustomerDetailSubmit: (
    formValues: CustomerEditFormSection,
    formType: CustomerFormTypes
  ) => void;
  bindSubmitForm?: (submitCallback: IFunction) => void;
  checkDataValidity?: (params: CustomerEditFormSection) => void;
  formFooter: (formType?: CustomerFormTypes, disabled?: boolean) => JSX.Element;
  title?: string;
  isNotAccordion?: boolean;
  radioBtnTab?: boolean;
  hasExpanded?: boolean;
};

/**
 * @type CustomerBasicInfoForm - Used in New Customer Details
 * returns handler for submit, reset, set draft form data along with footer & layout (accordion) function new customer forms
 */
export type CustomerBasicInfoForm = {
  handleCustomerDetailSubmit: (
    formValues: PrivateCustomerFormValues | BusinessCustomerFormValues,
    formType: CustomerFormTypes,
    updated?: boolean
  ) => void;
  bindSubmitForm?: (submitCallback: IFunction) => void;
  checkDataValidity?: (
    params: PrivateCustomerFormValues | BusinessCustomerFormValues
  ) => void;
  formFooter?: (
    formType?: CustomerFormTypes,
    disabled?: boolean
  ) => JSX.Element;
  title?: string;
  isNotAccordion?: boolean;
  radioButtonTab?: boolean;
  hasExpanded?: boolean;
  convertToFullAccount?: boolean;
};

interface MapAddressValidatorDataI
  extends Omit<
    Address,
    'mobileNumber' | 'countryCode' | 'regionCode' | '__typename'
  > {
  regionCode?: string;
}

export const mapAddressValidatorData = (
  addressData: AddressFull,
  regionDataList: SelectItemProps[]
): MapAddressValidatorDataI => {
  const street = [addressData.line1, addressData.line2]
    .filter((line) => line && line !== '')
    .join(', ');

  const regionName = regionDataList.find(
    (option: SelectItemProps) =>
      option.label?.toLowerCase() === addressData.provinceName?.toLowerCase()
  );
  return {
    postalCode: addressData?.postalCode ?? '',
    street: street ?? '',
    town: addressData?.city ?? '',
    ...(regionName && {
      regionCode: regionName.value,
    }),
  };
};

export const handleCustomerDetails = (customer: Record<string, any>) => {
  const getCustomerDetail = (
    detailKey: string,
    defaultValue?: string
  ): string => {
    if (
      customer !== undefined &&
      detailKey in customer &&
      customer[detailKey] &&
      customer[detailKey].length !== 0
    ) {
      return customer[detailKey];
    }
    if (defaultValue) {
      return defaultValue;
    }
    return '';
  };
  return { getCustomerDetail };
};

export const formatPhoneNumber = (
  phoneNumber: string,
  market: MarketCode
): string => {
  if (phoneNumber === '') return '';

  // Remove non-numeric characters and leading zero
  const numericPhoneNumber = phoneNumber
    .replace(/[^0-9]/g, '')
    .replace(/^0+/, '');

  const prefix = MarketCodePhonePrefixMap[market];

  return numericPhoneNumber.startsWith(prefix)
    ? `+${numericPhoneNumber}`
    : `+${prefix}${numericPhoneNumber}`;
};
