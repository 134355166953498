import { EmailSchema } from '@ui/validations/schemas/Email';
import { FirstNameSchema } from '@ui/validations/schemas/FirstName';
import { LastNameSchema } from '@ui/validations/schemas/LastName';
import { MobileNumberSchema } from '@ui/validations/schemas/MobileNumber';
import { MarketCode } from '@utils/market/types';
import { object } from 'yup';

const CreateLightAccountSchema = (market: MarketCode) => {
  const firstName = FirstNameSchema(market, true);
  const lastName = LastNameSchema(market, true);
  const email = EmailSchema(market);
  const mobileNumber = MobileNumberSchema();

  return object().shape({
    ...firstName,
    ...lastName,
    ...email,
    ...mobileNumber,
  });
};

export default CreateLightAccountSchema;
