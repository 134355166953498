import { Button, Text } from '@smart/react-components';
import classNames from 'classnames';
import AdbLink from '../../../atoms/AdbLink/AdbLink';
import {
  ActionItemType,
  ActionsWithTextProps,
  LengthType,
} from '../CarListItem.config';
import './ActionsWithText.scss';

const BASE_CLASS = 'adb-list-actions-with-text';

const ActionsWithText = ({
  actionItems = [],
  text = undefined,
  open = false,
  children,
}: ActionsWithTextProps) => (
  <div
    className={classNames(`${BASE_CLASS}__content`, {
      [`${BASE_CLASS}__content--closed`]: !open,
    })}
  >
    {typeof text === 'string' ? <Text as="p">{text}</Text> : text}

    <div className={`${BASE_CLASS}__actions`}>
      {children && children}
      {actionItems.length > LengthType.ZERO &&
        actionItems.length <= LengthType.TWO &&
        actionItems.map((item) => (
          <div key={item.key}>
            {item.type === ActionItemType.LINK ? (
              <AdbLink title={item.key} />
            ) : (
              <Button mode={200} variant="primary" onClick={item.onClick}>
                {item.key}
              </Button>
            )}
          </div>
        ))}
    </div>
  </div>
);

export default ActionsWithText;
