import { Button, IconButton, TextInput } from '@smart/react-components';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdbSearchInputProps } from './AdbSearchInput.config';
import './AdbSearchInput.scss';

const BASE_CLASS = 'adb-search-input';

const AdbSearchInput = ({
  label,
  value,
  className,
  disabled,
  name,
  required,
  type = 'text',
  onSearch,
}: AdbSearchInputProps) => {
  const { t } = useTranslation();

  const [textQuery, setTextQuery] = useState(value);

  const onReset = () => {
    onSearch('');
    setTextQuery('');
  };

  const handleSearch = () => onSearch(textQuery);

  return (
    <div className={BASE_CLASS}>
      <TextInput
        label={label ?? t('general.labels.search')}
        name={name}
        type={type}
        disabled={disabled}
        onChange={(e) => setTextQuery(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && onSearch(textQuery)}
        value={textQuery}
        className={classNames(className, `${BASE_CLASS}__search-input`)}
        required={required}
      />

      {textQuery && (
        <IconButton
          aria-label="reset button"
          mode={200}
          variant="ghost"
          onClick={onReset}
        >
          <Button.Icon
            icon="close-without-circle"
            aria-label="close-without-circle"
          />
        </IconButton>
      )}
      <IconButton
        aria-label="search button"
        mode={200}
        variant="ghost"
        onClick={handleSearch}
      >
        <Button.Icon icon="search" aria-label="search" />
      </IconButton>
    </div>
  );
};

export default AdbSearchInput;
