/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PreferredLanguagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PreferredLanguagesQuery = { __typename?: 'Query', preferredLanguages: Array<{ __typename?: 'Dropdown', label: string, value: string }> };


export const PreferredLanguagesDocument = gql`
    query PreferredLanguages {
  preferredLanguages {
    label
    value
  }
}
    `;

/**
 * __usePreferredLanguagesQuery__
 *
 * To run a query within a React component, call `usePreferredLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreferredLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreferredLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreferredLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<PreferredLanguagesQuery, PreferredLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreferredLanguagesQuery, PreferredLanguagesQueryVariables>(PreferredLanguagesDocument, options);
      }
export function usePreferredLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreferredLanguagesQuery, PreferredLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreferredLanguagesQuery, PreferredLanguagesQueryVariables>(PreferredLanguagesDocument, options);
        }
export function usePreferredLanguagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PreferredLanguagesQuery, PreferredLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreferredLanguagesQuery, PreferredLanguagesQueryVariables>(PreferredLanguagesDocument, options);
        }
export type PreferredLanguagesQueryHookResult = ReturnType<typeof usePreferredLanguagesQuery>;
export type PreferredLanguagesLazyQueryHookResult = ReturnType<typeof usePreferredLanguagesLazyQuery>;
export type PreferredLanguagesSuspenseQueryHookResult = ReturnType<typeof usePreferredLanguagesSuspenseQuery>;
export type PreferredLanguagesQueryResult = Apollo.QueryResult<PreferredLanguagesQuery, PreferredLanguagesQueryVariables>;