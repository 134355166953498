import { NotificationContext } from 'contexts/notification-context';
import { PreviewContext } from 'contexts/preview-context';
import useMqttSubscription from 'hooks/useMqttSubscription';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './EnvironmentNotifier.scss';

const BASE_CLASS = 'dev-info-wrapper';

const EnvironmentInfo = () => {
  const { DEPLOY_ENV, CURRENT_ENV } = import.meta.env;
  const local = CURRENT_ENV === 'local';
  return (
    <div className={`${BASE_CLASS}-item`}>
      <strong>Current env:</strong>
      <code>{local ? `${CURRENT_ENV} ${DEPLOY_ENV}` : DEPLOY_ENV}</code>
    </div>
  );
};

const GitBranch = () => {
  const { BUILDKITE_BRANCH } = import.meta.env;
  return (
    <div className={`${BASE_CLASS}-item`}>
      <strong>Current branch: </strong>
      <code>{BUILDKITE_BRANCH}</code>
    </div>
  );
};

const GitCommitHash = () => {
  const { BUILDKITE_COMMIT } = import.meta.env;
  return (
    <div className={`${BASE_CLASS}-item`}>
      <strong>Current commit hash: </strong>
      <code>{BUILDKITE_COMMIT}</code>
    </div>
  );
};

const Preview = () => {
  const preview = useContext(PreviewContext);
  return (
    <div className={`${BASE_CLASS}-item`}>
      <strong>Preview: </strong>
      <code>{preview.toString()}</code>
    </div>
  );
};

const EnvironmentNotifier = () => {
  const { DEPLOY_ENV, CURRENT_ENV } = import.meta.env;
  const prod = DEPLOY_ENV === 'prod';
  const local = CURRENT_ENV === 'local';

  const { addInformation } = useContext(NotificationContext);
  const { value, setValue } = useMqttSubscription({
    topic: `${DEPLOY_ENV}/application/currentVersion`,
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (value) {
      addInformation({
        label: t('general.info.version_update.label'),
        message: t('general.info.version_update.message'),
        onClose: () => {
          window.location.reload();
        },
        persist: true,
      });
      setValue(undefined);
    }
  }, [value, addInformation, setValue, t]);

  if (prod) return null;

  return (
    <div className={BASE_CLASS}>
      <EnvironmentInfo />
      {!local && (
        <>
          <GitBranch />
          <GitCommitHash />
        </>
      )}
      <Preview />
    </div>
  );
};

export { EnvironmentNotifier };
