type AspectRatioType = '16-9' | '9-16' | '4-3' | '3-4' | '3-2' | '1-1' | 'none';

export const aspectRatioMapping: Record<AspectRatioType, number> = {
  '16-9': 16 / 9,
  '9-16': 9 / 16,
  '4-3': 4 / 3,
  '3-4': 3 / 4,
  '3-2': 3 / 2,
  '1-1': 1 / 1,
  none: 1,
};

type AdbDynamicImageConfig = {
  aspectRatio: AspectRatioType;
  sizes: string;
};

export type AdbDynamicImageProps = {
  imageUrl: string;
  imageAltText: string;
  imageConfig: AdbDynamicImageConfig;
  className?: string;
};

const choosePreset = ({
  width,
  aspectRatio,
}: {
  width: number;
  aspectRatio: AspectRatioType;
}) => {
  const presets = {
    default: {
      opaque: '$smartResponsiveHiDPI$',
      transparent: '$smartResponsiveAlpha8$',
      darken: '$smartResponsiveDarkenHiDPI$',
    },
    xHiDPI: {
      opaque: '$smartResponsiveXHiDPI$',
      darken: '$smartResponsiveDarkenXHiDPI$',
    },
  };

  if (aspectRatio === 'none') {
    return '';
  }

  if (width > 1920) {
    return presets.xHiDPI.opaque;
  }

  return presets.default.opaque;
};

export const generateSrc = ({
  height,
  width,
  url,
  aspectRatio,
}: {
  height: number;
  width: number;
  url: string;
  aspectRatio: AspectRatioType;
}): string => {
  const params = new URLSearchParams({
    ...(aspectRatio === 'none' ? {} : { wid: Math.round(width).toString() }),
    hei: Math.round(height).toString(),
  });

  const aspectRParam = aspectRatio === 'none' || `:${aspectRatio}`;

  return `${url}${aspectRParam}?${choosePreset({
    aspectRatio,
    width,
  })}&${params}`;
};

export const generateSrcSet = ({
  maxHeight,
  aspectRatio,
  url,
}: {
  aspectRatio: AspectRatioType;
  url: string;
  maxHeight: number;
}): string => {
  const interval = 100 * 2;
  const minWidth = 320 * 2;

  const numericAspectRatio = aspectRatioMapping[aspectRatio];
  const modifiedInterval = numericAspectRatio < 1 ? interval / 2 : interval;

  let srcSet = '';

  const maxWidth = maxHeight * numericAspectRatio;

  for (let width = minWidth; width <= maxWidth; width += modifiedInterval) {
    const height = Math.round(width / numericAspectRatio);

    srcSet += `${width === minWidth ? '' : ', '}${generateSrc({
      width,
      height,
      url,
      aspectRatio,
    })} ${width}w`;
  }

  return srcSet;
};

export const imageConfig1To1: AdbDynamicImageConfig = {
  aspectRatio: '1-1',
  sizes: '100vw',
};

export const imageConfig4To3: AdbDynamicImageConfig = {
  aspectRatio: '4-3',
  sizes: '100vw',
};

export const imageConfig16To9: AdbDynamicImageConfig = {
  aspectRatio: '16-9',
  sizes: '100vw',
};
