import { Flex, Text } from '@smart/react-components';
import { CountryCode } from '@smart/types';
import { getCountryName } from '@utils/helpers/language';
import { getLanguageCookie } from 'contexts/language-context';
import { useTranslation } from 'react-i18next';
import OrderItemFeature from './OrderItemFeature';
import { OrderItemSection } from './OrderItems.config';

const BASE_CLASS = 'adb-order-items';

const CustomerDetails = ({ orderDetail }: OrderItemSection) => {
  const { t } = useTranslation();
  const { language } = getLanguageCookie();

  return (
    <Flex direction="column" width="full" gap={300}>
      <Text variant="lbl-200">{t('orders.customer_details.title')}</Text>
      <Flex
        alignContent="space-between"
        width="full"
        wrap="wrap"
        gap={300}
        className={`${BASE_CLASS}__features`}
      >
        <OrderItemFeature
          title={t('orders.customer_details.customer_name')}
          text={orderDetail?.customer?.name ?? '-'}
        />
        <OrderItemFeature
          title={t('orders.customer_details.smart_id')}
          text={orderDetail?.customer?.email ?? '-'}
        />
        <OrderItemFeature
          title={t('orders.customer_details.phone')}
          text={orderDetail?.customer?.phone ?? '-'}
        />
        <OrderItemFeature
          title={t('orders.customer_details.location')}
          text={orderDetail?.customer?.location ?? '-'}
        />
        <OrderItemFeature
          title={t('orders.customer_details.id_type')}
          text={orderDetail?.customer?.idType ?? '-'}
        />
        <OrderItemFeature
          title={t('orders.customer_details.id_country')}
          text={
            orderDetail?.customer?.idCountry
              ? getCountryName(
                  language,
                  orderDetail?.customer?.idCountry?.toLowerCase() as CountryCode
                )
              : '-'
          }
        />
        <OrderItemFeature
          title={t('orders.customer_details.id_number')}
          text={orderDetail?.customer?.idNumber ?? '-'}
        />
        <OrderItemFeature
          title={t('orders.customer_details.tax_code')}
          text={orderDetail?.customer?.taxCode ?? '-'}
        />
        <OrderItemFeature
          title={t('orders.customer_details.customer_type')}
          text={
            orderDetail?.customer?.customerType
              ? t(
                  `task.account.${orderDetail?.customer?.customerType.toLowerCase()}`
                )
              : '-'
          }
        />
      </Flex>
    </Flex>
  );
};

export default CustomerDetails;
