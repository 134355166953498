import { Text } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import AdbLink from '../../atoms/AdbLink/AdbLink';
import LoadingIndicator from '../../atoms/LoadingIndicator/LoadingIndicator';
import { DashboardPlaceholderProps } from './DashboardPlaceholder.config';
import './DashboardPlaceholder.scss';

const BASE_CLASS = 'adb-dashboard-placeholder';

const DashboardPlaceholder = ({
  title,
  link,
  iconButton,
  items,
  additionalContent,
  noItemsTitle,
  hideZero = false,
  loading,
  redirectLink,
  notYetImplemented = false,
}: DashboardPlaceholderProps) => {
  const { t } = useTranslation();

  const noItems = (
    <div className={`${BASE_CLASS}__no-items`}>
      {!hideZero && <Text variant="hl-300">0</Text>}
      <div className={`${BASE_CLASS}__center`}>
        <Text
          variant="cap-300"
          as="p"
          className={`${BASE_CLASS}__no-items-info`}
        >
          {noItemsTitle} {!hideZero && t('dashboard.right_now').toLowerCase()}
        </Text>
        {notYetImplemented && redirectLink !== undefined && redirectLink}
      </div>
    </div>
  );

  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}__title`}>
        <Text variant="lbl-200" as="p">
          {title}
        </Text>
        {link !== undefined && (
          <AdbLink
            path={link.path}
            title={link.text.toLowerCase()}
            variant="hyperlink-standalone"
          />
        )}
        {iconButton}
      </div>
      {additionalContent && (
        <div className={`${BASE_CLASS}__additional-content`}>
          {additionalContent}
        </div>
      )}

      {!loading && !!items ? (
        <div className={`${BASE_CLASS}__items`}>{items}</div>
      ) : (
        <>
          {loading && <LoadingIndicator />}
          {!loading && loading !== undefined && noItems}
        </>
      )}
    </div>
  );
};

export default DashboardPlaceholder;
