import { AdbLocale } from '@ui/library/helpers/date-locale';
import { NotificationState } from './types';

export enum SettingActionType {
  SET_LOADING = 'CALENDAR/SET LOADING',
  SET_LANGUAGE = 'CALENDAR/SET LANGUAGE',
  SET_LOCALE = 'CALENDAR/SET LOCALE',
  SET_HAS_FILTER_CHANGED = 'CALENDAR/SET HAS FILTER CHANGED',
  SET_NOTIFICATION = 'CALENDAR/SET NOTIFICATION',
  SET_ACTION_LOADING = 'CALENDAR/SET ACTION LOADING',
}

export type SettingAction =
  | { type: SettingActionType.SET_LOADING; isLoading: boolean }
  | { type: SettingActionType.SET_LANGUAGE; language: string }
  | { type: SettingActionType.SET_LOCALE; locale: AdbLocale }
  | {
      type: SettingActionType.SET_HAS_FILTER_CHANGED;
      hasFilterChanged: boolean;
    }
  | {
      type: SettingActionType.SET_NOTIFICATION;
      notification: NotificationState | undefined;
    }
  | {
      type: SettingActionType.SET_ACTION_LOADING;
      isActionLoading: boolean;
    };
