import { AppointmentStatus } from '@smart/adb-shared';
import DashboardItem from '@smart/components-adb/molecules/DashboardItem/DashboardItem';
import { DashboardItemType } from '@smart/components-adb/molecules/DashboardItem/DashboardItem.config';
import { IconProps, Text } from '@smart/react-components';
import {
  DateFormats,
  getLocaleDateFromString,
} from '@ui/library/helpers/date-locale';
import { useLanguageContext } from 'contexts/language-context';
import { getIconFromStatus } from 'pages/tasks/tasks/utils';
import { useTranslation } from 'react-i18next';
import { AppointmentProps } from '../YourTasks.config';
import './Appointment.scss';

const BASE_CLASS = 'adb-dashboard-appointment';

const Handover = ({ task }: AppointmentProps) => {
  const { locale } = useLanguageContext();
  const { t } = useTranslation();

  const iconProps = getIconFromStatus(task.status);

  const getTopRightIcon = (): IconProps['icon'] | undefined => {
    switch (task.status) {
      case AppointmentStatus.Scheduled:
        return 'contact';

      case AppointmentStatus.Requested:
        return 'calendar';

      default:
        return undefined;
    }
  };

  return (
    <DashboardItem
      id={task.id}
      title={t(task.title)}
      itemType={DashboardItemType.TASK}
      topRightIcon={getTopRightIcon()}
      additionalContent={
        <div className={`${BASE_CLASS}__details`}>
          <div>
            <Text
              variant="cap-300"
              as="p"
              className={`${BASE_CLASS}__details-customer`}
            >
              {`${task.customer?.firstName} ${task.customer?.lastName}`}
            </Text>
            {task.customer?.__typename === 'CustomerBusiness' &&
              task.customer?.companyName && (
                <Text
                  variant="cap-300"
                  as="p"
                  className={`${BASE_CLASS}__details-description`}
                >
                  {task.customer.companyName}
                </Text>
              )}
          </div>
          <div>
            <Text
              variant="cap-300"
              as="p"
              className={`${BASE_CLASS}__details-description`}
            >
              {`${t('task.due_date')}: ${getLocaleDateFromString(
                task.end,
                locale,
                DateFormats.DATE
              )}`}
            </Text>
          </div>
        </div>
      }
      icon={iconProps.icon}
      iconColor={iconProps.color}
      backgroundColor={iconProps.backgroundColor}
      key={task.id}
      iconHasBorder={iconProps.iconHasBorder}
      isItemClickable
    />
  );
};

export default Handover;
