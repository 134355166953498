import { AssetType, ExtendedCar } from '@smart/adb-shared';
import EmptyLayout from '@smart/components-adb/atoms/EmptyDataLayout/EmptyLayout';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import CarCard from '@smart/components-adb/molecules/CarCard';
import HandoverForm from '@smart/components-adb/molecules/CarCard/HandoverForm/HandoverForm';
import FilterDropdownMultiple from '@smart/components-adb/molecules/FilterDropdownMultiple/FilterDropdownMultiple';
import { Tag, Text } from '@smart/react-components';
import {
  FacetValue,
  OrderTypeFilterValues,
} from '@ui/data-models/facet/facet.model';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import { useCarsQuery } from 'graphql/queries/cars.generated';
import { useCurrentOutlet } from 'hooks/outlet';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import SimActivation from 'pages/customer/purchased-products/SimStatus';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DemoCarSearch from './Search/Search';
import VehicleOrderButton from './VehicleOrderButton/VehicleOrderButton';
import './outlet-cars.scss';

const BASE_CLASS = 'adb-outlet-cars';

const OutletCars = () => {
  const { t } = useTranslation();
  const outlet = useCurrentOutlet();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterQueries, setFilterQueries] = useState<FacetValue[]>([]);

  const { data: demoCarsData, loading: loadingDemoCars } = useCarsQuery({
    variables: {
      input: {
        type: AssetType.DemoVehicle,
        bpid: outlet?.bpId,
      },
    },
    skip: !outlet?.bpId,
  });

  const { data: showroomCarsData, loading: loadingShowroomCars } = useCarsQuery(
    {
      variables: {
        input: {
          type: AssetType.ShowroomVehicle,
          bpid: outlet?.bpId,
        },
      },
      skip: !outlet?.bpId,
    }
  );

  const demoCars = useMemo(() => {
    const cars = [
      ...(demoCarsData?.getCars.products ?? []),
      ...(showroomCarsData?.getCars.products ?? []),
    ];

    return cars.sort(
      (first, second) => Number(first.isInfleeted) - Number(second.isInfleeted)
    );
  }, [demoCarsData?.getCars.products, showroomCarsData?.getCars.products]);

  const formattedOrderTypeFilters = OrderTypeFilterValues.map((item) => ({
    ...item,
    displayName: t(`car.assets.vehicle_types.${item.queryValue.toLowerCase()}`),
    label: t(`car.assets.vehicle_types.${item.queryValue.toLowerCase()}`),
  }));

  const filteredCars = demoCars.filter((car) => {
    const matchesSearchQuery =
      (car.carId &&
        car.carId.toLowerCase().includes(searchQuery?.toLowerCase())) ||
      (car.licensePlateNumber &&
        car.licensePlateNumber
          .toLowerCase()
          .includes(searchQuery?.toLowerCase())) ||
      (car.type &&
        car.type.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (car.name && car.name.toLowerCase().includes(searchQuery.toLowerCase()));

    const matchesFilterQueries =
      filterQueries.length === 0 ||
      filterQueries.some((filter) => filter.queryValue === car.type);

    return matchesSearchQuery && matchesFilterQueries;
  });

  const getHeaderText = (car: ExtendedCar) =>
    `${t(`car.assets.vehicle_types.${car.type.toLowerCase()}`)} / ${car.name ?? '-'}${
      car.licensePlateNumber
        ? ` / ${t('car.assets.license_plate')}: ${car.licensePlateNumber}`
        : ''
    }`;

  const demoOrderingEnabled = useFeatureFlag({
    key: 'demo-vehicle-ordering',
    defaultValue: false,
  });

  const showroomOrderingEnabled = useFeatureFlag({
    key: 'showroom-vehicle-ordering',
    defaultValue: false,
  });

  const removeFilter = (filterItem: FacetValue): void => {
    const newFilterQueries = [...filterQueries];
    newFilterQueries.splice(
      filterQueries.findIndex((filter) => filter.name === filterItem.name),
      1
    );
    setFilterQueries(newFilterQueries);
  };

  const clearAllFilters = () => {
    setFilterQueries([]);
  };

  const loading =
    loadingDemoCars ||
    loadingShowroomCars ||
    !demoCarsData ||
    !showroomCarsData;

  return (
    <div className={BASE_CLASS}>
      <AdbLayout.Header>
        <DemoCarSearch
          initialInputValue={searchQuery}
          handleInputChange={(e) => setSearchQuery(e.target.value)}
          rows={filteredCars.length ?? 0}
          filters={
            <FilterDropdownMultiple
              filterName={t('outlet.showroom.demo_cars.filter')}
              filterValues={formattedOrderTypeFilters}
              onSelect={(filter) => setFilterQueries([...filter])}
              currentFilters={filterQueries}
            />
          }
        />
        {filterQueries.length > 0 && (
          <div className={`${BASE_CLASS}__tags`}>
            {filterQueries.map((filter) => (
              <div
                className={`${BASE_CLASS}__tags-item`}
                key={filter.queryValue}
              >
                <Tag
                  open
                  onOpenChange={() => removeFilter(filter)}
                  key={filter.displayName}
                >
                  {`${filter?.label}: ${filter.displayName}`}
                  <Tag.Close />
                </Tag>
              </div>
            ))}
            <div className={`${BASE_CLASS}__tags-item`} key="clear-all">
              <Tag
                className={`${BASE_CLASS}__tags-item-clear-all`}
                open
                onOpenChange={clearAllFilters}
                key="clear-all"
              >
                {t('orders.filter.clear_all')}
                <Tag.Close />
              </Tag>
            </div>
          </div>
        )}
      </AdbLayout.Header>
      <AdbLayout.Content backgroundColor="bg-level-2">
        <div className={`${BASE_CLASS}__container`}>
          <div className={`${BASE_CLASS}__container-header`}>
            <Text
              variant="hl-300"
              className={`${BASE_CLASS}__container-location`}
            >
              {outlet?.name}
            </Text>

            <div>
              {demoOrderingEnabled && (
                <VehicleOrderButton type="demo" variant="primary" />
              )}
              {showroomOrderingEnabled && (
                <VehicleOrderButton type="showroom" variant="secondary" />
              )}
            </div>
          </div>
          {!loading && filteredCars.length > 0 ? (
            filteredCars.map((car) => (
              <CarCard
                key={car.vin}
                car={car}
                headerText={getHeaderText(car)}
                saveButtonText={t('outlet.showroom.demo_cars.button.infleet')}
                additionalClasses={`${BASE_CLASS}__item`}
              >
                <SimActivation vin={car.vin} />
                <HandoverForm car={car} />
              </CarCard>
            ))
          ) : (
            <LoadingIndicator loading={loading} onFullPage>
              <EmptyLayout
                message={t('general.error_messages.no_records_found')}
              />
            </LoadingIndicator>
          )}
        </div>
      </AdbLayout.Content>
    </div>
  );
};

export default OutletCars;
