import { fromWebToken } from '@aws-sdk/credential-providers';
import { refreshTokens } from '@utils/refresh-oauth-token';
import { useAuthContext } from 'contexts/auth-context';
import { useEffect, useState } from 'react';

export interface AwsCredentials {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken?: string;
}

const useAWSCredentials = () => {
  const [credentials, setCredentials] = useState<any>();
  const auth = useAuthContext();

  const accessToken = auth.auth?.['id-token'] ?? '';

  useEffect(() => {
    async function getCredentials() {
      try {
        const creds = await fromWebToken({
          roleArn: import.meta.env.ADB_IAM_ROLE,
          webIdentityToken: accessToken,
          clientConfig: { region: 'eu-central-1' },
          durationSeconds: 43200, // 12 hours - currently max AWS supports,
        })();
        setCredentials(creds);
      } catch (error: any) {
        console.log('fromWebToken error: ', error);
        const errorMessage = error.message as string;

        if (errorMessage.includes('Token too old: ')) {
          refreshTokens();
        }
      }
    }

    if (accessToken && !credentials) {
      getCredentials();
    }
  }, [accessToken, credentials]);

  return credentials as AwsCredentials;
};

export default useAWSCredentials;
