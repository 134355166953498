import { useTranslation } from 'react-i18next';
import { string } from 'yup';

export const CalendarAppointmentType = () => {
  const { t } = useTranslation();

  const schemaObject = {
    appointmentType: string().required(
      `${t('feature_calendar.form_fields.appointment_type')}
              ${t('general.error_messages.validations.is_required')}`
    ),
  };

  return schemaObject;
};
