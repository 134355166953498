import { AppointmentType, Maybe, TaskType } from '@smart/adb-shared';
import type { IconProps } from '@smart/react-components';
import { Text } from '@smart/react-components';
import {
  DateFormats,
  getLocaleDateFromString,
  isValidDate,
} from '@ui/library/helpers/date-locale';
import { useLanguageContext } from 'contexts/language-context';
import {
  ActivityAppointmentCard,
  ActivityTaskCard,
  AppointmentCard,
  CardType,
  CaseCard,
  HandoverTaskCard,
  TaskCard,
} from 'pages/tasks/config';
import { translateTextIfExists } from 'pages/tasks/tasks/Table/tasktable.config';
import { useTranslation } from 'react-i18next';
import { IconBackground, IconColor } from '../../atoms/AdbIcon/AdbIcon.config';
import DashboardItem from '../../molecules/DashboardItem/DashboardItem';
import { DashboardItemType } from '../../molecules/DashboardItem/DashboardItem.config';
import DashboardPlaceholder from '../DashboardPlaceholder/DashboardPlaceholder';
import { RequestsProps } from './Requests.config';
import './Requests.scss';

const BASE_CLASS = 'adb-requests';

const Requests = ({ requests, loading, link, title }: RequestsProps) => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();

  const getIcon = (
    type: Maybe<AppointmentType> | undefined
  ): IconProps['icon'] => {
    switch (type) {
      case AppointmentType.TestDrive: {
        return 'test-drive';
      }
      case AppointmentType.Handover: {
        return 'car';
      }
      default:
        return 'watch-wish-list';
    }
  };

  const getTranslatedTitle = (request: HandoverTaskCard | TaskCard) => {
    if (
      request.type === TaskType.Handover ||
      request.type === TaskType.Lead ||
      request.type === TaskType.TestDrive
    ) {
      return translateTextIfExists(
        t,
        `task.types.${request.type?.toLowerCase()}`,
        (request.type ?? '') as string
      );
    }

    return request.title;
  };

  const renderRequestCard = (
    request:
      | AppointmentCard
      | TaskCard
      | ActivityAppointmentCard
      | ActivityTaskCard
      | CaseCard
      | HandoverTaskCard
  ) => {
    switch (request.cardType) {
      case CardType.APPOINTMENT:
        return (
          <DashboardItem
            key={`${request.id}-${CardType.APPOINTMENT}`}
            id={request.id}
            itemType={DashboardItemType.REQUEST}
            title={t(request.title)}
            description={
              request.car?.carId
                ? `${t(request.title)} : ${request.car.carId}`
                : t(request.title)
            }
            icon={getIcon(request.type)}
            iconColor={IconColor.WHITE}
            backgroundColor={IconBackground.BLACK}
            additionalContent={
              <Text variant="cap-300" as="p" className={`${BASE_CLASS}__date`}>
                {`${
                  request.type === AppointmentType.Handover
                    ? t('task.due_date')
                    : t('task.start_date')
                }: ${getLocaleDateFromString(
                  request.type === AppointmentType.Handover
                    ? request.end
                    : request.start,
                  locale,
                  DateFormats.DATE
                )}`}
              </Text>
            }
          />
        );

      case CardType.TASK:
        return (
          <DashboardItem
            key={`${request.id}-${CardType.TASK}`}
            id={request.id}
            itemType={DashboardItemType.REQUEST}
            title={getTranslatedTitle(request)}
            icon={getIcon(undefined)}
            iconColor={IconColor.WHITE}
            backgroundColor={IconBackground.BLACK}
            additionalContent={
              <Text variant="cap-300" as="p" className={`${BASE_CLASS}__date`}>
                {`${t('task.start_date')}: ${
                  request.scheduledDate
                    ? getLocaleDateFromString(
                        request.scheduledDate,
                        locale,
                        DateFormats.DATE
                      )
                    : '-'
                }`}
              </Text>
            }
          />
        );

      case CardType.HANDOVER_TASK:
        return (
          <DashboardItem
            key={`${request.id}-${CardType.HANDOVER_TASK}`}
            id={request.id}
            itemType={DashboardItemType.TASK}
            title={getTranslatedTitle(request)}
            icon={getIcon(undefined)}
            iconColor={IconColor.WHITE}
            backgroundColor={IconBackground.BLACK}
            additionalContent={
              <>
                <Text
                  variant="cap-300"
                  as="p"
                  className={`${BASE_CLASS}__date`}
                >
                  {`${t('customer.search.table.contact')}: ${request.customer?.firstName} ${request.customer?.lastName}`}
                </Text>
                <Text
                  variant="cap-300"
                  as="p"
                  className={`${BASE_CLASS}__date`}
                >
                  {`${t('task.due_date')}: ${
                    isValidDate(request.requestedDate) && request.requestedDate
                      ? getLocaleDateFromString(
                          request.requestedDate,
                          locale,
                          DateFormats.DATE
                        )
                      : '-'
                  }`}
                </Text>
              </>
            }
          />
        );

      case CardType.ACTIVITY_APPOINTMENT:
        return (
          <DashboardItem
            key={`${request.id}-${CardType.ACTIVITY_APPOINTMENT}`}
            id={request.id}
            itemType={DashboardItemType.REQUEST}
            title={t(request.subjectAppointment)}
            icon={getIcon(undefined)}
            iconColor={IconColor.WHITE}
            backgroundColor={IconBackground.BLACK}
            additionalContent={
              <Text variant="cap-300" as="p" className={`${BASE_CLASS}__date`}>
                {`${t('task.start_date')}: ${getLocaleDateFromString(
                  request.startDateTime,
                  locale,
                  DateFormats.DATE
                )}`}
              </Text>
            }
          />
        );

      case CardType.ACTIVITY_TASK:
        return (
          <DashboardItem
            key={`${request.id}-${CardType.ACTIVITY_TASK}`}
            id={request.id}
            itemType={DashboardItemType.TASK}
            title={t(request.subjectTask)}
            icon={getIcon(undefined)}
            iconColor={IconColor.WHITE}
            backgroundColor={IconBackground.BLACK}
            additionalContent={
              request.activityDate &&
              isValidDate(request.activityDate) && (
                <Text
                  variant="cap-300"
                  as="p"
                  className={`${BASE_CLASS}__date`}
                >
                  {`${t('task.start_date')}: ${getLocaleDateFromString(
                    request.activityDate,
                    locale,
                    DateFormats.DATE
                  )}`}
                </Text>
              )
            }
          />
        );

      case CardType.CASE:
        return (
          <DashboardItem
            key={`${request.caseId}-${CardType.CASE}-${request.lastModifiedDate}`}
            id={request.caseId}
            itemType={DashboardItemType.CASE}
            title={request.subject}
            description={`Case ID: ${request.caseId}`}
            icon="newsletter-mail"
            iconColor={IconColor.WHITE}
            backgroundColor={IconBackground.BLACK}
            isItemClickable
            additionalContent={
              <>
                <Text
                  variant="cap-300"
                  as="p"
                  className={`${BASE_CLASS}__updated-by`}
                >
                  {`${t('task.updated_by')}: ${request.lastModifiedBy.Name}`}
                </Text>

                <Text
                  variant="cap-300"
                  as="p"
                  className={`${BASE_CLASS}__date`}
                >
                  {`${t('task.start_date')}: ${getLocaleDateFromString(
                    request.lastModifiedDate,
                    locale,
                    DateFormats.DATE
                  )}`}
                </Text>
              </>
            }
          />
        );

      default:
        return <p>N/A</p>;
    }
  };

  return (
    <DashboardPlaceholder
      title={title}
      link={link}
      noItemsTitle={t('dashboard.requests')}
      loading={loading}
      items={
        requests.length > 0 &&
        requests.map((request) => renderRequestCard(request))
      }
    />
  );
};

export default Requests;
