import { TextInput } from '@smart/react-components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import SearchFilterPanel from '../../../atoms/SearchFilterPanel/SearchFilterPanel';
import { useCartsContext } from '../CartItemsContext';

const CartsSearch = () => {
  const { t } = useTranslation();
  const { handleQueryUpdate, pagination } = useCartsContext();
  const [params, setParams] = useSearchParams();

  const searchQuery = params.get('search') ?? '';
  const page = params.get('page') ?? '';

  useEffect(() => {
    if (page && setParams) {
      // Reset page on filter/search update to avoid ending up on a page that does not exist
      const newParams = new URLSearchParams(params.toString());
      newParams.delete('page');
      setParams(newParams);
    }
    handleQueryUpdate(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleQueryUpdate, searchQuery]);

  // Function to handle search query
  const handleSearchQuery = (value: string): void => {
    setParams((prev: URLSearchParams) => {
      if (prev.has('page')) prev.delete('page');

      prev.set('search', value);
      return prev;
    });
  };

  return (
    <SearchFilterPanel
      searchBar={
        <TextInput
          type="text"
          name="search"
          id="text_input_id"
          onChange={(e) => handleSearchQuery(e.target.value)}
          label={t('orders.ald_carts.search')}
          value={searchQuery}
          caption={`${pagination.totalResults} ${t('general.labels.results')}`}
        />
      }
      showSearch
      darkMode
    />
  );
};

export default CartsSearch;
