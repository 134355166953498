import { Spinner } from '@smart/react-components';
import { isProd } from '@utils/helpers/environment';
import SimStatus from './SimStatus';
import SimStatusError from './SimStatusError';
import { useSimStatusQuery } from './queries.generated';
import { isErrorResponse } from './utils';

const SimActivation = ({ vin }: { vin: string }) => {
  const { data: simData, loading: statusLoading } = useSimStatusQuery({
    variables: { vin },
    skip: !isProd(),
  });

  if (!isProd()) {
    return <SimStatusError />;
  }

  const simStatus = simData?.simStatus;

  if (statusLoading || !simStatus) return <Spinner size="md" />;

  if (isErrorResponse(simStatus)) {
    return <SimStatusError />;
  }

  return <SimStatus statusResponse={simStatus} vin={vin} />;
};

export default SimActivation;
