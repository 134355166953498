import { Task, TaskStatus } from '@smart/adb-shared';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { TableRow } from '@smart/components-adb/molecules/Table/TableComponents/TableComponents';
import { Button, Text } from '@smart/react-components';
import { Row } from '@tanstack/react-table';
import { useLanguageContext } from 'contexts/language-context';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeOwnerModal } from '../ChangeOwnerModal';
import { useTaskContext } from '../task-context';
import { getTaskAssignee, getTaskDetails } from '../utils';
import UploadIdModal from './modal/UploadIdModal';

const BASE_CLASS = 'adb-tasks';

export const PosIdentTaskDetails = ({ task: taskRow }: { task: Row<Task> }) => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const { getTask, refetchTasks } = useTaskContext();

  const { registerModal, closeModal } = useModal();
  const task = taskRow.original;

  const { customer } = task;

  const taskDetails = getTaskDetails(task, t, locale, customer);

  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const onActionCompleted = (refetchTaskAction = 'single') => {
    setIsLoadingAction(true);
    closeModal();

    if (refetchTaskAction === 'single')
      getTask(task.id).finally(() => setIsLoadingAction(false));
    if (refetchTaskAction === 'all') refetchTasks();
  };

  return (
    <TableRow gridColumns="">
      <td className={`${BASE_CLASS}__task-inner`}>
        <div className={`${BASE_CLASS}__task-details`}>
          <Text variant="lbl-200" as="p">
            {t('task.pos_ident.title', { orderNumber: task.orderNumber })}
          </Text>
          <Text variant="p-100" as="p">
            {t('task.pos_ident.instruction')}
          </Text>
          <div>
            <ul className={`${BASE_CLASS}__task-details-list`}>
              {Object.keys(taskDetails).map((key) => (
                <li key={key}>
                  <Text variant="lbl-100">{t(key)}</Text>
                  {typeof taskDetails[key] === 'string' ||
                  typeof taskDetails[key] === 'number' ? (
                    <Text variant="p-100">{taskDetails[key] as string}</Text>
                  ) : (
                    <>{(taskDetails[key] as () => JSX.Element)()}</>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={`${BASE_CLASS}__task-actions`}>
          <Button
            variant="primary"
            disabled={taskRow.original.status === TaskStatus.Completed}
            loading={isLoadingAction}
            onClick={() => {
              registerModal(
                <ChangeOwnerModal
                  task={taskRow}
                  onComplete={onActionCompleted}
                />
              );
            }}
          >
            {getTaskAssignee({ task, t })}
            {task.isAgentAssigned ? (
              <Button.Icon
                icon="successful-checkmark"
                aria-label="successful-checkmark"
              />
            ) : (
              <Button.Icon icon="arrow-right" aria-label="arrow-right" />
            )}
          </Button>
          <Button
            variant="primary"
            disabled={
              isLoadingAction ||
              !task.isAgentAssigned ||
              taskRow.original.status === TaskStatus.Completed
            }
            onClick={() =>
              registerModal(
                <UploadIdModal
                  task={taskRow.original}
                  onComplete={closeModal}
                />
              )
            }
          >
            {t('task.pos_ident.upload_document')}
          </Button>
        </div>
      </td>
    </TableRow>
  );
};
