import { AssetType } from '@smart/adb-shared';
import { Text } from '@smart/react-components';
import { useNotificationContext } from 'contexts/notification-context';
import { CarsDocument } from 'graphql/queries/cars.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutlet } from 'hooks/outlet';
import { GetCarDocument } from 'pages/tasks/tasks/queries.generated';
import { useTranslation } from 'react-i18next';
import AdbDialog from '../../AdbDialog/AdbDialog';
import { useModal } from '../../Modal';
import { useUpdateCarMutation } from '../CarInfleeter/queries.generated';
import { IConfirmModal } from './config';

export const ConfirmModal = ({
  vin,
  installDate,
  licensePlateNumber,
  registrationDate,
  preRegistrationDate,
}: IConfirmModal) => {
  const { t } = useTranslation();
  const outlet = useCurrentOutlet();
  const { addSuccess } = useNotificationContext();
  const { closeModal } = useModal();

  const [updateProduct, { loading }] = useUpdateCarMutation({
    onError: (error) => {
      enhanceError({
        error,
        label: 'Failed to activate car',
        displayMessage: error.message,
      });
    },
    onCompleted: () => {
      addSuccess({
        label: t(
          'outlet.showroom.demo_cars.notification.infleeted_democar_title'
        ),
        message: '',
      });
    },
    refetchQueries: [
      {
        query: CarsDocument,
        variables: {
          input: {
            bpid: outlet?.bpId,
            type: AssetType.DemoVehicle,
          },
        },
      },
      {
        query: GetCarDocument,
        variables: {
          input: {
            bpid: outlet?.bpId,
            type: AssetType.DemoVehicle,
            vin,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const onConfirm = () =>
    updateProduct({
      variables: {
        input: {
          installDate,
          licensePlateNumber,
          registrationDate,
          vin,
          ...(preRegistrationDate && {
            preRegistrationDate,
          }),
        },
      },
      onCompleted: () => {
        closeModal();
      },
    });

  return (
    <AdbDialog
      id="purchased-product-dialog"
      buttons={{
        primary: {
          label: t('customer.purchased_products.buttons.confirm'),
          onClick: () => onConfirm(),
          isLoading: loading,
          disabled: loading,
        },
        secondary: {
          label: t('customer.purchased_products.buttons.cancel'),
          onClick: closeModal,
        },
      }}
    >
      <AdbDialog.Header>
        <Text variant="hl-100">
          {t('customer.purchased_products.dialog.confirm_warning')}
        </Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <Text variant="cap-100">
          {t('customer.purchased_products.dialog.confirm_proceed_question')}
        </Text>
      </AdbDialog.Content>
    </AdbDialog>
  );
};
