import { AdbTextInput } from '@smart/components-adb/adb-required-label/AdbTextInput';
import { getFieldLabel } from '@smart/components-adb/adb-required-label/helper';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import { truncateLanguage } from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut.config';
import { Button, Icon, Text } from '@smart/react-components';
import { convertMarketIdToCountryCode } from '@smart/utils';
import {
  MarketCodePhonePrefixMap,
  getPhoneNumberHintForMarket,
} from '@utils/market/types';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { Form, Formik, FormikErrors, FormikProps, FormikTouched } from 'formik';
import { AllCustomersDocument } from 'graphql/queries/customers.generated';
import { useCurrentOutlet } from 'hooks/outlet';
import { useLightRegistrationMutation } from 'pages/test-drive/booking/account/create-light-user/queries.generated';
import { lazy, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BASE_CLASS_CREATE_APPOINTMENT } from '../config';
import { CreateLightCustomerValues } from './CreateAppointmentDialog.config';
import CreateLightAccountSchema from './CreateLightAccountSchema';

const AdbPhoneInput = lazy(
  () => import('@smart/components-adb/molecules/AdbPhoneInput/AdbPhoneInput')
);

interface CreateLightAccountFormI {
  initialData?: {
    email?: string;
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
  };
  onCreated: (
    uuid: string,
    firstName: string,
    lastName: string,
    email: string,
    mobileNumber: string
  ) => void;
  onLoading?: (loading: boolean) => void;
  onClose?: () => void;
  submitRef?: React.RefObject<HTMLFormElement>;
  submitEnabled?: boolean;
}

export const CreateLightAccountForm = ({
  initialData,
  onLoading,
  onClose,
  onCreated,
  submitRef,
  submitEnabled = true,
}: CreateLightAccountFormI) => {
  const { t } = useTranslation();
  const { market } = useMarketContext();
  const { locale } = useLanguageContext();
  const outlet = useCurrentOutlet();

  const marketCode = convertMarketIdToCountryCode(market);
  const localeTrunc = truncateLanguage(locale);

  const [createLightCustomer, { loading }] = useLightRegistrationMutation({
    refetchQueries: [{ query: AllCustomersDocument }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (onLoading) onLoading(loading);
  }, [loading, onLoading]);

  const onSubmit = ({
    email,
    firstName,
    lastName,
    mobileNumber,
  }: CreateLightCustomerValues) => {
    createLightCustomer({
      variables: {
        input: {
          consentGranted: true,
          countryCode: market,
          firstName,
          lastName,
          userId: email,
          phone: mobileNumber.replaceAll(' ', ''),
          bpId: outlet?.bpId ?? '',
        },
      },
      onCompleted: ({ lightRegistration }) => {
        onCreated(
          lightRegistration.uuid,
          firstName,
          lastName,
          email,
          mobileNumber
        );
      },
    });
  };

  const showError = (
    key: keyof CreateLightCustomerValues,
    values: CreateLightCustomerValues,
    touched: FormikTouched<CreateLightCustomerValues>,
    errors: FormikErrors<CreateLightCustomerValues>
  ) =>
    (values[key] || (key in touched && touched[key])) &&
    key in errors &&
    errors[key];

  const validationSchema = CreateLightAccountSchema(market);

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
        ...initialData,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }: FormikProps<CreateLightCustomerValues>) => {
        const onBlur = (
          event: React.FormEvent<HTMLInputElement | HTMLSelectElement>
        ) => {
          const { name, value } = event.currentTarget;
          if (name && value) {
            setFieldTouched(name, true, false);
            setFieldValue(name, value.trim(), true);
          }
        };

        return (
          <Form autoComplete="on" onSubmit={handleSubmit} ref={submitRef}>
            <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__body`}>
              <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                <Icon
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                  icon="account"
                  mode={300}
                />
                <div
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
                >
                  <AdbTextInput
                    value={values.firstName}
                    id="firstName"
                    type="text"
                    key="firstName"
                    name="firstName"
                    label={t('form_fields.basic.first_name')}
                    schema={validationSchema}
                    onChange={handleChange}
                    onBlur={onBlur}
                  />
                  {showError('firstName', values, touched, errors) && (
                    <ErrorField errorMsg={errors.firstName ?? ''} />
                  )}
                </div>
              </div>
              <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                <Icon
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                  icon="account"
                  mode={300}
                />
                <div
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
                >
                  <AdbTextInput
                    value={values.lastName}
                    id="lastName"
                    name="lastName"
                    label={t('form_fields.basic.last_name')}
                    schema={validationSchema}
                    onChange={handleChange}
                    onBlur={onBlur}
                  />
                  {showError('lastName', values, touched, errors) && (
                    <ErrorField errorMsg={errors.lastName ?? ''} />
                  )}
                </div>
              </div>
              <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                <Icon
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                  icon="existing-customer"
                  mode={300}
                />
                <div
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
                >
                  <AdbTextInput
                    value={values.email}
                    id="email"
                    name="email"
                    label={t('form_fields.basic.email')}
                    type="email"
                    schema={validationSchema}
                    onChange={handleChange}
                    onBlur={onBlur}
                  />
                  {showError('email', values, touched, errors) && (
                    <ErrorField errorMsg={errors.email ?? ''} />
                  )}
                </div>
              </div>
              <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                <Icon
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-icon`}
                  icon="hotline"
                  mode={300}
                />
                <div
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
                >
                  <AdbPhoneInput
                    value={values.mobileNumber}
                    id="mobileNumber"
                    name="mobileNumber"
                    label={getFieldLabel(
                      validationSchema,
                      'mobileNumber',
                      t('form_fields.basic.mobile')
                    )}
                    schema={validationSchema}
                    onChange={(value: string) =>
                      setFieldValue('mobileNumber', value)
                    }
                    onBlur={onBlur}
                    message={t('form_fields.basic.mobile_hint', {
                      prefix: MarketCodePhonePrefixMap[market],
                      phoneNumber: getPhoneNumberHintForMarket(market),
                    })}
                  />
                  {showError('mobileNumber', values, touched, errors) && (
                    <ErrorField errorMsg={errors.mobileNumber ?? ''} />
                  )}
                </div>
              </div>
              <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__section`}>
                <Icon
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-hidden-icon`}
                  icon="car"
                  mode={300}
                />
                <div
                  className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-content`}
                >
                  <Text
                    variant="p-200"
                    className={`${BASE_CLASS_CREATE_APPOINTMENT}__section-consent`}
                  >
                    <Trans
                      i18nKey="customer.register.consent"
                      components={{
                        newline: (
                          <>
                            <br />
                            <br />
                          </>
                        ),
                        smartIdTerms: (
                          <a
                            href={`https://${marketCode}.smart.com/${localeTrunc}/service/terms-of-service/`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            text
                          </a>
                        ),
                        dataSharing: (
                          <a
                            href={`https://${marketCode}.smart.com/${localeTrunc}/service/ecosystem-and-marketing/#6`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            text
                          </a>
                        ),
                        privacyStatement: (
                          <a
                            href={`https://${marketCode}.smart.com/${localeTrunc}/legal/data-protection/`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            text
                          </a>
                        ),
                      }}
                    />
                  </Text>
                </div>
              </div>
            </div>
            <div className={`${BASE_CLASS_CREATE_APPOINTMENT}__footer`}>
              {onClose && (
                <Button variant="secondary" onClick={onClose}>
                  {t('feature_calendar.general.buttons.cancel')}
                </Button>
              )}

              {submitEnabled && (
                <Button
                  type="submit"
                  variant="primary"
                  disabled={loading}
                  loading={loading}
                >
                  <Button.Spinner />
                  {t('customer.details.buttons.save')}
                </Button>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
