import { AdbLocale } from '@ui/library/helpers/date-locale';
import { getMarketDomain } from './market-domain';
import type {
  AppMarketConfig,
  LicensePlateExamples,
  MarketCode,
  MarketLanguage,
} from './types';

export const licensePlateExample: LicensePlateExamples = {
  at: 'ABCD1234',
  be: '1-ABC-003',
  ch: 'ZH 445 789',
  de: 'BB SE 001 E',
  es: '1234 ABC',
  fr: 'AA-229-AA',
  it: 'AB 123 CD',
  nl: 'NL-01-AB',
  pt: 'AA-88-BB',
  se: 'ABC 123',
  gb: 'AB51 CDE',
  bg: '',
  cy: '',
  cz: '',
  dk: '',
  ee: '',
  fi: '',
  gr: '',
  hr: '',
  hu: '',
  ie: '',
  is: '',
  li: '',
  lt: '',
  lu: '',
  lv: '',
  mt: '',
  pl: '',
  ro: '',
  si: '',
  sk: '',
  sb: '',
  fm: '',
  no: '',
};

/**
 * an array of configured and allowed Markets for ADB
 */
const markets: AppMarketConfig[] = [
  {
    name: 'Germany',
    market: 'de',
    domain: getMarketDomain('de'),
    isDefault: true,
    outletMarket: 'de',
    languages: [
      {
        locale: 'de-DE',
        isDefault: true,
        displayCode: 'de',
      },
    ],
  },
  {
    name: 'United Kingdom',
    market: 'gb',
    domain: getMarketDomain('gb'),
    outletMarket: 'gb',
    languages: [
      {
        locale: 'en-GB',
        isDefault: true,
        displayCode: 'en',
      },
    ],
  },
  {
    name: 'Austria',
    market: 'at',
    domain: getMarketDomain('at'),
    outletMarket: 'at',
    languages: [
      {
        locale: 'de-AT',
        isDefault: true,
        displayCode: 'de',
      },
    ],
  },
  {
    name: 'Belgium',
    market: 'be',
    domain: getMarketDomain('be'),
    outletMarket: 'be',
    languages: [
      {
        locale: 'fr-BE',
        isDefault: true,
        displayCode: 'fr',
      },
      {
        locale: 'nl-BE',
        displayCode: 'nl',
      },
    ],
  },
  {
    name: 'France',
    market: 'fr',
    domain: getMarketDomain('fr'),
    outletMarket: 'fr',
    languages: [
      {
        locale: 'fr-FR',
        isDefault: true,
        displayCode: 'fr',
      },
    ],
  },
  {
    name: 'Italy',
    market: 'it',
    domain: getMarketDomain('it'),
    outletMarket: 'it',
    languages: [
      {
        locale: 'it-IT',
        isDefault: true,
        displayCode: 'it',
      },
    ],
  },
  {
    name: 'Netherlands',
    market: 'nl',
    domain: getMarketDomain('nl'),
    outletMarket: 'nl',
    languages: [
      {
        locale: 'nl-NL',
        isDefault: true,
        displayCode: 'nl',
      },
    ],
  },
  {
    name: 'Portugal',
    market: 'pt',
    domain: getMarketDomain('pt'),
    outletMarket: 'pt',
    languages: [
      {
        locale: 'pt-PT',
        isDefault: true,
        displayCode: 'pt',
      },
    ],
  },
  {
    name: 'Spain',
    market: 'es',
    domain: getMarketDomain('es'),
    outletMarket: 'es',
    languages: [
      {
        locale: 'es-ES',
        isDefault: true,
        displayCode: 'es',
      },
    ],
  },
  {
    name: 'Switzerland',
    market: 'ch',
    domain: getMarketDomain('ch'),
    outletMarket: 'ch',
    languages: [
      {
        locale: 'de-CH',
        displayCode: 'de',
        isDefault: true,
      },
      {
        locale: 'fr-CH',
        displayCode: 'fr',
      },
      {
        locale: 'it-CH',
        displayCode: 'it',
      },
    ],
  },
  {
    name: 'Sweden',
    market: 'se',
    domain: getMarketDomain('se'),
    outletMarket: 'se',
    languages: [
      {
        locale: 'sv-SE',
        isDefault: true,
        displayCode: 'sv',
      },
    ],
  },
];

export const MARKET_CONFIG = markets.map((obj) => {
  if (obj.market === 'gb') return obj;
  return {
    ...obj,
    languages: [
      ...obj.languages,
      {
        locale: 'en-GB',
        isTestEnv: true,
        displayCode: 'en',
      },
    ] as MarketLanguage[],
  };
});

export const getLanguage = (
  market: MarketCode,
  lang?: AdbLocale
): MarketLanguage => {
  const marketConfig = MARKET_CONFIG.find((x) => x.market === market);

  if (!marketConfig) {
    return MARKET_CONFIG[0].languages[0];
  }

  let foundLanguage: MarketLanguage | undefined;
  if (lang) {
    foundLanguage = marketConfig?.languages.find((y) => y.locale === lang);
  }

  return (
    foundLanguage ??
    marketConfig?.languages.find((c) => c.isDefault === true) ??
    marketConfig.languages[0]
  );
};

export const getLocale = (market: MarketCode, lang?: AdbLocale) =>
  getLanguage(market, lang).locale;
