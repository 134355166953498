import { ErrorCode } from '@smart/adb-shared';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const errorTranslation = (
  t: TFunction,
  parameter?: string
): Record<ErrorCode, string | undefined> => ({
  no_roles_assigned: t('auth.error_page.no_roles_assigned'),
  missing_environment_variable: undefined,
  invalid_arguments: undefined,
  unexpected_mulesoft_response: undefined,
  user_inactive_cdc: t('auth.error_page.user_inactive_cdc'),
  user_inactive_mcs: t('auth.error_page.user_inactive_mcs'),
  salesforce_unavailable: undefined,
  generic_error: undefined,
  ecom_token_error: t('auth.error_page.ecom_token_error'),
  customer_order_not_found: undefined,
  user_mismatch_error: undefined,
  access_token_error: undefined,
  invalid_grant: t('general.error_messages.invalid_grant'),
  invalid_user_account: t('auth.error_page.invalid_user_account'),
  sme_customer_registration: t('customer.register.save_sme_customer_error'),
  appointment_date_past: t(
    'feature_calendar.notification.error_description_time_in_past'
  ),
  appointment_slots_not_available: t(
    'feature_calendar.notification.error_description_no_time_slots'
  ),
  null_value: t('general.error_messages.null_value', { parameter }),
  light_customer_registration: t('customer.register.save_light_customer_error'),
  appointment_not_found: t('general.error_messages.appointment_not_found'),
});

const useErrorMessage = (error: any) => {
  const { t } = useTranslation();
  const { cause, stack } = error;
  const name = error.name ?? t('general.error_messages.something_went_wrong');

  const parameter = error?.extensions?.parameter;
  const errorTranslations = errorTranslation(t, parameter);
  const code = error?.extensions?.smartErrorCode as ErrorCode;

  const message = errorTranslations[code]
    ? errorTranslations[code]
    : (error.extensions?.message ?? error.message);

  const clipboard =
    typeof error !== 'string'
      ? [
          `${name}: ${message}`,
          `${cause ? `- Cause: ${cause}` : ''}`,
          `${stack ? `- Stack: ${stack}` : ''}`,
        ].join(' ')
      : error;

  return {
    code,
    message,
    clipboard,
    cause,
    name,
  };
};

export default useErrorMessage;
