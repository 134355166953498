import CalendarWeekDays from '@smart/components-adb/molecules/CalendarWeekDays/CalendarWeekDays';
import DashboardPlaceholder from '@smart/components-adb/organisms/DashboardPlaceholder/DashboardPlaceholder';
import { Button, IconButton } from '@smart/react-components';
import { getWeekStartDate } from '@ui/library/helpers/date';
import { useLanguageContext } from 'contexts/language-context';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppointmentsProps } from './Appointments.config';

const Appointments = ({ appointments, loading }: AppointmentsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useLanguageContext();

  const [selectedDate, setSelectedDate] = useState<string>(
    new Date().toISOString()
  );

  const handleDateSelection = (date: string): void => {
    setSelectedDate(date);
  };

  const handleAppointmentClick = (): void => {
    navigate('/calendar');
  };

  return (
    <DashboardPlaceholder
      title={t('dashboard.your_appointments')}
      noItemsTitle={t('dashboard.appointments')}
      iconButton={
        <IconButton
          data-testid="appointment-calendar-button"
          mode={200}
          variant="ghost"
          onClick={() => handleAppointmentClick()}
        >
          <Button.Icon icon="calendar" aria-label="calendar" />
        </IconButton>
      }
      loading={loading}
      items={
        <CalendarWeekDays
          selected={selectedDate}
          start={getWeekStartDate().toISOString()}
          appointments={appointments}
          handleDateSelection={handleDateSelection}
          language={locale}
        />
      }
    />
  );
};

export default Appointments;
