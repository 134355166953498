import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { CalendarProps } from '@store/calendar/types';
import '@ui/library/styles/index.scss';
import { Suspense, lazy } from 'react';
import { CalendarContextProvider } from '../../contexts/calendar-context';
import { Modal } from '../molecules/Modal';

const CalendarWrapper = lazy(() => import('./CalendarWrapper'));

export default function SmartCalendar(calendarProps: CalendarProps) {
  return (
    <CalendarContextProvider {...calendarProps}>
      <Suspense fallback={<LoadingIndicator />}>
        <Modal.Provider>
          <Modal />
          <CalendarWrapper />
        </Modal.Provider>
      </Suspense>
    </CalendarContextProvider>
  );
}
