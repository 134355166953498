/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OutletQueryVariables = Types.Exact<{
  bpId: Types.Scalars['String']['input'];
}>;


export type OutletQuery = { __typename?: 'Query', outlet: { __typename?: 'Outlet', bpId: string, mcsAutoIncrementId: number, mcsId: string, name: string, vat?: string | null, address?: { __typename?: 'OutletAddress', country: string, countryCode: string, postalCode: string, regionCode: string, street: string, buildingNumber: string, town: string } | null, contact?: { __typename?: 'OutletContact', email: string, phone: string } | null } };

export type OutletAgentsQueryVariables = Types.Exact<{
  outletId: Types.Scalars['ID']['input'];
}>;


export type OutletAgentsQuery = { __typename?: 'Query', outletAgents: Array<{ __typename?: 'McsAgent', id: string, expertId: string, gigyaId?: string | null, firstName: string, lastName: string, email: string, phone?: string | null, outletId: string, languages?: Array<string | null> | null, isActive: boolean, prefCommLang?: string | null, createdAt: string, updatedAt: string }> };


export const OutletDocument = gql`
    query Outlet($bpId: String!) {
  outlet(bpId: $bpId) {
    address {
      country
      countryCode
      postalCode
      regionCode
      street
      buildingNumber
      town
    }
    bpId
    contact {
      email
      phone
    }
    mcsAutoIncrementId
    mcsId
    name
    vat
  }
}
    `;

/**
 * __useOutletQuery__
 *
 * To run a query within a React component, call `useOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletQuery({
 *   variables: {
 *      bpId: // value for 'bpId'
 *   },
 * });
 */
export function useOutletQuery(baseOptions: Apollo.QueryHookOptions<OutletQuery, OutletQueryVariables> & ({ variables: OutletQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutletQuery, OutletQueryVariables>(OutletDocument, options);
      }
export function useOutletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletQuery, OutletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutletQuery, OutletQueryVariables>(OutletDocument, options);
        }
export function useOutletSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OutletQuery, OutletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OutletQuery, OutletQueryVariables>(OutletDocument, options);
        }
export type OutletQueryHookResult = ReturnType<typeof useOutletQuery>;
export type OutletLazyQueryHookResult = ReturnType<typeof useOutletLazyQuery>;
export type OutletSuspenseQueryHookResult = ReturnType<typeof useOutletSuspenseQuery>;
export type OutletQueryResult = Apollo.QueryResult<OutletQuery, OutletQueryVariables>;
export const OutletAgentsDocument = gql`
    query OutletAgents($outletId: ID!) {
  outletAgents(outletId: $outletId) {
    id
    expertId
    gigyaId
    firstName
    lastName
    email
    phone
    outletId
    languages
    isActive
    prefCommLang
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useOutletAgentsQuery__
 *
 * To run a query within a React component, call `useOutletAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletAgentsQuery({
 *   variables: {
 *      outletId: // value for 'outletId'
 *   },
 * });
 */
export function useOutletAgentsQuery(baseOptions: Apollo.QueryHookOptions<OutletAgentsQuery, OutletAgentsQueryVariables> & ({ variables: OutletAgentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutletAgentsQuery, OutletAgentsQueryVariables>(OutletAgentsDocument, options);
      }
export function useOutletAgentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletAgentsQuery, OutletAgentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutletAgentsQuery, OutletAgentsQueryVariables>(OutletAgentsDocument, options);
        }
export function useOutletAgentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OutletAgentsQuery, OutletAgentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OutletAgentsQuery, OutletAgentsQueryVariables>(OutletAgentsDocument, options);
        }
export type OutletAgentsQueryHookResult = ReturnType<typeof useOutletAgentsQuery>;
export type OutletAgentsLazyQueryHookResult = ReturnType<typeof useOutletAgentsLazyQuery>;
export type OutletAgentsSuspenseQueryHookResult = ReturnType<typeof useOutletAgentsSuspenseQuery>;
export type OutletAgentsQueryResult = Apollo.QueryResult<OutletAgentsQuery, OutletAgentsQueryVariables>;