import {
  Appointment,
  AppointmentType,
  ContractType,
  Dropdown,
  MetadataInput,
} from '@smart/adb-shared';
import { AdbTextInput } from '@smart/components-adb/adb-required-label/AdbTextInput';
import { getFieldLabel } from '@smart/components-adb/adb-required-label/helper';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import { Checkbox, Select, TextArea } from '@smart/react-components';
import { formatDateAndTime } from '@ui/library/helpers/date';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { Formik } from 'formik';
import { useCurrentOutlet } from 'hooks/outlet';
import { useTranslation } from 'react-i18next';
import {
  TestDriveReturnFormValues,
  TestDriveReturnProps,
  createTestDriveReturnPayload,
} from '../CreateDocumentType/CreateDocumentType.config';
import '../CreateDocumentType/CreateDocumentType.scss';
import { ELO_VERSION_COMMENT, ELO_VERSION_LABEL } from '../Documents.config';
import TestDriveContractReturnSchema from './TestDriveContractReturnSchema';

const BASE_CLASS = 'adb-create-document';

const TestDriveContractReturn = ({
  formFooter,
  appointments,
  customer,
  createDocument,
  docSystem,
}: TestDriveReturnProps) => {
  const { t } = useTranslation();
  const outlet = useCurrentOutlet();
  const { locale, language } = useLanguageContext();
  const { agent } = useAgentContext();
  const { market } = useMarketContext();
  const testDriveAppointments = appointments.filter(
    (item) => item.type === AppointmentType.TestDrive
  );

  const appointmentSelectOptions: Dropdown[] = testDriveAppointments?.map(
    (appointment) => ({
      label: formatDateAndTime(new Date(appointment.start), locale),
      value: appointment.id,
    })
  );

  const defaultAppointment = appointmentSelectOptions[0]?.value ?? '';

  const initialValues: TestDriveReturnFormValues = {
    drivingLicenseNo: '',
    appointmentTime: defaultAppointment,
    returnMileage: '',
    chargeCableReturn: false,
    comment: '',
  };

  const validationSchema = TestDriveContractReturnSchema(market);

  const handleTestDriveReturnSubmit = (
    formValues: TestDriveReturnFormValues,
    appointment: Appointment
  ) => {
    if (!outlet || !agent) {
      return;
    }

    const contract = createTestDriveReturnPayload(
      {
        timestamp: new Date(),
        customer,
        outlet,
        agent,
        appointment,
        language,
        locale,
        market,
      },
      formValues
    );

    const params: MetadataInput = {
      docSystem,
      uuid: customer.uuid,
      ...(customer.__typename === 'CustomerBusiness' && { bpid: outlet.bpId }),
      marketCode: market,
      documentType: ContractType.TEST_DRIVE_VEHICLE_RETURN_CONFIRMATION,
      language: customer.preferredLanguage ?? language,
      transactionId: appointment.id,
      versionComment: ELO_VERSION_COMMENT,
      versionLabel: ELO_VERSION_LABEL,
    };

    createDocument(contract, params);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(v) => {
        const appointment = testDriveAppointments.find(
          (a) => a.id === v.appointmentTime
        );
        if (!appointment) return;
        handleTestDriveReturnSubmit(v, appointment);
      }}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form autoComplete="on" onSubmit={handleSubmit}>
            <div className={`${BASE_CLASS}__form-input`}>
              <AdbTextInput
                id="driving_license_no"
                type="text"
                name="drivingLicenseNo"
                key="drivingLicenseNo"
                value={values?.drivingLicenseNo}
                label={t('form_fields.documents.driving_license_number')}
                onChange={handleChange}
                onBlur={handleBlur}
                schema={validationSchema}
              />
              {errors?.drivingLicenseNo && touched?.drivingLicenseNo && (
                <ErrorField errorMsg={errors?.drivingLicenseNo} />
              )}
            </div>

            <div className={`${BASE_CLASS}__form-input`}>
              <Select
                id="appointment_time"
                value={values.appointmentTime}
                onSelectionChange={(value) =>
                  setFieldValue('appointmentTime', value, true)
                }
                items={appointmentSelectOptions}
                label={getFieldLabel(
                  validationSchema,
                  'appointmentType',
                  t('form_fields.documents.test_drive_appointment')
                )}
                name="appointmentTime"
              >
                {(items) =>
                  items.map((item) => (
                    <Select.Item key={item.value} {...item} />
                  ))
                }
              </Select>

              {errors?.appointmentTime && touched?.appointmentTime && (
                <ErrorField errorMsg={errors?.appointmentTime} />
              )}
            </div>

            <div className={`${BASE_CLASS}__form-input`}>
              <AdbTextInput
                id="return_mileage"
                type="text"
                name="returnMileage"
                label={t(
                  'form_fields.document_test_drive_contract_return.return_mileage'
                )}
                value={values?.returnMileage}
                onChange={handleChange}
                onBlur={handleBlur}
                schema={validationSchema}
              />
              {errors?.returnMileage && touched?.returnMileage && (
                <ErrorField errorMsg={errors?.returnMileage} />
              )}
            </div>

            <div className={`${BASE_CLASS}__form-checkbox`}>
              <Checkbox
                id="charge_cable_return"
                name="chargeCableReturn"
                className={`${BASE_CLASS}__consent`}
                checked={values?.chargeCableReturn}
                onCheckedChange={(value: boolean) =>
                  setFieldValue('chargeCableReturn', value, true)
                }
              >
                {t(
                  'form_fields.document_test_drive_contract_return.charging_cables_returned'
                )}
              </Checkbox>
              {errors?.chargeCableReturn && touched?.chargeCableReturn && (
                <ErrorField errorMsg={errors?.chargeCableReturn} />
              )}
            </div>

            <div className={`${BASE_CLASS}__image`}>
              <img
                src="/images/TestDriveDocuments_Picture.png"
                alt={t('form_fields.document_test_drive_contract_return.image')}
              />
            </div>

            <div className={`${BASE_CLASS}__form-input`}>
              <TextArea
                id="comment"
                label={t('form_fields.documents.comment')}
                caption={t(
                  'form_fields.document_test_drive_contract_return.comment'
                )}
                name="comment"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comment}
              />

              {errors?.comment && touched?.comment && (
                <ErrorField errorMsg={errors?.comment} />
              )}
            </div>
            {formFooter(!agent || !outlet)}
          </form>
        );
      }}
    </Formik>
  );
};

export default TestDriveContractReturn;
