import { useModal } from '@smart/components-adb/molecules/Modal';

import { useFeatureFlag } from '@utils/configs/featureFlag';
import React, { useCallback } from 'react';
import { FeedbackButton } from './FeedbackButton';
import { FeedbackDialog } from './FeedbackDialog';

const Feedback = () => {
  const { registerModal, closeModal } = useModal();

  const enabled = useFeatureFlag({
    defaultValue: false,
    key: 'use-rate-adb',
  });

  const openFeedbackDialog = useCallback(() => {
    registerModal(
      <FeedbackDialog
        onClose={() => {
          closeModal();
        }}
      />
    );
  }, [registerModal, closeModal]);

  return enabled && <FeedbackButton onClick={() => openFeedbackDialog()} />;
};

export default React.memo(Feedback);
