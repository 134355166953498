import { Routes } from 'routes.config';
import Tasks from './tasks';

export const tasks: Routes[] = [
  {
    path: '/tasks',
    component: Tasks,
    title: 'navigation.dashboard.all_tasks',
  },
];
