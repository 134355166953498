import { Button } from '@smart/react-components';
import { useCallAttemptMutation } from 'graphql/queries/tasks.generated';
import { useTranslation } from 'react-i18next';
import { TaskActionProps } from '../types';

export const CallAttemptsButton = ({
  task,
  onComplete,
  disabled,
}: TaskActionProps) => {
  const { t } = useTranslation();

  const [updateCallAttempts, { loading }] = useCallAttemptMutation();

  return (
    <Button
      variant="primary"
      onClick={() => {
        updateCallAttempts({
          variables: {
            input: {
              taskId: task.original.id,
            },
          },
        }).then(() => onComplete('single'));
      }}
      loading={loading}
      disabled={disabled}
    >
      <Button.Spinner />
      {t('task.lead.call_attempts_cta')}
    </Button>
  );
};
