export type IdType = 'german-id' | 'passport' | 'foreign-id';

export interface UploadIdFormValues {
  idType: IdType;
  nationality: string;
  idNumber: string;
  dateOfIssue: string;
  idIssuingAuthority: string;
  dateOfExpiry: string;
  imageResolutionCheck: boolean;
  idValidityCheck: boolean;
  agentAssuranceCheck: boolean;
  residencyForeignPassportCheck: boolean;
}

export const InitialFormValues: UploadIdFormValues = {
  nationality: 'de',
  idType: 'german-id',
  idIssuingAuthority: '',
  dateOfIssue: '',
  idNumber: '',
  dateOfExpiry: '',
  imageResolutionCheck: false,
  agentAssuranceCheck: false,
  idValidityCheck: false,
  residencyForeignPassportCheck: false,
};
