import { ListItem, UnOrderedList } from '@smart/components-adb/atoms/List/List';
import { Logo } from '@smart/react-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './SplashHeader.scss';

const BASE_CLASS = 'adb-splash-header';

const SplashHeader = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={`${BASE_CLASS}__border-bottom`}>
      <nav
        className={classNames(BASE_CLASS, {
          [`dark-theme`]: false,
        })}
      >
        <UnOrderedList>
          <div className={`${BASE_CLASS}__logo-nav`}>
            <div
              className={classNames(
                `${BASE_CLASS}__wrap`,
                `${BASE_CLASS}__wrap__logo`,
                {
                  [`dark-theme`]: false,
                }
              )}
            >
              <ListItem
                content={
                  <NavLink to="/">
                    <Logo
                      logo="smart-logo-vertical"
                      label={t('general.labels.home')}
                    />
                  </NavLink>
                }
              />
            </div>
          </div>
        </UnOrderedList>
      </nav>
    </div>
  );
};

export default SplashHeader;
