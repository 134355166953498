/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConfirmAppointmentMutationVariables = Types.Exact<{
  input: Types.UpdateAppointmentStatusInput;
}>;


export type ConfirmAppointmentMutation = { __typename?: 'Mutation', confirmAppointment: { __typename?: 'UpdateAppointmentResponse', appointmentId: string } };

export type StartAppointmentMutationVariables = Types.Exact<{
  input: Types.UpdateAppointmentStatusInput;
}>;


export type StartAppointmentMutation = { __typename?: 'Mutation', startAppointment: { __typename?: 'UpdateAppointmentResponse', appointmentId: string } };

export type CompleteAppointmentMutationVariables = Types.Exact<{
  input: Types.UpdateAppointmentStatusInput;
}>;


export type CompleteAppointmentMutation = { __typename?: 'Mutation', completeAppointment: { __typename?: 'UpdateAppointmentResponse', appointmentId: string } };

export type CancelAppointmentMutationVariables = Types.Exact<{
  input: Types.CancelAppointmentInput;
}>;


export type CancelAppointmentMutation = { __typename?: 'Mutation', cancelAppointment: { __typename?: 'UpdateAppointmentResponse', appointmentId: string } };

export type RescheduleAppointmentMutationVariables = Types.Exact<{
  input: Types.RescheduleAppointmentInput;
}>;


export type RescheduleAppointmentMutation = { __typename?: 'Mutation', rescheduleAppointment: { __typename?: 'UpdateAppointmentResponse', appointmentId: string } };

export type AllAppointmentsQueryVariables = Types.Exact<{
  input: Types.AllAppointmentsInput;
}>;


export type AllAppointmentsQuery = { __typename?: 'Query', allAppointments: { __typename?: 'AllAppointmentsResponse', appointments: Array<{ __typename?: 'Appointment', createdOn: string, end: string, id: string, start: string, status?: Types.AppointmentStatus | null, type?: Types.AppointmentType | null, title: string, car?: { __typename?: 'AppointmentCar', carId: string, licensePlate?: string | null, name?: string | null, registrationDate?: string | null, model?: string | null, line?: string | null, exterior?: string | null, interior?: string | null } | null, customer?: { __typename?: 'CustomerBusiness', firstName: string, lastName: string, uuid: string, userId: string, mobileNumber?: string | null, sfCustomerId?: string | null, bpid?: string | null, accountType?: Types.CustomerAccountType | null, sfOrgId?: string | null, companyName?: string | null } | { __typename?: 'CustomerPrivate', firstName: string, lastName: string, uuid: string, userId: string, mobileNumber?: string | null, sfCustomerId?: string | null, accountType?: Types.CustomerAccountType | null } | null, expert?: { __typename?: 'AppointmentExpert', address: string, fullName: string, id: string } | null }> } };


export const ConfirmAppointmentDocument = gql`
    mutation ConfirmAppointment($input: UpdateAppointmentStatusInput!) {
  confirmAppointment(input: $input) {
    appointmentId
  }
}
    `;
export type ConfirmAppointmentMutationFn = Apollo.MutationFunction<ConfirmAppointmentMutation, ConfirmAppointmentMutationVariables>;

/**
 * __useConfirmAppointmentMutation__
 *
 * To run a mutation, you first call `useConfirmAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAppointmentMutation, { data, loading, error }] = useConfirmAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmAppointmentMutation, ConfirmAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmAppointmentMutation, ConfirmAppointmentMutationVariables>(ConfirmAppointmentDocument, options);
      }
export type ConfirmAppointmentMutationHookResult = ReturnType<typeof useConfirmAppointmentMutation>;
export type ConfirmAppointmentMutationResult = Apollo.MutationResult<ConfirmAppointmentMutation>;
export type ConfirmAppointmentMutationOptions = Apollo.BaseMutationOptions<ConfirmAppointmentMutation, ConfirmAppointmentMutationVariables>;
export const StartAppointmentDocument = gql`
    mutation StartAppointment($input: UpdateAppointmentStatusInput!) {
  startAppointment(input: $input) {
    appointmentId
  }
}
    `;
export type StartAppointmentMutationFn = Apollo.MutationFunction<StartAppointmentMutation, StartAppointmentMutationVariables>;

/**
 * __useStartAppointmentMutation__
 *
 * To run a mutation, you first call `useStartAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAppointmentMutation, { data, loading, error }] = useStartAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<StartAppointmentMutation, StartAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartAppointmentMutation, StartAppointmentMutationVariables>(StartAppointmentDocument, options);
      }
export type StartAppointmentMutationHookResult = ReturnType<typeof useStartAppointmentMutation>;
export type StartAppointmentMutationResult = Apollo.MutationResult<StartAppointmentMutation>;
export type StartAppointmentMutationOptions = Apollo.BaseMutationOptions<StartAppointmentMutation, StartAppointmentMutationVariables>;
export const CompleteAppointmentDocument = gql`
    mutation CompleteAppointment($input: UpdateAppointmentStatusInput!) {
  completeAppointment(input: $input) {
    appointmentId
  }
}
    `;
export type CompleteAppointmentMutationFn = Apollo.MutationFunction<CompleteAppointmentMutation, CompleteAppointmentMutationVariables>;

/**
 * __useCompleteAppointmentMutation__
 *
 * To run a mutation, you first call `useCompleteAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAppointmentMutation, { data, loading, error }] = useCompleteAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CompleteAppointmentMutation, CompleteAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteAppointmentMutation, CompleteAppointmentMutationVariables>(CompleteAppointmentDocument, options);
      }
export type CompleteAppointmentMutationHookResult = ReturnType<typeof useCompleteAppointmentMutation>;
export type CompleteAppointmentMutationResult = Apollo.MutationResult<CompleteAppointmentMutation>;
export type CompleteAppointmentMutationOptions = Apollo.BaseMutationOptions<CompleteAppointmentMutation, CompleteAppointmentMutationVariables>;
export const CancelAppointmentDocument = gql`
    mutation CancelAppointment($input: CancelAppointmentInput!) {
  cancelAppointment(input: $input) {
    appointmentId
  }
}
    `;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, options);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const RescheduleAppointmentDocument = gql`
    mutation RescheduleAppointment($input: RescheduleAppointmentInput!) {
  rescheduleAppointment(input: $input) {
    appointmentId
  }
}
    `;
export type RescheduleAppointmentMutationFn = Apollo.MutationFunction<RescheduleAppointmentMutation, RescheduleAppointmentMutationVariables>;

/**
 * __useRescheduleAppointmentMutation__
 *
 * To run a mutation, you first call `useRescheduleAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleAppointmentMutation, { data, loading, error }] = useRescheduleAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleAppointmentMutation, RescheduleAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleAppointmentMutation, RescheduleAppointmentMutationVariables>(RescheduleAppointmentDocument, options);
      }
export type RescheduleAppointmentMutationHookResult = ReturnType<typeof useRescheduleAppointmentMutation>;
export type RescheduleAppointmentMutationResult = Apollo.MutationResult<RescheduleAppointmentMutation>;
export type RescheduleAppointmentMutationOptions = Apollo.BaseMutationOptions<RescheduleAppointmentMutation, RescheduleAppointmentMutationVariables>;
export const AllAppointmentsDocument = gql`
    query AllAppointments($input: AllAppointmentsInput!) {
  allAppointments(input: $input) {
    appointments {
      car {
        carId
        licensePlate
        name
        registrationDate
        model
        line
        exterior
        interior
      }
      createdOn
      customer {
        ... on CustomerBusiness {
          firstName
          lastName
          uuid
          userId
          mobileNumber
          sfCustomerId
          bpid
          accountType
          sfOrgId
          companyName
        }
        ... on CustomerPrivate {
          firstName
          lastName
          uuid
          userId
          mobileNumber
          sfCustomerId
          accountType
        }
      }
      end
      expert {
        address
        fullName
        id
      }
      id
      start
      status
      type
      title
    }
  }
}
    `;

/**
 * __useAllAppointmentsQuery__
 *
 * To run a query within a React component, call `useAllAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppointmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<AllAppointmentsQuery, AllAppointmentsQueryVariables> & ({ variables: AllAppointmentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAppointmentsQuery, AllAppointmentsQueryVariables>(AllAppointmentsDocument, options);
      }
export function useAllAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAppointmentsQuery, AllAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAppointmentsQuery, AllAppointmentsQueryVariables>(AllAppointmentsDocument, options);
        }
export function useAllAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllAppointmentsQuery, AllAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllAppointmentsQuery, AllAppointmentsQueryVariables>(AllAppointmentsDocument, options);
        }
export type AllAppointmentsQueryHookResult = ReturnType<typeof useAllAppointmentsQuery>;
export type AllAppointmentsLazyQueryHookResult = ReturnType<typeof useAllAppointmentsLazyQuery>;
export type AllAppointmentsSuspenseQueryHookResult = ReturnType<typeof useAllAppointmentsSuspenseQuery>;
export type AllAppointmentsQueryResult = Apollo.QueryResult<AllAppointmentsQuery, AllAppointmentsQueryVariables>;