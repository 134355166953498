import classNames from 'classnames';
import { ReactNode } from 'react';

import { CONFIG_UI } from '@ui/library/configs/general';

import { BadgeProps, BadgeThemes } from './Badge.config';
import './Badge.scss';

const BASE_CLASS = 'adb-badge';

const getDefaultShape = (badgeText: ReactNode): string => {
  let badgeShape = 'circle';

  if (badgeText && typeof badgeText === 'string') {
    badgeShape = badgeText.length > 2 ? 'pill' : 'circle';
  }

  return badgeShape;
};

const Badge = (badgeProps: BadgeProps) => {
  const {
    color,
    backgroundColor,
    text,
    shape,
    additionalClasses,
    darkMode,
    theme = BadgeThemes.GREEN,
  } = badgeProps;

  const badgeShape = shape ?? getDefaultShape(text);

  const classes = classNames(BASE_CLASS, additionalClasses, {
    [`${BASE_CLASS}--${badgeShape}`]: badgeShape,
    [CONFIG_UI.cssClasses.darkMode]: darkMode,
    [`${BASE_CLASS}--text`]: text,
    [`${BASE_CLASS}--theme--${theme}`]: theme,
  });

  const customStyle =
    theme === BadgeThemes.CUSTOM ? { backgroundColor, color } : {};

  return (
    <div className={classes} style={customStyle} data-testid="adb-badge">
      {text && (
        <p className={`${BASE_CLASS}__text ${BASE_CLASS}__text--${theme}`}>
          {text}
        </p>
      )}
    </div>
  );
};

export default Badge;
