import { MarketCode } from '@utils/market/types';
import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { GetMaxLengthValidationMsg } from '../helpers/utils';

export const CompanyNameSchema = (
  validationMarket: MarketCode,
  required = false
) => {
  const { t } = useTranslation();

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'de':
        schemaObject = {
          companyName: required
            ? string()
                .required(
                  `${t('form_fields.business.company_name')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .max(
                  255,
                  GetMaxLengthValidationMsg(
                    t('form_fields.business.company_name'),
                    255
                  )
                )
            : string().max(
                255,
                GetMaxLengthValidationMsg(
                  t('form_fields.business.company_name'),
                  255
                )
              ),
        };
        break;
      default:
        schemaObject = {
          companyName: required
            ? string()
                .required(
                  `${t('form_fields.business.company_name')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .max(
                  255,
                  GetMaxLengthValidationMsg(
                    t('form_fields.business.company_name'),
                    255
                  )
                )
            : string().max(
                255,
                GetMaxLengthValidationMsg(
                  t('form_fields.business.company_name'),
                  255
                )
              ),
        };
        break;
    }
  }

  return schemaObject;
};
