import { MarketCode } from '@utils/market/types';

export function getHref(url: string): string {
  const trimmed = url.replace('https://', '').replace('www.', '');

  if (
    trimmed.startsWith('adb') ||
    trimmed.startsWith('qa') ||
    trimmed.startsWith('dev')
  )
    return trimmed;

  return trimmed.split('.').slice(1).join('.');
}

export function isLocalhost() {
  return window.location.hostname.includes('localhost');
}

export const generateMarketUrl = (agentMarket: MarketCode): string => {
  if (isLocalhost()) return 'http://localhost:3000/';
  if (!agentMarket) return import.meta.env.GIGYA_REDIRECT_URI;

  if (agentMarket === 'gb')
    return `https://uk.${getHref(window.location.hostname)}/`;

  return `https://${agentMarket}.${getHref(window.location.hostname)}/`;
};
