/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
export type ActivityAppointmentFieldsFragment = { __typename: 'ActivityAppointment', endDateTime: string, id: string, startDateTime: string, subjectAppointment: string, whoId: string, whatId: string, serviceAppointment?: string | null, ownerId: string, attributes: { __typename?: 'Attributes', type: string, url: string }, who: { __typename?: 'CustomerDetailsData', uuid: string, email: string, id: string, name: string, firstName: string, lastName: string, attributes?: { __typename?: 'Attributes', type: string, url: string } | null }, owner?: { __typename?: 'ActivityOwnerData', gigyaId: string, id: string, name: string, attributes?: { __typename?: 'Attributes', type: string, url: string } | null } | null, what: { __typename?: 'AppointmentCarDetails', vin: string, type: string, id: string, licensePlateNumber?: string | null, registrationDate?: string | null, name: string, attributes: { __typename?: 'Attributes', type: string, url: string } } };

export type ActivityTaskFieldsFragment = { __typename: 'ActivityTask', activityDate?: string | null, description?: string | null, id: string, ownerId: string, recordTypeId: string, subjectTask: string, whoId: string, status?: string | null, attributes: { __typename?: 'Attributes', type: string, url: string }, recordType: { __typename?: 'RecordType', id: string, developerName: string, attributes: { __typename?: 'Attributes', type: string, url: string } }, who: { __typename?: 'CustomerDetailsData', uuid: string, email: string, id: string, name: string, firstName: string, lastName: string, attributes?: { __typename?: 'Attributes', type: string, url: string } | null }, owner?: { __typename?: 'ActivityOwnerData', gigyaId: string, id: string, name: string, attributes?: { __typename?: 'Attributes', type: string, url: string } | null } | null };

export const ActivityAppointmentFieldsFragmentDoc = gql`
    fragment ActivityAppointmentFields on ActivityAppointment {
  __typename
  attributes {
    type
    url
  }
  endDateTime
  id
  startDateTime
  subjectAppointment
  who {
    uuid
    email
    id
    name
    attributes {
      type
      url
    }
    firstName
    lastName
  }
  whoId
  whatId
  serviceAppointment
  owner {
    gigyaId
    attributes {
      type
      url
    }
    id
    name
  }
  ownerId
  what {
    vin
    type
    attributes {
      type
      url
    }
    id
    licensePlateNumber
    registrationDate
    name
  }
}
    `;
export const ActivityTaskFieldsFragmentDoc = gql`
    fragment ActivityTaskFields on ActivityTask {
  __typename
  attributes {
    type
    url
  }
  activityDate
  description
  id
  ownerId
  recordType {
    attributes {
      type
      url
    }
    id
    developerName
  }
  recordTypeId
  subjectTask
  who {
    uuid
    email
    id
    name
    attributes {
      type
      url
    }
    firstName
    lastName
  }
  whoId
  owner {
    gigyaId
    attributes {
      type
      url
    }
    id
    name
  }
  status
}
    `;