import { ReactNode } from 'react';
import SplashHeader from './SplashHeader/SplashHeader';
import './SplashLayout.scss';

const BASE_CLASS = 'adb-splash-layout';

type SplashLayoutProps = { children: ReactNode };

/**
 * SplashLayout Component
 * @param SplashLayoutProps SplashLayoutProps
 * @returns <SplashLayout> ReactNode
 */

const SplashLayout = ({ children }: SplashLayoutProps) => (
  <>
    <SplashHeader />
    <div className={`${BASE_CLASS}__splash-section`}>{children}</div>
  </>
);

export default SplashLayout;
