import { ApolloError, makeVar } from '@apollo/client';
import { SignalVariant } from '@smart/components-adb/molecules/AdbNotification/AdbNotification.config';
import { GraphQLFormattedError } from 'graphql';

interface GqlError {
  error?: GraphQLFormattedError;
}

const initialValue: GqlError = {};

export const errorVar = makeVar(initialValue);

export const setGraphQLError = (error: GraphQLFormattedError) =>
  errorVar({ error });
export const clearGraphQLError = () => {
  errorVar({});
};

export const enhanceError = ({
  error,
  label,
  displayMessage,
  level = 'error',
}: {
  error: ApolloError;
  label: string;
  displayMessage: string;
  level?: SignalVariant;
}) => {
  const gqlError = error.graphQLErrors[0] ?? error.networkError;

  if (gqlError.extensions) {
    gqlError.extensions.label = label;
    gqlError.extensions.message = displayMessage;
    gqlError.extensions.level = level;
  }

  return error;
};
