import { PreferredTimeSchema } from '@ui/validations/schemas/PreferredTime';
import { MarketCode } from '@utils/market/types';
import { object } from 'yup';

const CommunicationSchema = (validationMarket: MarketCode) => {
  const preferredTime = PreferredTimeSchema(validationMarket, false);

  return object().shape({
    ...preferredTime,
  });
};

export default CommunicationSchema;
