import { BASE_CLASS } from '@smart/components-adb/calendar/config';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@smart/components-adb/molecules/Table/TableComponents/TableComponents';
import {
  ColumnFiltersState,
  flexRender,
  useReactTable,
} from '@tanstack/react-table';
import { useLanguageContext } from 'contexts/language-context';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskContext } from '../task-context';
import getTableConfig, { isNewTask } from './tasktable.config';
import TaskTableRow from './TaskTableRow';

const TaskTable = ({
  search,
  setSearch,
  setCount,
  setNewTasksCount,
  filters,
}: {
  search: string;
  setSearch: (newVal: string) => void;
  setCount: (count: number) => void;
  setNewTasksCount: (count: number) => void;
  filters: ColumnFiltersState;
}) => {
  const { t } = useTranslation();
  const { allTasks } = useTaskContext();
  const { locale } = useLanguageContext();

  const table = useReactTable(
    getTableConfig({
      search,
      setSearch,
      filters,
      tasks: allTasks,
      t,
      locale,
    })
  );

  const { getHeaderGroups, getRowModel, getFilteredRowModel } = table;
  const rows = getFilteredRowModel();

  const newTasksCount = useMemo(
    () => rows.rows.filter((r) => isNewTask(r.original)).length,
    [rows]
  );

  useEffect(() => {
    setCount(rows.rows.length);
    setNewTasksCount(newTasksCount);
  }, [rows, setCount, newTasksCount, setNewTasksCount]);

  if (!allTasks || allTasks.length === 0) return null;

  return (
    <div className={`${BASE_CLASS}__table_container`}>
      <Table>
        <TableHead>
          {getHeaderGroups().map((headerGroup) => (
            <TableRow gridColumns="" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  enableSorting={header.column.getCanSort()}
                  key={header.id}
                  textVariant={
                    header.column.getIsSorted() ? 'lbl-100' : undefined
                  }
                  onClick={
                    header.column.getCanSort()
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {getRowModel().rows.map((row) => (
            <TaskTableRow task={row} key={row.id} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TaskTable;
