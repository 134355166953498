import { AdbTextInput } from '@components/adb-required-label/AdbTextInput';
import { AdbDatePicker } from '@smart/components-adb/adb-required-label/AdbDatePicker';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import { Button } from '@smart/react-components';
import classNames from 'classnames';
import { useMarketContext } from 'contexts/market-context';
import { format } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { licensePlateExample } from 'utils/market/config';
import { useModal } from '../../Modal';
import '../index.scss';
import {
  CarInfleeterFormValues,
  CarInfleeterProps,
} from './CarInfleeter.config';
import CarInfleeterSchema from './CarInfleeterSchema';
import { UpdateCarModal } from './UpdateCarModal';

const BASE_CLASS = 'adb-car-activation';

const CarInfleeter = ({
  car,
  uuid,
  saveButtonText,
  setUpdated,
}: CarInfleeterProps) => {
  const {
    licensePlateNumber,
    registrationDate,
    preRegistrationDate,
    installDate,
    vin,
    isInfleeted,
  } = car;
  const { t } = useTranslation();
  const { market } = useMarketContext();
  const { registerModal } = useModal();

  const initialValues = {
    licensePlateNumber: licensePlateNumber ?? '',
    registrationDate: registrationDate ?? '',
    preRegistrationDate: preRegistrationDate ?? '',
    installDate: installDate ?? '',
  };

  const onSubmit = (values: CarInfleeterFormValues) => {
    registerModal(
      <UpdateCarModal
        values={values}
        vin={car.vin}
        uuid={uuid}
        setUpdated={setUpdated}
      />
    );
  };

  const DateOrNull = (date: string) => (date ? new Date(date) : null);
  const validationSchema = CarInfleeterSchema({ market, initialValues });

  if (isInfleeted) return null;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(props: FormikProps<CarInfleeterFormValues>) => {
        const {
          handleSubmit,
          handleBlur,
          errors,
          touched,
          dirty,
          values,
          setFieldValue,
          setFieldTouched,
        } = props;

        const onPreRegistrationDateDateChange = (date: Date | null) => {
          setFieldTouched('preRegistrationDate', true);
          setFieldValue(
            'preRegistrationDate',
            date ? format(new Date(date), 'yyyy-MM-dd') : ''
          );
        };

        const onRegistrationDateDateChange = (date: Date | null) => {
          setFieldTouched('registrationDate', true);
          setFieldValue(
            'registrationDate',
            date ? format(new Date(date), 'yyyy-MM-dd') : ''
          );
        };

        const onLicencePlateNumberChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          setFieldTouched('licensePlateNumber', true);
          setFieldValue('licensePlateNumber', event.target.value.toUpperCase());
        };

        const submitDisabled = Boolean(
          !dirty || errors.licensePlateNumber || errors.preRegistrationDate
        );

        return (
          <form autoComplete="on" onSubmit={handleSubmit}>
            <div
              className={classNames(
                `${BASE_CLASS}__form`,
                market === 'gb' ? `${BASE_CLASS}__form--span-4` : ''
              )}
            >
              {market === 'gb' && (
                <div className={`${BASE_CLASS}__form-input`}>
                  <AdbDatePicker
                    showIcon
                    id={`pre_registration_date-${vin}`}
                    name="preRegistrationDate"
                    selected={DateOrNull(values.preRegistrationDate)}
                    label={t('car.assets.pre_registration_date')}
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    onChange={onPreRegistrationDateDateChange}
                    disabled={!!initialValues.preRegistrationDate}
                    schema={validationSchema}
                  />
                  {errors.preRegistrationDate &&
                    touched.preRegistrationDate && (
                      <ErrorField errorMsg={errors.preRegistrationDate} />
                    )}
                </div>
              )}
              <div className={`${BASE_CLASS}__form-input`}>
                <AdbTextInput
                  id={`license_plate_${vin}`}
                  type="text"
                  name="licensePlateNumber"
                  value={values.licensePlateNumber}
                  label={t('car.assets.license_plate')}
                  onChange={onLicencePlateNumberChange}
                  onBlur={handleBlur}
                  schema={validationSchema}
                  disabled={!!initialValues.licensePlateNumber}
                  caption={licensePlateExample[market]}
                />
                {errors.licensePlateNumber && touched.licensePlateNumber && (
                  <ErrorField errorMsg={errors.licensePlateNumber} />
                )}
              </div>
              <div className={`${BASE_CLASS}__form-input`}>
                <AdbDatePicker
                  showIcon
                  id={`registration_date-${vin}`}
                  name="registrationDate"
                  selected={DateOrNull(values.registrationDate)}
                  label={t('car.assets.registration_date')}
                  dateFormat="yyyy-MM-dd"
                  minDate={new Date('2023-01-01')}
                  maxDate={new Date()}
                  onChange={onRegistrationDateDateChange}
                  disabled={!!initialValues.registrationDate}
                  schema={validationSchema}
                />
                {errors.registrationDate && touched.registrationDate && (
                  <ErrorField errorMsg={errors.registrationDate} />
                )}
              </div>
              <div className={`${BASE_CLASS}__form-actions`}>
                <Button
                  variant="primary"
                  type="submit"
                  mode={200}
                  disabled={submitDisabled}
                >
                  {saveButtonText}
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CarInfleeter;
