import { ApolloQueryResult } from '@apollo/client';
import { CartSummary, Pagination } from '@smart/adb-shared';
import { useAgentContext } from 'contexts/agent-context';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  AllCartsQuery,
  useAllCartsQuery,
} from '../../../pages/orders/carts/queries.generated';

const emptyPagination = {
  currentPage: 0,
  pageSize: 0,
  totalPages: 0,
  totalResults: 0,
};

interface CartsContextValue {
  allCarts: CartSummary[];
  refetchCarts: () => Promise<ApolloQueryResult<AllCartsQuery> | void>;
  loading: boolean;
  pagination: Pagination;
  handleQueryUpdate: (textSearch: string | undefined) => void;
}

const CartsContext = React.createContext<CartsContextValue>({
  allCarts: [],
  loading: true,
  refetchCarts: () => Promise.resolve(),
  pagination: emptyPagination,
  handleQueryUpdate: () => {},
});

export const CartsContextProvider = ({ children }: PropsWithChildren) => {
  const { agent } = useAgentContext();
  const [params] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>('');

  const page = Number(params.get('page') ?? 1);
  const pageSize = Number(params.get('size') ?? 300);

  const { data, loading, refetch } = useAllCartsQuery({
    variables: {
      input: {
        filter: {
          searchKey: searchQuery,
        },
        userId: agent?.email ?? '',
        paging: {
          page: page - 1,
          pageSize,
        },
      },
    },
    skip: !agent?.email,
    fetchPolicy: 'no-cache',
  });

  const pagination = data?.allCarts.pagination ?? emptyPagination;

  const [localPagination, setLocalPagination] = useState({
    totalPages: pagination.totalPages ?? 0,
    totalResults: pagination.totalResults ?? 0,
  });

  useEffect(() => {
    if (
      pagination.totalPages !== localPagination.totalPages ||
      pagination.totalResults !== localPagination.totalResults
    ) {
      setLocalPagination({
        totalPages: pagination.totalPages ?? 0,
        totalResults: pagination.totalResults ?? 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  // Function to store the current query
  const handleQueryUpdate = useCallback(
    (textSearch: string | undefined): void => {
      setSearchQuery(textSearch ?? '');
    },
    []
  );

  const value = useMemo(
    () => ({
      allCarts: data?.allCarts?.carts || [],
      refetchCarts: refetch,
      loading,
      pagination: localPagination,
      handleQueryUpdate,
    }),
    [data, refetch, loading, localPagination, handleQueryUpdate]
  );

  return (
    <CartsContext.Provider value={value}>{children}</CartsContext.Provider>
  );
};

export const useCartsContext = () => {
  const context = useContext(CartsContext);
  if (!context) {
    throw new Error('useCartsContext must be used within a CartsContext');
  }
  return context;
};
