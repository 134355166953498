import { CustomerAccountType } from '@smart/adb-shared';
import { convertMarketIdToCountryCode } from '@utils/helpers/market';
import { PreviewContext } from 'contexts/preview-context';
import { useContext } from 'react';
import {
  CurrentEnvironment,
  SmartComLinkOutProps,
} from './SmartComLinkOut.config';
import './SmartComLinkOut.scss';

const BASE_CLASS = 'adb-link-out';

const SmartComLinkOut = ({
  children,
  redirectPath,
  config,
  disabled = false,
}: SmartComLinkOutProps) => {
  const marketCode = convertMarketIdToCountryCode(config?.market ?? 'de');
  const preview = useContext(PreviewContext);

  const baseUrl = `https://${preview ? 'preview.' : ''}${
    config?.currentEnv
      ? (CurrentEnvironment as any)[config.currentEnv]
      : CurrentEnvironment.prod
  }${marketCode}.smart.com/adb-context-set`;

  const getRedirectPath = () => {
    if (config?.customerType === CustomerAccountType.Fleet)
      return `//${redirectPath}`;

    return `/${config?.language?.substring(0, 2)}/${redirectPath}`;
  };

  return (
    <form
      action={baseUrl}
      className={`${BASE_CLASS}__form`}
      data-testid="link-out-form"
      method="post"
    >
      {config?.customerPreferredLang && (
        <input
          data-testid="linkOut-hidden-input"
          name="customerPreferredLang"
          type="hidden"
          value={config?.customerPreferredLang}
        />
      )}
      {redirectPath && (
        <input
          data-testid="linkOut-hidden-input"
          name="redirectPath"
          type="hidden"
          value={getRedirectPath()}
        />
      )}
      {config?.customerUserId && (
        <input
          data-testid="linkOut-hidden-input"
          name="customerUserId"
          type="hidden"
          value={config?.customerUserId}
        />
      )}
      {config?.referrerPath && (
        <input
          data-testid="linkOut-hidden-input"
          name="referrerPath"
          type="hidden"
          value={`${config.referrerPath}${preview ? '?preview=true' : ''}`}
        />
      )}
      {config?.outletId && (
        <input
          data-testid="linkOut-hidden-input"
          name="outletId"
          type="hidden"
          value={config.outletId}
        />
      )}
      {config?.customerUUID && (
        <input
          data-testid="linkOut-hidden-input"
          name="customerUUID"
          type="hidden"
          value={config.customerUUID}
        />
      )}
      {config?.customerType && (
        <input
          data-testid="linkOut-hidden-input"
          name="customerType"
          type="hidden"
          value={config.customerType}
        />
      )}
      {config?.accessToken && (
        <input
          data-testid="linkOut-hidden-input"
          name="accessToken"
          type="hidden"
          value={config.accessToken}
        />
      )}
      {config?.idToken && (
        <input
          data-testid="linkOut-hidden-input"
          name="idToken"
          type="hidden"
          value={config.idToken}
        />
      )}
      {config?.refreshToken && (
        <input
          data-testid="linkOut-hidden-input"
          name="refreshToken"
          type="hidden"
          value={config.refreshToken}
        />
      )}
      {config?.demoVehicle && (
        <input
          data-testid="linkOut-hidden-input"
          name="demoVehicle"
          type="hidden"
          value={config.demoVehicle}
        />
      )}
      {config?.showroomVehicle && (
        <input
          data-testid="linkOut-hidden-input"
          name="showroomVehicle"
          type="hidden"
          value={config.showroomVehicle}
        />
      )}
      {config?.opportunityId && (
        <input
          data-testid="linkOut-hidden-input"
          name="opportunityId"
          type="hidden"
          value={config.opportunityId}
        />
      )}
      <button
        className={`${BASE_CLASS}__form-submit`}
        type="submit"
        disabled={disabled}
      >
        {children}
      </button>
    </form>
  );
};

export default SmartComLinkOut;
