import {
  TestDriveBookingSteps,
  TestDriveNavItem,
} from 'pages/test-drive/config';
import { NavigateFunction } from 'react-router-dom';

/**
 * @method stepStatusHandler
 * This method is used  to update stepStatus in the navigation object
 * @param navigationItems: NavItemTypes[]
 * @param referenceObj:{StepTitle.Car: true, StepTitle.Time: false}
 * @returns updated navigationItems array
 * */
export const stepStatusHandler = (
  navigationItems: TestDriveNavItem[],
  referenceObj: Record<string, any>
): TestDriveNavItem[] => {
  const stepTitleNames = Object.keys(referenceObj);

  return navigationItems.map((navItem) => {
    const navObj = { ...navItem };
    const { name } = navObj;

    if (stepTitleNames.includes(name)) {
      navObj.status = referenceObj[name];
    }

    return navObj;
  });
};

/**
 * @method getNavigationItem
 * This method is used  to get the route path from navList.
 * @param navigationItems: NavItemTypes[]
 * @param stepTitle: String
 * @returns navigationObject
 * */
export const getNavigationItem = (
  navigationItems: TestDriveNavItem[],
  stepTitle: string
) => navigationItems.find((item) => item.name === stepTitle) ?? { path: '' };

/**
 * @method hasCompletedRequiredSteps
 * This method is used  to check if all the required steps are completed.
 * @param navigationItems: NavItemTypes[]
 * @param requiredStepsArr:[StepTitle.Car, StepTitle.Time]
 * @returns boolean
 * */
export const handleRequiredSteps = (
  navigationItems: TestDriveNavItem[],
  requiredStepsArr: TestDriveBookingSteps[],
  navigate: NavigateFunction
) => {
  const listItems = navigationItems.filter(
    (item) => requiredStepsArr.includes(item.name) && !item.status
  );

  if (listItems.length) {
    navigate(getNavigationItem(navigationItems, listItems[0].name).path);
  }
};

export const handleNavigationStepLinks = (
  navigationItems: TestDriveNavItem[],
  customerId?: string,
  sfCustomerId?: string
) => {
  const isCustomerDataAvailable = customerId && sfCustomerId;

  const updatePathForItem = (item: TestDriveNavItem) => {
    switch (item.name) {
      case TestDriveBookingSteps.CONFIRMATION:
        return isCustomerDataAvailable
          ? `/test-drive/confirmation/summary/${customerId}/${sfCustomerId}`
          : item.path;
      default:
        return item.path;
    }
  };

  return navigationItems.map((item) => ({
    ...item,
    path: updatePathForItem(item),
  }));
};
