/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddressesInfoQueryVariables = Types.Exact<{
  input: Types.AddressesInfoInput;
}>;


export type AddressesInfoQuery = { __typename?: 'Query', addressesInfo: Array<{ __typename?: 'AddressInfo', id: string, description: string, highlight: string, text: string, type: Types.AddressType } | null> };

export type AddressFullQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type AddressFullQuery = { __typename?: 'Query', addressFull: { __typename?: 'AddressFull', id: string, domesticId: string, street: string, line1: string, district: string, city: string, province: string, postalCode: string, countryName: string, countryIsoNumber: number, countryIso2: string, countryIso3: string, label: string, adminAreaCode?: string | null, adminAreaName?: string | null, barcode?: string | null, block?: string | null, buildingName?: string | null, buildingNumber?: string | null, company?: string | null, dataLevel?: string | null, department?: string | null, field1?: string | null, field2?: string | null, field3?: string | null, field4?: string | null, field5?: string | null, field6?: string | null, field7?: string | null, field8?: string | null, field9?: string | null, field10?: string | null, field11?: string | null, field12?: string | null, field13?: string | null, field14?: string | null, field15?: string | null, field16?: string | null, field17?: string | null, field18?: string | null, field19?: string | null, field20?: string | null, language?: string | null, languageAlternatives?: string | null, line2?: string | null, line3?: string | null, line4?: string | null, line5?: string | null, neighbourhood?: string | null, poBoxNumber?: string | null, provinceCode?: string | null, provinceName?: string | null, secondaryStreet?: string | null, sortingNumber1?: string | null, sortingNumber2?: string | null, subBuilding?: string | null, type?: string | null } };


export const AddressesInfoDocument = gql`
    query AddressesInfo($input: AddressesInfoInput!) {
  addressesInfo(input: $input) {
    id
    description
    highlight
    text
    type
  }
}
    `;

/**
 * __useAddressesInfoQuery__
 *
 * To run a query within a React component, call `useAddressesInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressesInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressesInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddressesInfoQuery(baseOptions: Apollo.QueryHookOptions<AddressesInfoQuery, AddressesInfoQueryVariables> & ({ variables: AddressesInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressesInfoQuery, AddressesInfoQueryVariables>(AddressesInfoDocument, options);
      }
export function useAddressesInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressesInfoQuery, AddressesInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressesInfoQuery, AddressesInfoQueryVariables>(AddressesInfoDocument, options);
        }
export function useAddressesInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AddressesInfoQuery, AddressesInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddressesInfoQuery, AddressesInfoQueryVariables>(AddressesInfoDocument, options);
        }
export type AddressesInfoQueryHookResult = ReturnType<typeof useAddressesInfoQuery>;
export type AddressesInfoLazyQueryHookResult = ReturnType<typeof useAddressesInfoLazyQuery>;
export type AddressesInfoSuspenseQueryHookResult = ReturnType<typeof useAddressesInfoSuspenseQuery>;
export type AddressesInfoQueryResult = Apollo.QueryResult<AddressesInfoQuery, AddressesInfoQueryVariables>;
export const AddressFullDocument = gql`
    query AddressFull($id: String!) {
  addressFull(id: $id) {
    id
    domesticId
    street
    line1
    district
    city
    province
    postalCode
    countryName
    countryIsoNumber
    countryIso2
    countryIso3
    label
    adminAreaCode
    adminAreaName
    barcode
    block
    buildingName
    buildingNumber
    company
    dataLevel
    department
    field1
    field2
    field3
    field4
    field5
    field6
    field7
    field8
    field9
    field10
    field11
    field12
    field13
    field14
    field15
    field16
    field17
    field18
    field19
    field20
    language
    languageAlternatives
    line2
    line3
    line4
    line5
    neighbourhood
    poBoxNumber
    provinceCode
    provinceName
    secondaryStreet
    sortingNumber1
    sortingNumber2
    subBuilding
    type
  }
}
    `;

/**
 * __useAddressFullQuery__
 *
 * To run a query within a React component, call `useAddressFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressFullQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddressFullQuery(baseOptions: Apollo.QueryHookOptions<AddressFullQuery, AddressFullQueryVariables> & ({ variables: AddressFullQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressFullQuery, AddressFullQueryVariables>(AddressFullDocument, options);
      }
export function useAddressFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressFullQuery, AddressFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressFullQuery, AddressFullQueryVariables>(AddressFullDocument, options);
        }
export function useAddressFullSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AddressFullQuery, AddressFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddressFullQuery, AddressFullQueryVariables>(AddressFullDocument, options);
        }
export type AddressFullQueryHookResult = ReturnType<typeof useAddressFullQuery>;
export type AddressFullLazyQueryHookResult = ReturnType<typeof useAddressFullLazyQuery>;
export type AddressFullSuspenseQueryHookResult = ReturnType<typeof useAddressFullSuspenseQuery>;
export type AddressFullQueryResult = Apollo.QueryResult<AddressFullQuery, AddressFullQueryVariables>;