import AdbIcon from '@smart/components-adb/atoms/AdbIcon/AdbIcon';
import { AdbIconProps } from '@smart/components-adb/atoms/AdbIcon/AdbIcon.config';
import { Text } from '@smart/react-components';
import { getCustomFormattedDate } from '@ui/library/helpers/date-locale';
import classNames from 'classnames';

import { Email, EmailType, SenderType } from '@smart/adb-shared';

import { useLanguageContext } from 'contexts/language-context';
import './Message.scss';

const Message = ({
  body,
  date,
  receipient,
  sender,
  lastModifiedBy,
  type,
}: Omit<Email, 'subject'>) => {
  const BASE_CLASS = 'adb-conversation-message';
  const { locale } = useLanguageContext();

  const supportIcon: AdbIconProps['icon'] = 'account-support';
  const accountIcon: AdbIconProps['icon'] = 'account';
  const dealerIcon: AdbIconProps['icon'] = 'garage-car-selection';
  const notificationIcon: AdbIconProps['icon'] = 'notification';

  const INTERNAL_NOTE = type === EmailType.InternalNote;

  const getIcon = () => {
    switch (lastModifiedBy) {
      case SenderType.Agent:
        return dealerIcon;

      case SenderType.Cec:
        return supportIcon;

      case SenderType.User:
        return accountIcon;

      default:
        return accountIcon;
    }
  };

  return (
    <div
      className={`${classNames(BASE_CLASS)} ${
        INTERNAL_NOTE ? `${BASE_CLASS}__internal` : ''
      }`}
    >
      <div
        className={`${BASE_CLASS}__avatar ${
          INTERNAL_NOTE ? `${BASE_CLASS}__avatar--internal` : ''
        }`}
      >
        <AdbIcon icon={getIcon()} size="large" />
        {lastModifiedBy && lastModifiedBy !== SenderType.User && (
          <div
            className={`${BASE_CLASS}__avatar--mini ${
              INTERNAL_NOTE ? `${BASE_CLASS}__avatar--mini--internal` : ''
            }`}
          >
            <AdbIcon
              icon={INTERNAL_NOTE ? notificationIcon : accountIcon}
              size="small"
            />
          </div>
        )}
      </div>
      <div className={`${BASE_CLASS}__content`}>
        <div className={`${BASE_CLASS}__header`}>
          <div>
            <Text
              variant="lbl-100"
              as="p"
              className={`${BASE_CLASS}__header--sender`}
            >
              {sender}
            </Text>
            <Text
              variant="cap-300"
              as="p"
              className={`${BASE_CLASS}__header--receipient`}
            >
              {INTERNAL_NOTE ? 'INTERNAL NOTE' : `To: ${receipient}`}
            </Text>
          </div>
          <Text
            variant="cap-300"
            as="p"
            className={`${BASE_CLASS}__header--date`}
          >
            {getCustomFormattedDate(new Date(date), 'dd/MM/yyyy HH:mm', locale)}
          </Text>
        </div>
        <Text
          variant="cap-200"
          as="p"
          className={`${BASE_CLASS}__content--body`}
        >
          {body}
        </Text>
      </div>
    </div>
  );
};

export default Message;
