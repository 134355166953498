import {
  CustomerAccountType,
  CustomerPrivate,
  CustomerRecordType,
  CustomerType,
} from '@smart/adb-shared';

export const emptyPrivateCustomer: CustomerPrivate = {
  accountType: CustomerAccountType.Private,
  communicationInfo: {},
  privateAdditionalInfo: {},
  systemInfo: {},
  address: {
    countryCode: '',
    postalCode: '',
    regionCode: '',
    street: '',
    town: '',
  },
  firstName: '',
  lastName: '',
  market: '',
  mobileNumber: '',
  preferredLanguage: '',
  titleCode: '',
  userId: '',
  uuid: '',
  car: {
    carStatus: '',
    interior: '',
    exterior: '',
    loadingCircumstance: '',
    mileage: '',
    name: '',
    nextInspection: '',
    registrationDate: '',
    warrantyExtension: '',
  },
  lastModified: '',
  purchaseModel: {
    endDate: '',
    paymentMethod: '',
    price: '',
    startDate: '',
  },
  recordType: CustomerRecordType.PrivateCustomer,
  sfCustomerId: '',
  type: CustomerType.Account,
};
