import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getLanguageCookie } from 'contexts/language-context';
import { ADB_COOKIES } from './cookie/adb-cookies';
import { removeCookie } from './cookie/cookie-configurator';
import { refreshTokens } from './refresh-oauth-token';

interface BffError {
  code: number;
  error: string;
}

const isRefreshTokenError = (error: AxiosError<BffError>) =>
  !!error.config?.params.refreshToken;

const isUnauthorizedError = (error: AxiosError<BffError>) => {
  if (!error.response) return false;

  if (
    error.response?.status === 400 ||
    error.response?.status === 401 ||
    error.response?.status === 403
  ) {
    return true;
  }

  if (error.response.data && error.response.data.error === 'Unauthorized') {
    return true;
  }

  if (
    error.response.data &&
    error.response.data.code &&
    (error.response.data.code === 401 || error.response.data.code === 403)
  ) {
    return true;
  }

  return false;
};

const shouldAddLanguage = (config: AxiosRequestConfig<any>) =>
  config.url?.includes('/integration/') &&
  !config.params?.lang &&
  config.method?.toLowerCase() === 'get';

axios.interceptors.request.use((config) => {
  if (!shouldAddLanguage(config)) return config;

  if (!config.params) config.params = {};

  const { locale } = getLanguageCookie();
  config.params.lang = locale.substring(0, 2);

  return config;
});

axios.interceptors.response.use(null, (error: AxiosError<BffError>) => {
  if (isRefreshTokenError(error)) {
    removeCookie(ADB_COOKIES.TOKENS);
    window.location.replace(import.meta.env.GIGYA_REDIRECT_URI);
  }

  if (isUnauthorizedError(error)) {
    return refreshTokens()
      .then((auth) => {
        if (error.config) {
          error.config.headers.access_token = auth?.['access-token'];
          return axios.request(error.config);
        }
        throw new Error('No config to rebuild');
      })
      .catch(() => {
        removeCookie(ADB_COOKIES.TOKENS);
        window.location.replace(import.meta.env.GIGYA_REDIRECT_URI);
      });
  }

  return Promise.reject(error);
});
