import { AdbLocale } from '@ui/library/helpers/date-locale';
import { LanguageCode } from '@ui/translations';
import { MARKET_CONFIG } from './config';
import type { AppMarketConfig, MarketCode, MarketLanguage } from './types';

/**
 * ---------------------------
 * Getters for Market
 * ---------------------------
 */

/**
 * Get market for the provided market code
 *
 * @param marketCode string | undefined
 * @returns AppMarketConfig | undefined
 */
const getFoundMarket = (marketCode?: string): AppMarketConfig | undefined =>
  MARKET_CONFIG.find((market) => marketCode && market.market === marketCode);

/**
 * Get default market configured for the website
 *
 * @returns AppMarketConfig | undefined
 */
const getDefaultMarket = (): AppMarketConfig => {
  const defaultMarket = MARKET_CONFIG.find(
    (market) => market.isDefault === true
  );

  if (!defaultMarket) throw new Error('No default market found!');

  return defaultMarket;
};
/**
 * Get market information
 *  - market for the provided market code
 *  - default market for the website
 *  - first market from the configuration
 *
 * @param marketCode string | undefined
 * @returns AppMarketConfig
 */
const getMarket = (marketCode?: MarketCode): AppMarketConfig =>
  getFoundMarket(marketCode) ?? getDefaultMarket() ?? MARKET_CONFIG[0];

/**
 * ---------------------------
 * Getters for Language
 * ---------------------------
 */

/**
 * determine if the provided market contains languages
 *
 * @param market MarketCode
 * @returns boolean
 */
const marketHasLanguages = (market: MarketCode): boolean => {
  const foundMarket = getMarket(market);
  return (
    !!foundMarket && foundMarket.languages && foundMarket.languages.length > 0
  );
};

export interface MarketSelectOption {
  displayName: string;
  value: string;
}

/**
 * get available language codes for the provided market code
 *
 * @param marketCode MarketCode
 * @returns string[] | []
 */
export const getMarketLanguages = (
  marketCode: MarketCode,
  isTestEnv: boolean
): MarketSelectOption[] | [] => {
  if (!marketCode || !marketHasLanguages(marketCode)) {
    return [];
  }

  const market = getMarket(marketCode);

  return market.languages
    .filter((lang) =>
      isTestEnv && lang.isTestEnv ? lang.isTestEnv : !lang.isTestEnv
    )
    .map((lang) => ({
      value: lang.locale,
      displayName: lang.displayCode ?? lang.locale,
    }));
};

/**
 * get valid language from the market
 * - find if the languageCode matches to a language code in the provided market
 * - find if the languageCode matches to a language's alternative codes in the provided market
 *
 * @param marketCode string
 * @param languageCode string
 * @returns MarketLanguage | undefined
 */
const getValidLanguage = (
  marketCode: MarketCode,
  languageCode: AdbLocale
): MarketLanguage | undefined => {
  const marketConfig = getFoundMarket(marketCode);

  if (!marketConfig || !marketHasLanguages(marketCode)) {
    return undefined;
  }

  return marketConfig.languages.find(
    (language) => language.locale === languageCode
  );
};

/**
 * get default language from the provided market
 *
 * @param marketCode string
 * @returns MarketLanguage | undefined
 */
const getDefaultMarketLanguage = (
  marketCode: MarketCode
): MarketLanguage | undefined => {
  const marketConfig = getFoundMarket(marketCode);

  if (!marketConfig || !marketHasLanguages(marketCode)) {
    return undefined;
  }

  return marketConfig.languages.find((language) => language.isDefault);
};

/**
 * get user browser language validated with market
 *
 * @param marketCode string
 * @returns MarketLanguage | undefined
 */
const getValidUserLanguage = (
  marketCode: MarketCode
): MarketLanguage | undefined => {
  const userLanguage = navigator.languages
    ? (navigator.languages[0] as LanguageCode)
    : (navigator.language as LanguageCode);
  const language = getValidLanguage(marketCode, userLanguage);

  return language ?? undefined;
};

/**
 * Get language for current market or the default language for the website
 * Matches in the following order :
 *  - if language provided, use the provided language for the market, if valid for the provided market
 *  - take default language from the provided market
 *  - take first language from the provided market
 *  - take user language, if valid for the provided market
 *  - take first language from first market
 *
 * @param market MarketCode
 * @param languageCode LanguageCode
 * @returns MarketLanguage
 */
export const getLanguage = (
  market: MarketCode,
  languageCode?: AdbLocale
): MarketLanguage => {
  // valid provided language
  if (languageCode) {
    const currentLanguage = getValidLanguage(market, languageCode);

    if (currentLanguage) {
      return currentLanguage;
    }
  }

  // default language for the market
  const defaultMarketLanguage = getDefaultMarketLanguage(market);

  if (defaultMarketLanguage) {
    return defaultMarketLanguage;
  }

  // first language of the market
  if (marketHasLanguages(market)) {
    const foundMarket = getMarket(market);
    return foundMarket.languages[0];
  }

  // user language
  const validUserLanguage = getValidUserLanguage(market);

  if (validUserLanguage) {
    return validUserLanguage;
  }

  // first language from the first market
  return MARKET_CONFIG[0].languages[0];
};
