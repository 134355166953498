import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { Text } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import { CheckoutButton } from './CheckoutButton';
import { TestDriveButton } from './TestDriveButton';

import { CloseTaskButton } from '../CloseTaskButton';
import { TaskActionProps } from '../types';
import { CallAttemptsButton } from './CallAttemptsButton';
import { ConvertLeadButton } from './ConvertLeadButton';
import { CreateLightAccountButton } from './CreateLightAccountButton';
import './LeadActionsModal.scss';
import { RescheduleButton } from './RescheduleButton';

const BASE_CLASS = 'adb-lead-actions-modal';

export const LeadActionsModal = ({ task, onComplete }: TaskActionProps) => {
  const { t } = useTranslation();

  const { customer } = task.original;
  const hasAccount = task.original.isAccountAssigned;

  return (
    <AdbDialog id={BASE_CLASS} className={BASE_CLASS}>
      <AdbDialog.Header>
        <Text variant="hl-100">{t('task.lead.next_step_cta')}</Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <CreateLightAccountButton
          disabled={hasAccount}
          onComplete={onComplete}
          task={task}
        />
        <CallAttemptsButton
          disabled={!hasAccount}
          task={task}
          onComplete={onComplete}
        />
        <TestDriveButton customer={customer} task={task} />
        <RescheduleButton
          disabled={!hasAccount}
          task={task}
          onComplete={onComplete}
        />
        <ConvertLeadButton disabled={!hasAccount} task={task} />
        <CheckoutButton disabled={!hasAccount} task={task} />
        <CloseTaskButton
          disabled={!hasAccount}
          task={task}
          label={t('task.lead.close_lead_cta')}
        />
      </AdbDialog.Content>
    </AdbDialog>
  );
};
