/**
 * @interface FacetSortByValue
 * FacetSortBy values information
 */
export interface FacetSortByValue {
  displayName: string;
  queryValue: string;
}

/**
 * @method getFormattedFiltersWithDisplayName
 * This method is used  to return the array of items in the format SortByValue.
 * @param filters: ObjectType
 * @returns FilterProp[]
 * */

export const getFormattedFiltersWithDisplayName = (
  filters: Record<string, any>
) =>
  Object.values(filters).map((item) => ({
    displayName: item,
    queryValue: item,
  }));

/**
 * @method getTranslationLabel
 * A helper function to generate the translation key for existing translations else use the fallback label
 * @param label
 * @param key
 * @returns string[]
 * */
export const getTranslationLabel = (label: string, key: string): string[] => [
  `customer.details.filters.${key}.${label
    .toLowerCase()
    .replace(/ /g, '_')
    .replace(/[.,:\-/\\\s]/gm, '_')}`,
  label,
];

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value != null;
}
