import {
  Appointment,
  AssetType,
  Customer,
  DocumentType,
  ExtendedCar,
  GigyaAgent,
  HandoverInput,
  MetadataInput,
  OrderPaymentType,
  Outlet,
} from '@smart/adb-shared';
import { Language } from '@smart/types';
import { MarketCode } from '@utils/market/types';
import { TFunction } from 'i18next';
import { CustomerOrderQuery } from 'pages/customer/orders/queries.generated';
import { OutletOrderQuery } from 'pages/orders/queries.generated';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ChecklistValueAgent,
  ChecklistValueCustomer,
  HandoverFormValues,
  createHandoverPayload,
} from '../CreateDocumentType/CreateDocumentType.config';
import { ELO_VERSION_COMMENT, ELO_VERSION_LABEL } from '../Documents.config';
import { DocumentSource } from '../documents-context';

type CheckboxType =
  | 'checkbox'
  | 'special'
  | 'special-web-only'
  | 'special-cashbuy'
  | 'checkbox-adb-only';

type Checkbox = {
  type: CheckboxType;
  leasingSpecific?: boolean;
  label?: JSX.Element;
};

type CheckboxCustomer = Checkbox & {
  key: ChecklistValueCustomer;
};

type CheckboxAgent = Checkbox & {
  key: ChecklistValueAgent;
};

type Checklists = {
  customer?: CheckboxCustomer[];
  agent?: CheckboxAgent[];
};

interface HandoverContractConfig {
  market: MarketCode;
  checklists: Checklists;
}

export enum ContractType {
  Leasing,
  CashBuy,
  Loa,
}

const cashBuyConfigs: HandoverContractConfig[] = [
  {
    market: 'de',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        {
          key: 'checklist_fourteen_days_return',
          type: 'special-web-only',
        },
      ],
    },
  },
  {
    market: 'fr',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        {
          key: 'checklist_confirmation_insured',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        { key: 'checklist_tesla_charger', type: 'special' },
        {
          key: 'checklist_fourteen_days_return',
          type: 'special-web-only',
        },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'gb',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        {
          key: 'checklist_locking_wheel_nut',
          type: 'checkbox',
        },
        {
          key: 'checklist_fourteen_days_return',
          type: 'special-web-only',
        },
        { key: 'checklist_purchase_options', type: 'checkbox' },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'it',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        {
          key: 'checklist_fourteen_days_return',
          type: 'special-web-only',
        },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'ch',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'nl',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        {
          key: 'checklist_fourteen_days_return',
          type: 'special-web-only',
        },
      ],
      agent: [{ key: 'checklist_id', type: 'checkbox' }],
    },
  },
  {
    market: 'pt',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        {
          key: 'checklist_locking_wheel_nut',
          type: 'checkbox',
        },
        {
          key: 'checklist_fourteen_days_return',
          type: 'special-web-only',
        },
        {
          key: 'checklist_purchase_options',
          type: 'checkbox-adb-only',
        },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'se',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        {
          key: 'checklist_fourteen_days_return',
          type: 'special-cashbuy',
        },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'es',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        {
          key: 'checklist_fourteen_days_return',
          type: 'special-web-only',
        },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'be',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        {
          key: 'checklist_confirmation_insured',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        {
          key: 'checklist_fourteen_days_return',
          type: 'special-web-only',
        },
      ],
      agent: [
        { key: 'checklist_insurance', type: 'checkbox' },
        { key: 'checklist_coc_document', type: 'checkbox' },
        {
          key: 'checklist_certificate_registration',
          type: 'checkbox',
        },
      ],
    },
  },
  {
    market: 'at',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        {
          key: 'checklist_fourteen_days_return',
          type: 'special-web-only',
        },
      ],
    },
  },
];

const leasingConfigs: HandoverContractConfig[] = [
  {
    market: 'de',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'fr',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        {
          key: 'checklist_confirmation_insured',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        { key: 'checklist_tesla_charger', type: 'special' },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'gb',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        {
          key: 'checklist_locking_wheel_nut',
          type: 'checkbox',
        },
        { key: 'checklist_purchase_options', type: 'checkbox' },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'it',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'ch',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'nl',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
      ],
      agent: [{ key: 'checklist_id', type: 'checkbox' }],
    },
  },
  {
    market: 'pt',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'se',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'es',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'be',
    checklists: {
      customer: [
        {
          key: 'checklist_equipment',
          type: 'checkbox',
          leasingSpecific: true,
        },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        {
          key: 'checklist_documents',
          type: 'checkbox',
          leasingSpecific: true,
        },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        {
          key: 'checklist_confirmation_insured',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
      ],
      agent: [
        { key: 'checklist_insurance', type: 'checkbox' },
        { key: 'checklist_coc_document', type: 'checkbox' },
        {
          key: 'checklist_certificate_registration',
          type: 'checkbox',
        },
        { key: 'checklist_e705_application', type: 'checkbox' },
      ],
    },
  },
  {
    market: 'at',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        { key: 'checklist_awareness', type: 'checkbox' },
      ],
    },
  },
];

const loaConfigs: HandoverContractConfig[] = [
  {
    market: 'fr',
    checklists: {
      customer: [
        { key: 'checklist_equipment', type: 'checkbox' },
        { key: 'checklist_key', type: 'checkbox' },
        { key: 'checklist_accessories', type: 'checkbox' },
        { key: 'checklist_documents', type: 'checkbox' },
        {
          key: 'checklist_vehicle_condition',
          type: 'checkbox',
        },
        {
          key: 'checklist_confirmation_insured',
          type: 'checkbox',
        },
        { key: 'checklist_functionality', type: 'checkbox' },
        { key: 'checklist_tesla_charger', type: 'special' },
      ],
      agent: [
        { key: 'checklist_id', type: 'checkbox' },
        { key: 'checklist_insurance', type: 'checkbox' },
        { key: 'checklist_loa', type: 'checkbox' },
      ],
    },
  },
];
const translationKey = ({
  item,
  order,
}: {
  item: CheckboxCustomer | CheckboxAgent;
  order?: CustomerOrderQuery['customerOrder'];
}) => {
  if (!item) return null;

  const { key, type, leasingSpecific } = item;
  if (
    type === 'checkbox' ||
    (type === 'checkbox-adb-only' && order?.source === 'ADB')
  ) {
    return {
      ...item,
      label: leasingSpecific
        ? `form_fields.document_handover_contract.leasing.${key}`
        : `form_fields.document_handover_contract.${key}`,
    };
  }

  if (
    type === 'special' ||
    (type === 'special-web-only' && order?.source === 'Web') ||
    (type === 'special-cashbuy' &&
      order?.paymentType !== OrderPaymentType.Leasing &&
      order?.paymentType !== OrderPaymentType.Loa &&
      order?.paymentType !== OrderPaymentType.ThirdPartyLeasing)
  ) {
    return { ...item, label: `form_fields.document_handover_contract.${key}` };
  }

  return null;
};
export const getHandoverContractTranslations = (
  t: TFunction,
  marketCode: MarketCode,
  language: Language,
  checklists?: HandoverContractConfig,
  order?: CustomerOrderQuery['customerOrder']
): { [key: string]: JSX.Element } => {
  if (!checklists) return {};

  const { agent, customer } = checklists.checklists;
  const url = `https://${marketCode}.smart.com/${language}/service/terms-conditions/`;

  return {
    ...customer?.reduce((acc, item) => {
      const key = translationKey({
        item,
        order,
      })?.label;

      if (!key) {
        return acc;
      }

      return {
        ...acc,
        [item.key]: (
          <Trans
            t={t}
            i18nKey={key}
            components={{
              u: <u />,
              bold: <strong />,
              anchor: <Link to={url}>click here</Link>,
            }}
          />
        ),
      };
    }, {}),
    ...agent?.reduce((acc, item) => {
      const key = translationKey({
        item,
        order,
      })?.label;

      if (!key) {
        return acc;
      }

      return {
        ...acc,
        [item.key]: (
          <Trans
            t={t}
            i18nKey={key}
            components={{
              bold: <strong />,
              u: <u />,
              anchor: <Link to={url}>click here</Link>,
            }}
          />
        ),
      };
    }, {}),
  };
};

const removeHtmlTags = (str: string): string => str.split(/<[^>]*>/).join('');

export const translationsAsString = ({
  translations,
}: {
  translations: { [key: string]: JSX.Element };
}): { [key: string]: string } =>
  Object.entries(translations).reduce(
    (acc, [key, translation]) => ({
      ...acc,
      [key]: removeHtmlTags(translation.props.t(translation.props.i18nKey)),
    }),
    {}
  );

const getHandoverContractConfig = ({
  config,
  market,
  order,
  isOutletOrder,
}: {
  config: HandoverContractConfig[];
  market: MarketCode;
  order?: CustomerOrderQuery['customerOrder'];
  isOutletOrder?: boolean;
}): HandoverContractConfig => {
  const checklist = config.find((x) => x.market === market);
  const customer = checklist?.checklists.customer?.filter(
    (item) =>
      translationKey({
        item,
        order,
      }) !== null
  ) as CheckboxCustomer[];

  const agent = isOutletOrder
    ? undefined
    : (checklist?.checklists.agent?.filter(
        (item) =>
          translationKey({
            item,
            order,
          }) !== null
      ) as CheckboxAgent[]);

  return {
    market,
    checklists: {
      customer,
      agent,
    },
  };
};

export const getChecklistConfig = ({
  market,
  type,
  order,
  isOutletOrder,
}: {
  market: MarketCode;
  type?: ContractType | OrderPaymentType | null;
  order?: CustomerOrderQuery['customerOrder'];
  isOutletOrder?: boolean;
}): HandoverContractConfig | undefined => {
  if (type === undefined) return undefined;

  switch (type) {
    case OrderPaymentType.Cashbuy:
    case ContractType.CashBuy:
      return getHandoverContractConfig({
        config: cashBuyConfigs,
        market,
        order,
        isOutletOrder,
      });
    case ContractType.Loa:
      return getHandoverContractConfig({
        config: loaConfigs,
        market,
        order,
        isOutletOrder,
      });

    default:
      return getHandoverContractConfig({
        config: leasingConfigs,
        market,
        order,
        isOutletOrder,
      });
  }
};

export const getInitialValues = (
  config: HandoverContractConfig | undefined
): HandoverFormValues => ({
  comment: '',
  checklist_customer:
    config?.checklists.customer?.reduce(
      (acc, checkitem) => ({
        ...acc,
        [checkitem.key]: false,
      }),
      {}
    ) ?? {},

  checklist_agent:
    config?.checklists.agent?.reduce(
      (acc, checkitem) => ({
        ...acc,
        [checkitem.key]: false,
      }),
      {}
    ) ?? {},
});

export const createHandoverSubmitHandler =
  ({
    outlet,
    agent,
    selectedAppointment,
    selectedOrder,
    selectedCar,
    language,
    customer,
    tireSpecFlag,
    market,
    translations,
    docSystem,
    createDocument,
  }: {
    outlet?: Outlet;
    agent?: GigyaAgent;
    selectedAppointment?: Appointment;
    selectedOrder?:
      | CustomerOrderQuery['customerOrder']
      | OutletOrderQuery['outletOrder'];
    selectedCar?: ExtendedCar;
    language: Language;
    customer: Customer;
    tireSpecFlag: boolean;
    market: MarketCode;
    translations: { [key: string]: string };
    docSystem: DocumentSource;
    createDocument: (contract: HandoverInput, params: MetadataInput) => void;
  }) =>
  (formValues: HandoverFormValues) => {
    if (
      !outlet ||
      !agent ||
      !selectedOrder ||
      !selectedCar ||
      (!selectedAppointment && selectedCar.type === AssetType.SellableVehicle)
    ) {
      return;
    }

    const contract = createHandoverPayload({
      timestamp: new Date(),
      formValues,
      customer,
      outlet,
      agent,
      language,
      order: !tireSpecFlag
        ? { ...selectedOrder, wheelSpecification: undefined }
        : selectedOrder,
      market,
      car: selectedCar,
      translations,
    });

    const params: MetadataInput = {
      docSystem,
      transactionId: selectedAppointment?.id ?? '',
      uuid: customer.uuid,
      ...((customer.__typename === 'CustomerBusiness' ||
        selectedCar.type === AssetType.DemoVehicle) && { bpid: outlet.bpId }),
      marketCode: market,
      documentType:
        selectedCar.type === AssetType.DemoVehicle
          ? DocumentType.HANDOVER_CONTRACT_DEMO
          : DocumentType.HANDOVER_CONTRACT,
      language: customer.preferredLanguage ?? language,
      versionComment: ELO_VERSION_COMMENT,
      versionLabel: ELO_VERSION_LABEL,
    };

    createDocument(contract, params);
  };
