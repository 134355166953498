import { AppointmentType, Task } from '@smart/adb-shared';
import CalendarModalWrapper from '@smart/components-adb/calendar/CalendarModal';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button } from '@smart/react-components';
import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { isAppointmentCompleted } from '../utils';

export const TestDriveRescheduleCancelButton = ({
  task,
}: {
  task: Row<Task>;
}) => {
  const { t } = useTranslation();
  const { registerModal } = useModal();

  const { customer, appointment, vin } = task.original;

  return (
    <Button
      variant="primary"
      mode={200}
      disabled={!appointment || isAppointmentCompleted(task.original)}
      onClick={() =>
        appointment &&
        registerModal(
          <CalendarModalWrapper
            customerId={customer.uuid ?? ''}
            appointmentType={AppointmentType.TestDrive}
            carId={vin}
            preSelectedAppointmentId={
              task.original.appointment?.id ?? undefined
            }
            preSelectedStartTime={task.original.scheduledDate ?? undefined}
          />
        )
      }
    >
      {`${t('feature_calendar.general.buttons.reschedule')} / ${t('feature_calendar.general.buttons.cancel')}`}
    </Button>
  );
};
