import { CarDetails } from '@smart/adb-shared';
import AdbDynamicImage from '@smart/components-adb/molecules/AdbDynamicImage/AdbDynamicImage';
import { imageConfig4To3 } from '@smart/components-adb/molecules/AdbDynamicImage/AdbDynamicImage.config';
import { Text } from '@smart/react-components';
import { getTimeFormat } from '@ui/library/helpers/date';
import { DateFormats, getLocaleDate } from '@ui/library/helpers/date-locale';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useTestDriveContext } from 'contexts/test-drive-context';
import { useCustomerByIdQuery } from 'graphql/queries/customer.generated';
import FocusLayout from 'layouts/focus-layout/FocusLayout';
import { mapCarSelectItem } from 'pages/test-drive/config';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTestDriveTimeSlotsQuery } from '../../queries.generated';
import './Success.scss';

const BASE_CLASS = 'adb-success';

const TestDriveSuccess = () => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const { agent } = useAgentContext();

  const { navigation, currentTestDriveBooking } = useTestDriveContext();
  const { customerId, sfCustomerId } = currentTestDriveBooking;
  const { sfOrgId } = useParams();

  const { data: customerData } = useCustomerByIdQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        customerId: customerId ?? '',
        sfCustomerId: sfCustomerId ?? '',
        sfOrgId: sfOrgId ?? '',
      },
    },
    skip: !customerId || !sfCustomerId,
  });

  const customer = customerData?.customerById;

  /**
   * ------------------------------------------------------------
   * Test drive booking information
   * ------------------------------------------------------------
   */

  const { data } = useTestDriveTimeSlotsQuery({
    variables: {
      input: {
        outletId: agent.outletId,
        model: currentTestDriveBooking.carModel ?? '',
      },
    },
    skip: !currentTestDriveBooking.carModel || !agent.outletId,
    fetchPolicy: 'network-only',
  });

  const testDriveCars = useMemo(
    () =>
      data?.testDriveTimeSlots.slotDetails &&
      data?.testDriveTimeSlots.carDetails
        ? mapCarSelectItem(
            data.testDriveTimeSlots.slotDetails,
            data.testDriveTimeSlots.carDetails as CarDetails[]
          )
        : [],
    [data?.testDriveTimeSlots.carDetails, data?.testDriveTimeSlots.slotDetails]
  );

  const selectedCar = testDriveCars.find(
    (car) => car.carId === currentTestDriveBooking.car?.carId
  );

  // selected data
  // TODO: translate to local date string
  let displayDate;

  if (currentTestDriveBooking?.slot?.date) {
    displayDate = getLocaleDate(
      currentTestDriveBooking?.slot?.date,
      locale,
      DateFormats.DATE
    );
  }

  // TODO: translate to local date string
  const formatTime = (dateTime: string | undefined) => {
    if (!dateTime) return '';

    const newDate = new Date(dateTime);

    return getTimeFormat(newDate);
  };

  /**
   * ------------------------------------------------------------
   * Navigation
   * ------------------------------------------------------------
   */

  // test drive navigation
  const navData = navigation;

  /**
   * ------------------------------------------------------------
   * Page Content
   * ------------------------------------------------------------
   */

  return (
    <FocusLayout
      navList={navData}
      nextButtonLabel={t('test_drive.success.buttons.go_to_home_screen')}
      nextButtonRoute="/"
      isPrevStepsDisabled
      hideDialogWarning
    >
      {/**
       *  This screen shows the details of customer data, selected date and time slots for pickup and drop off
       *  It includes image & specification of the car
       */}

      <div className={`${BASE_CLASS}__container`}>
        <Text variant="hl-300" className={`${BASE_CLASS}__heading`} as="h1">
          {t('test_drive.success.success_message')}
        </Text>

        {currentTestDriveBooking?.slot && (
          <Text variant="p-100" as="p">
            {`${t('test_drive.success.success_date_label')} ${displayDate}`}
          </Text>
        )}

        <div className={`${BASE_CLASS}__content-wrap`}>
          <div className={`${BASE_CLASS}__content`}>
            {selectedCar?.name && (
              <Text variant="lbl-100" as="p">
                {selectedCar?.longName ?? selectedCar?.name}
              </Text>
            )}
            <div>
              {currentTestDriveBooking?.slot?.date && (
                <Text variant="lbl-100" as="p">
                  {displayDate}{' '}
                </Text>
              )}
              {currentTestDriveBooking?.slot?.pickUpTime && (
                <Text variant="cap-200" as="p">
                  {t('test_drive.time.pick_up', {
                    time: formatTime(currentTestDriveBooking?.slot?.pickUpTime),
                  })}
                </Text>
              )}
              {currentTestDriveBooking?.slot?.dropOffTime && (
                <Text variant="cap-200" as="p">
                  {t('test_drive.time.drop_off', {
                    time: formatTime(
                      currentTestDriveBooking?.slot?.dropOffTime
                    ),
                  })}
                </Text>
              )}
            </div>
            <div>
              {customer?.firstName && customer?.lastName && (
                <Text variant="lbl-100" as="p">
                  {`${customer.firstName} ${customer.lastName} `}
                </Text>
              )}
              {customer?.userId && (
                <Text variant="cap-200" as="p">
                  {customer.userId}
                </Text>
              )}
              {customer?.address?.street && (
                <Text variant="cap-200" as="p">
                  {customer?.address?.street}
                </Text>
              )}
              {customer?.address?.postalCode && customer?.address?.town && (
                <Text variant="cap-200" as="p">
                  {`${customer.address?.postalCode} ${customer.address?.town}`}
                </Text>
              )}
            </div>
          </div>
          {selectedCar?.image && (
            <div className={`${BASE_CLASS}__picture-wrap`}>
              <AdbDynamicImage
                imageUrl={selectedCar.image}
                imageAltText={selectedCar.name ?? '-'}
                imageConfig={imageConfig4To3}
                className={`${BASE_CLASS}__picture`}
              />
            </div>
          )}
        </div>
      </div>
    </FocusLayout>
  );
};

export default TestDriveSuccess;
