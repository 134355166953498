import { Customer, CustomerAccountType } from '@smart/adb-shared';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Text } from '@smart/react-components';
import { useNotificationContext } from 'contexts/notification-context';
import { useCustomerRegistrationMutation } from 'graphql/queries/customer.generated';
import {
  BusinessCustomerFormValues,
  PrivateCustomerFormValues,
} from 'pages/customer/details/helper';
import { getCustomerPayload } from 'pages/customer/helper';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentOutlet } from './outlet';

export const useConvertAccount = (
  customer: Customer | undefined,
  closeModal?: (onClose?: (() => void) | undefined) => void
) => {
  const { t } = useTranslation();
  const outlet = useCurrentOutlet();
  const { addNotification } = useNotificationContext();
  const navigate = useNavigate();
  const { customerId } = useParams();

  const [registerCustomer, { data, loading }] =
    useCustomerRegistrationMutation();

  const convertToFullAccount = (
    updatedFormValues: PrivateCustomerFormValues | BusinessCustomerFormValues
  ) => {
    const customerInfo = getCustomerPayload(
      {
        ...updatedFormValues,
        __typename:
          updatedFormValues.accountType === CustomerAccountType.Business
            ? 'CustomerBusiness'
            : 'CustomerPrivate',
        userId: customer?.userId ?? '',
        uuid: customerId ?? '',
      },
      outlet
    );

    registerCustomer({
      variables: {
        input: customerInfo,
      },
    });
  };

  useEffect(() => {
    if (loading || !data?.customerRegistration) return;

    if (closeModal) closeModal();

    if (data.customerRegistration.success) {
      addNotification({
        label: t('customer.register.success'),
        type: 'success',
        message: `${t('customer.details.convert_account.success')}`,
      });
      navigate('/customer');
    } else {
      addNotification({
        label: t('customer.register.error'),
        type: 'error',
        message: `${t('customer.details.convert_account.error')}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return {
    convertToFullAccount,
    loading,
  };
};

export const ConfirmAccountConvertDialog = ({
  customer,
  formValues,
}: {
  customer: Customer | undefined;
  formValues: PrivateCustomerFormValues | BusinessCustomerFormValues;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { convertToFullAccount, loading } = useConvertAccount(
    customer,
    closeModal
  );

  return (
    <AdbDialog
      id="confirm-account-convert-modal"
      buttons={{
        primary: {
          label: t('customer.details.convert_account.dialog.button.confirm'),
          onClick: () => convertToFullAccount(formValues),
          isLoading: loading,
          disabled: loading,
        },
        secondary: {
          label: t('customer.details.convert_account.dialog.button.cancel'),
          onClick: closeModal,
        },
      }}
    >
      <AdbDialog.Header>
        <Text variant="hl-100">
          {t('customer.details.convert_account.dialog.title')}
        </Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <Text variant="p-100" as="p">
          {t('customer.details.convert_account.dialog.message')}
        </Text>
      </AdbDialog.Content>
    </AdbDialog>
  );
};
