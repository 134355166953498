import { Text } from '@smart/react-components';
import { getCustomFormattedDate } from '@ui/library/helpers/date-locale';
import classNames from 'classnames';
import {
  addDays,
  addMonths,
  isSameDay,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdbLink from '../../atoms/AdbLink/AdbLink';
import { DateSlotsProps } from './DateSlots.config';
import './DateSlots.scss';

const DateSlots = ({
  onDaySelection,
  locale,
  enableDays = 13,
  selectedDate = '',
  showCalenderLink = false,
}: DateSlotsProps) => {
  const { t } = useTranslation();

  const BASE_CLASS = 'adb-date-picker';

  const [selectedDateValue, setSelectedDateValue] = useState<Date>(
    new Date(selectedDate)
  );

  const monthDiff = (dateFirst: Date, dateSecond: Date) => {
    let months;

    months = (dateSecond.getFullYear() - dateFirst.getFullYear()) * 12;
    months -= dateFirst.getMonth();
    months += dateSecond.getMonth();

    return months > 0 ? months : 0;
  };

  const startDate = new Date();

  const twoWeeksTime = startDate.getTime() + 1000 * 60 * 60 * 24 * enableDays;
  const lastDate = new Date(twoWeeksTime);
  const monthsDifference = monthDiff(startDate, lastDate);

  const onDateClick = (dateSelected: Date) => {
    setSelectedDateValue(dateSelected);
    onDaySelection(dateSelected.toISOString());
  };

  const getDaysBasedOnMonths = (
    start: number,
    end: number,
    month: Date,
    dateFormat: string
  ) => {
    const dayFormat = 'E';
    const days: Array<ReactElement> = [];

    for (let i = start; i < end; i += 1) {
      days.push(
        <div
          role="button"
          key={Number(addDays(month, i))}
          tabIndex={0}
          className={classNames(`${BASE_CLASS}__date-day-item`, {
            [`${BASE_CLASS}__date-day-item--disabled`]:
              addDays(month, i).getDay() === 0,
          })}
          onClick={() => onDateClick(addDays(month, i))}
          onKeyDown={() => onDateClick(addDays(month, i))}
        >
          <Text
            variant="cap-300"
            as="p"
            className={classNames({
              [`${BASE_CLASS}__date-day-item--disabled`]:
                addDays(month, i).getDay() === 0,
            })}
          >
            {getCustomFormattedDate(
              addDays(month, i),
              dayFormat,
              locale
            ).toLocaleUpperCase()}
          </Text>

          <Text
            variant="lbl-200"
            as="p"
            className={classNames(`${BASE_CLASS}__date-label`, {
              [`${BASE_CLASS}__date-day-item--selected`]: isSameDay(
                addDays(month, i),
                selectedDateValue
              ),
              [`${BASE_CLASS}__date-day-item--disabled`]:
                addDays(month, i).getDay() === 0,
            })}
          >
            {getCustomFormattedDate(addDays(month, i), dateFormat, locale)}
          </Text>
        </div>
      );
    }
    return days;
  };

  const renderDaysList = useMemo(() => {
    const months: Array<ReactElement> = [];
    const monthsLabel: Array<string> = [];
    const dateFormat = 'd';

    for (let i = 0; i <= monthsDifference; i += 1) {
      const month = startOfMonth(addMonths(startDate, i));
      const start =
        i === 0
          ? Number(getCustomFormattedDate(startDate, dateFormat, locale)) - 1
          : 0;
      const end =
        i === monthsDifference
          ? Number(getCustomFormattedDate(lastDate, 'd', locale))
          : Number(getCustomFormattedDate(lastDayOfMonth(month), 'd', locale));
      monthsLabel.push(getCustomFormattedDate(month, 'MMMM', locale));
      months.push(
        <div
          key={Number(addDays(month, i))}
          className={`${BASE_CLASS}__day-wrapper`}
          data-testid="render-days-list"
        >
          {getDaysBasedOnMonths(start, end, month, dateFormat)}
        </div>
      );
    }
    return (
      <>
        <div className={`${BASE_CLASS}__title-container`}>
          <Text variant="hl-300" as="h1">
            {monthsLabel.length > 1
              ? `${monthsLabel[0]}/${monthsLabel[1]}`
              : monthsLabel[0]}
          </Text>
          {showCalenderLink && (
            <span className={`${BASE_CLASS}__open-calendar`}>
              <AdbLink
                path="/calendar"
                title={t('test_drive.time.different_date')}
                icon={{ icon: 'calendar' }}
                iconPosition="left"
              />
            </span>
          )}
        </div>

        <div
          key={Number(selectedDateValue)}
          className={`${BASE_CLASS}__dates-wrapper`}
        >
          {months}
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateValue]);

  return (
    <div key={Number(selectedDateValue)} className={BASE_CLASS}>
      {renderDaysList}
    </div>
  );
};

export default DateSlots;
