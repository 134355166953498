import CartItems from '@smart/components-adb/molecules/AldCartItems/CartItems';
import { CartsContextProvider } from '@smart/components-adb/molecules/AldCartItems/CartItemsContext';

const Carts = () => (
  <CartsContextProvider>
    <CartItems />
  </CartsContextProvider>
);

export default Carts;
