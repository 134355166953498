import { useTranslation } from 'react-i18next';
import { string } from 'yup';

import { MarketCode } from '@utils/market/types';
import { REGEX_FORMS } from '../configs/forms';

export const CountrySchema = (
  validationMarket: MarketCode,
  required = false
) => {
  const { t } = useTranslation();

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'de':
        schemaObject = {
          countryCode: required
            ? string()
                .required(
                  `${t('form_fields.basic.country')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  REGEX_FORMS.stringCharacters.de,
                  `${t('form_fields.basic.country')} ${t(
                    'general.error_messages.validations.invalid'
                  )}`
                )
            : string().matches(
                REGEX_FORMS.stringCharacters.de,
                `${t('form_fields.basic.country')} ${t(
                  'general.error_messages.validations.invalid'
                )}`
              ),
        };
        break;
      default:
        schemaObject = {
          countryCode: required
            ? string()
                .required(
                  `${t('form_fields.basic.country')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  REGEX_FORMS.stringCharacters.default,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.country'
                  )} `
                )
            : string().matches(
                REGEX_FORMS.stringCharacters.default,
                `${t('general.error_messages.validations.invalid')} ${t(
                  'form_fields.basic.country'
                )} `
              ),
        };
        break;
    }
  }

  return schemaObject;
};
