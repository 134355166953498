import { Icon, Text } from '@smart/react-components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { UnOrderedList } from '../../atoms/List/List';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import {
  TABLE_PAGE_SIZES,
  getTablePageSizeOptions,
} from '../Table/TableComponents/TableComponents.config';
import { PaginationProps } from './Pagination.config';
import './Pagination.scss';

const BASE_CLASS = 'adb-pagination';

const siblingCount = 1;

const Pagination = <T,>({ instance, defaultSize }: PaginationProps<T>) => {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();

  const pageCount = instance.getPageCount();
  const itemCount = instance.getRowCount();
  const moreThanOnePage = pageCount > 1;

  const currentPage = Number(params.get('page') ?? 1);
  const currentPageSize = Number(
    params.get('size') ?? defaultSize ?? TABLE_PAGE_SIZES[0]
  );

  const setPage = (value: number) => {
    const newParams = new URLSearchParams(params.toString());
    newParams.set('page', value.toString());
    setParams(newParams);
  };

  const setPageSize = (num: number) => {
    const newParams = new URLSearchParams(params.toString());
    newParams.set('size', num.toString());
    // Set current page to the last one if it does not exist in the current page size
    if (itemCount < num * currentPage) {
      newParams.set('page', Math.ceil(itemCount / num).toString());
    }
    setParams(newParams);
  };

  useEffect(() => {
    instance.setPageIndex(currentPage - 1);
  }, [instance, currentPage]);

  useEffect(() => {
    instance.setPageSize(currentPageSize);
  }, [instance, currentPageSize]);

  const goToPage = (page: number) => {
    setPage(page);
  };

  const goToFirstPage = () => {
    setPage(1);
  };

  const goToPreviousPage = () => {
    setPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setPage(currentPage + 1);
  };

  const goToLastPage = () => {
    setPage(pageCount);
  };

  const firstPage = 1;
  const lastPage = pageCount;
  const visiblePages = 5;

  const maxVisiblePages = siblingCount * 2 + visiblePages;

  const DOTS = '...';
  const showHiddenPages = siblingCount + 1;

  const [pageList, setPageList] = useState<number[]>([]);
  const [leftHiddenPages, setLeftHiddenPages] = useState<number[]>([]);
  const [rightHiddenPages, setRightHiddenPages] = useState<number[]>([]);

  const getPages = (start: number, end: number): Array<number> => {
    const startIndex = start + 1;
    const endIndex = end;
    const length = endIndex - startIndex;

    return Array.from({ length }, (_, id) => id + startIndex);
  };

  useEffect(() => {
    const getCurrentIndexValue = (): number =>
      currentPage === firstPage || currentPage === lastPage ? 0 : 1;

    const getSiblingsCount = (diff: number) =>
      diff > showHiddenPages ? siblingCount : Math.max(0, diff);

    const getStartIndex = (leftSiblings: number, firstSibling: number) =>
      Math.max(currentPage - leftSiblings, firstSibling);

    const getPageList = (): Array<number> => {
      const firstSibling = firstPage + 1;
      const lastSibling = lastPage - 1;

      const leftSiblings = getSiblingsCount(currentPage - firstSibling);
      const rightSiblings = getSiblingsCount(lastSibling - currentPage);

      const currentIndex = getCurrentIndexValue();

      const startIndex = getStartIndex(leftSiblings, firstSibling);

      const numberOfPages = leftSiblings + rightSiblings + currentIndex;

      return Array.from({ length: numberOfPages }, (_, id) => id + startIndex);
    };

    if (pageCount < 1) return;

    if (pageCount <= maxVisiblePages) {
      setPageList(getPages(firstPage, pageCount));
    } else {
      setPageList(getPageList());
    }
  }, [
    currentPage,
    pageCount,
    maxVisiblePages,
    firstPage,
    lastPage,
    showHiddenPages,
  ]);

  useEffect(() => {
    setLeftHiddenPages(getPages(firstPage, pageList[0]));
    setRightHiddenPages(getPages(pageList[pageList.length - 1], lastPage));
  }, [pageList, lastPage, firstPage]);

  const showPages = (left: boolean) =>
    left ? leftHiddenPages : rightHiddenPages;

  return (
    <div className={BASE_CLASS}>
      {moreThanOnePage && (
        <UnOrderedList>
          <button
            type="button"
            className={classNames(`${BASE_CLASS}__page`, {
              [`${BASE_CLASS}__page--disabled`]: firstPage === currentPage,
              [`${BASE_CLASS}__page--not-current`]: firstPage !== currentPage,
            })}
            onClick={goToPreviousPage}
            aria-label="previous-page"
            disabled={firstPage === currentPage}
          >
            <Icon icon="chevron-compact-left" />
          </button>
          <button
            type="button"
            className={classNames(`${BASE_CLASS}__page`, {
              [`${BASE_CLASS}__page--current`]: firstPage === currentPage,
              [`${BASE_CLASS}__page--not-current`]: firstPage !== currentPage,
            })}
            onClick={goToFirstPage}
          >
            <Text
              variant="cap-300"
              as="p"
              className={classNames(`${BASE_CLASS}__text`, {
                [`${BASE_CLASS}__text--current`]: firstPage === currentPage,
              })}
            >
              {firstPage}
            </Text>
          </button>
          {leftHiddenPages.length > 0 && (
            <button
              type="button"
              className={`${BASE_CLASS}__page`}
              onClick={() => showPages(true)}
              aria-label="left-hidden"
            >
              <Text variant="cap-300" as="p" className={`${BASE_CLASS}__text`}>
                {DOTS}
              </Text>
            </button>
          )}
          {pageList &&
            pageList.map((page) => (
              <button
                type="button"
                onClick={() => goToPage(page)}
                key={page}
                className={classNames(`${BASE_CLASS}__page`, {
                  [`${BASE_CLASS}__page--current`]: page === currentPage,
                  [`${BASE_CLASS}__page--not-current`]: page !== currentPage,
                })}
              >
                <Text
                  variant="cap-300"
                  as="p"
                  className={classNames(`${BASE_CLASS}__text`, {
                    [`${BASE_CLASS}__text--current`]: page === currentPage,
                  })}
                >
                  {page}
                </Text>
              </button>
            ))}
          {rightHiddenPages.length > 0 && (
            <button
              type="button"
              className={`${BASE_CLASS}__page`}
              onClick={() => showPages(false)}
              aria-label="right-hidden"
            >
              <Text variant="cap-300" as="p" className={`${BASE_CLASS}__text`}>
                {DOTS}
              </Text>
            </button>
          )}
          {!!lastPage && (
            <button
              type="button"
              className={classNames(`${BASE_CLASS}__page`, {
                [`${BASE_CLASS}__page--current`]: lastPage === currentPage,
                [`${BASE_CLASS}__page--not-current`]: lastPage !== currentPage,
              })}
              onClick={goToLastPage}
            >
              <Text
                variant="cap-300"
                as="p"
                className={classNames(`${BASE_CLASS}__text`, {
                  [`${BASE_CLASS}__text--current`]: lastPage === currentPage,
                })}
              >
                {lastPage}
              </Text>
            </button>
          )}
          <button
            type="button"
            className={classNames(`${BASE_CLASS}__page`, {
              [`${BASE_CLASS}__page--disabled`]: lastPage === currentPage,
              [`${BASE_CLASS}__page--not-current`]: lastPage !== currentPage,
            })}
            onClick={goToNextPage}
            aria-label="next-page"
            disabled={lastPage === currentPage}
          >
            <Icon icon="chevron-compact-right" />
          </button>
        </UnOrderedList>
      )}
      <div className={`${BASE_CLASS}__select`}>
        <FilterDropdown
          label={t('customer.search.show')}
          items={getTablePageSizeOptions()}
          onSelect={(e) =>
            e !== undefined
              ? setPageSize(Number(e.queryValue))
              : setPageSize(Number(TABLE_PAGE_SIZES[0]))
          }
          selectedFilter={{
            queryValue: instance.getState().pagination.pageSize.toString(),
            displayName: instance.getState().pagination.pageSize.toString(),
          }}
          defaultSelected={getTablePageSizeOptions()[0]}
          showSelectedFilter
          yPos="bottom"
        />
      </div>
    </div>
  );
};

export default Pagination;
