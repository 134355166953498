import { Icon, Text } from '@smart/react-components';
import classNames from 'classnames';
import AdbIcon from '../../../atoms/AdbIcon/AdbIcon';
import { IconColor } from '../../../atoms/AdbIcon/AdbIcon.config';
import { ListItem } from '../../../atoms/List/List';
import { HeaderProps, ImageType, LengthType } from '../CarListItem.config';
import './Header.scss';

const BASE_CLASS = 'adb-list-header';

const Header = ({
  open = false,
  handleAccordion,
  featuresCount = 0,
  carInfo = undefined,
  name = '',
  availableBadge = [],
  hasAccordion,
  children,
}: HeaderProps) => {
  const header = (
    <div
      className={classNames(`${BASE_CLASS}__title`, {
        [`${BASE_CLASS}__title--mobile`]:
          !open && featuresCount <= LengthType.FOUR,
      })}
    >
      <Text className={`${BASE_CLASS}__name`} variant="lbl-200" as="p">
        {name}
      </Text>
      {carInfo?.key && (
        <div className={`${BASE_CLASS}__icon`}>
          {carInfo?.key === ImageType.WARNING && (
            <AdbIcon icon="warning" color={IconColor.ERROR} />
          )}
          {carInfo?.key === ImageType.REQUESTSUBMITTED && (
            <Icon icon="duration" />
          )}
          {carInfo?.value && (
            <Text
              variant="cap-300"
              as="p"
              className={classNames(`${BASE_CLASS}__icon`, {
                [`${BASE_CLASS}__icon--mobile`]:
                  !open && featuresCount > LengthType.FOUR,
              })}
            >
              {carInfo.value}
            </Text>
          )}
        </div>
      )}
      {availableBadge && availableBadge?.length > LengthType.ZERO && (
        <div
          className={classNames(`${BASE_CLASS}__badges`, {
            [`${BASE_CLASS}__badges--mobile`]:
              !open && featuresCount > LengthType.FOUR,
          })}
        >
          {availableBadge.map((availabilityValue) => (
            <ListItem
              additionalClasses={`${BASE_CLASS}__badge`}
              content={availabilityValue}
              key={availabilityValue}
            />
          ))}
        </div>
      )}
    </div>
  );

  return children || hasAccordion ? (
    <div
      className={classNames(`${BASE_CLASS}__accordion`, {
        [`${BASE_CLASS}__accordion--closed`]: !open,
      })}
      onClick={handleAccordion}
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
    >
      {header}
      <Icon
        icon={open ? 'chevron-compact-up' : 'chevron-compact-down'}
        mode={300}
        className={`${BASE_CLASS}__chevron-icon`}
      />
    </div>
  ) : (
    header
  );
};

export default Header;
