import { Select } from '@smart/react-components';
import type { SelectOptionProps } from 'features/features.config';
import { useTranslation } from 'react-i18next';

const TypeOfContractSelect = ({
  value,
  handleChange,
  options,
  disabled,
}: SelectOptionProps) => {
  const { t } = useTranslation();

  return (
    <Select
      id="typeOfContract"
      name="typeOfContract"
      label={t('form_fields.documents.type_of_document')}
      items={options ?? []}
      value={value}
      onSelectionChange={handleChange}
      disabled={disabled}
    >
      {(items) =>
        items.map((item) => <Select.Item key={item.value} {...item} />)
      }
    </Select>
  );
};

export default TypeOfContractSelect;
