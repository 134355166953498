import { TestDriveSlotTimeSlot } from '@smart/adb-shared';
import { Button } from '@smart/react-components';
import { TestDriveBookingSlot } from '@ui/data-models/test-drive/test-drive.model';
import './TimeSlotsPanel.scss';

const BASE_CLASS = 'adb-time-slot';

/**
 * @exports
 * @type TimeSlotSelectionProps
 * Props types for TimeSlotSelectionPanel component
 */
export type TimeSlotSelectionProps = {
  timeSlots: TestDriveSlotTimeSlot[];
  selectedTimeSlot?: TestDriveBookingSlot;
  onTimeSlotSelected: (slot: TestDriveBookingSlot) => void;
};

/**
 * TimeSlotSelectionPanel component
 * @param props TimeSlotSelectionProps
 * @returns <TimeSlotSelectionPanel> ReactNode
 */
const TimeSlotSelectionPanel = (props: TimeSlotSelectionProps) => {
  const { timeSlots, selectedTimeSlot, onTimeSlotSelected } = props;

  return (
    <div className={BASE_CLASS}>
      {timeSlots.map((timeSlot) => {
        const slotPickUpTime = new Date(timeSlot.pickUpTime);
        const slotDropOffTime = new Date(timeSlot.dropOffTime);
        const addPrefixZero = (num: number): string =>
          (num < 10 ? '0' : '') + num;

        // @TODO: move to a date utility library
        const timeSlotLabel = `${addPrefixZero(
          slotPickUpTime.getHours()
        )}:${addPrefixZero(slotPickUpTime.getMinutes())} - ${addPrefixZero(
          slotDropOffTime.getHours()
        )}:${`${addPrefixZero(slotDropOffTime.getMinutes())}`}`;

        return (
          <Button
            variant="secondary"
            mode={200}
            disabled={!timeSlot.isAvailable}
            className={`${BASE_CLASS}__button ${
              selectedTimeSlot?.pickUpTime === timeSlot.pickUpTime &&
              selectedTimeSlot?.dropOffTime === timeSlot.dropOffTime
                ? 'active'
                : ''
            }`}
            onClick={() => {
              onTimeSlotSelected({
                date: timeSlot.pickUpTime,
                pickUpTime: timeSlot.pickUpTime,
                dropOffTime: timeSlot.dropOffTime,
              });
            }}
            key={timeSlot.pickUpTime}
          >
            {timeSlotLabel}
          </Button>
        );
      })}
    </div>
  );
};

export default TimeSlotSelectionPanel;
