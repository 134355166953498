import { Checkbox, Icon } from '@smart/react-components';
import classNames from 'classnames';
import AdbTooltip from '../../AdbTooltip/AdbTooltip';
import '../FilterDropdown.scss';
import { FilterItemProps } from './FilterItem.config';

const BASE_CLASS = 'adb-filter-dropdown';

const FilterItem = ({
  filterItem,
  isSelected,
  onFilterSelect,
  shouldShowCheckbox = false,
}: FilterItemProps) => {
  const onFilterClick = () => {
    onFilterSelect(filterItem);
  };

  const props = filterItem.disabled
    ? {}
    : {
        onClick: onFilterClick,
        onKeyDown: onFilterClick,
        tabIndex: 0,
      };

  return (
    <li
      className={classNames(`${BASE_CLASS}__item`, {
        [`${BASE_CLASS}__item--selected`]: isSelected,
        [`${BASE_CLASS}__item--selected-disabled`]: filterItem.disabled,
      })}
      data-testid={filterItem.displayName}
    >
      <div className={`${BASE_CLASS}__item-container`} role="button" {...props}>
        {shouldShowCheckbox && (
          <div>
            <Checkbox
              value={filterItem.queryValue}
              id={filterItem.queryValue}
              checked={isSelected}
            />
          </div>
        )}

        <label
          htmlFor={filterItem.queryValue}
          className={classNames({
            [`${BASE_CLASS}__item--selected-title`]: isSelected,
            [`${BASE_CLASS}__item--selected-title-disabled`]:
              filterItem.disabled,
          })}
          data-testid={`${filterItem.displayName}-label`}
        >
          {filterItem.displayName}
        </label>
        {filterItem.disabled && filterItem.disabledMessage && (
          <AdbTooltip
            className={`${BASE_CLASS}__item-tooltip`}
            title=""
            message={filterItem.disabledMessage}
          >
            <Icon icon="info-legal" />
          </AdbTooltip>
        )}
      </div>
    </li>
  );
};

export default FilterItem;
