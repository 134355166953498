import { Dropdown } from '@smart/adb-shared';
import { getTranslationLabel } from '@ui/library/helpers/filters';
import { useTranslation } from 'react-i18next';

const useSelect = () => {
  const { t } = useTranslation();
  const getTranslatedFilters = (filters: Dropdown[], key: string): Dropdown[] =>
    filters.map((item) => ({
      label: t(getTranslationLabel(item.label, key)),
      value: item.value,
    }));
  return { getTranslatedFilters };
};

export default useSelect;
