import {
  getNavigationItem,
  handleRequiredSteps,
} from '@utils/helpers/test-drive';
import { useTestDriveContext } from 'contexts/test-drive-context';
import FocusLayout from 'layouts/focus-layout/FocusLayout';
import CustomerSearch from 'pages/customer/search';
import { TestDriveBookingSteps } from 'pages/test-drive/config';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Account.scss';

const BASE_CLASS = 'adb-test-drive-user-details';

const Account = () => {
  const navigate = useNavigate();
  const { navigation } = useTestDriveContext();

  // Navigating to "Account" step from previous/next test drive steps
  useEffect(() => {
    handleRequiredSteps(
      navigation,
      [TestDriveBookingSteps.CAR, TestDriveBookingSteps.TIME],
      navigate
    );
  });

  return (
    <FocusLayout
      navList={navigation}
      backButtonRoute={
        getNavigationItem(navigation, TestDriveBookingSteps.TIME).path
      }
    >
      <div className={BASE_CLASS}>
        <CustomerSearch isTestDrive />
      </div>
    </FocusLayout>
  );
};

export default Account;
