import { CarStatus } from '@smart/adb-shared';
import { ButtonProps } from '@smart/react-components';
import { PropsWithChildren, ReactNode } from 'react';
import { BadgeThemes } from '../../atoms/Badge/Badge.config';

export type CarDetailsKey = string | { text: string; tooltip: ReactNode };

type CarDetailsType = {
  key: CarDetailsKey;
  value: ReactNode;
  type?: CarCategoryType;
  badgeColor?: BadgeThemes;
  inlineTextValue?: string;
  loading?: boolean;
  extraText?: string;
};

type CustomInfoType = {
  key: ImageType;
  value?: string;
  imgSrc?: string;
};

export type ActionType = {
  key: string;
  type?: ActionItemType;
  onClick?: () => void;
  buttonType?: ButtonProps['variant'];
  buttonSize?: ButtonProps['mode'];
  isLoading?: boolean;
};

export enum CarCategoryType {
  BADGE = 'badge',
  TEXT = 'text',
  INLINETEXT = 'inlineText',
}

export enum AvailableBadge {
  MORNING = 'Morning',
  MIDDAY = 'Midday',
  EVENING = 'Evening',
}

export enum ImageType {
  WARNING = 'warning',
  REQUESTSUBMITTED = 'request_submitted',
  IMAGE = 'image',
}

export enum LengthType {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  EIGHT = 8,
}

export enum ActionItemType {
  LINK = 'link',
  BUTTON = 'button',
}

export interface CarListItemProps {
  id: string;
  name: JSX.Element | string;
  imageSrc?: string;
  imageAltText?: string;
  carDetails: CarDetailsType[];
  carInfo?: CustomInfoType;
  availableBadge?: AvailableBadge[];
  additionalClasses?: string;
  actionItems?: ActionType[];
  actionItemsWithText?: ActionsWithTextProps;
  children?: ReactNode;
  isInfleeted?: boolean;
  location?: string;
  slice?: LengthType;
  showAllFeatureItems?: boolean;
  status?: CarStatus;
  showBorder?: boolean;
  hasAccordion?: boolean;
  isOpen?: boolean;
  imageShare?: '15' | '20' | '30';
  handleAccordionProp?: (open: boolean) => void;
  hasNoAccordion?: boolean;
}

export interface FeatureProps {
  carItem: CarDetailsType;
  index: number;
  featuresCount: number;
  open: boolean;
  loading?: boolean;
}

export interface HeaderProps extends PropsWithChildren {
  open: boolean;
  handleAccordion: () => void;
  featuresCount: number;
  carInfo?: CustomInfoType;
  availableBadge?: AvailableBadge[];
  name: ReactNode;
  hasAccordion?: boolean;
}

export interface ActionsProps {
  actionItems: ActionType[];
}

export type ActionsWithTextProps = {
  text: string | ReactNode;
  open?: boolean;
  actionItems?: ActionType[];
} & PropsWithChildren;

/**
 * --------------------
 * Mock data for different variants
 * --------------------
 */

export const ArgsCarListItemIcon: CarListItemProps = {
  id: '1',
  name: 'SUV Advantage 412 WD',
  imageSrc:
    'https://s7.future.smart.com/is/image/smarteuropestage/SMART_HX1ERD1A51EU010032_CUS_VLN_STAGE_D-S1_FR-00',
  carInfo: {
    key: ImageType.WARNING,
    value: 'leasing running out',
  },
  carDetails: [
    {
      key: 'Interior',
      value: 'Marple Black/ Simple White',
    },
    {
      key: 'Exterior',
      value: 'Flash Yellow/ Marple Black',
    },
    {
      key: 'Leasing',
      value: '210$',
      type: CarCategoryType.INLINETEXT,
      inlineTextValue: 'Delivery: ',
    },
    {
      key: 'Available',
      value: 'now',
      type: CarCategoryType.BADGE,
      badgeColor: BadgeThemes.SUCCESS,
    },
    {
      key: 'Next Inspection',
      value: '21.04.2022',
    },
    {
      key: 'Registration Date',
      value: '21.04.2018',
    },
  ],
  actionItems: [
    {
      key: 'Customize',
      onClick: () => {
        // eslint-disable-next-line no-console
        console.log('Customize button clicked');
      },
      type: ActionItemType.BUTTON,
      buttonType: 'secondary',
    },
    {
      key: 'Open Documents',
      onClick: () => {
        // eslint-disable-next-line no-console
        console.log('Open Documents button clicked');
      },
      type: ActionItemType.BUTTON,
    },
  ],
};

export const ArgsCarListItemBadges: CarListItemProps = {
  id: '1',
  name: 'SUV Advantage 412 WD',
  availableBadge: [AvailableBadge.EVENING],
  imageSrc:
    'https://s7.future.smart.com/is/image/smarteuropestage/SMART_HX1ERD1A51EU010032_CUS_VLN_STAGE_D-S1_FR-00',
  carDetails: [
    {
      key: 'Interior',
      value: 'Marple Black/ Simple White',
    },
    {
      key: 'Exterior',
      value: 'Flash Yellow/ Marple Black',
    },
    {
      key: 'Status',
      value: '04.06.2022',
      type: CarCategoryType.INLINETEXT,
      inlineTextValue: 'Delivery: ',
    },
    {
      key: 'Available',
      value: 'now',
      type: CarCategoryType.BADGE,
      badgeColor: BadgeThemes.SUCCESS,
    },
  ],
  actionItems: [
    {
      key: '10.00 - 12.00',
      onClick: () => {
        // eslint-disable-next-line no-console
        console.log('10.00 - 12.00 button clicked');
      },
      type: ActionItemType.LINK,
    },
  ],
};

export const ArgsCarListItemActionWithText: CarListItemProps = {
  id: '1',
  name: 'SUV Advantage 412 WD',
  imageSrc:
    'https://s7.future.smart.com/is/image/smarteuropestage/SMART_HX1ERD1A51EU010032_CUS_VLN_STAGE_D-S1_FR-00',
  carDetails: [
    {
      key: 'Interior',
      value: 'Marple Black/ Simple White',
    },
    {
      key: 'Exterior',
      value: 'Flash Yellow/ Marple Black',
    },
    {
      key: 'Milage',
      value: '30 000km',
    },
    {
      key: 'Leasing',
      value: '210$',
    },
    {
      key: 'Leasing',
      value: '210$',
    },
    {
      key: 'Leasing',
      value: '210$',
    },
    {
      key: 'Leasing',
      value: '210$',
    },
  ],
  actionItemsWithText: {
    text: 'price range',
    actionItems: [
      {
        key: 'Customize',
        onClick: () => {
          // eslint-disable-next-line no-console
          console.log('Customize button clicked');
        },
      },
      {
        key: 'Explore',
        type: ActionItemType.LINK,
        onClick: () => {
          // eslint-disable-next-line no-console
          console.log('Customize button clicked');
        },
      },
    ],
  },
};
