import { CustomerContextProvider } from 'contexts/customer-context';
import { TestDriveContextProvider } from 'contexts/test-drive-context';
import DashboardLayout from 'layouts/dashboard-layout/DashboardLayout';
import HomePage from 'pages';
import { ErrorPage404 } from 'pages/404';
import AuthError from 'pages/auth/error';
import { Outlet, Route } from 'react-router-dom';
import { Routes } from 'routes.config';
import { calendar } from './pages/calendar/routes';
import { customer } from './pages/customer/routes';
import { orders } from './pages/orders/routes';
import { outlet } from './pages/outlet/routes';
import { stock } from './pages/stock/routes';
import { tasks } from './pages/tasks/routes';
import { testDrive } from './pages/test-drive/routes';

const dashboardLayoutRoutes: Routes[] = [
  {
    path: '/',
    component: HomePage,
    content: 'general.title',
    title: 'general.title',
  },
  ...customer,
  ...outlet,
  ...tasks,
  ...stock,
  ...orders,
];

const routesWithoutWrapperLayout: Routes[] = [
  {
    path: '/auth/error/:errorType?',
    component: AuthError,
    content: 'auth.error_page.content',
    title: 'auth.error_page.title',
  },
  ...testDrive,
  ...calendar,
];

export const applicationRoutes: Routes[] = [
  ...routesWithoutWrapperLayout,
  ...dashboardLayoutRoutes,
];

export const getRoutes = () => (
  <>
    <Route path="/auth/error" element={<AuthError />} />
    {routesWithoutWrapperLayout.map((route) => (
      <Route
        path={route.path}
        element={
          route.path.includes('/test-drive/') ? (
            <TestDriveContextProvider>
              <CustomerContextProvider>
                <route.component />
              </CustomerContextProvider>
            </TestDriveContextProvider>
          ) : (
            <route.component />
          )
        }
        key={route.path}
      />
    ))}
    <Route
      path="/"
      element={
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      }
    >
      <Route index element={<HomePage />} />
      {dashboardLayoutRoutes.map(
        (route: Routes) =>
          route.path !== '/' && (
            <Route
              path={route.path}
              element={
                route.path.includes('/customer/') ? (
                  <CustomerContextProvider>
                    <route.component />
                  </CustomerContextProvider>
                ) : (
                  <route.component />
                )
              }
              key={route.path}
            />
          )
      )}
    </Route>

    <Route path="*" element={<ErrorPage404 />} />
  </>
);
