import { Icon } from '@smart/react-components';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { AdbLinkProps } from './AdbLink.config';
import './AdbLink.scss';

const BASE_CLASS = 'adb-link';

const AdbLink = ({
  path,
  title,
  additionalClasses,
  target,
  icon,
  iconPosition,
  state,
  variant = 'hyperlink-standalone',
  notLink = false,
  disabled = false,
}: AdbLinkProps) =>
  !notLink && path ? (
    <Link
      to={path}
      target={target}
      state={state}
      className={classnames(
        BASE_CLASS,
        additionalClasses,
        `${BASE_CLASS}--${variant}`,
        { [`${BASE_CLASS}--icon-${iconPosition}`]: iconPosition }
      )}
    >
      <span className={`${BASE_CLASS}__text`}>{title}</span>
      {icon && <Icon {...icon} className={`${BASE_CLASS}__icon`} />}
    </Link>
  ) : (
    <span
      className={classnames(
        BASE_CLASS,
        additionalClasses,
        `${BASE_CLASS}--${variant}`,
        { [`${BASE_CLASS}--icon-${iconPosition}`]: iconPosition },
        { [`${BASE_CLASS}--${variant}--disabled`]: disabled }
      )}
    >
      <span className={`${BASE_CLASS}__text`}>{title}</span>
      {icon && <Icon {...icon} className={`${BASE_CLASS}__icon`} />}
    </span>
  );

export default AdbLink;
