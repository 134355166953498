import { CustomerAccountType } from '@smart/adb-shared';
import { CompanyNameSchema } from '@ui/validations/schemas/CompanyName';
import { CountrySchema } from '@ui/validations/schemas/Country';
import { FirstNameSchema } from '@ui/validations/schemas/FirstName';
import { LastNameSchema } from '@ui/validations/schemas/LastName';
import { MobileNumberSchema } from '@ui/validations/schemas/MobileNumber';
import { PostalCodeSchema } from '@ui/validations/schemas/PostalCode';
import { PreferredLanguageSchema } from '@ui/validations/schemas/PreferredLanguage';
import { RegionSchema } from '@ui/validations/schemas/Region';
import { StreetSchema } from '@ui/validations/schemas/Street';
import { TitleSchema } from '@ui/validations/schemas/Title';
import { TownSchema } from '@ui/validations/schemas/Town';
import { UserIdSchema } from '@ui/validations/schemas/UserId';
import { VatRegistrationNumberSchema } from '@ui/validations/schemas/VatRegistrationNumber';
import { MarketCode } from '@utils/market/types';
import { object } from 'yup';

const BasicInfoSchema = (
  market: MarketCode,
  customerAccountType: CustomerAccountType
) => {
  const companyName = CompanyNameSchema(market, true);
  const countryCode = CountrySchema(market, true);
  const email = UserIdSchema(market);
  const firstName = FirstNameSchema(market, true);
  const lastName = LastNameSchema(market, true);
  const mobile = MobileNumberSchema();
  const postalCode = PostalCodeSchema(market, true);
  const preferredLanguage = PreferredLanguageSchema(market, true);
  const region = RegionSchema(true);
  const street = StreetSchema(market, true);
  const titleCode = TitleSchema(market, true);
  const town = TownSchema(true);
  const vatRegistrationNumber = VatRegistrationNumberSchema(market);

  let schemaObject = object().shape({
    ...titleCode,
    ...email,
    ...firstName,
    ...lastName,
    ...mobile,
    ...town,
    ...region,
    ...postalCode,
    ...street,
    ...countryCode,
    ...preferredLanguage,
  });

  if (customerAccountType === CustomerAccountType.Business) {
    schemaObject = schemaObject.concat(
      object().shape({
        ...vatRegistrationNumber,
        ...companyName,
      })
    );
  }
  return schemaObject;
};

export default BasicInfoSchema;
