import { CommercialRegisterNrSchema } from '@ui/validations/schemas/CommercialRegisterNr';
import { LegalFormSchema } from '@ui/validations/schemas/LegalForm';
import { MarketCode } from '@utils/market/types';
import { object } from 'yup';

const BusinessCompanyInfoSchema = (validationMarket: MarketCode) => {
  const legalForm = LegalFormSchema(validationMarket, false);

  const commercialRegisterNo = CommercialRegisterNrSchema(
    validationMarket,
    false
  );

  return object().shape({
    ...legalForm,
    ...commercialRegisterNo,
  });
};

export default BusinessCompanyInfoSchema;
