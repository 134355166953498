import { ApolloProvider } from '@apollo/client';
import { AdbHelmet } from '@components/adb-helmet/AdbHelmet';
import { Modal } from '@smart/components-adb/molecules/Modal';
import { ErrorBoundaryFallback } from '@smart/components-adb/organisms/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { ComponentProvider, Toast } from '@smart/react-components';
import { AgentContextProvider } from 'contexts/agent-context';
import { AuthContextProvider } from 'contexts/auth-context';
import { LanguageProvider } from 'contexts/language-context';
import { MarketContextProvider } from 'contexts/market-context';
import { NotificationContextProvider } from 'contexts/notification-context';
import { PageViewTrackContextProvider } from 'contexts/page-view-track-context';
import PreviewProvider from 'contexts/preview-context';
import { UrlMarketContextProvider } from 'contexts/url-market-context';
import AuthGuard from 'guards/AuthGuard';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Routes } from 'react-router-dom';
import { getRoutes } from 'routes';
import './app.scss';
import { EnvironmentNotifier } from './components/organisms/EnvironmentNotifier/EnvironmentNotifier';
import { client } from './graphql/client';

const BASE_CLASS = 'agent-dashboard';

export default function App() {
  return (
    <BrowserRouter basename="/">
      <UrlMarketContextProvider>
        <ApolloProvider client={client}>
          <AuthContextProvider>
            <AgentContextProvider>
              <AuthGuard>
                <MarketContextProvider>
                  <PageViewTrackContextProvider>
                    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                      <PreviewProvider>
                        <LanguageProvider>
                          <NotificationContextProvider>
                            <ComponentProvider>
                              <AdbHelmet />
                              <div className={BASE_CLASS}>
                                <Modal.Provider>
                                  <Toast.Provider>
                                    <Modal />
                                    <Routes>{getRoutes()}</Routes>
                                  </Toast.Provider>
                                </Modal.Provider>
                              </div>
                              <EnvironmentNotifier />
                            </ComponentProvider>
                          </NotificationContextProvider>
                        </LanguageProvider>
                      </PreviewProvider>
                    </ErrorBoundary>
                  </PageViewTrackContextProvider>
                </MarketContextProvider>
              </AuthGuard>
            </AgentContextProvider>
          </AuthContextProvider>
        </ApolloProvider>
      </UrlMarketContextProvider>
    </BrowserRouter>
  );
}
