import { useTranslation } from 'react-i18next';
import { date } from 'yup';

export const PreRegistrationDate = (initialValue: string, required = false) => {
  const { t } = useTranslation();
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (required && initialValue && initialValue !== '') {
    return {
      preRegistrationDate: date().required(
        `${t('car.assets.pre_registration_date')} ${t(
          'general.error_messages.validations.is_required'
        )}`
      ),
    };
  }

  return {
    preRegistrationDate: required
      ? date()
          .min(
            today,
            `${t('general.error_messages.validations.invalid')} ${t(
              'car.assets.pre_registration_date'
            )}`
          )
          .required(
            `${t('car.assets.pre_registration_date')} ${t(
              'general.error_messages.validations.is_required'
            )}`
          )
      : date().min(
          today,
          `${t('general.error_messages.validations.invalid')} ${t(
            'car.assets.pre_registration_date'
          )}`
        ),
  };
};
