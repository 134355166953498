import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { Spinner, Text } from '@smart/react-components';
import { useOutletAgentsQuery } from 'graphql/queries/outlet.generated';
import { useCurrentOutlet } from 'hooks/outlet';
import { Suspense, lazy } from 'react';
import './details-and-team.scss';

const OutletAddress = lazy(() => import('./OutletAddress'));

const BASE_CLASS = 'adb-outlet-details';

const DetailsAndTeam = () => {
  const outlet = useCurrentOutlet();

  const { data: agentsData, loading: agentsLoading } = useOutletAgentsQuery({
    variables: {
      outletId: outlet?.mcsId ?? '',
    },
    skip: !outlet?.mcsId,
  });

  return agentsLoading ? (
    <div className={`${BASE_CLASS}__loading`}>
      <LoadingIndicator onFullPage />
    </div>
  ) : (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}__title`}>
        <Text
          variant="hl-200"
          className={`${BASE_CLASS}__title-heading`}
          as="h1"
        >
          {outlet?.name}
        </Text>
        {outlet && (
          <div className={`${BASE_CLASS}__address`}>
            <Suspense fallback={<Spinner size="xl" />}>
              <OutletAddress outlet={outlet} />
            </Suspense>
          </div>
        )}
      </div>
      <div className={`${BASE_CLASS}__experts-list`}>
        {agentsData?.outletAgents.map((agent) => (
          <div className="expert" key={agent.id}>
            <Text
              variant="lbl-200"
              as="p"
            >{`${agent?.firstName} ${agent?.lastName}`}</Text>
            <Text variant="cap-100" as="p">
              {agent?.email}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailsAndTeam;
