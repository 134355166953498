import { useEffect, useState } from 'react';

const getDeviceBooleans = () => ({
  isMobile: window.screen.width < 768,
  isTablet: window.screen.width < 992 && window.screen.width > 768,
  isDesktop: window.screen.width > 992,
});

export const useScreenSize = () => {
  const [devices, setDevices] = useState(getDeviceBooleans());

  const updateDevices = () => {
    setDevices(getDeviceBooleans());
  };

  useEffect(() => {
    window.addEventListener('orientationchange', updateDevices);
    return () => {
      window.removeEventListener('orientationchange', updateDevices);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateDevices);
    return () => {
      window.removeEventListener('resize', updateDevices);
    };
  }, []);

  return devices;
};
