import {
  CustomerAccountType,
  CustomerEditInput,
  CustomerRecordType,
  CustomerRegType,
  CustomerType,
} from '@smart/adb-shared';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import AdbNotification from '@smart/components-adb/molecules/AdbNotification/AdbNotification';
import { SignalVariant } from '@smart/components-adb/molecules/AdbNotification/AdbNotification.config';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button } from '@smart/react-components';
import classNames from 'classnames';
import { useCustomerContext } from 'contexts/customer-context';
import {
  CustomerByIdDocument,
  useCustomerConsentsQuery,
  useCustomerEditMutation,
} from 'graphql/queries/customer.generated';
import {
  ConfirmAccountConvertDialog,
  useConvertAccount,
} from 'hooks/useConvertAccount';
import CustomerLayout from 'layouts/customer-layout/CustomerLayout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BasicInfo from './BasicInfo/BasicInfo';
import BusinessAdditionalInfo from './BusinessAdditionalInfo/BusinessAdditionalInfo';
import BusinessCompanyInfo from './BusinessCompanyInfo/BusinessCompanyInfo';
import PrimaryContactPersonInfo from './BusinessPrimaryContact/BusinessPrimaryContact';
import Consents from './Consents/Consents';
import './CustomerDetails.scss';
import PrivateAdditionalInfo from './PrivateAdditionalInfo/PrivateAdditionalInfo';
import PrivateCommunicationInfo from './PrivateCommunicationInfo/PrivateCommunicationInfo';
import SystemInfo from './SystemInfo/SystemInfo';
import {
  BusinessCustomerFormValues,
  CustomerDetailsState,
  CustomerEditFormSection,
  CustomerFormTypes,
  PrivateCustomerFormValues,
} from './helper';

const BASE_CLASS = 'adb-details';

const CustomerDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state }: CustomerDetailsState = useLocation();
  const { customerId } = useParams();
  const { customer, customerIsLoading } = useCustomerContext();
  const { convertToFullAccount, loading } = useConvertAccount(customer);
  const { registerModal } = useModal();

  if (!customerId) {
    navigate('/customer');
  }

  const [
    editCustomer,
    { data: editCustomerMutationData, loading: editCustomerMutationLoading },
  ] = useCustomerEditMutation();

  const refetchQueries = [
    {
      query: CustomerByIdDocument,
      variables: {
        input: {
          customerId: customer?.uuid ?? '',
          sfCustomerId: customer?.sfCustomerId ?? '',
          ...(customer?.__typename === 'CustomerBusiness' && {
            sfOrgId: customer?.sfOrgId ?? '',
          }),
        },
      },
    },
  ];

  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationVariant, setNotificationVariant] =
    useState<SignalVariant>('success');

  const { data: consents, loading: consentsIsLoading } =
    useCustomerConsentsQuery({
      variables: {
        input: {
          uuid: customer?.uuid ?? '',
          regType: customer?.regType,
        },
      },
      skip: !customer,
    });

  useEffect(() => {
    if (editCustomerMutationLoading) return;
    if (!editCustomerMutationData?.customerEdit) return;

    if (editCustomerMutationData.customerEdit.success) {
      setNotificationText(t('customer.details.customer_update_success'));
      setNotificationVariant('success');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCustomerMutationData, editCustomerMutationLoading]);

  const shouldConvertToFullAccount =
    customer?.regType === CustomerRegType.Lite && state?.convertToFullAccount;

  const shouldConvertToFullBusinessAccount = (
    updatedFormValues: PrivateCustomerFormValues | BusinessCustomerFormValues
  ) =>
    shouldConvertToFullAccount &&
    updatedFormValues.accountType === CustomerAccountType.Business;

  /* Common submit function to dispatch the action from different forms
   * type of updated form values is defined in the helper file
   */
  const handleBasicInfoSubmit = (
    updatedFormValues: PrivateCustomerFormValues | BusinessCustomerFormValues
  ) => {
    if (shouldConvertToFullBusinessAccount(updatedFormValues)) {
      registerModal(
        <ConfirmAccountConvertDialog
          customer={customer}
          formValues={updatedFormValues}
        />
      );
    } else if (shouldConvertToFullAccount) {
      convertToFullAccount(updatedFormValues);
    } else {
      const customerInfo: CustomerEditInput = {
        ...updatedFormValues,
        recordType: customer?.recordType ?? '',
        sfCustomerId: customer?.sfCustomerId ?? '',
        userId: customer?.userId ?? '',
        uuid: customerId ?? '',
        bpid: customer?.__typename === 'CustomerBusiness' ? customer?.bpid : '',
        sfOrgId:
          customer?.__typename === 'CustomerBusiness' ? customer?.sfOrgId : '',
      };

      editCustomer({
        variables: {
          input: customerInfo,
        },
        refetchQueries,
      });
    }
  };

  const handleOtherCustomerDetailSubmit = (
    updatedFormValues:
      | CustomerEditFormSection
      | PrivateCustomerFormValues
      | BusinessCustomerFormValues
  ) => {
    const customerInfo: CustomerEditInput = {
      ...updatedFormValues,
      recordType: customer?.recordType ?? '',
      sfCustomerId: customer?.sfCustomerId ?? '',
      userId: customer?.userId ?? '',
      uuid: customerId ?? '',
      bpid: customer?.__typename === 'CustomerBusiness' ? customer?.bpid : '',
      sfOrgId:
        customer?.__typename === 'CustomerBusiness' ? customer?.sfOrgId : '',
    };

    editCustomer({
      variables: {
        input: customerInfo,
      },
      refetchQueries,
    });
  };

  /* Common footer buttons used across multiple sub forms */
  const formFooter = (formType?: CustomerFormTypes, disabled?: boolean) => (
    <div className={`${BASE_CLASS}__action-item`}>
      <Button mode={200} variant="secondary" disabled={false} type="reset">
        {t('customer.details.buttons.reset')}
      </Button>
      <Button
        variant="primary"
        type="submit"
        mode={200}
        disabled={disabled}
        loading={editCustomerMutationLoading || loading}
      >
        <Button.Spinner />
        {t('customer.details.buttons.save')}
        <Button.Spinner />
      </Button>
    </div>
  );

  const basicInfoFormProps = {
    formFooter,
    handleCustomerDetailSubmit: handleBasicInfoSubmit,
    hasExpanded: false,
    ...(state?.convertToFullAccount && {
      convertToFullAccount: state.convertToFullAccount,
      isNotAccordion: true,
    }),
  };

  /* Common form props used by multiple sub forms */
  const formProps = {
    formFooter,
    handleCustomerDetailSubmit: handleOtherCustomerDetailSubmit,
    hasExpanded: false,
  };

  return (
    <CustomerLayout>
      <div
        className={classNames(`${BASE_CLASS}__container`, {
          [`${BASE_CLASS}__container--loading`]: customerIsLoading,
        })}
      >
        <BasicInfo
          {...basicInfoFormProps}
          customerDetails={{
            firstName: customer?.firstName ?? '',
            lastName: customer?.lastName ?? '',
            market: customer?.market ?? '',
            mobileNumber: customer?.mobileNumber ?? '',
            preferredLanguage: customer?.preferredLanguage ?? '',
            titleCode: customer?.titleCode ?? '',
            userId: customer?.userId ?? '',
            uuid: customer?.uuid ?? '',
            lastModified: customer?.lastModified ?? '',
            recordType:
              customer?.recordType ?? CustomerRecordType.PersonAccount,
            sfCustomerId: customer?.sfCustomerId ?? '',
            type: customer?.type ?? CustomerType.Account,
            street: customer?.address?.street ?? '',
            town: customer?.address?.town ?? '',
            countryCode: customer?.address?.countryCode ?? '',
            postalCode: customer?.address?.postalCode ?? '',
            regionCode: customer?.address?.regionCode ?? '',
            accountType: customer?.accountType ?? CustomerAccountType.Private,
            ...(customer?.__typename === 'CustomerBusiness' && {
              sfOrgId: customer?.sfOrgId,
              bpid: customer?.bpid,
              companyName: customer?.companyName,
              vatRegistrationNumber: customer?.vatRegistrationNumber,
              companyRegistrationNumber: customer?.companyRegistrationNumber,
            }),
          }}
          title={t('customer.details.basic_information')}
        />
        {customer?.__typename === 'CustomerPrivate' && (
          <>
            <PrivateAdditionalInfo
              {...formProps}
              privateAdditionalDetails={customer?.privateAdditionalInfo}
            />
            <Consents
              {...formProps}
              consentDetails={
                !consentsIsLoading && consents?.customerConsents.preferences
                  ? consents?.customerConsents.preferences
                  : []
              }
            />
            <PrivateCommunicationInfo
              {...formProps}
              communicationDetails={customer?.communicationInfo}
            />
            <SystemInfo {...formProps} systemDetails={customer?.systemInfo} />
          </>
        )}

        {customer?.__typename === 'CustomerBusiness' && (
          <>
            <BusinessAdditionalInfo
              {...formProps}
              businessAdditionalDetails={customer?.businessAdditionalInfo}
            />
            <BusinessCompanyInfo
              {...formProps}
              businessCompanyDetails={customer?.companyInfo}
            />
            <PrimaryContactPersonInfo
              {...formProps}
              businessPrimaryContactDetails={customer?.primaryContactInfo}
            />
            <SystemInfo {...formProps} systemDetails={customer?.systemInfo} />
            <Consents
              {...formProps}
              consentDetails={
                !consentsIsLoading && consents?.customerConsents.preferences
                  ? consents.customerConsents.preferences
                  : []
              }
            />
          </>
        )}
      </div>
      {customerIsLoading && (
        <LoadingIndicator
          onFullPage
          additionalClasses={`${BASE_CLASS}__loading`}
        />
      )}
      {notificationText && (
        <AdbNotification
          variant={notificationVariant}
          text={notificationText}
          label={t(`customer.register.${notificationVariant}`)}
          isVisible={!!notificationText}
          onClose={() => setNotificationText('')}
        />
      )}
    </CustomerLayout>
  );
};

export default CustomerDetails;
