import { TextInput } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import SearchFilterPanel from '../../../../components/atoms/SearchFilterPanel/SearchFilterPanel';
import { SearchProps } from './Search.config';

const PurchasedItemsSearch = ({
  handleQueryUpdate,
  rows,
  searchText,
  label,
}: SearchProps) => {
  const { t } = useTranslation();

  const handleSearchQuery = (value: string): void => handleQueryUpdate(value);

  return (
    <SearchFilterPanel
      searchBar={
        <TextInput
          type="text"
          name="search"
          id="text_input_id"
          onChange={(e) => handleSearchQuery(e.target.value)}
          label={label ?? t('customer.purchased_products.search')}
          caption={`${rows} ${t('general.labels.results')}`}
          value={searchText}
        />
      }
      darkMode
    />
  );
};

export default PurchasedItemsSearch;
