import { TaskStatus } from '@smart/adb-shared';
import { Button } from '@smart/react-components';
import { useNotificationContext } from 'contexts/notification-context';
import { useCompleteAppointmentMutation } from 'graphql/queries/appointments.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskContext } from '../task-context';
import { TaskActionProps } from '../types';
import { isAppointmentCompleted } from '../utils';

export const TestDriveEventCloseButton = ({
  task,
  onComplete,
  disabled,
}: TaskActionProps) => {
  const { t } = useTranslation();
  const { getTask } = useTaskContext();
  const { addSuccess } = useNotificationContext();
  const [completeAppointment] = useCompleteAppointmentMutation({
    variables: {
      input: {
        appointmentId: task.original.appointment?.id ?? '',
        ownerId: task.original.agent?.sfId ?? '',
      },
    },
  });

  const [isClosingTask, setIsClosingTask] = useState(false);

  const onCloseTask = async () => {
    setIsClosingTask(true);
    await completeAppointment()
      .catch((error) => {
        setIsClosingTask(false);
        throw error;
      })
      .then(() =>
        getTask(task.id)
          .catch((error) => {
            enhanceError({
              error,
              label: t('feature_calendar.notification.error_title'),
              displayMessage: t(
                'feature_calendar.notification.error_description'
              ),
            });
          })
          .then(() => {
            addSuccess({
              label: t(
                'feature_calendar.notification.returned_appointment_title'
              ),
              message: t(
                'feature_calendar.notification.returned_appointment_description'
              ),
            });
            onComplete('single');
          })
          .finally(() => setIsClosingTask(false))
      );
  };

  const disabledButton =
    disabled || isClosingTask || isAppointmentCompleted(task.original);

  return (
    <Button
      variant="primary"
      mode={200}
      onClick={onCloseTask}
      loading={isClosingTask}
      disabled={disabledButton}
    >
      {t('task.test_drive.event.complete_appointment')}
      {task.original.status === TaskStatus.Completed ? (
        <Button.Icon
          icon="successful-checkmark"
          aria-label="successful-checkmark"
        />
      ) : (
        <Button.Spinner />
      )}
    </Button>
  );
};
