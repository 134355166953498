import { Language, MarketId } from '@smart/types';
import { AdbLocale } from '@ui/library/helpers/date-locale';

export type MarketLanguage = {
  // check 'date-fns/locale' for locales
  locale: AdbLocale;
  displayCode?: Language;
  isDefault?: boolean;
  isTestEnv?: boolean;
};

export type MarketCode = MarketId;

type MarketCodePhonePrefix = {
  [K in MarketCode]: string;
};

export const MarketCodePhonePrefixMap: MarketCodePhonePrefix = {
  at: '43',
  be: '32',
  ch: '41',
  de: '49',
  es: '34',
  fr: '33',
  it: '39',
  nl: '31',
  pt: '351',
  gb: '44',
  se: '46',
  bg: '',
  cy: '',
  cz: '',
  dk: '',
  ee: '',
  fi: '',
  gr: '',
  hr: '',
  hu: '',
  ie: '',
  is: '',
  li: '',
  lt: '',
  lu: '',
  lv: '',
  mt: '',
  pl: '',
  ro: '',
  si: '',
  sk: '',
  sb: '',
  fm: '',
  no: '',
};

type MarketCodeTimeZone = {
  [K in MarketCode | 'default']: string;
};

export const marketCodeTimeZoneMap: MarketCodeTimeZone = {
  de: 'Europe/Berlin',
  se: 'Europe/Stockholm',
  at: 'Europe/Vienna',
  be: 'Europe/Brussels',
  ch: 'Europe/Zurich',
  es: 'Europe/Madrid',
  fr: 'Europe/Paris',
  it: 'Europe/Rome',
  nl: 'Europe/Amsterdam',
  pt: 'Europe/Lisbon',
  gb: 'Europe/London',
  bg: 'Europe/Sofia',
  cy: 'Asia/Nicosia',
  cz: 'Europe/Prague',
  dk: 'Europe/Copenhagen',
  ee: 'Europe/Tallinn',
  fi: 'Europe/Helsinki',
  gr: 'Europe/Athens',
  hr: 'Europe/Zagreb',
  hu: 'Europe/Budapest',
  ie: 'Europe/Dublin',
  is: 'Atlantic/Reykjavik',
  li: 'Europe/Vaduz',
  lt: 'Europe/Vilnius',
  lu: 'Europe/Luxembourg',
  lv: 'Europe/Riga',
  mt: 'Europe/Malta',
  pl: 'Europe/Warsaw',
  ro: 'Europe/Bucharest',
  si: 'Europe/Ljubljana',
  sk: 'Europe/Bratislava',
  sb: 'Europe/Belgrade',
  fm: 'Pacific/Pohnpei',
  no: 'Europe/Oslo',
  default: 'UTC',
};

export const getPhoneNumberHintForMarket = (market: MarketCode): string => {
  switch (market) {
    case 'be':
      return 'XXX00000';
    case 'pt':
      return 'XXX000000';
    case 'gb':
      return 'XXX0000000';
    case 'at':
      return 'XXX0000000';
    case 'ch':
      return 'XXX000000';
    case 'de':
      return 'XXX0000000';
    case 'es':
      return 'XXX000000';
    case 'fr':
      return 'XXX000000';
    case 'it':
      return 'XXX0000000';
    case 'nl':
      return 'XXX000000';
    case 'se':
      return 'XXX000000';
    default:
      return 'XXX00000';
  }
};

type MarketCountryIsoCode = {
  [K in MarketCode]: string;
};

// All values should be valid iso codes https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export const CountryIsoCode: MarketCountryIsoCode = {
  at: 'AT',
  be: 'BE',
  fr: 'FR',
  de: 'DE',
  it: 'IT',
  nl: 'NL',
  pt: 'PT',
  es: 'ES',
  ch: 'CH',
  gb: 'GB',
  se: 'SE',
  no: 'NO',
  bg: '',
  cy: '',
  cz: '',
  dk: '',
  ee: '',
  fi: '',
  gr: '',
  hr: '',
  hu: '',
  ie: '',
  is: '',
  li: '',
  lt: '',
  lu: '',
  lv: '',
  mt: '',
  pl: '',
  ro: '',
  si: '',
  sk: '',
  sb: '',
  fm: '',
};

/**
 * @type AppMarket
 * Information on the current application market
 */
export type AppMarket = {
  domain: string;
  market: MarketCode;
  outletMarket: string;
};

/**
 * @type AppMarketConfig
 * Configuration for the application market
 * @param name The name of the market in English
 */
export type AppMarketConfig = AppMarket & {
  name: string;
  isDefault?: boolean;
  languages: MarketLanguage[];
};

export type LicensePlateExamples = {
  [K in MarketCode]: string;
};
