import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { Text } from '@smart/react-components';
import { getTimeFormat } from '@ui/library/helpers/date';
import { getCustomFormattedDate } from '@ui/library/helpers/date-locale';
import { useLanguageContext } from 'contexts/language-context';
import { useTranslation } from 'react-i18next';
import { NextAppointmentProps } from './NextAppointment.config';
import './NextAppointment.scss';

const BASE_CLASS = 'adb-next-appointment';

const NextAppointment = ({
  loading,
  end,
  name,
  start,
  title,
  onClick,
  exists,
}: NextAppointmentProps) => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();

  return exists && !loading ? (
    <div
      className={`${BASE_CLASS} ${BASE_CLASS}__item`}
      role="button"
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
    >
      <div className={`${BASE_CLASS}__details ${BASE_CLASS}__details--left`}>
        {start && (
          <>
            <Text variant="cap-300" as="p">
              {`${t('dashboard.next_appointment')}: `}
              {getCustomFormattedDate(start, 'dd.MM', locale)}
            </Text>
            <Text variant="hl-300">{getTimeFormat(start)}</Text>
          </>
        )}

        {end && (
          <Text variant="cap-300" as="p">
            {t('dashboard.to')} {getTimeFormat(end)}
          </Text>
        )}
        {title && (
          <Text variant="cap-300" as="p">
            {title}
          </Text>
        )}
      </div>
      <div className={`${BASE_CLASS}__details ${BASE_CLASS}__details--right`}>
        {name}
      </div>
    </div>
  ) : (
    <div className={`${BASE_CLASS} ${BASE_CLASS}__no-items`}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Text variant="p-100" as="p">
          {t('dashboard.no_appointments_today')}
        </Text>
      )}
    </div>
  );
};

export default NextAppointment;
