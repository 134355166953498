import { AppointmentTimeSchema } from '@ui/validations/schemas/AppointmentTime';
import { DepartureMileageSchema } from '@ui/validations/schemas/DepartureMileage';
import { DrivingLicenseNoSchema } from '@ui/validations/schemas/DrivingLicenseNo';
import { MarketCode } from '@utils/market/types';
import { object } from 'yup';

const TestDriveContractSchema = (market: MarketCode) => {
  const drivingLicenseNo = DrivingLicenseNoSchema(market, true);
  const departureMileage = DepartureMileageSchema(true);
  const appointmentTime = AppointmentTimeSchema(market, true);

  return object().shape({
    ...drivingLicenseNo,
    ...departureMileage,
    ...appointmentTime,
  });
};

export default TestDriveContractSchema;
