/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TestDriveTimeSlotsQueryVariables = Types.Exact<{
  input: Types.TestDriveTimeSlotsInput;
}>;


export type TestDriveTimeSlotsQuery = { __typename?: 'Query', testDriveTimeSlots: { __typename?: 'TestDriveTimeSlotsResponse', slotDetails: Array<{ __typename?: 'TestDriveSlots', carId: string, testDriveCarId: string, licensePlate?: string | null, status?: Types.TestDriveCarStatus | null, timeSlots: Array<{ __typename?: 'TestDriveTimeSlot', date: string, label: string, availability?: Types.TestDriveSlotDateAvailability | null, timeOfDay: Array<Types.TestDriveSlotTimeOfDay>, timeSlots: Array<{ __typename?: 'TestDriveSlotTimeSlot', pickUpTime: string, dropOffTime: string, isAvailable?: boolean | null }> }> }>, carDetails: Array<{ __typename?: 'CarDetails', carId: string, image: string, line?: string | null, name?: string | null, type?: string | null, longName?: string | null, model?: string | null, pn18?: string | null, vehicleCondition?: string | null, vehicleType?: string | null, features?: { __typename?: 'CarFeaturesTestDrive', exterior: string, accessories?: string | null, battery?: string | null, distance?: string | null, driveline?: string | null, interior?: string | null, mark?: string | null, mileage?: string | null, power?: string | null, range?: string | null, roof?: string | null, steeringWheelPosition?: string | null, transmission?: string | null, yearEdition?: string | null } | null } | null> } };


export const TestDriveTimeSlotsDocument = gql`
    query TestDriveTimeSlots($input: TestDriveTimeSlotsInput!) {
  testDriveTimeSlots(input: $input) {
    slotDetails {
      carId
      testDriveCarId
      licensePlate
      status
      timeSlots {
        date
        label
        timeSlots {
          pickUpTime
          dropOffTime
          isAvailable
        }
        availability
        timeOfDay
      }
    }
    carDetails {
      carId
      image
      line
      name
      type
      features {
        exterior
        accessories
        battery
        distance
        driveline
        interior
        mark
        mileage
        power
        range
        roof
        steeringWheelPosition
        transmission
        yearEdition
      }
      longName
      model
      pn18
      vehicleCondition
      vehicleType
    }
  }
}
    `;

/**
 * __useTestDriveTimeSlotsQuery__
 *
 * To run a query within a React component, call `useTestDriveTimeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestDriveTimeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestDriveTimeSlotsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestDriveTimeSlotsQuery(baseOptions: Apollo.QueryHookOptions<TestDriveTimeSlotsQuery, TestDriveTimeSlotsQueryVariables> & ({ variables: TestDriveTimeSlotsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestDriveTimeSlotsQuery, TestDriveTimeSlotsQueryVariables>(TestDriveTimeSlotsDocument, options);
      }
export function useTestDriveTimeSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestDriveTimeSlotsQuery, TestDriveTimeSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestDriveTimeSlotsQuery, TestDriveTimeSlotsQueryVariables>(TestDriveTimeSlotsDocument, options);
        }
export function useTestDriveTimeSlotsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TestDriveTimeSlotsQuery, TestDriveTimeSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestDriveTimeSlotsQuery, TestDriveTimeSlotsQueryVariables>(TestDriveTimeSlotsDocument, options);
        }
export type TestDriveTimeSlotsQueryHookResult = ReturnType<typeof useTestDriveTimeSlotsQuery>;
export type TestDriveTimeSlotsLazyQueryHookResult = ReturnType<typeof useTestDriveTimeSlotsLazyQuery>;
export type TestDriveTimeSlotsSuspenseQueryHookResult = ReturnType<typeof useTestDriveTimeSlotsSuspenseQuery>;
export type TestDriveTimeSlotsQueryResult = Apollo.QueryResult<TestDriveTimeSlotsQuery, TestDriveTimeSlotsQueryVariables>;