/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FeatureFlagQueryVariables = Types.Exact<{
  input: Types.FeatureFlagInput;
}>;


export type FeatureFlagQuery = { __typename?: 'Query', featureFlag?: { __typename?: 'FeatureFlag', key: string, value?: any | null } | null };


export const FeatureFlagDocument = gql`
    query FeatureFlag($input: FeatureFlagInput!) {
  featureFlag(input: $input) {
    key
    value
  }
}
    `;

/**
 * __useFeatureFlagQuery__
 *
 * To run a query within a React component, call `useFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFeatureFlagQuery(baseOptions: Apollo.QueryHookOptions<FeatureFlagQuery, FeatureFlagQueryVariables> & ({ variables: FeatureFlagQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FeatureFlagDocument, options);
      }
export function useFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagQuery, FeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FeatureFlagDocument, options);
        }
export function useFeatureFlagSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FeatureFlagQuery, FeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FeatureFlagDocument, options);
        }
export type FeatureFlagQueryHookResult = ReturnType<typeof useFeatureFlagQuery>;
export type FeatureFlagLazyQueryHookResult = ReturnType<typeof useFeatureFlagLazyQuery>;
export type FeatureFlagSuspenseQueryHookResult = ReturnType<typeof useFeatureFlagSuspenseQuery>;
export type FeatureFlagQueryResult = Apollo.QueryResult<FeatureFlagQuery, FeatureFlagQueryVariables>;