import { Task } from '@smart/adb-shared';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { Text } from '@smart/react-components';
import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { CloseTaskButton } from '../CloseTaskButton';
import { ConvertLeadButton } from '../Lead/ConvertLeadButton';
import './TestDriveActionsModal.scss';
import { TestDriveEventCheckoutButton } from './TestDriveEventCheckoutButton';
import { TestDriveEventCloseButton } from './TestDriveEventCloseButton';
import { TestDriveRescheduleCancelButton } from './TestDriveRescheduleCancelButton';

const BASE_CLASS = 'adb-td-actions-modal';

interface ITestDriveActionsModal {
  task: Row<Task>;
  onCompleted: () => void;
}

export const TestDriveEventActionsModal = ({
  task,
  onCompleted,
}: ITestDriveActionsModal) => {
  const { t } = useTranslation();

  return (
    <AdbDialog size="medium" id={BASE_CLASS} className={BASE_CLASS}>
      <AdbDialog.Header>
        <Text variant="hl-100">{t('task.lead.next_step_cta')}</Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <TestDriveEventCheckoutButton task={task} />
        <ConvertLeadButton task={task} />
        <TestDriveRescheduleCancelButton task={task} />
        <TestDriveEventCloseButton task={task} onComplete={onCompleted} />
        <CloseTaskButton task={task} label={t('task.lead.close_lead_cta')} />
      </AdbDialog.Content>
    </AdbDialog>
  );
};
