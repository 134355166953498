import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { useNotificationContext } from 'contexts/notification-context';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ELOSignDocument.scss';
import { useLoadSignUrlQuery } from './queries.generated';

const BASE_CLASS = 'elo-sign-document';

const ELOSignDocument = ({ documentId }: { documentId: string }) => {
  const { t } = useTranslation();

  const { data: signUrlData, error: loadSignURLError } = useLoadSignUrlQuery({
    variables: {
      input: {
        id: documentId,
      },
    },
    skip: !documentId,
  });

  const signUrl = signUrlData?.loadSignURL.signURL;
  const { addError } = useNotificationContext();

  useEffect(() => {
    if (loadSignURLError) {
      addError({
        label: t('customer.documents.notification.error_title'),
        message: t('customer.documents.notification.error_document_sign_url'),
      });
    }
  }, [loadSignURLError, addError, t]);

  if (!signUrl) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <object data={signUrl} className={`${BASE_CLASS}__signature-object`}>
        <embed src={signUrl} />
      </object>
    </div>
  );
};

export default ELOSignDocument;
