import { SimPackage } from '@smart/adb-shared';
import { IconBackground } from '@smart/components-adb/atoms/AdbIcon/AdbIcon.config';
import { Text } from '@smart/react-components';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import SimStatusContainer from './SimStatusContainer';

const BASE_CLASS = 'adb-customer-products';

const ActiveSim = ({
  simPackages,
  startTime,
}: {
  simPackages: SimPackage[] | undefined | null;
  startTime: string;
}) => {
  const { t } = useTranslation();

  const startTimeAsDate = new Date(Date.parse(startTime.replace(/ \+.*$/, '')));

  return (
    <SimStatusContainer
      icon="successful-checkmark"
      iconBackground={IconBackground.SUCCESS}
      title={t('customer.purchased_products.sim.sim_card_activated')}
    >
      <div className="adb-customer-products__action_item">
        <Text
          variant="lbl-100"
          as="p"
          className={`${BASE_CLASS}__sim_status_text`}
        >
          {t('customer.purchased_products.sim.current_plan')}
        </Text>
        <Text variant="cap-300" as="p">
          {simPackages?.map((p) => p.name).join(', ')} (
          {format(startTimeAsDate, 'yyyy-MM-dd')})
        </Text>
      </div>
    </SimStatusContainer>
  );
};

export default ActiveSim;
