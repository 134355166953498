import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { AdbLocale } from '@ui/library/helpers/date-locale';
import { getCurrentMarket } from '@utils/helpers/market';
import { getLanguage } from '@utils/market/config';
import { MarketCode } from '@utils/market/types';
import { useAgentContext } from 'contexts/agent-context';
import { isAuthenticated } from 'contexts/auth-context';
import { useLanguageContext } from 'contexts/language-context';
import useNavigateWithParams from 'hooks/useNavigateWithParams';
import { PropsWithChildren, useEffect } from 'react';
import { Location, useLocation, useSearchParams } from 'react-router-dom';
import { generateMarketUrl, isLocalhost } from './utils';

export function isNotInMarket(url: string): boolean {
  const hostnames = [
    'adb.smart.com',
    'www.adb.smart.com',
    'dev.adb.srv.smart.com',
    'www.dev.adb.srv.smart.com',
    'qa.adb.srv.smart.com',
    'www.qa.adb.srv.smart.com',
  ];

  return hostnames.some((pattern) =>
    new RegExp(`^https?://(www\\.)?${pattern}(/|$)`, 'i').test(url)
  );
}

export const isInWrongMarket = (
  currentMarket: string | null,
  agentMarket: MarketCode
): boolean => agentMarket !== undefined && currentMarket !== agentMarket;

const getPrevRouteLocation = (
  location: Location,
  authCode: string | null
): string | undefined => {
  // no previous route if an auth error page or a successful login has landed with auth code to the app index
  if (location.pathname.startsWith('/auth/error') || authCode) {
    return '/';
  }

  // test drive booking success goes to dashboard
  if (location.pathname.includes('/test-drive/confirmation/success')) {
    return '/';
  }

  // the current location
  return location.pathname;
};

const buildAuthorizeUrl = (locale: AdbLocale) => {
  const market = getCurrentMarket(window.location.hostname) as MarketCode;
  const language = getLanguage(market ?? locale.substring(0, 2));
  let authorizeURL = import.meta.env.GIGYA_URL_AUTH ?? '';
  authorizeURL += import.meta.env.GIGYA_API_KEY;
  authorizeURL += '/authorize?response_type=code';
  authorizeURL += `&client_id=${import.meta.env.GIGYA_CLIENT_ID}`;
  authorizeURL += `&redirect_uri=${generateMarketUrl(market)}`;
  authorizeURL += `&scope=openid%20email%20profile%20CustScope%20CustScope1%20CustScope2%20address%20SignatureTimestamp%20UIDSignature&code_challenge_method=plain&code_challenge=${
    import.meta.env.GIGYA_AUTH_CODE_CHALLENGE
  }&nonce=1652790796120`;
  authorizeURL += `&ui_locales=${language.locale}`;
  return authorizeURL;
};

/**
 * This component is used to check if user have already logged in or not if
 * user not logged in it will ask user to first logged in then only he/she can access the application
 */
const AuthGuard = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const navigate = useNavigateWithParams();
  const { locale, setLanguage } = useLanguageContext();

  const currentMarket = getCurrentMarket(window.location.hostname);
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get('code');

  const { agent: authenticatedAgent } = useAgentContext();

  // redirect user to correct market except when on localhost
  useEffect(() => {
    const agentMarket = authenticatedAgent?.market as MarketCode;

    if (isLocalhost()) {
      return;
    }

    if (
      (agentMarket && isInWrongMarket(currentMarket, agentMarket)) ||
      (agentMarket && isNotInMarket(window.location.hostname))
    ) {
      window.location.replace(generateMarketUrl(agentMarket));
    }
  }, [authenticatedAgent, currentMarket, setLanguage, locale]);

  useEffect(() => {
    if (authenticatedAgent?.id) {
      navigate(sessionStorage.getItem('prev_route') ?? '/', {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedAgent]);

  if (isAuthenticated()) {
    // set previous route
    const prevRouteLocation = getPrevRouteLocation(location, authCode);

    if (prevRouteLocation) {
      sessionStorage.setItem('prev_route', prevRouteLocation);
    }

    // render the route
    return children;
  }

  if (authCode) return <LoadingIndicator onFullPage />;

  window.location.replace(buildAuthorizeUrl(locale));
  return null;
};

export default AuthGuard;
