import { PropsWithChildren } from 'react';

import classNames from 'classnames';
import { AdbLayoutProps } from './AdbLayout.config';
import './AdbLayout.scss';

const BASE_CLASS = 'adb-spacing-layout';

const AdbLayout = ({ children }: PropsWithChildren) => (
  <div className={BASE_CLASS}>{children}</div>
);

const Header = ({
  backgroundColor,
  compact = false,
  children,
  additionalClasses,
}: AdbLayoutProps) => (
  <div
    className={classNames(`${BASE_CLASS}__header`, additionalClasses, {
      [`${BASE_CLASS}__${backgroundColor}`]: backgroundColor,
      [`${BASE_CLASS}__header-padding-tb`]: !compact,
    })}
  >
    {children}
  </div>
);

const Content = ({
  backgroundColor,
  children,
  additionalClasses,
}: AdbLayoutProps) => (
  <div
    className={classNames(`${BASE_CLASS}__content`, additionalClasses, {
      [`${BASE_CLASS}__${backgroundColor}`]: backgroundColor,
    })}
  >
    {children}
  </div>
);

const Footer = ({ backgroundColor, children }: AdbLayoutProps) => (
  <div
    className={classNames(`${BASE_CLASS}__footer`, {
      [`${BASE_CLASS}__${backgroundColor}`]: backgroundColor,
    })}
  >
    {children}
  </div>
);

AdbLayout.Header = Header;
AdbLayout.Content = Content;
AdbLayout.Footer = Footer;

export default AdbLayout;
