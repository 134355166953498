import {
  Modal,
  ModalWindowProps,
  useModal,
} from '@smart/components-adb/molecules/Modal';
import { Button, IconButton, IconProps } from '@smart/react-components';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import './AdbDialog.scss';

const BASE_CLASS = 'adb-dialog';

interface AdbDialogProps extends PropsWithChildren, ModalWindowProps {
  buttons?: {
    primary?: {
      label: string;
      onClick: () => any;
      isLoading: boolean;
      disabled?: boolean;
      iconName?: IconProps['icon'];
      iconPosition?: 'left' | 'right' | undefined;
    };
    secondary?: {
      label: string;
      disabled?: boolean;
      onClick: () => any;
    };
    link?: {
      label: string;
      onClick: () => any;
    };
  };
  onClose?: () => void;
  className?: string;
}

const AdbDialog = ({
  buttons,
  onClose,
  children,
  className,
  ...rest
}: AdbDialogProps) => {
  const { closeModal } = useModal();

  return (
    <Modal.Window {...rest}>
      <div className={classNames(BASE_CLASS, className && className)}>
        <IconButton
          aria-label=""
          variant="ghost"
          onClick={() => {
            if (onClose && typeof onClose === 'function') {
              closeModal(onClose);
            } else {
              closeModal();
            }
          }}
          className={`${BASE_CLASS}__close-btn`}
          mode={200}
        >
          <Button.Icon
            icon="close-without-circle"
            aria-label="close-without-circle"
          />
        </IconButton>

        {children}

        {buttons && (
          <div className={`${BASE_CLASS}__footer`}>
            {buttons?.link?.label && buttons?.link?.onClick && (
              <Button mode={200} variant="ghost" onClick={buttons.link.onClick}>
                <Button.Icon icon="edit" />
                {buttons.link.label}
              </Button>
            )}
            {buttons?.secondary?.label && buttons?.secondary?.onClick && (
              <Button
                variant="secondary"
                mode={200}
                onClick={buttons.secondary.onClick}
                disabled={buttons.secondary.disabled}
              >
                {buttons.secondary.label}
              </Button>
            )}
            {buttons?.primary?.label && buttons?.primary?.onClick && (
              <Button
                variant="primary"
                mode={200}
                onClick={buttons.primary.onClick}
                disabled={buttons.primary.disabled}
                loading={buttons?.primary?.isLoading}
              >
                {buttons.primary.iconPosition === 'left' &&
                  buttons.primary.iconName && (
                    <Button.Icon icon={buttons.primary.iconName} />
                  )}
                {buttons.primary.label}
                {buttons.primary.iconPosition === 'right' &&
                  buttons.primary.iconName && (
                    <Button.Icon icon={buttons.primary.iconName} />
                  )}
                <Button.Spinner />
              </Button>
            )}
          </div>
        )}
      </div>
    </Modal.Window>
  );
};

const Header = ({ children }: PropsWithChildren) => (
  <div className={`${BASE_CLASS}__header`}>{children}</div>
);

const Content = ({ children }: PropsWithChildren) => (
  <div className={`${BASE_CLASS}__content`}>{children}</div>
);

AdbDialog.Header = Header;
AdbDialog.Content = Content;

export default AdbDialog;
