import { Spinner, Text } from '@smart/react-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LoadingProps } from './LoadingIndicator.config';
import './LoadingIndicator.scss';

const BASE_CLASS = 'adb-loader';

const LoadingIndicator = ({
  message,
  onFullPage,
  additionalClasses,
  size = '4xl',
  loading = true,
  ...rest
}: LoadingProps) => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="loading-component"
      className={classNames(BASE_CLASS, additionalClasses, {
        [`${BASE_CLASS}__full-page`]: onFullPage,
      })}
    >
      <Spinner loading={loading} size={size} {...rest} />
      {loading && (
        <Text
          className={`${BASE_CLASS}__text`}
          as="span"
          variant={size === '4xl' || size === 'xl' ? 'p-200' : 'p-100'}
        >
          {message ?? t('general.labels.loading_msg')}
        </Text>
      )}
    </div>
  );
};

export default LoadingIndicator;
