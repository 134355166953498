import { useApolloClient } from '@apollo/client';
import { Language } from '@smart/types';
import type { AdbLocale } from '@ui/library/helpers/date-locale';
import type { LanguageCode } from '@ui/translations';
import { ADB_COOKIES } from '@utils/cookie/adb-cookies';
import {
  getCookie,
  removeCookie,
  setCookie,
} from '@utils/cookie/cookie-configurator';
import { getLanguage, getLocale } from '@utils/market/config';
import { MarketCode, MarketLanguage } from '@utils/market/types';
import i18next from 'i18next';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMarketContext } from './market-context';

declare global {
  interface Window {
    OneTrust: any;
  }
}

interface ILanguageCookie {
  locale: AdbLocale;
  language: Language;
}

interface ILanguageContext extends ILanguageCookie {
  setLanguage?: (lang: LanguageCode) => void;
}

const isTestEnv = () => process.env.NODE_ENV === 'test';

const getLanguageFromSubDomain = (win: Window = window): MarketLanguage => {
  const url = win.location.hostname;

  const domainParts = url.split('.');

  const locale =
    domainParts.length >= 2 && domainParts[0] !== 'www'
      ? domainParts[0]
      : domainParts[1];

  // The domain of the GB market is actually https://uk.adb.smart.com/
  const actualLocale = locale === 'uk' ? 'gb' : locale;

  return getLanguage(actualLocale as MarketCode);
};

const getDefaultCookie = (): ILanguageCookie => {
  const language = getLanguageFromSubDomain();
  if (isTestEnv()) {
    return {
      locale: 'en-GB',
      language: 'en',
    };
  }

  return {
    locale: language.locale,
    language: (language.displayCode ?? 'en') as Language,
  };
};

export const getLanguageCookie = (): ILanguageCookie => {
  const cookie = getCookie(ADB_COOKIES.LANGUAGE);
  if (!cookie) {
    return getDefaultCookie();
  }

  try {
    return JSON.parse(cookie);
  } catch (error: unknown) {
    if (error instanceof SyntaxError) {
      if (error.message.startsWith('Unexpected token')) {
        removeCookie(ADB_COOKIES.LANGUAGE);
      }
    }
    return getDefaultCookie();
  }
};

const defaultState: ILanguageContext = getLanguageCookie();

export const LanguageContext = createContext<ILanguageContext>(defaultState);

export const LanguageProvider = ({ children }: PropsWithChildren) => {
  const [{ locale, language }, setLocale] = useState(defaultState);

  const { market } = useMarketContext();
  const client = useApolloClient();

  const setLanguage = useCallback(
    async (lang: AdbLocale) => {
      const newLocale = getLocale(market, lang);
      const newLanguage = newLocale.split('-')[0] as Language;
      setLocale({ locale: newLocale, language: newLanguage });
      setCookie(
        ADB_COOKIES.LANGUAGE,
        JSON.stringify({ locale: newLocale, language: newLanguage })
      );
      i18next.changeLanguage(newLocale);
      await client.resetStore();
    },
    [market, setLocale, client]
  );

  useEffect(() => {
    if (!market) return;

    const initLang = getLanguage(market, locale).locale;
    if (initLang !== i18next.language || initLang !== defaultState.locale) {
      setLanguage(initLang);
    }

    if (
      window.OneTrust &&
      window.OneTrust.GetDomainData().Language.Culture !== locale
    ) {
      window.OneTrust.changeLanguage(locale);
    }
  }, [setLanguage, market, locale]);

  const value = useMemo(
    () => ({
      setLanguage,
      locale,
      language,
    }),
    [setLanguage, locale, language]
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => {
  const value = useContext(LanguageContext);

  if (!value) throw new Error('Got undefined language context!');

  return value;
};
