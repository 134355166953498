import { Customer } from '@smart/adb-shared';
import Documents from '@smart/components-adb/documents';
import { UploadType } from '@smart/components-adb/documents/Documents.config';

import { DocumentsContextProvider } from '@smart/components-adb/documents/documents-context';
import { useCurrentOutlet } from 'hooks/outlet';

const OutletDocuments = () => {
  const outlet = useCurrentOutlet();

  return (
    <DocumentsContextProvider
      customer={
        {
          uuid: outlet?.bpId,
          bpid: outlet?.bpId,
          __typename: 'CustomerBusiness',
        } as Customer
      }
      uploadType={UploadType.Outlet}
    >
      <Documents />
    </DocumentsContextProvider>
  );
};

export default OutletDocuments;
