import { OrderSummary } from '@smart/adb-shared';
import { FacetValue } from '@ui/data-models/facet/facet.model';
import {
  filterOrdersByDeliveryDateData,
  sortByOrderStatus,
  sortOrdersByDate,
} from '@utils/helpers/order-helper';

export type OrderKeys = keyof OrderSummary;

type OrderFilterKeys = keyof Pick<
  OrderSummary,
  | 'beautifiedDeliveryStatus'
  | 'deliveryLocation'
  | 'status'
  | 'paymentType'
  | 'paymentStatus'
>;

class OrderFilter {
  private filters: FacetValue[];

  private filtersByCode: Record<string, FacetValue[]>;

  constructor(filters: FacetValue[]) {
    this.filters = filters;
    this.filtersByCode = filters.reduce(
      (acc: Record<string, FacetValue[]>, curr: FacetValue) => {
        if (acc[curr.code]) {
          acc[curr.code].push(curr);
        } else {
          acc[curr.code] = [curr];
        }
        return acc;
      },
      {}
    );
  }

  isFilterMatch(order: OrderSummary): boolean {
    return this.filters.every((key) => {
      const filterOptions = this.filtersByCode[key.code];
      return filterOptions.some(
        (option) =>
          option.queryValue.toUpperCase() ===
          order[option.code as OrderFilterKeys]?.toUpperCase()
      );
    });
  }

  static isFreeTextMatch = (
    order: OrderSummary,
    searchQuery: string
  ): boolean =>
    Object.keys(order).some((key) => {
      const value = order[key as OrderKeys];

      if (
        value &&
        typeof value === 'string' &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
      )
        return true;

      return false;
    });

  findOrders = (
    orders: OrderSummary[] | undefined,
    sortQuery?: string,
    searchQuery?: string
  ) => {
    if (!orders) return [];
    let result = [...orders].sort((firstItem, secondItem) =>
      sortByOrderStatus(secondItem.status, firstItem.status)
    );

    if (searchQuery) {
      result = result.filter((order) =>
        OrderFilter.isFreeTextMatch(order, searchQuery)
      );
    }

    if (result.length > 0 && this.filters.length > 0) {
      result = result.filter((order) => this.isFilterMatch(order));
    }

    if (result.length > 0 && sortQuery) {
      // below filter for deliverDate is explicitly done because some of the data does not have values
      const sortWithoutDeliveryDates = result.filter((order) =>
        filterOrdersByDeliveryDateData(sortQuery, order, false)
      );

      const sortedData = result
        .filter((order) =>
          filterOrdersByDeliveryDateData(sortQuery, order, true)
        )
        .sort(sortOrdersByDate(sortQuery));

      result = [...sortedData, ...sortWithoutDeliveryDates];
    }

    return result;
  };
}

export default OrderFilter;
