/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PreferredOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PreferredOptionsQuery = { __typename?: 'Query', preferredOptions: { __typename?: 'PreferredOptions', countryCode: Array<{ __typename?: 'Dropdown', label: string, value: string }>, titleCode: Array<{ __typename?: 'Dropdown', label: string, value: string }>, language: Array<{ __typename?: 'Dropdown', label: string, value: string }>, fleetSize: Array<{ __typename?: 'Dropdown', label: string, value: string }>, source: Array<{ __typename?: 'Dropdown', label: string, value: string }>, sanctionFlag: Array<{ __typename?: 'Dropdown', label: string, value: string }>, daimlerFrameworkAgreement: Array<{ __typename?: 'Dropdown', label: string, value: string }>, amountOfCars: Array<{ __typename?: 'Dropdown', label: string, value: string }>, communicationChannel: Array<{ __typename?: 'Dropdown', label: string, value: string }>, idType: Array<{ __typename?: 'Dropdown', label: string, value: string }>, nationality: Array<{ __typename?: 'Dropdown', label: string, value: string }>, preferredTime: Array<{ __typename?: 'Dropdown', label: string, value: string }> } };


export const PreferredOptionsDocument = gql`
    query PreferredOptions {
  preferredOptions {
    countryCode {
      label
      value
    }
    titleCode {
      label
      value
    }
    language {
      label
      value
    }
    fleetSize {
      label
      value
    }
    source {
      label
      value
    }
    sanctionFlag {
      label
      value
    }
    daimlerFrameworkAgreement {
      label
      value
    }
    amountOfCars {
      label
      value
    }
    communicationChannel {
      label
      value
    }
    idType {
      label
      value
    }
    nationality {
      label
      value
    }
    preferredTime {
      label
      value
    }
  }
}
    `;

/**
 * __usePreferredOptionsQuery__
 *
 * To run a query within a React component, call `usePreferredOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreferredOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreferredOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreferredOptionsQuery(baseOptions?: Apollo.QueryHookOptions<PreferredOptionsQuery, PreferredOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreferredOptionsQuery, PreferredOptionsQueryVariables>(PreferredOptionsDocument, options);
      }
export function usePreferredOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreferredOptionsQuery, PreferredOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreferredOptionsQuery, PreferredOptionsQueryVariables>(PreferredOptionsDocument, options);
        }
export function usePreferredOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PreferredOptionsQuery, PreferredOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreferredOptionsQuery, PreferredOptionsQueryVariables>(PreferredOptionsDocument, options);
        }
export type PreferredOptionsQueryHookResult = ReturnType<typeof usePreferredOptionsQuery>;
export type PreferredOptionsLazyQueryHookResult = ReturnType<typeof usePreferredOptionsLazyQuery>;
export type PreferredOptionsSuspenseQueryHookResult = ReturnType<typeof usePreferredOptionsSuspenseQuery>;
export type PreferredOptionsQueryResult = Apollo.QueryResult<PreferredOptionsQuery, PreferredOptionsQueryVariables>;