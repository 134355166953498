import { Icon, Text } from '@smart/react-components';
import classNames from 'classnames';
import { useState } from 'react';
import { SectionProps } from './Section.config';
import './Section.scss';

const Section = ({
  expanded = false,
  children = '',
  title = '',
  additionalClasses = '',
  hasAccordion,
  hasBorder = false,
  convertAccountToggle,
}: SectionProps) => {
  const [openAccordion, setOpenAccordion] = useState(expanded);

  const BASE_CLASS = 'adb-section';
  const BASE_CLASS_BORDER = 'adb-section--border';

  const classes = classNames(BASE_CLASS, additionalClasses, {
    [BASE_CLASS_BORDER]: hasBorder && openAccordion,
  });

  const handleOpenAccordion = () =>
    hasAccordion && setOpenAccordion((prev) => !prev);

  return (
    <div className={classNames({ [`${BASE_CLASS}--closed`]: !openAccordion })}>
      <section className={classes}>
        <div
          className={classNames(`${BASE_CLASS}__title-wrap`, {
            clickable: hasAccordion,
          })}
          role="button"
          onClick={handleOpenAccordion}
          onKeyDown={handleOpenAccordion}
          tabIndex={0}
        >
          <Text variant="lbl-200" as="p">
            {title}
          </Text>
          {hasAccordion && (
            <Icon
              mode={300}
              icon={
                openAccordion ? 'chevron-compact-up' : 'chevron-compact-down'
              }
            />
          )}
          {convertAccountToggle}
        </div>
        {openAccordion && (
          <div className={`${BASE_CLASS}__content`}>{children}</div>
        )}
      </section>
    </div>
  );
};

export default Section;
