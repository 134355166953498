/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllCustomersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AllCustomersFilter>;
}>;


export type AllCustomersQuery = { __typename?: 'Query', allCustomers: { __typename?: 'AllCustomersResponse', customers: Array<{ __typename?: 'CustomerBusiness', regType?: Types.CustomerRegType | null, accountType?: Types.CustomerAccountType | null, firstName: string, lastModified?: string | null, lastName: string, mobileNumber?: string | null, sfCustomerId?: string | null, type?: Types.CustomerType | null, userId: string, uuid: string, bpid?: string | null, sfOrgId?: string | null, market?: string | null, preferredLanguage?: string | null, titleCode?: string | null, recordType?: string | null, companyName?: string | null, vatRegistrationNumber?: string | null, companyRegistrationNumber?: string | null, isActive?: boolean | null, address?: { __typename?: 'Address', street?: string | null, town?: string | null, countryCode?: string | null, postalCode?: string | null, regionCode?: string | null } | null } | { __typename?: 'CustomerPrivate', regType?: Types.CustomerRegType | null, accountType?: Types.CustomerAccountType | null, firstName: string, lastModified?: string | null, lastName: string, mobileNumber?: string | null, sfCustomerId?: string | null, type?: Types.CustomerType | null, userId: string, uuid: string, market?: string | null, preferredLanguage?: string | null, titleCode?: string | null, recordType?: string | null, isActive?: boolean | null, address?: { __typename?: 'Address', street?: string | null, town?: string | null, countryCode?: string | null, postalCode?: string | null, regionCode?: string | null } | null }> } };


export const AllCustomersDocument = gql`
    query AllCustomers($filter: AllCustomersFilter) {
  allCustomers(filter: $filter) {
    customers {
      ... on CustomerPrivate {
        regType
        accountType
        firstName
        lastModified
        lastName
        mobileNumber
        sfCustomerId
        type
        userId
        uuid
        market
        preferredLanguage
        titleCode
        recordType
        address {
          street
          town
          countryCode
          postalCode
          regionCode
        }
        isActive
      }
      ... on CustomerBusiness {
        regType
        accountType
        firstName
        lastModified
        lastName
        mobileNumber
        sfCustomerId
        type
        userId
        uuid
        bpid
        sfOrgId
        market
        preferredLanguage
        titleCode
        recordType
        address {
          street
          town
          countryCode
          postalCode
          regionCode
        }
        companyName
        vatRegistrationNumber
        companyRegistrationNumber
        isActive
      }
    }
  }
}
    `;

/**
 * __useAllCustomersQuery__
 *
 * To run a query within a React component, call `useAllCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCustomersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllCustomersQuery(baseOptions?: Apollo.QueryHookOptions<AllCustomersQuery, AllCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCustomersQuery, AllCustomersQueryVariables>(AllCustomersDocument, options);
      }
export function useAllCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCustomersQuery, AllCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCustomersQuery, AllCustomersQueryVariables>(AllCustomersDocument, options);
        }
export function useAllCustomersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllCustomersQuery, AllCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllCustomersQuery, AllCustomersQueryVariables>(AllCustomersDocument, options);
        }
export type AllCustomersQueryHookResult = ReturnType<typeof useAllCustomersQuery>;
export type AllCustomersLazyQueryHookResult = ReturnType<typeof useAllCustomersLazyQuery>;
export type AllCustomersSuspenseQueryHookResult = ReturnType<typeof useAllCustomersSuspenseQuery>;
export type AllCustomersQueryResult = Apollo.QueryResult<AllCustomersQuery, AllCustomersQueryVariables>;