/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllCartsQueryVariables = Types.Exact<{
  input: Types.AllCartsInput;
}>;


export type AllCartsQuery = { __typename?: 'Query', allCarts: { __typename?: 'AllCartsResponse', carts: Array<{ __typename?: 'CartSummary', ald: { __typename?: 'Ald', customer: string, aldStatus: string, inStatusForDays: string, identityStatus: string, code: string, name: string, id: string }, cartDetails: { __typename?: 'CartDetails', model: string, line: string, exterior: string, innertior: string, battery: string, roof: string }, customerDetails: { __typename?: 'CustomerDetails', customerName: string, smartId: string, phone: string, location: string, idType: string, country: string, idNumber: string, customerType: string } }>, pagination: { __typename?: 'Pagination', currentPage?: number | null, pageSize?: number | null, totalPages?: number | null, totalResults?: number | null } } };


export const AllCartsDocument = gql`
    query AllCarts($input: AllCartsInput!) {
  allCarts(input: $input) {
    carts {
      ald {
        customer
        aldStatus
        inStatusForDays
        identityStatus
        code
        name
        id
      }
      cartDetails {
        model
        line
        exterior
        innertior
        battery
        roof
      }
      customerDetails {
        customerName
        smartId
        phone
        location
        idType
        country
        idNumber
        customerType
      }
    }
    pagination {
      currentPage
      pageSize
      totalPages
      totalResults
    }
  }
}
    `;

/**
 * __useAllCartsQuery__
 *
 * To run a query within a React component, call `useAllCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCartsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllCartsQuery(baseOptions: Apollo.QueryHookOptions<AllCartsQuery, AllCartsQueryVariables> & ({ variables: AllCartsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCartsQuery, AllCartsQueryVariables>(AllCartsDocument, options);
      }
export function useAllCartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCartsQuery, AllCartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCartsQuery, AllCartsQueryVariables>(AllCartsDocument, options);
        }
export function useAllCartsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllCartsQuery, AllCartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllCartsQuery, AllCartsQueryVariables>(AllCartsDocument, options);
        }
export type AllCartsQueryHookResult = ReturnType<typeof useAllCartsQuery>;
export type AllCartsLazyQueryHookResult = ReturnType<typeof useAllCartsLazyQuery>;
export type AllCartsSuspenseQueryHookResult = ReturnType<typeof useAllCartsSuspenseQuery>;
export type AllCartsQueryResult = Apollo.QueryResult<AllCartsQuery, AllCartsQueryVariables>;