import { Checkbox } from '@smart/react-components';
import classNames from 'classnames';
import { useState } from 'react';
import '../../FilterDropdown/FilterDropdown.scss';
import { FilterItemMultipleProps } from './FilterItemMultiple.config';

const BASE_CLASS = 'adb-filter-dropdown';

/**
 * FilterItemMultiple component
 *
 * @exports
 * @param FilterItemMultipleProps FilterItemMultipleProps
 * @returns <FilterItemMultiple> ReactNode
 */

const FilterItemMultiple = ({
  filterItem,
  isFilterSelected,
  onFilterSelect,
}: FilterItemMultipleProps) => {
  const [selected, setSelected] = useState<boolean>(
    isFilterSelected(filterItem)
  );

  const onFilterClick = () => {
    onFilterSelect(filterItem);
    setSelected(isFilterSelected(filterItem));
  };

  return (
    <li
      className={classNames(
        ` ${BASE_CLASS}__item ${BASE_CLASS}__item-check-box`,
        {
          [`${BASE_CLASS}__item--selected`]: selected,
        }
      )}
      data-testid={filterItem.name}
    >
      <div className={`${BASE_CLASS}__item-container`}>
        <Checkbox
          value={filterItem.name}
          id={filterItem.name}
          onCheckedChange={onFilterClick}
          checked={selected}
        />
        <label
          htmlFor={filterItem.name}
          className={classNames(`${BASE_CLASS}__item--label`, {
            [`${BASE_CLASS}__item--selected-title`]: selected,
          })}
          data-testid={`${filterItem.displayName}-label`}
        >
          {filterItem.displayName}
        </label>
      </div>
    </li>
  );
};

export default FilterItemMultiple;
