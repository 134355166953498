import { CustomerAccountType } from '@smart/adb-shared';
import { truncateLanguage } from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut.config';
import { Checkbox, Link, Text } from '@smart/react-components';
import { convertMarketIdToCountryCode } from '@utils/helpers/market';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { Trans } from 'react-i18next';
import { DeclarationTextProps } from './DeclarationText.config';

const BASE_CLASS = 'adb-details';

const DeclarationText = ({
  consent,
  onConsentChange,
  accountType,
}: DeclarationTextProps) => {
  const { market } = useMarketContext();

  const marketCode = convertMarketIdToCountryCode(market);

  const { locale } = useLanguageContext();
  const localeTrunc = truncateLanguage(locale);

  const handleOnConsentChange = () => onConsentChange(!consent);

  return (
    <div className={`${BASE_CLASS}__form-declaration`}>
      <Text variant="p-100" as="p">
        <Trans i18nKey="form_fields.consent.info">
          Your smart ID is the basis...
        </Trans>
      </Text>
      {accountType === CustomerAccountType.Private && (
        <div className={`${BASE_CLASS}__form-checkbox`}>
          <Checkbox
            id="consent"
            onCheckedChange={handleOnConsentChange}
            value=""
            checked={consent ?? false}
          />
          <Text variant="p-100" as="p">
            <Trans i18nKey="form_fields.consent.private_customer">
              YES, I agree...
              <Link
                inline
                href={`https://${marketCode}.smart.com/${localeTrunc}/service/ecosystem-and-marketing/#1/`}
                target="_blank"
                rel="noreferrer noopener"
              >
                companies affiliated with smart
              </Link>
              and my
              <Link
                inline
                href={`https://${marketCode}.smart.com/${localeTrunc}/service/ecosystem-and-marketing/#2/`}
                target="_blank"
                rel="noreferrer noopener"
              >
                smart Agents and smart Authorized Service
              </Link>
              may analyze...
              <Link
                inline
                href={`https://${marketCode}.smart.com/${localeTrunc}/service/ecosystem-and-marketing/#3/`}
                target="_blank"
                rel="noreferrer noopener"
              >
                data and preferences provided
              </Link>
              by me as well...
              <Link
                inline
                href={`https://${marketCode}.smart.com/${localeTrunc}/service/ecosystem-and-marketing/#4/`}
                target="_blank"
                rel="noreferrer noopener"
              >
                usage/purchasing behavior
              </Link>
              in order to...
              <Link
                inline
                href={`https://${marketCode}.smart.com/${localeTrunc}/service/ecosystem-and-marketing/#5/`}
                target="_blank"
                rel="noreferrer noopener"
              >
                products, services and offers
              </Link>
            </Trans>
          </Text>
        </div>
      )}
      <Text variant="p-100" as="p">
        <Trans i18nKey="form_fields.consent.consent_info">
          This consent ...
          <Link
            inline
            href={`https://${marketCode}.smart.com/${localeTrunc}/service/data-protection/`}
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy statement
          </Link>
        </Trans>
      </Text>
      <Text variant="p-100" as="p">
        <Trans i18nKey="form_fields.consent.register_info">
          By registering...
          <Link
            inline
            href={`https://${marketCode}.smart.com/${localeTrunc}/service/terms-conditions/`}
            target="_blank"
            rel="noreferrer noopener"
          >
            smart ID Terms of Use
          </Link>
          apply. I have...
          <Link
            inline
            href={`https://${marketCode}.smart.com/${localeTrunc}/service/ecosystem-and-marketing/#6`}
            target="_blank"
            rel="noreferrer noopener"
          >
            data sharing in the smart Ecosystem
          </Link>
          and the smart
          <Link
            inline
            href={`https://${marketCode}.smart.com/${localeTrunc}/service/data-protection/`}
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy Statement
          </Link>
        </Trans>
      </Text>
    </div>
  );
};

export default DeclarationText;
