import { useEffect } from 'react';

const handleIntersections = (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver,
  callback: () => void,
  target: HTMLElement
) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      callback();
      observer.unobserve(target);
    }
  });
};

export const useInitialIntersection = (
  elementRef: React.RefObject<HTMLElement>,
  callback: () => void,
  margin = `50%`
) => {
  useEffect(() => {
    const target = elementRef.current;

    if (target) {
      const intersectionObserver = new IntersectionObserver(
        (entries, observer) =>
          handleIntersections(entries, observer, callback, target),
        { root: null, rootMargin: margin, threshold: 0 }
      );

      intersectionObserver.observe(target);
    }
  }, [callback, elementRef, margin]);
};
