import { useAgentContext } from 'contexts/agent-context';
import { useMarketContext } from 'contexts/market-context';
import { useFeatureFlagQuery } from 'graphql/queries/featureFlags.generated';

interface FlagDefinition<T> {
  key: FeatureFlagKey;
  defaultValue: T;
}

// extend type below with "... | 'new-feature-flag'" when adding new feature flags
export type FeatureFlagKey =
  | 'use-tirespecs'
  | 'use-dms-search'
  | 'superlight-campaign-id' // Used to setup market specific campaign id's in superlight test drive
  | 'demo-vehicle-ordering'
  | 'showroom-vehicle-ordering'
  | 'use-rate-adb'
  | 'use-outlet-documents'
  | 'use-new-document-system'
  | 'use-aldCarts'
  | 'use-aldIdentityStatus'
  | 'use-documens-dmp-elo-toggler';

export function useFeatureFlag<T>({ key, defaultValue }: FlagDefinition<T>): T {
  const { market } = useMarketContext();
  const { agent } = useAgentContext();

  const flag = useFeatureFlagQuery({
    variables: {
      input: {
        key,
        marketId: market ?? '',
        outletId: agent?.outletId,
      },
    },
    skip: !market || !agent,
  });

  if (
    flag.data?.featureFlag?.value === undefined ||
    flag.data?.featureFlag?.value === null
  )
    return defaultValue;

  return flag.data.featureFlag.value as T;
}
