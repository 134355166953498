import { useTranslation } from 'react-i18next';

/**
 * @method getMaxLengthValidationMsg
 * This method is used to return the max length validation message for schemas
 * @param fieldName: string
 * @param  maxLength: number
 */

export const GetMaxLengthValidationMsg = (
  msg: string,
  maxLength: number
): string => {
  const { t } = useTranslation();

  return `${msg} ${t(
    'general.error_messages.validations.length_validation'
  )} ${maxLength} ${t('general.error_messages.validations.characters')}`;
};
