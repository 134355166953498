import { CountryCode, Language } from '@smart/types';

export const getCountryName = (
  language: Language,
  countryCode: CountryCode
): string => {
  const regionNames = new Intl.DisplayNames([language], {
    type: 'region',
    fallback: 'none',
  });
  const country = regionNames.of(countryCode.toUpperCase());

  if (!country) throw new Error(`Could not translate ${countryCode}`);

  return country;
};
