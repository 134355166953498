import DashboardItem from '@smart/components-adb/molecules/DashboardItem/DashboardItem';
import { DashboardItemType } from '@smart/components-adb/molecules/DashboardItem/DashboardItem.config';
import {
  DateFormats,
  getLocaleDateFromString,
} from '@ui/library/helpers/date-locale';
import { useLanguageContext } from 'contexts/language-context';
import { getIconFromStatus } from 'pages/tasks/tasks/utils';
import { useTranslation } from 'react-i18next';
import { TaskProps } from '../YourTasks.config';

const Task = ({ task }: TaskProps) => {
  const { locale } = useLanguageContext();
  const { t } = useTranslation();

  const iconProps = getIconFromStatus(task.status);

  return (
    <DashboardItem
      key={`${task.id}-${DashboardItemType.TASK}`}
      id={task.id}
      title={task.title}
      itemType={DashboardItemType.TASK}
      description={`${t('task.due_date')}: ${
        task.scheduledDate
          ? getLocaleDateFromString(
              task.scheduledDate,
              locale,
              DateFormats.DATE
            )
          : '-'
      } `}
      icon={iconProps.icon}
      iconColor={iconProps.color}
      backgroundColor={iconProps.backgroundColor}
      iconHasBorder={iconProps.iconHasBorder}
      isItemClickable
    />
  );
};

export default Task;
