import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { Text, TextArea } from '@smart/react-components';
import { setHour } from '@ui/library/helpers/date-locale';
import { removeCanceledAppointmentFromGqlCache } from '@utils/helpers/gqlCacheHelper';
import { useNotificationContext } from 'contexts/notification-context';
import { useCancelAppointmentMutation } from 'graphql/queries/appointments.generated';
import { AllTasksDocument } from 'graphql/queries/tasks.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutlet } from 'hooks/outlet';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_CLASS } from '../config';
import {
  CancelDialogProps,
  TextCharacterLimit,
} from './AppointmentDialog.config';

const BASE_CLASS_APPOINTMENT_DIALOG = `${BASE_CLASS}__dialog`;

const getTranslations = (t: TFunction) => ({
  cancel: {
    header: t('feature_calendar.calendar_dialog.cancellation_heading'),
    label: t('feature_calendar.calendar_dialog.cancellation_reason'),
    success: {
      label: t('feature_calendar.notification.cancelled_appointment_title'),
      message: t(
        'feature_calendar.notification.cancelled_appointment_description'
      ),
    },
    error: {
      label: t('task.notification.task_error_title'),
      message: t('task.notification.task_error_description'),
    },
  },
  decline: {
    header: t('feature_calendar.calendar_dialog.decline_heading'),
    label: t('feature_calendar.calendar_dialog.decline_reason'),
    success: {
      label: t('feature_calendar.notification.declined_appointment_title'),
      message: t(
        'feature_calendar.notification.declined_appointment_description'
      ),
    },
    error: {
      label: t('task.notification.task_error_title'),
      message: t('task.notification.task_error_description'),
    },
  },
});

const CancelDialog = ({
  appointmentId,
  onClose,
  onCloseParent,
  calendarDateRange,
  type,
}: CancelDialogProps) => {
  const { t } = useTranslation();
  const { addSuccess } = useNotificationContext();
  const outlet = useCurrentOutlet();

  const [reason, setReason] = useState('');
  const isReasonValid = reason.trim().length > 0;
  const translations = getTranslations(t);

  const onChangeReason = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(event.target.value);
  };

  const refetchQueries = [
    {
      query: AllTasksDocument,
      variables: {
        input: {
          outletId: outlet?.mcsId,
        },
      },
      skip: !outlet?.mcsId,
    },
  ];

  const closeAll = () => {
    onClose();
    if (onCloseParent) {
      onCloseParent();
    }
  };

  const [cancelAppointment, { loading }] = useCancelAppointmentMutation({
    variables: {
      input: { appointmentId, reason },
    },
    update: (cache, { data }) =>
      removeCanceledAppointmentFromGqlCache({
        cache,
        appointmentId: data?.cancelAppointment.appointmentId,
        input: {
          startDateTime: setHour(calendarDateRange[0], 6),
          endDateTime: setHour(calendarDateRange[1], 23),
          outletId: outlet?.bpId ?? '',
        },
      }),
    onCompleted: () => {
      addSuccess({
        label: translations[type].success.label,
        message: translations[type].success.message,
      });
      closeAll();
    },
    onError: (error) => {
      enhanceError({
        error,
        label: translations[type].error.label,
        displayMessage: translations[type].error.message,
      });
      closeAll();
    },
    refetchQueries,
  });

  return (
    <AdbDialog
      id="cancel-dialog"
      buttons={{
        primary: {
          label: t('feature_calendar.general.buttons.confirm'),
          onClick: cancelAppointment,
          disabled: loading || !isReasonValid,
          isLoading: loading,
        },
        secondary: {
          label: t('feature_calendar.general.buttons.close'),
          onClick: onClose,
        },
      }}
    >
      <AdbDialog.Header>
        <Text variant="hl-100">{translations[type].header}</Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <div
          className={`${BASE_CLASS_APPOINTMENT_DIALOG}__confirmation-modal-body`}
        >
          <TextArea
            value={reason}
            label={translations[type].label}
            id="cancelling_reason"
            onChange={onChangeReason}
            maxLength={TextCharacterLimit}
            caption={`${reason.length}/${TextCharacterLimit}`}
            required
            error={
              !isReasonValid && (
                <ErrorField
                  errorMsg={`${t('feature_calendar.general.reason')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`}
                />
              )
            }
          />
        </div>
      </AdbDialog.Content>
    </AdbDialog>
  );
};

export default CancelDialog;
