import AdbIcon from '@smart/components-adb/atoms/AdbIcon/AdbIcon';
import {
  IconBackground,
  IconColor,
} from '@smart/components-adb/atoms/AdbIcon/AdbIcon.config';
import { IconProps, Text } from '@smart/react-components';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

const BASE_CLASS = 'adb-customer-products';

interface SimStatusContainerProps extends PropsWithChildren {
  icon: IconProps['icon'];
  iconBackground: IconBackground;
  title: string;
}

const SimStatusContainer = ({
  icon,
  iconBackground,
  title,
  children,
}: SimStatusContainerProps) => (
  <div
    className={classNames(
      'adb-dashboard-item',
      `${BASE_CLASS}__dashboard_item`
    )}
  >
    <AdbIcon
      icon={icon}
      color={IconColor.WHITE}
      backgroundColor={iconBackground}
    />
    <div
      className={classNames(
        `${BASE_CLASS}__details`,
        `${BASE_CLASS}__sim_status_text_container`
      )}
    >
      <Text variant="cap-300" as="p" className={`${BASE_CLASS}__details-title`}>
        {title}
      </Text>

      <div className={`${BASE_CLASS}__action_item`}>{children}</div>
    </div>
  </div>
);

export default SimStatusContainer;
