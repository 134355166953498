import { Customer, Maybe, TaskCustomer } from '@smart/adb-shared';

export const getCustomerRoute = (
  customer: Maybe<Customer> | Maybe<TaskCustomer> | undefined,
  subView = 'overview'
) => {
  if (!customer) return '';
  const baseUrl = `/customer/${subView}/${customer.uuid}/${customer.sfCustomerId}`;
  if ('bpid' in customer && 'sfOrgId' in customer) {
    return `${baseUrl}/${customer.bpid}/${customer.sfOrgId}`;
  }

  return baseUrl;
};
