/**
 * Date / time utilities
 */

import { AdbLocale, getCustomFormattedDate } from './date-locale';

/**
 * Add leading 0 to make a single digit number to a double digit string
 *
 * @param num number
 * @returns string
 */
export const padTo2Digits = (num: number): string =>
  num.toString().padStart(2, '0');

/**
 * @method getRemainingTimeByDate
 * This method is used  to return the remaining time based on dates.
 * @param startDate: date string
 * @param endDate:  date String
 * @returns number
 * */
export const getRemainingTimeByDate = (startDate: string, endDate: string) =>
  Math.floor(
    Math.abs(new Date(endDate).getTime() - new Date(startDate).getTime()) /
      1000 /
      3600
  ) % 24;

/**
 * Get mock dates relative to today
 *
 * @param increment number: how many days from now
 * @param hour number: which hour of the day
 * @returns Date
 */
export const getDateFromToday = (increment: number, hour: number) => {
  const today = new Date();
  const newDay = new Date(today);

  newDay.setDate(newDay.getDate() + increment);
  newDay.setHours(hour, 0, 0, 0);

  return newDay;
};

/**
 * @method getTimeFormat
 * This method is used  to return the time in format TIMEw (hh:mm)
 * @param date Date
 * @returns string
 * */
export const getTimeFormat = (date: Date) => {
  const hour = padTo2Digits(date.getHours());
  const min = padTo2Digits(date.getMinutes());

  return `${hour}:${min}`;
};

/**
 * @method getWeekStartDate
 * This method is used  to return the current week ending date
 * @param date Date
 * @returns Date
 * */
export const getWeekStartDate = (): Date => {
  const currentDate = new Date();

  currentDate.setHours(0, 0, 0, 0);

  const currentDay = currentDate.getDay();
  const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1);

  return new Date(currentDate.setDate(diff));
};

/**
 * @method getWeekEndDate
 * This method is used  to return the current week ending date
 * @param date Date
 * @returns Date
 * */
export const getWeekEndDate = (): Date => {
  const currentDate = getWeekStartDate();
  const endDateTime = new Date(currentDate.setDate(currentDate.getDate() + 6));

  endDateTime.setHours(23, 59, 59, 59);

  return endDateTime;
};

/**
 * @method getIsFutureDate
 * This method is used to return boolean if the dateToCompare passed is greater than current date
 * @param dateToCompare Date
 * @returns boolean
 * */
export const getIsFutureDate = (dateToCompare: Date): boolean =>
  new Date(new Date(dateToCompare).toISOString()).getDate() >
  new Date().getDate();

/**
 * @method getIsFutureTime
 * This method is used to return boolean if the dateToCompare passed is greater than current time
 * @param dateToCompare Date
 * @returns boolean
 * */
export const getIsFutureTime = (dateToCompare: Date): boolean =>
  new Date(new Date(dateToCompare).toISOString()).getTime() >
  new Date().getTime();

/**
 * @method getDaysInCurrentMonth
 * This method is used to return number of days exists in current month
 * @param date Date
 * @returns number
 * */
export const getDaysInCurrentMonth = (date: Date): number =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

/**
 * @method getYearEndDate
 * This method is used  to return the current year end date
 * @param date Date
 * @returns Date
 * */
export const getYearEndDate = (date: Date): Date =>
  new Date(date.getFullYear(), 11, 31, 23, 59, 59);

/**
 * @method formatDateAndTime
 * this method is used to format the date and time
 * * @param date Date
 * @returns string
 */
export const formatDateAndTime = (date: Date, locale: AdbLocale) =>
  getCustomFormattedDate(date, 'P, kk:mm', locale);

export const formatDate = (date: Date, locale: AdbLocale) =>
  getCustomFormattedDate(date, 'P', locale);

/**
 * @method getDateXMonthsBefore
 * This method is used to return last x month date
 * @param x number of months
 * @returns Date
 * */
export const getDateXMonthsBefore = (x: number): Date => {
  const currentDate = new Date();

  currentDate.setHours(0, 0, 0, 0);

  const pastXMonthValue = currentDate.getMonth() - x;

  return new Date(currentDate.setMonth(pastXMonthValue));
};

/**
 * @method getDateXMonthsAfter
 * This method is used to return future x month date
 * @param x number of months
 * @returns Date
 * */
export const getDateXMonthsAfter = (x: number): Date => {
  const currentDate = new Date();
  currentDate.setHours(23, 59, 59, 59);

  const futureXMonthValue = currentDate.getMonth() + x;

  return new Date(currentDate.setMonth(futureXMonthValue));
};

/**
 * get start date and time of the today's date
 * @returns Date
 */
export const getTodayStartDateTime = (): Date => {
  const startDate = new Date();

  startDate.setHours(0, 0, 0, 0);

  return startDate;
};
