import { Customer } from '@smart/adb-shared';
import EmptyLayout from '@smart/components-adb/atoms/EmptyDataLayout/EmptyLayout';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { Flex } from '@smart/react-components';
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { useTranslation } from 'react-i18next';
import Pagination from '../Pagination/Pagination';
import { useOrdersContext } from './order-items-context';
import OrderItem from './OrderItem';
import './OrderItems.scss';
import OrdersSearch from './Search/Search';

const defaultSize = 300;

const BASE_CLASS = 'adb-order-items';

interface OrderItemsI {
  customer?: Customer;
}

const OrderItems = ({ customer }: OrderItemsI) => {
  const { loading, allOrders, pagination } = useOrdersContext();
  const { t } = useTranslation();

  const tableInstance = useReactTable({
    columns: [{ accessorKey: 'id' }],
    data: allOrders,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: false,
    getFilteredRowModel: getFilteredRowModel(),
    pageCount: pagination.totalPages ?? 0,
    manualPagination: true,
  });

  return (
    <Flex direction="column" height="full">
      <AdbLayout.Header>
        <OrdersSearch />
      </AdbLayout.Header>
      <AdbLayout.Content backgroundColor="bg-level-2">
        {!loading && allOrders.length > 0 ? (
          tableInstance
            .getRowModel()
            .rows.map((row) => (
              <OrderItem
                order={row.original}
                key={row.id}
                customer={customer}
              />
            ))
        ) : (
          <div key="loading" className={`${BASE_CLASS}__loading`}>
            <LoadingIndicator loading={loading}>
              <EmptyLayout message={t('orders.empty_msg')} />
            </LoadingIndicator>
          </div>
        )}
      </AdbLayout.Content>
      {!loading && (
        <AdbLayout.Footer backgroundColor="bg-level-2">
          <Pagination instance={tableInstance} defaultSize={defaultSize} />
        </AdbLayout.Footer>
      )}
    </Flex>
  );
};

export default OrderItems;
