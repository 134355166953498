/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddOrderLeasingDetailsMutationVariables = Types.Exact<{
  input: Types.AddOrderLeasingDetailsInput;
}>;


export type AddOrderLeasingDetailsMutation = { __typename?: 'Mutation', addOrderLeasingDetails: string };


export const AddOrderLeasingDetailsDocument = gql`
    mutation AddOrderLeasingDetails($input: AddOrderLeasingDetailsInput!) {
  addOrderLeasingDetails(input: $input)
}
    `;
export type AddOrderLeasingDetailsMutationFn = Apollo.MutationFunction<AddOrderLeasingDetailsMutation, AddOrderLeasingDetailsMutationVariables>;

/**
 * __useAddOrderLeasingDetailsMutation__
 *
 * To run a mutation, you first call `useAddOrderLeasingDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderLeasingDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderLeasingDetailsMutation, { data, loading, error }] = useAddOrderLeasingDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrderLeasingDetailsMutation(baseOptions?: Apollo.MutationHookOptions<AddOrderLeasingDetailsMutation, AddOrderLeasingDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrderLeasingDetailsMutation, AddOrderLeasingDetailsMutationVariables>(AddOrderLeasingDetailsDocument, options);
      }
export type AddOrderLeasingDetailsMutationHookResult = ReturnType<typeof useAddOrderLeasingDetailsMutation>;
export type AddOrderLeasingDetailsMutationResult = Apollo.MutationResult<AddOrderLeasingDetailsMutation>;
export type AddOrderLeasingDetailsMutationOptions = Apollo.BaseMutationOptions<AddOrderLeasingDetailsMutation, AddOrderLeasingDetailsMutationVariables>;