import { PurchasedItems } from '@smart/components-adb/molecules/PurchasedItems/PurchasedItems';
import CustomerLayout from 'layouts/customer-layout/CustomerLayout';

const PurchasedProducts = () => (
  <CustomerLayout>
    <PurchasedItems />
  </CustomerLayout>
);

export default PurchasedProducts;
