import SearchFilterPanel from '@smart/components-adb/atoms/SearchFilterPanel/SearchFilterPanel';
import FilterDropdown from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown';
import { Button, TextInput } from '@smart/react-components';
import { ColumnFilter } from '@tanstack/react-table';
import {
  FacetValue,
  getTaskTypeFilterValues,
  TaskExpertFilterValues,
  TaskStepFilterValues,
} from '@ui/data-models/facet/facet.model';
import { useAgentContext } from 'contexts/agent-context';
import { useMarketContext } from 'contexts/market-context';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ExpertFilter, FilterType } from '../config';
import {
  filterIdMap,
  getFilterValue,
  getFormattedFiltersWithTranslation,
  TaskSearchProps,
} from './search.config';
import './search.scss';

const BASE_CLASS = 'adb-tasks-search';

const TaskSearch = <T,>({
  refetch,
  onSearchQueryUpdate,
  filters,
  setFilters,
  rows,
  currentFilter,
}: TaskSearchProps<T>) => {
  const { t } = useTranslation();
  const { agent } = useAgentContext();
  const { market } = useMarketContext();
  const [params, setParams] = useSearchParams();
  const searchQuery = params.get('search') ?? '';

  const formattedTaskTypeFilters: FacetValue[] =
    getFormattedFiltersWithTranslation(getTaskTypeFilterValues(market), t);

  const formattedTaskExpertFilters: FacetValue[] =
    getFormattedFiltersWithTranslation(TaskExpertFilterValues, t);

  const formattedTaskStepFilters: FacetValue[] =
    getFormattedFiltersWithTranslation(TaskStepFilterValues, t);

  // React hook to store current free text search
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [showFilters, setShowFilters] = useState<boolean>(false);

  useEffect(() => {
    setCurrentSearch(searchQuery);
    onSearchQueryUpdate(searchQuery, currentFilter);
  }, [searchQuery, setCurrentSearch, onSearchQueryUpdate, currentFilter]);

  const debouncedSetParams = useMemo(
    () => debounce((val) => setParams({ search: val }), 100),
    [setParams]
  );

  // Function to update text search query
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    setCurrentSearch(value);
    onSearchQueryUpdate(value, currentFilter);
    debouncedSetParams(value);
  };

  const clearAllFilters = (): void => {
    setFilters?.((prev) => ({
      ...prev,
      [FilterType.TASK]: [],
      [FilterType.EXPERT]: [],
      [FilterType.STEP]: [],
    }));
    setCurrentSearch('');
    onSearchQueryUpdate('', currentFilter);
  };

  const getSelectedFilters = (filterType: FilterType) => {
    const selectedFilters = filters?.[filterType];

    if (selectedFilters?.length === 0)
      return [{ displayName: t('task.filters.all'), queryValue: 'all' }];

    const getDisplayName = (filter: ColumnFilter) => {
      switch (filter.value) {
        case agent.id:
          return t(`task.filters.${ExpertFilter.YOU}`);
        case '-':
          return t(`task.filters.${ExpertFilter.UNASSIGNED}`);
        default:
          return t(`task.filters.${(filter.value as string).toLowerCase()}`);
      }
    };

    const getQueryValue = (filterValue: string) => {
      switch (filterValue) {
        case agent.id:
          return ExpertFilter.YOU;
        case '-':
          return ExpertFilter.UNASSIGNED;
        default:
          return filterValue;
      }
    };

    return selectedFilters?.map((filter) => ({
      displayName: getDisplayName(filter),
      queryValue: getQueryValue(filter.value as string),
    }));
  };

  const handleFilterChange = (filterType: FilterType, filter: FacetValue) => {
    const filterValue = getFilterValue(filterType, filter, agent);

    setFilters?.((prevFilterQueries) => {
      const currentFilters = prevFilterQueries[filterType];
      const isSelected = currentFilters.some((f) => f.value === filterValue);
      const newFilters = isSelected
        ? currentFilters.filter((f) => f.value !== filterValue)
        : [{ id: filterIdMap[filterType], value: filterValue }];

      return {
        ...prevFilterQueries,
        [filterType]: newFilters,
      };
    });
  };

  const {
    [FilterType.STATUS]: rmFilter1,
    new: rmFilter2,
    ...activeFilters
  } = filters ?? {};

  return (
    <div className={BASE_CLASS}>
      <SearchFilterPanel
        searchBar={
          <TextInput
            type="text"
            name="search"
            id="text_input_id"
            onChange={handleSearchInputChange}
            label={t('task.tasks_search')}
            caption={`${rows} ${t('general.labels.results')}`}
            value={currentSearch}
          />
        }
        filters={
          <>
            {refetch && (
              <Button
                className={`${BASE_CLASS}__icon-btn`}
                variant="ghost"
                mode={200}
                type="button"
                onClick={() => refetch()}
              >
                <Button.Icon icon="refresh" />
                {t('customer.documents.buttons.refresh')}
              </Button>
            )}
            <Button
              className={`${BASE_CLASS}__icon-btn`}
              variant="ghost"
              mode={200}
              type="button"
              onClick={() => setShowFilters(!showFilters)}
            >
              {showFilters
                ? t('customer.documents.buttons.hide_filter')
                : t('customer.documents.buttons.show_filter')}
              <Button.Icon icon="filter" />
            </Button>
          </>
        }
      />
      {showFilters && (
        <div className={`${BASE_CLASS}__filters`}>
          <div className={`${BASE_CLASS}__filters-wrapper`}>
            <FilterDropdown
              label={`${t('task.filters.task')}:`}
              items={formattedTaskTypeFilters}
              onSelect={(filter) =>
                handleFilterChange(FilterType.TASK, filter as FacetValue)
              }
              selectedFilter={getSelectedFilters(FilterType.TASK)}
              showSelectedFilter
              xPos="left"
            />
            <FilterDropdown
              label={`${t('task.expert')}:`}
              items={formattedTaskExpertFilters}
              onSelect={(filter) =>
                handleFilterChange(FilterType.EXPERT, filter as FacetValue)
              }
              selectedFilter={getSelectedFilters(FilterType.EXPERT)}
              showSelectedFilter
              xPos="left"
            />
            <FilterDropdown
              label={`${t('task.step')}:`}
              items={formattedTaskStepFilters}
              onSelect={(filter) =>
                handleFilterChange(FilterType.STEP, filter as FacetValue)
              }
              selectedFilter={getSelectedFilters(FilterType.STEP)}
              showSelectedFilter
              xPos="left"
            />
            {!!Object.values(activeFilters).flat().length && (
              <Button
                className={`${BASE_CLASS}__filters-clear-all`}
                variant="secondary"
                mode={200}
                onClick={clearAllFilters}
              >
                {t('orders.filter.clear_all')}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskSearch;
