import { AdbDatePicker } from '@smart/components-adb/adb-required-label/AdbDatePicker';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button, Text } from '@smart/react-components';
import { useRescheduleMutation } from 'graphql/queries/tasks.generated';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import { format } from 'date-fns';
import { TaskActionProps } from '../types';
import './RescheduleModal.scss';

const BASE_CLASS_MODAL = `adb-reschedule-modal`;

const RescheduleModal = ({ task, onComplete }: TaskActionProps) => {
  const { t } = useTranslation();
  const [rescheduleLead, { loading }] = useRescheduleMutation();
  const { closeModal } = useModal();

  const [activityDate, setActivityDate] = useState(
    task.original.activityDate ?? ''
  );

  const validationSchema = object().shape({
    activityDate: string().required(),
  });

  return (
    <AdbDialog
      id={BASE_CLASS_MODAL}
      className={BASE_CLASS_MODAL}
      buttons={{
        primary: {
          onClick: async () => {
            const validation = await validationSchema.validate({
              activityDate,
            });

            if (validation.activityDate) {
              rescheduleLead({
                variables: {
                  input: {
                    taskId: task.original.id,
                    activityDate,
                  },
                },
              }).then(() => onComplete('single'));
            }
          },
          label: t('task.finish'),
          isLoading: loading,
        },
        secondary: {
          onClick: closeModal,
          label: t('feature_calendar.general.buttons.cancel'),
        },
      }}
    >
      <AdbDialog.Header>
        <Text variant="hl-100">{t('task.lead.reschedule_title')}</Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <AdbDatePicker
          showIcon
          portalId="activity-date-portal"
          id="activityDate"
          name="activityDate"
          value={activityDate}
          label={t('task.preferred_date')}
          dateFormat="yyyy-MM-dd"
          onChange={(date: Date) =>
            date && setActivityDate(format(date, 'yyyy-MM-dd'))
          }
          schema={validationSchema}
        />
      </AdbDialog.Content>
    </AdbDialog>
  );
};

export const RescheduleButton = ({
  task,
  onComplete,
  disabled,
}: TaskActionProps) => {
  const { t } = useTranslation();

  const { registerModal } = useModal();

  return (
    <Button
      variant="primary"
      mode={200}
      onClick={() =>
        registerModal(<RescheduleModal task={task} onComplete={onComplete} />)
      }
      disabled={disabled}
    >
      {t('task.lead.reschedule_cta')}
    </Button>
  );
};
