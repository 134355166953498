import { MarketCode } from '@utils/market/types';
import { CustomerAccountType } from '@smart/adb-shared';
import {
  BusinessCustomerFormValues,
  CustomerBasicInfoForm,
  PrivateCustomerFormValues,
} from '../helper';

/**
 * @type BasicInfoProps
 * Props types for BasicInfo component
 */
export type BasicInfoProps = CustomerBasicInfoForm & {
  customerDetails: PrivateCustomerFormValues | BusinessCustomerFormValues;
  consent?: boolean;
  onConsentChange?: (consent: boolean) => void;
  disableAllFields?: boolean;
};

export type FormProps = Omit<
  CustomerBasicInfoForm,
  'handleCustomerDetailSubmit'
> & {
  consent?: boolean;
  onConsentChange?: (consent: boolean) => void;
  disableAllFields: boolean;
  setAccountType: (accountType: CustomerAccountType) => void;
};

export const getCompanyRegistrationNumberLabel = (market: MarketCode) => {
  switch (market) {
    case 'fr':
      return 'SIRET';
    case 'it':
      return 'SDI';
    case 'nl':
      return 'KVK';
    case 'es':
      return 'CIF de empresa';
    case 'se':
      return 'Organisationsnummer';
    default:
      return 'Company Registration Number';
  }
};
