import { AdbLocale } from '@ui/library/helpers/date-locale';
import { Dispatch } from 'react';

import { CalendarAction } from '../types';
import { SettingActionType } from './action';
import { NotificationState } from './types';

/**
 * Set language of the calendar to the context store
 * @param dispatch Dispatch<CalendarAction>
 * @param currentLanguage string
 */
export const setLanguage = (
  dispatch: Dispatch<CalendarAction>,
  currentLanguage: string
) => {
  dispatch({ type: SettingActionType.SET_LANGUAGE, language: currentLanguage });
};

/**
 * Set locale of the calendar to the context store
 * @param dispatch Dispatch<CalendarAction>
 * @param currentLocale string
 */
export const setLocale = (
  dispatch: Dispatch<CalendarAction>,
  currentLocale: AdbLocale
) => {
  dispatch({ type: SettingActionType.SET_LOCALE, locale: currentLocale });
};

/**
 * Toggle status of the filter
 * @param dispatch Dispatch<CalendarAction>
 * @param hasFilterChanged boolean
 */
export const setHasFilterChanged = (
  dispatch: Dispatch<CalendarAction>,
  hasFilterChanged: boolean
) => {
  dispatch({
    type: SettingActionType.SET_HAS_FILTER_CHANGED,
    hasFilterChanged,
  });
};

/**
 * Set notification to the context store
 * @param dispatch Dispatch<CalendarAction>
 * @param notification NotificationState | undefined
 */
export const setNotification = (
  dispatch: Dispatch<CalendarAction>,
  notification: NotificationState | undefined
) => {
  dispatch({
    type: SettingActionType.SET_NOTIFICATION,
    notification,
  });
};
