import { ENDPOINTS } from '@services/endpoints';
import { AuthTokens } from '@ui/data-models/auth/auth.model';
import axios from 'axios';
import {
  AuthCookie,
  getAuthCookie,
  setAuthCookie,
} from 'contexts/auth-context';
import { ADB_COOKIES } from './cookie/adb-cookies';
import { removeCookie } from './cookie/cookie-configurator';

let refreshPromise: Promise<AuthCookie | void> | undefined;

const getRefreshPromise = () => {
  const cookie = getAuthCookie();
  if (!cookie) return Promise.reject();

  return axios
    .post<AuthTokens>(ENDPOINTS.AUTH.LOAD_AUTH_TOKENS, '', {
      params: {
        refreshToken: cookie['refresh-token'],
      },
    })
    .then((response) => setAuthCookie(response.data))
    .catch(() => {
      removeCookie(ADB_COOKIES.TOKENS);
      window.location.reload();
    });
};

export const refreshTokens = async () => {
  if (!refreshPromise) {
    refreshPromise = getRefreshPromise();
  }

  const auth = await refreshPromise;
  refreshPromise = undefined;
  return auth;
};
