import { Appointment } from '@smart/adb-shared';

/**
 * @type CalendarWeekDaysProps
 * Prop types for <CalendarWeekDays>
 */

export type CalendarWeekDaysProps = {
  selected: string;
  start: string;
  appointments?: Appointment[];
  handleDateSelection?: (date: string) => void;
  handleAppointmentClick?: () => void;
  language: string;
};

export const Weekdays = {
  'de-DE': [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag',
  ],
  'en-GB': [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ],
  'fr-FR': [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ],
};
