import {
  Appointment,
  AppointmentType,
  ContractType,
  Dropdown,
  MetadataInput,
} from '@smart/adb-shared';
import { AdbTextInput } from '@smart/components-adb/adb-required-label/AdbTextInput';
import { getFieldLabel } from '@smart/components-adb/adb-required-label/helper';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import { Checkbox, Select, TextArea } from '@smart/react-components';
import { formatDateAndTime } from '@ui/library/helpers/date';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { Formik } from 'formik';
import { useCurrentOutlet } from 'hooks/outlet';
import { useTranslation } from 'react-i18next';
import {
  TestDriveFormValues,
  TestDriveProps,
  createTestDrivePayload,
} from '../CreateDocumentType/CreateDocumentType.config';
import '../CreateDocumentType/CreateDocumentType.scss';
import { ELO_VERSION_COMMENT, ELO_VERSION_LABEL } from '../Documents.config';
import TestDriveContractSchema from './TestDriveContractSchema';

const BASE_CLASS = 'adb-create-document';

const initialValues: TestDriveFormValues = {
  drivingLicenseNo: '',
  appointmentTime: '',
  departureMileage: '',
  chargeCableHandover: false,
  comment: '',
};

const TestDriveContract = ({
  formFooter,
  appointments,
  customer,
  createDocument,
  docSystem,
}: TestDriveProps) => {
  const { t } = useTranslation();
  const { locale, language } = useLanguageContext();
  const { market } = useMarketContext();
  const { agent } = useAgentContext();
  const outlet = useCurrentOutlet();

  const testDriveAppointments = appointments.filter(
    (item) => item.type === AppointmentType.TestDrive
  );

  const appointmentSelectOptions: Dropdown[] = testDriveAppointments?.map(
    (appointment) => ({
      label: formatDateAndTime(new Date(appointment.start), locale),
      value: appointment.id,
    })
  );

  const defaultAppointment = appointmentSelectOptions[0]?.value ?? '';

  const handleTestDriveSubmit = (
    formValues: TestDriveFormValues,
    appointment: Appointment
  ) => {
    if (!outlet || !agent) {
      return;
    }

    const contract = createTestDrivePayload(
      {
        timestamp: new Date(),
        customer,
        outlet,
        agent,
        appointment,
        language,
        locale,
        market,
      },
      formValues
    );

    const params: MetadataInput = {
      docSystem,
      uuid: customer.uuid,
      ...(customer.__typename === 'CustomerBusiness' && { bpid: outlet.bpId }),
      marketCode: market,
      documentType: ContractType.TEST_DRIVE_CONTRACT,
      language: customer.preferredLanguage ?? language,
      transactionId: appointment.id,
      versionComment: ELO_VERSION_COMMENT,
      versionLabel: ELO_VERSION_LABEL,
    };

    createDocument(contract, params);
  };

  const validationSchema = TestDriveContractSchema(market);

  return (
    <Formik
      initialValues={{ ...initialValues, appointmentTime: defaultAppointment }}
      validationSchema={validationSchema}
      onSubmit={(v) => {
        const appointment = testDriveAppointments.find(
          (a) => a.id === v.appointmentTime
        );
        if (!appointment) return;
        handleTestDriveSubmit(v, appointment);
      }}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <form autoComplete="on" onSubmit={handleSubmit}>
          <div className={`${BASE_CLASS}__form-input`}>
            <AdbTextInput
              id="driving_license_no"
              type="text"
              name="drivingLicenseNo"
              key="drivingLicenseNo"
              value={values?.drivingLicenseNo}
              label={t('form_fields.documents.driving_license_number')}
              onChange={handleChange}
              onBlur={handleBlur}
              schema={validationSchema}
            />
            {errors?.drivingLicenseNo && touched?.drivingLicenseNo && (
              <ErrorField errorMsg={errors?.drivingLicenseNo} />
            )}
          </div>

          <div className={`${BASE_CLASS}__form-input`}>
            <Select
              id="appointment_time"
              name="appointmentTime"
              value={values.appointmentTime}
              onSelectionChange={(value) =>
                setFieldValue('appointmentTime', value, true)
              }
              items={appointmentSelectOptions}
              label={getFieldLabel(
                validationSchema,
                'appointmentType',
                t('form_fields.documents.test_drive_appointment')
              )}
            >
              {(items) =>
                items.map((item) => <Select.Item key={item.value} {...item} />)
              }
            </Select>

            {errors?.appointmentTime && touched?.appointmentTime && (
              <ErrorField errorMsg={errors?.appointmentTime} />
            )}
          </div>

          <div className={`${BASE_CLASS}__form-input`}>
            <AdbTextInput
              id="departure_mileage"
              type="text"
              name="departureMileage"
              label={t('form_fields.document_test_drive_contract.mileage')}
              value={values?.departureMileage}
              onChange={handleChange}
              onBlur={handleBlur}
              schema={validationSchema}
            />
            {errors?.departureMileage && touched?.departureMileage && (
              <ErrorField errorMsg={errors?.departureMileage} />
            )}
          </div>

          <div className={`${BASE_CLASS}__form-checkbox`}>
            <Checkbox
              id="chargeCableHandover"
              name="chargeCableHandover"
              className={`${BASE_CLASS}__consent`}
              checked={values?.chargeCableHandover}
              onCheckedChange={(value: boolean) =>
                setFieldValue('chargeCableHandover', value, true)
              }
            >
              {t(
                'form_fields.document_test_drive_contract.charging_cables_included'
              )}
            </Checkbox>
            {errors?.chargeCableHandover && touched?.chargeCableHandover && (
              <ErrorField errorMsg={errors?.chargeCableHandover} />
            )}
          </div>

          <div className={`${BASE_CLASS}__image`}>
            <img
              src="/images/TestDriveDocuments_Picture.png"
              alt={t('form_fields.document_test_drive_contract.image')}
            />
          </div>

          <div className={`${BASE_CLASS}__form-input`}>
            <TextArea
              id="comment"
              label={t('form_fields.documents.comment')}
              caption={t('form_fields.document_test_drive_contract.comment')}
              name="comment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.comment}
            />

            {errors?.comment && touched?.comment && (
              <ErrorField errorMsg={errors?.comment} />
            )}
          </div>
          {formFooter(!agent || !outlet)}
        </form>
      )}
    </Formik>
  );
};

export default TestDriveContract;
