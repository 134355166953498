import { CustomerRegType } from '@smart/adb-shared';
import {
  Button,
  Dialog,
  Drawer,
  Flex,
  IconButton,
  Text,
} from '@smart/react-components';
import classNames from 'classnames';
import { useCustomerContext } from 'contexts/customer-context';
import { useScreenSize } from 'hooks/useScreenSize';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ListItem, UnOrderedList } from '../../atoms/List/List';
import AdbTooltip from '../AdbTooltip/AdbTooltip';
import PageNavigation from '../PageNavigation/PageNavigation';
import SmartComLinkOut from '../SmartComLinkOut/SmartComLinkOut';
import { NavItem, TopNavigationProps } from './TopNavigation.config';
import './TopNavigation.scss';

const BASE_CLASS = 'adb-top-nav';

const TopNavigation = ({
  navItems,
  activeItem,
  additionalClasses,
  darkMode,
}: TopNavigationProps) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const { customer, customerIsLoading } = useCustomerContext();

  const isPageItemActive = (item: NavItem): boolean =>
    item.pageItems !== undefined &&
    item.pageItems.length > 0 &&
    item.pageItems.some((child) => child.path === activeItem);

  const isItemActive = (item: NavItem): boolean =>
    activeItem === item.path ||
    !!activeItem?.startsWith(item.path) ||
    isPageItemActive(item);

  const havePageItems: NavItem | undefined = navItems.find((item) =>
    isPageItemActive(item)
  );

  const isSuperLiteAccount = customer?.regType === CustomerRegType.Lite;

  const shouldDisableLinkOut = (navItem: NavItem): boolean => {
    const { redirectPath, config } = navItem;

    const outletId = config?.outletId;
    const appLanguage = config?.language;
    const appMarket = config?.market;

    return (
      !appLanguage ||
      !appMarket ||
      !redirectPath ||
      !outletId ||
      isSuperLiteAccount
    );
  };

  const mobileListItems = navItems.map((item) => (
    <div key={item.name}>
      <ListItem
        content={
          <NavLink
            to={item.path}
            state={
              isSuperLiteAccount && {
                convertToFullAccount: true,
              }
            }
            className={`${BASE_CLASS}__link`}
          >
            <Drawer.Close>
              <Text
                variant="hl-100"
                as="span"
                color="c-high-contrast"
                className={classNames({
                  [`dark-theme`]: darkMode,
                })}
              >
                {t(item.title)}
              </Text>
            </Drawer.Close>
          </NavLink>
        }
      />
    </div>
  ));

  const listItems = navItems.map((item) => (
    <div key={item.name}>
      <ListItem
        content={
          item.isLinkOut ? (
            <SmartComLinkOut
              redirectPath={item.redirectPath}
              config={item.config}
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              disabled={
                shouldDisableLinkOut(item) ||
                customerIsLoading ||
                !customer ||
                item.disabled
              }
            >
              <AdbTooltip
                className={`${BASE_CLASS}__tooltip`}
                disabled={!isSuperLiteAccount}
                title={t('customer.overview.tooltip.title')}
                message={t('customer.overview.tooltip.message')}
              >
                <div
                  className={classNames(`${BASE_CLASS}__link`, {
                    [`${BASE_CLASS}__link--active`]: isItemActive(item),
                    [`${BASE_CLASS}__link--inactive`]: !isItemActive(item),
                    [`${BASE_CLASS}__link--disabled`]:
                      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                      item.disabled ||
                      shouldDisableLinkOut(item) ||
                      customerIsLoading ||
                      !customer,
                    [`dark-theme`]: darkMode,
                  })}
                >
                  <Text
                    variant="link-100"
                    as="p"
                    color="c-high-contrast"
                    className={classNames({
                      [`dark-theme`]: darkMode,
                    })}
                  >
                    {t(item.title)}
                  </Text>
                </div>
              </AdbTooltip>
            </SmartComLinkOut>
          ) : (
            <NavLink
              to={item.path}
              state={
                isSuperLiteAccount && {
                  convertToFullAccount: true,
                }
              }
              className={classNames(`${BASE_CLASS}__link`, {
                [`${BASE_CLASS}__link--active`]: isItemActive(item),
                [`${BASE_CLASS}__link--inactive`]: !isItemActive(item),
                [`${BASE_CLASS}__link--disabled`]: item.disabled,
                [`dark-theme`]: darkMode,
              })}
            >
              <Text
                variant="link-100"
                as="span"
                color="c-high-contrast"
                className={classNames({
                  [`dark-theme`]: darkMode,
                })}
              >
                {t(item.title)}
              </Text>
            </NavLink>
          )
        }
      />
    </div>
  ));

  const pageListItem = havePageItems?.pageItems && (
    <>
      <div
        className={`${BASE_CLASS}__full-w-background ${BASE_CLASS}__full-w`}
      />
      <div className={`${BASE_CLASS}__page-nav`}>
        <PageNavigation
          pageItems={havePageItems.pageItems}
          activeItem={activeItem}
        />
      </div>
    </>
  );

  const renderMobileMenuButton = () => (
    <Drawer
      title="Mobile menu"
      trigger={
        <IconButton
          variant="ghost"
          className={classNames(`${BASE_CLASS}__top-nav-hamburger-menu`, {
            [`${BASE_CLASS}-white`]: darkMode,
          })}
        >
          <Button.Icon icon="burger-menu" />
        </IconButton>
      }
    >
      <Flex
        direction="column"
        height="full"
        padding={400}
        justifyContent="space-between"
      >
        <Dialog.Headline className={`${BASE_CLASS}-hidden`}>-</Dialog.Headline>
        <Dialog.Text>
          <UnOrderedList>{mobileListItems}</UnOrderedList>
        </Dialog.Text>
        <Dialog.Close asChild>
          <Button mode={300} variant="secondary">
            {t('feature_calendar.general.buttons.close')}
          </Button>
        </Dialog.Close>
      </Flex>
    </Drawer>
  );

  return (
    <div className={classNames(BASE_CLASS, additionalClasses)}>
      <nav className={`${BASE_CLASS}__top-nav`}>
        {isMobile && !darkMode ? (
          renderMobileMenuButton()
        ) : (
          <UnOrderedList additionalClasses={`${BASE_CLASS}__top-nav-list`}>
            {listItems}
          </UnOrderedList>
        )}
      </nav>
      <hr className={`${BASE_CLASS}__full-w`} />
      {pageListItem}
    </div>
  );
};

export default TopNavigation;
