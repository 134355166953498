import { Routes } from 'routes.config';
import Stock from './index';

export const stock: Routes[] = [
  {
    path: '/stock',
    component: Stock,
    title: 'navigation.stock.stock',
  },
];
