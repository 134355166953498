import { useClickOutside } from 'hooks/useClickOutside';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import './Modal.scss';

type ModalNode = React.ReactNode;

interface ModalContextValue {
  modals: ModalNode[];
  visibleModalIds: string[];
  setVisibleModalIds: React.Dispatch<React.SetStateAction<string[]>>;
  registerModal: (modal: ModalNode) => void;
  closeModal: (onClose?: () => void) => void;
}

const ModalContext = createContext<ModalContextValue>({
  modals: [], // List of mounted modal elements
  registerModal: () => {},
  visibleModalIds: [],
  setVisibleModalIds: () => {},
  closeModal: () => {},
});

const useModal = () => useContext(ModalContext);

interface ModalComponent {
  Provider: typeof ModalProvider;
  Window: typeof ModalWindow;
}

const ModalProvider = ({ children }: PropsWithChildren) => {
  const [modals, setModals] = useState<ModalContextValue['modals']>([]);
  const [visibleModalIds, setVisibleModalIds] = useState<
    ModalContextValue['visibleModalIds']
  >([]);

  useEffect(() => {
    if (modals.length > 0) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modals, visibleModalIds]);

  const value = useMemo(() => {
    const registerModal = (modal: ModalNode) => {
      setModals((prev) => [modal, ...prev]);
    };
    const closeModal = (onClose?: () => void) => {
      setModals((prev) => prev.filter((val) => val === prev));

      if (onClose && typeof onClose === 'function') onClose();
    };
    return {
      modals,
      registerModal,
      visibleModalIds,
      setVisibleModalIds,
      closeModal,
    };
  }, [modals, visibleModalIds, setVisibleModalIds, setModals]);

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export interface ModalWindowProps extends PropsWithChildren {
  id: string;
  size?: 'medium' | 'large' | 'maximized' | 'double-medium';
  theme?: 'dark' | 'light';
}

const ModalWindow = ({
  children,
  id,
  size = 'medium',
  theme = 'light',
}: ModalWindowProps) => {
  const windowRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const {
    visibleModalIds,
    setVisibleModalIds,
    // closeModal
  } = useModal();

  useEffect(() => {
    setVisibleModalIds([...visibleModalIds, id]);

    return () => {
      setVisibleModalIds(visibleModalIds.filter((mId) => mId !== id));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useClickOutside(windowRef, () => {
    if (visibleModalIds.includes(id)) {
      // closeModal();
    }
  });

  return (
    <div
      id={id}
      role="dialog"
      className={classNames(
        'adb-modal-window',
        `adb-modal-window--${size}`,
        `${theme}-theme`
      )}
    >
      <div ref={windowRef} className="adb-modal-overlay__inner">
        {children}
      </div>
    </div>
  );
};

const Modal: FC & ModalComponent = () => {
  const { modals } = useModal();
  const hasModals = modals.length > 0;

  return (
    <div
      className={classNames(
        'adb-modal-overlay',
        hasModals && 'adb-modal-overlay--visible'
      )}
    >
      {hasModals ? modals[0] : null}
    </div>
  );
};

Modal.Provider = ModalProvider;
Modal.Window = ModalWindow;

export { Modal, useModal };
